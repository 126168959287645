import React, { useState, useEffect } from 'react';
import { useLinkClickHandler, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, TopBar, Input, ErrorCard, Navbar } from '../../../components/basic/index'
import { GreenCash, BackArrow } from '../../../assets/icons/index'
import allBenefits from '../../../components/benefits';

import './styles.scss';

const EmployeeBenefits = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [error, setError] = useState('')
    const [selected, setSelected] = useState('my')
    const [client, setClient] = useState(null)
    const [activatedBenefits, setActivatedBenefits] = useState([])

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        getClient()
    }, [])

    const getClient = async () => {
        setClient(commonStore.user.Client)
        if (commonStore.user.Client.AvailableProducts.length > 0) {
            let active = commonStore.user.Client.AvailableProducts
                .filter((item) => item.ClientHasConfirmed).map((item) => item.id)
            setActivatedBenefits(active)
        }
        forceUpdate()
    }

    const getBenefitObj = (id) => {
        return allBenefits.filter(item => id === item.id)
    }

    const handleBenefitClick = async (e) => {
        let id = e.currentTarget.id
        let ben = allBenefits.filter(item => id === item.id)

        switch (ben[0].Name) {
            case "Flexible pay":
                getFlexiblePayUser(id)
                break
            default:
                break
        }

    }

    const getFlexiblePayUser = async (id) => {
        if (!activatedBenefits.includes(id)) {
            setError("This benefit is not activated")
            return
        }
        if (!commonStore.user.IDVerification || commonStore.user.IDVerification?.length === 0) {
            setError("Your ID has not been verified, please contact support at +27 71 665 0782")
            return
        }
        commonStore.setLoading(true)

        try {
            let flexPayUser = await commonStore.getFlexiblePayUser(commonStore.user.Username)
            commonStore.setLoading(false)
            if (flexPayUser === "User not found") {
                navigate(`/employee/benefits/info?id=${id}`)
                return
            }
            //check if user and client can use service
            else if (flexPayUser.Client.Blocked) {
                console.log("Client blocked for flexible pay")
                setError("Service cannot be used at the moment.")
                return
            }
            else if (!flexPayUser.Client.AllowAdvances) {
                console.log("Client not active for flexible pay")
                setError("Service cannot be used at the moment.")
                return
            }
            else if (flexPayUser.Blocked) {
                console.log("User blocked for flexible pay")
                setError("User cannot use this service at the moment.")
                return
            }
            else if (flexPayUser.IDNumber) {
                navigate(`/employee/flexiblepay`)
                return
            }
            else {
                commonStore.setSnackBar("error", "Error occured, please try again")
                return
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }
    }

    if (isMobile()) {
        return (
            <main>
                <div className='fixed-top-div'>
                    <div style={{ marginBottom: '10px' }} className='back-arrow-btn' onClick={() => navigate("/employee/home")}>
                        <img src={BackArrow} />
                        <div>Back</div>
                    </div>
                    <header className='employee-dash-header'>My benefits</header>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ width: '100%' }}>
                            <div onClick={() => setSelected('my')} className={`employee-dash-benefits-option ${selected === 'my' && 'selected'}`}>
                                My benefits
                            </div>
                            {selected === 'my' && <hr className='employee-dash-benefits-option-hr' />}
                        </div>
                        <div style={{ width: '100%' }}>
                            <div onClick={() => setSelected('explore')} className={`employee-dash-benefits-option ${selected === 'explore' && 'selected'}`}>
                                Explore
                            </div>
                            {selected === 'explore' && <hr className='employee-dash-benefits-option-hr' />}
                        </div>
                    </div>
                </div>

                <br />
                {selected === "explore" &&
                    <div className='flex-column' style={{ gap: '10px' }}>
                        {allBenefits.map((ben) => (
                            <div className='employee-dash-explore-benefit-container'>
                                <div className='flex flex-row justify-between w-full items-center'>
                                    <div style={{ color: '#010143', fontSize: '14px', fontWeight: 600 }}>{ben.Name}</div>
                                    <img src={ben.image} className='employee-dash-benefit-img' />
                                </div>
                                <div className='confirm-employee-benefits-benefit-description-card-description-text-container'>
                                    <p className='confirm-employee-benefits-benefit-description-card-type-text'>
                                        {ben.description}
                                    </p>
                                </div>
                                {activatedBenefits.length > 0 && activatedBenefits.includes(ben.id) &&
                                    <div style={{ padding: '5px 8px', borderRadius: '10px', background: '#E3FFE2', marginLeft: 'auto' }}>
                                        <div style={{ color: '#2CB573', fontSize: '12px', fontWeight: 500 }}>Active</div>
                                    </div>
                                }
                            </div>

                        ))
                        }
                    </div>
                }
                {selected === "my" &&
                    <div className='flex-column' style={{ gap: '10px' }}>
                        {error !== "" && <ErrorCard type='error' message={error} />}
                        {client && client.AvailableProducts.map((ben) => (
                            <div className='employee-dash-my-benefit-container' key={ben.id} id={ben.id} onClick={handleBenefitClick}>
                                <img src={getBenefitObj(ben.id)[0].image} style={{ width: '110px' }} />
                                <div style={{ color: '#010143', fontSize: '14px', fontWeight: 600 }}>{getBenefitObj(ben.id)[0].shortDescription}</div>
                            </div>
                        ))
                        }
                    </div>
                }
            </main>
        );
    } else {
        return (
            <div>
                <TopBar />
                <main style={{ alignItems: 'center' }}>
                    <header className='employee-benefits-assessment-submit-header'>Benefits</header>
                </main>
            </div>
        );
    }
})

export default EmployeeBenefits
