import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { TopBar, ErrorCard, Button } from '../../../components/basic/index'
import { LevelShopGraphic } from '../../../assets/icons/index'
import allBenefits from '../../../components/benefits';

import './styles.scss';

const EmployeeShop = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    if (isMobile()) {
        return (
            <main>
                <div className='fixed-top-div'>
                    <br />
                    <header className='employee-dash-header'>Level Finance Shop</header>
                    <br />
                    <div>
                        <img style={{height: '60vh'}} src={LevelShopGraphic} />
                    </div>
                    <br />
                    <div>
                        <Button
                            width='100%'
                            onClick={() => window.open('https://www.getlevelfinance.com/', '_blank')}>
                            Start shopping!
                        </Button>
                    </div>
                </div>
                <br />
            </main>
        );
    } else {
        return (
            <div>
                <TopBar />
                <main style={{ alignItems: 'center' }}>
                    <header className='employee-benefits-assessment-submit-header'>Shop</header>
                </main>
            </div>
        );
    }
})

export default EmployeeShop
