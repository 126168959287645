import React, { useState } from 'react';
import { LevelBlueWhiteLogo, DropDownArrowDown, GreyProfileIcon, LogoutIconWhite, ProfileIconWhite } from '../../assets/icons';
import { useStore } from '../../stores/Store';
import './styles.scss'
import { useNavigate } from 'react-router-dom';

const TopBar = () => {

    const commonStore = useStore()
    const navigate = useNavigate()

    return (
        <div style={{ position: 'sticky', top: 0, background: '#ffffff', zIndex: 10 }} className='admin-dash-topbar'>
            <img src={LevelBlueWhiteLogo} />
        </div>
    );
};

export default TopBar;
