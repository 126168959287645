import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler
} from 'chart.js';

// Register the required components
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    ChartDataLabels

);

const DoughnutChartBenefitsTotalPerc = ({ report, color, entity, id }) => {
    const overallPercOptions = {
        plugins: {
            tooltip: {
                enabled: false
            },
            datalabels: {
                display: false
            }
        }
    }
    const overallPercChartData = {
        datasets: [
            {
                data: [report[`${entity}TotalPerc`], 100 - report[`${entity}TotalPerc`]],
                backgroundColor: [
                    color,
                    'rgba(232 185 254 / 15%)',
                ],
            },
        ],
    };

    return <div style={{ width: '300px', height: '300px', float: 'left', position: 'relative' }} id={id}>
        <div className='benefits-report-donut-perc-text purple'
            style={{ width: '100%', height: '40px', position: 'absolute', top: '50%', left: '0', marginTop: '-23px', lineHeight: '19px', textAlign: 'center', zIndex: '100' }}>
            <br />
            {`${Math.round(report[`${entity}TotalPerc`])} %`}
        </div>
        <Doughnut data={overallPercChartData} options={overallPercOptions} />
    </div>

};

export default DoughnutChartBenefitsTotalPerc;
