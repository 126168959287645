const BaselineAssessmentQuestions = {
    Demographic: {
        category: "Demographic",
        question: "Which demographic do you fall within?",
        zuluQuestion: "Iyiphi iminyaka ozalelwe kuyo?",
        sesothoQuestion: "Ke leloko lefe la lilemo le o leng ho lona?",
        answerPrefix: "",
        options: [
            { answer: "Gen Z (1997-2012)", score: null, zuluAnswer: "Gen Z (1997-2012)", sesothoAnswer: "Gen Z (1997-2012)" },
            { answer: "Millennial (1981-1996)", score: null, zuluAnswer: "Millennial (1981-1996)", sesothoAnswer: "Millennial (1981-1996)" },
            { answer: "Gen X (1965-1980)", score: null, zuluAnswer: "Gen X (1965-1980)", sesothoAnswer: "Gen X (1965-1980)" },
            { answer: "Baby Boomers (1946-1964)", score: null, zuluAnswer: "Baby Boomers (1946-1964)", sesothoAnswer: "Baby Boomers (1946-1964)" },
        ],
    },

    EmploymentLength: {
        category: "EmploymentLength",
        question: "How long have you been employed?",
        zuluQuestion: "Sekuyisikhathi esingakanani usebenza?",
        sesothoQuestion: "O ntse o sebetsa nako e kae?",
        answerPrefix: "",
        options: [
            { answer: "Less than a year", score: null, zuluAnswer: "Ngaphansi konyaka", sesothoAnswer: "Ka tlase ho selemo" },
            { answer: "1-3 years", score: null, zuluAnswer: "1-3 iminyaka", sesothoAnswer: "1-3 lilemo" },
            { answer: "3-6 years", score: null, zuluAnswer: "3-6 iminyaka", sesothoAnswer: "3-6 lilemo" },
            { answer: "6-10 years", score: null, zuluAnswer: "6-10 iminyaka", sesothoAnswer: "6-10 lilemo" },
            { answer: "10-15 years", score: null, zuluAnswer: "10-15 iminyaka", sesothoAnswer: "10-15 lilemo" },
            { answer: "15+ years", score: null, zuluAnswer: "15 iminyaka noma ngaphezulu", sesothoAnswer: "15 lilemo kapa ho feta" },
        ],
    },

    HouseholdBudget: {
        category: "HouseholdBudget",
        question: "Does your household have a budget?",
        zuluQuestion: "Umndeni wakho unohlelo lwezimali?",
        sesothoQuestion: "Na lelapa la heno le na le moralo wa ditjhelete?",
        answerPrefix: "",
        options: [
            { answer: "Yes", score: null, zuluAnswer: "Yebo", sesothoAnswer: "E" },
            { answer: "No", score: null, zuluAnswer: "Cha", sesothoAnswer: "Che" },
            { answer: "Maybe", score: null, zuluAnswer: "Mhlawumbe", sesothoAnswer: "Mohlomong" },
        ],
    },

    HouseholdBudgetDiscipline: {
        category: "HouseholdBudgetDiscipline",
        question: "Does your household stick to your budget?",
        zuluQuestion: "Umndeni wakho uyakugcina yini uhlelo lwezimali?",
        sesothoQuestion: "Na lelapa la heno le latela moralo wa lona wa ditjhelete?",
        answerPrefix: "",
        options: [
            { answer: "Yes", score: null, zuluAnswer: "Yebo", sesothoAnswer: "E" },
            { answer: "No", score: null, zuluAnswer: "Cha", sesothoAnswer: "Che" },
        ],
    },

    Spend: [
        {
            category: "Spend",
            question: "In the past year, did your household spend more or less than what you earned?",
            zuluQuestion: "Ngonyaka odlule, ingabe umndeni wakho uchithe imali eningi noma encane kunaleyo eniyiholile?",
            sesothoQuestion: "Selemong se fetileng, na lelapa la heno le sebelitse ho feta kapa ka tlase ho seo le se fumaneng?",
            answerPrefix: "",
            options: [
                { answer: "We spent a lot less than we earned.", score: 100, zuluAnswer: "Sichithe imali encane kakhulu kunaleyo esiyiholile", sesothoAnswer: "Re sebelitse ka tlase haholo ho seo re se fumaneng" },
                { answer: "We spent a little less than we earned.", score: 75, zuluAnswer: "Sichithe imali encane kancane kunaleyo esiyiholile", sesothoAnswer: "Re sebelitse hanyane ka tlase ho seo re se fumaneng" },
                { answer: "We spent about the same as we earned.", score: 50, zuluAnswer: "Sichithe imali efanayo naleyo esiyiholile", sesothoAnswer: "Re sebelitse hoo e ka bang se lekanang le seo re se fumaneng" },
                { answer: "We spent a little more than we earned.", score: 25, zuluAnswer: "Sichithe imali eningi kancane kunaleyo esiyiholile", sesothoAnswer: "Re sebelitse hanyane ho feta seo re se fumaneng" },
                { answer: "We spent a lot more than we earned.", score: 0, zuluAnswer: "Sichithe imali eningi kakhulu kunaleyo esiyiholile", sesothoAnswer: "Re sebelitse haholo ho feta seo re se fumaneng" },
            ],
        },
        {
            category: "Spend",
            question: "In the past year, how did your household manage to pay its bills?",
            zuluQuestion: "Ngonyaka odlule, umndeni wakho ukhombise kanjani ukukhokhela izikweletu?",
            sesothoQuestion: "Selemong se fetileng, lelapa la heno le ile la lefa ditefello jwang?",
            answerPrefix: "",
            options: [
                { answer: "We paid all our bills on time.", score: 100, zuluAnswer: "Sikwazile ukukhokha zonke izikweletu ngesikhathi", sesothoAnswer: "Re lefisitse ditefello tsohle ka nako" },
                { answer: "We paid almost all our bills on time.", score: 60, zuluAnswer: "Sikwazile ukukhokha cishe zonke izikweletu ngesikhathi", sesothoAnswer: "Re lefisitse hoo e batlang e le ditefello tsohle ka nako" },
                { answer: "We paid most of our bills on time.", score: 40, zuluAnswer: "Sikwazile ukukhokha iningi lezikweletu ngesikhathi", sesothoAnswer: "Re lefisitse boholo ba ditefello tsa rona ka nako" },
                { answer: "We paid only some of our bills on time.", score: 20, zuluAnswer: "Sikwazile ukukhokha ezinye izikweletu ngesikhathi", sesothoAnswer: "Re lefisitse tse ding tsa ditefello ka nako" },
                { answer: "We paid very few of our bills on time.", score: 0, zuluAnswer: "Sikwazile ukukhokha izikweletu ezimbalwa kakhulu ngesikhathi", sesothoAnswer: "Re lefisitse feela tse seng kae ka nako" },
            ],
        },
    ],

    Save: [
        {
            category: "Save",
            question: "If you only had the money you have now (without borrowing or using savings for retirement), how long could your household pay for everything?",
            zuluQuestion: "Uma ungabe usebenzisa imali oyiholayo kuphela (ngaphandle kokuboleka noma ukusebenzisa imali yokuthatha umhlalaphansi), ingabe umndeni wakho ungaphila isikhathi esingakanani?",
            sesothoQuestion: "Haeba o ne o na le tjhelete eo o nang le yona jwale feela (ntle le ho alima kapa ho sebelisa tjhelete e bolokilweng bakeng sa penshene), lelapa la heno le ka phela nako e kae?",
            answerPrefix: "",
            options: [
                { answer: "6 months or more.", score: 100, zuluAnswer: "Izinyanga eziyisi-6 noma ngaphezulu", sesothoAnswer: "Dikgwedi tse 6 kapa ho feta" },
                { answer: "3-5 months.", score: 75, zuluAnswer: "Izinyanga ezi-3 kuya kwezi-5", sesothoAnswer: "Dikgwedi tse 3 ho isa ho tse 5" },
                { answer: "1-2 months.", score: 50, zuluAnswer: "Izinyanga ezi-1 kuya kwezi-2", sesothoAnswer: "Dikgwedi tse 1 ho isa ho tse 2" },
                { answer: "1-3 weeks.", score: 25, zuluAnswer: "Amaviki angu-1 kuya kwayi-3", sesothoAnswer: "Dibeke tse 1 ho isa ho tse 3" },
                { answer: "Less than 1 week.", score: 0, zuluAnswer: "Ngaphansi kweviki elilodwa", sesothoAnswer: "Ka tlase ho beke e le 1" },
            ],
        },
        {
            category: "Save",
            question: "How sure are you that your household is doing enough to save money for future needs (like saving for a vacation, a house, or retirement)?",
            zuluQuestion: "Ungazizwa kanjani ngokuthi umndeni wakho wenza okwanele yini ukonga imali yezidingo zangomuso (njengokonga iholide, ukuthenga ikhaya, noma ukuthatha umhlalaphansi)?",
            sesothoQuestion: "O tiisitse hakae hore lelapa la heno le boloka tjhelete e lekaneng bakeng sa ditlhoko tsa nakong e tlang (joalo ka ho boloka bakeng sa phomolo, ntlo, kapa penshene)?",
            answerPrefix: "",
            options: [
                { answer: "Very sure.", score: 100, zuluAnswer: "Ngiyakwazi kakhulu", sesothoAnswer: "Ke tiisitse haholo" },
                { answer: "Somewhat sure.", score: 75, zuluAnswer: "Ngiyakwazi kancane", sesothoAnswer: "Ke tiisitse hanyane" },
                { answer: "A little sure.", score: 50, zuluAnswer: "Ngiyakwazi kancane kakhulu", sesothoAnswer: "Ke tiisitse hanyane haholo" },
                { answer: "Not very sure.", score: 25, zuluAnswer: "Angikwazi kahle", sesothoAnswer: "Ha ke tiisitse hakaalo" },
                { answer: "Not sure at all.", score: 0, zuluAnswer: "Angikwazi neze", sesothoAnswer: "Ha ke tiisitse ho hang" },
            ],
        },
    ],

    Borrow: [
        {
            category: "Borrow",
            question: "How do you feel about your household’s debt (like loans, medical bills, or money you owe others)?",
            zuluQuestion: "Uzwani ngesikweletu somndeni wakho (njengezikweletu zokuboleka, izikweletu zezempilo, noma imali eniyikweleta abanye)?",
            sesothoQuestion: "O ikutlwa jwang ka dikoloto tsa lelapa la heno (joalo ka dikoloto tsa kalimo, ditefello tsa bongaka, kapa tjhelete eo o e kolotang ba bang)?",
            answerPrefix: "",
            options: [
                { answer: "We have no debt.", score: 100, zuluAnswer: "Asinayo imali esiyikweletayo", sesothoAnswer: "Ha re na dikoloto" },
                { answer: "We can manage our debt.", score: 85, zuluAnswer: "Singakwazi ukukhokha izikweletu zethu", sesothoAnswer: "Re khona ho laola dikoloto tsa rona" },
                { answer: "We have a bit more debt than we can manage.", score: 40, zuluAnswer: "Sinezikweletu ezingaphezulu kancane kunalokho esingakwazi ukukhokha", sesothoAnswer: "Re na le dikoloto tse fetang hanyane ho seo re ka se laolang" },
                { answer: "We have much more debt than we can manage.", score: 0, zuluAnswer: "Sinezikweletu ezingaphezu kakhulu kwalokho esingakwazi ukukhokha", sesothoAnswer: "Re na le dikoloto tse ngata haholo ho feta seo re ka se laolang" },
            ],
        },
        {
            category: "Borrow",
            question: "How would you describe your credit score?",
            zuluQuestion: "Uchaza kanjani izinga lakho lesikweletu?",
            sesothoQuestion: "O ka hlalosa boemo ba mokitlane wa hao jwang?",
            answerPrefix: "",
            options: [
                { answer: "Excellent.", score: 100, zuluAnswer: "Lihle kakhulu", sesothoAnswer: "E ntle haholo" },
                { answer: "Very good.", score: 80, zuluAnswer: "Lihle", sesothoAnswer: "E ntle haholo" },
                { answer: "Good.", score: 60, zuluAnswer: "Lihle kancane", sesothoAnswer: "E ntle" },
                { answer: "Fair.", score: 40, zuluAnswer: "Lingaphakathi", sesothoAnswer: "E kgolo" },
                { answer: "Poor.", score: 20, zuluAnswer: "Libi", sesothoAnswer: "E tlase" },
                { answer: "I don't know what my credit score is.", score: 0, zuluAnswer: "Angazi izinga lami lesikweletu", sesothoAnswer: "Ha ke tsebe boemo ba mokitlane wa ka" },
            ],
        },
    ],

    Plan: [
        {
            category: "Plan",
            question: "How sure are you that the insurance your household has (like health, car, or life insurance) will help in an emergency?",
            zuluQuestion: "Ungazizwa kanjani ngokuthi iziqinisekiso zomshwalense womndeni wakho zingasekela ngesikhathi sezimo eziphuthumayo?",
            sesothoQuestion: "O tiisitse hakae hore inshorense eo lelapa la heno le nang le yona (joalo ka inshorense ya bophelo, koloi, kapa bophelo) e tla thusa ha ho e-na le kotsi?",
            answerPrefix: "",
            options: [
                { answer: "Very sure.", score: 100, zuluAnswer: "Ngiyazethemba kakhulu", sesothoAnswer: "Ke tiisitse haholo" },
                { answer: "Somewhat sure.", score: 75, zuluAnswer: "Ngiyazethemba kancane", sesothoAnswer: "Ke tiisitse hanyane" },
                { answer: "A little sure.", score: 50, zuluAnswer: "Ngiyazethemba kancane kakhulu", sesothoAnswer: "Ke tiisitse hanyane haholo" },
                { answer: "Not very sure.", score: 25, zuluAnswer: "Angizethembi kahle", sesothoAnswer: "Ha ke tiisitse hakaalo" },
                { answer: "Not sure at all.", score: 10, zuluAnswer: "Angizethembi neze", sesothoAnswer: "Ha ke tiisitse ho hang" },
                { answer: "We don’t have any insurance.", score: 0, zuluAnswer: "Asinawo umshwalense", sesothoAnswer: "Ha re na inshorense" },
            ],
        },
        {
            category: "Plan",
            question: 'Do you agree or disagree with this statement: "My household saves and plans for the future"?',
            zuluQuestion: 'Uvumelana noma awuvumelani nalesi sitatimende: "Umndeni wami uhlela izimali zesikhathi esizayo"?',
            sesothoQuestion: 'Na o dumellana kapa ha o dumellane le polelo ena: "Lelapa la ka le boloka le ho rera bakeng sa bokamoso"?',
            answerPrefix: "",
            options: [
                { answer: "Strongly agree.", score: 100, zuluAnswer: "Ngiyavumelana kakhulu", sesothoAnswer: "Ke dumellana haholo" },
                { answer: "Somewhat agree.", score: 65, zuluAnswer: "Ngiyavumelana kancane", sesothoAnswer: "Ke dumellana hanyane" },
                { answer: "Neither agree nor disagree.", score: 35, zuluAnswer: "Angivumelani noma angiphikisani", sesothoAnswer: "Ha ke na qeto" },
                { answer: "Somewhat disagree.", score: 15, zuluAnswer: "Ngiphikisana kancane", sesothoAnswer: "Ke hanela hanyane" },
                { answer: "Strongly disagree.", score: 0, zuluAnswer: "Ngiphikisana kakhulu", sesothoAnswer: "Ke hanela haholo" },
            ],
        },
    ],

    HouseholdIncome: {
        category: "HouseholdIncome",
        question: "What is your approximate annual household income?",
        zuluQuestion: "Uyithola malini ngonyaka umndeni wakho?",
        sesothoQuestion: "Ke lekanye lefe la moputso wa lelapa la hao wa selemo?",
        answerPrefix: "",
        options: [
            { answer: "Less than 50,000 net per annum", score: null, zuluAnswer: "Ngaphansi kuka-50,000 ngonyaka", sesothoAnswer: "Ka tlase ho 50,000 ka selemo" },
            { answer: "50,000 - 150,000 net per annum", score: null, zuluAnswer: "50,000 - 150,000 ngonyaka", sesothoAnswer: "50,000 - 150,000 ka selemo" },
            { answer: "150,000 - 300,000 net per annum", score: null, zuluAnswer: "150,000 - 300,000 ngonyaka", sesothoAnswer: "150,000 - 300,000 ka selemo" },
            { answer: "300,000 - 500,000 net per annum", score: null, zuluAnswer: "300,000 - 500,000 ngonyaka", sesothoAnswer: "300,000 - 500,000 ka selemo" },
            { answer: "500,000+", score: null, zuluAnswer: "500,000+", sesothoAnswer: "500,000+" },
        ],
    },

    FinancialStress: {
        category: "FinancialStress",
        question: "Over the last 12 months, how much stress, if any, did your finances cause you?",
        zuluQuestion: "Ezinyangeni eziyi-12 ezedlule, ingakanani ingcindezi imali edalile kuwe?",
        sesothoQuestion: "Dikgweding tse 12 tse fetileng, na ditjhelete tsa hao di bile le tshusumetso e kae ho wena?",
        answerPrefix: "",
        options: [
            { answer: "High stress", score: null, zuluAnswer: "Ingcindezi enkulu", sesothoAnswer: "Kgohlelo e hodimo" },
            { answer: "Moderate", score: null, zuluAnswer: "Esimaphakathi", sesothoAnswer: "E ka tlase hanyane" },
            { answer: "Some stress", score: null, zuluAnswer: "Ingxenye encane yengcindezi", sesothoAnswer: "E nyane" },
            { answer: "None", score: null, zuluAnswer: "Ayikho", sesothoAnswer: "Ha ho letho" },
        ],
    },

    FinancialStressImpact: {
        category: "FinancialStressImpact",
        question: "Over the last 12 months, how often did financial stress impact your daily life?",
        zuluQuestion: "Ezinyangeni eziyi-12 ezedlule, kangaki ingcindezi yezimali ithinte ukuphila kwakho kwansuku zonke?",
        sesothoQuestion: "Dikgweding tse 12 tse fetileng, ditjhelete di bile le tshusumetso e kae bophelong ba hao ba letsatsi le letsatsi?",
        answerPrefix: "",
        options: [
            { answer: "Very often", score: null, zuluAnswer: "Kuvame kakhulu", sesothoAnswer: "Ka kgafetsa haholo" },
            { answer: "Often", score: null, zuluAnswer: "Kuvame", sesothoAnswer: "Ka kgafetsa" },
            { answer: "Seldom", score: null, zuluAnswer: "Kuqhamuka kancane", sesothoAnswer: "Ka sewelo" },
            { answer: "Never", score: null, zuluAnswer: "Akukaze kwenzeke", sesothoAnswer: "Ha ho mohla" },
        ],
    },
};

export default BaselineAssessmentQuestions;
