import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { EmployeeBottomNav } from '../../components/basic/index'
import { SuccessIcon } from '../../assets/icons/index'
import NonResponsivePage from '../nonResponsive';

import EmployeeHome from './home';
import EmployeeBenefits from './benefits';
import EmployeeShop from './shop'

import './styles.scss';

const EmployeeDash = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const { section } = useParams()

    const [error, setError] = useState('')
    const [selected, setSelected] = useState(section ? section : "home")
    const location = useLocation();
    const refresh = location.state?.refresh || false

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        if (section) {
            setSelected(section)
        }
    }, [section])

    useEffect(() => {
        if (refresh) {
            getUser()
        }
    }, [refresh])

    const getUser = async () => {
        try {
            commonStore.setLoading(true)

            let user = await commonStore.getUserByUsername(commonStore.user.Username)
            commonStore.setLoading(false)
            if (user) {
                commonStore.setUser(user)
            }
            forceUpdate()

        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.logout()
            navigate('./signin')
        }
    }

    const handleSetPage = (page) => {
        navigate(`/employee/${page}`)
        setSelected(page)
        forceUpdate()
    }

    if (isMobile()) {
        return (
            <div>
                <main style={{ minHeight: '100vh' }}>
                    {selected === "home" &&
                        <EmployeeHome />
                    }
                    {selected === "benefits" &&
                        <EmployeeBenefits />
                    }
                    {selected === "shop" &&
                        <EmployeeShop setPage={handleSetPage} />
                    }
                </main>
                <EmployeeBottomNav setPage={handleSetPage} />
            </div>
        );
    } else {
        return (
            <div>
                <NonResponsivePage/>
            </div>
        );
    }
})

export default EmployeeDash
