import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Link } from 'react-router-dom';
import { Button, TopBar, ErrorCard, Input } from '../../components/basic/index'
import { AndroidInstallPrompt, IOSInstallPrompt } from '../../shared/components';
import { LandingImage } from '../../assets/icons';

import './styles.scss';

const SignIn = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [webauthn, setWebauthn] = useState(true);
    const [usePassword, setUsePassword] = useState(false);
    const [displayPrompt, setDisplayPrompt] = useState(true);
    const [device, setDevice] = useState('');
    const [prompt, setPrompt] = useState({});
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    function closeInstallPrompt() {
        // let { data } = state;
        setDisplayPrompt(false);
    }

    function installPromptInstall() {
        // let { data } = state;
        prompt.prompt();
        setDisplayPrompt(false);
    }

    useEffect(() => {
        if (typeof window.PublicKeyCredential !== 'undefined') {
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available) {
                    //Webauthn Available
                    setWebauthn(true);
                } else {
                    //Webauthn not available
                    setWebauthn(false);
                }
            }).catch((e) => {
                //Webauthn not available
                setWebauthn(false);
            })
        } else {
            setWebauthn(false);
        }

        if (isMobile()) { //Install Prompt

            if (window.matchMedia('(display-mode: standalone)').matches) {
                //If already running PWA
                return
            }
            const ua = window.navigator.userAgent;
            if (!!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Mac/i)) {
                //iOS
                loadIosPrompt();
            } else {
                //Android
                window.addEventListener('beforeinstallprompt', (event) => {
                    console.log('[beforeinstallprompt] Called');
                    // Prevent Chrome <= 67 from automatically showing the prompt
                    event.preventDefault();
                    // Stash the event so it can be triggered later.
                    setPrompt(event);
                    // Update the install UI to notify the user app can be installed
                    // document.querySelector('#install-button').disabled = false;
                    loadPrompt();
                });

            }
        }
    }, []);

    function loadPrompt() {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            //If already running PWA
            return
        }
        const ua = window.navigator.userAgent;

        if (!!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Mac/i)) {
            //iOS
            // data.device = 'iOS';
            // setStateData({ data: data});
            return
        } else {
            //Android
            setDevice('Android');
        }
    }

    function loadIosPrompt() {
        setDevice('iOS');
    }

    const handleUsernameOnChange = (value) => {
        setUsePassword(false)
        setUsername(value)
        setError('')
    }

    const handlePasswordOnChange = (value) => {
        setPassword(value)
        setError('')
    }

    async function signIn(e) {
        e.preventDefault()
        setError('')
        let id = username;
        let pass = password;
        try {
            if (id && id.length > 6) {
                if (pass && pass.length > 0) {
                    let valid = await commonStore.passwordLogin(id, pass)

                    if (valid === 'Blocked') {
                        setError('This account has been blocked, please contact support at +27 71 665 0782.')
                        commonStore.setLoading(false);
                        return
                    }
                    if (valid === 'Client deactivated') {
                        setError('Apologies, currently cannot access this service')
                        commonStore.setLoading(false);
                        return
                    }

                    else if (valid) {
                        commonStore.setLoading(false);
                        if (commonStore.user.Role === "5d8e0b079f51d42bbece9202" || commonStore.user.Role === "5d8e0b079f51d42bbece9002") {
                            navigate('/employee/home', { page: 'Dashboard' });
                        } else if (commonStore.user.Role === "5d8e0b079f51d42bbece9201") {
                            navigate('/admin/home/Overview');
                        } else if (commonStore.user.Role === '62bd55b7c5793941427c702d') {
                            navigate('/client/home/Overview');
                        }

                    } else {
                        commonStore.setLoading(false);
                        setError('Invalid login details')
                        return
                    }
                } else {
                    commonStore.setLoading(false);
                    setError('Invalid login details')
                    return
                }
            } else {
                console.log("ID length less 6")
                commonStore.setLoading(false);
                setError('Please enter a valid username')
                return
            }
        } catch (error) {
            console.log("Error occured", error)
            commonStore.setLoading(false);
            setError('Error occured, please try again')
            return
        }
    }

    const UsePassword = () => {
        setUsePassword(false);
        setWebauthn(false);
        if (!commonStore.passwordAvailable) {
            resetPassword();
        }
    }

    const resetPassword = () => {
        console.log("Ran reset password")
        if (username.length < 6) {
            setError('Please enter a valid username')
            return
        }
        commonStore.setLoading(true);
        commonStore.generatePasswordReset(username).then((ret) => {
            if (ret.status === true) {
                commonStore.setLoading(false);
                commonStore.setSnackBar("info", "A password reset link has been sent to your work email")
                return
            } else {
                commonStore.setLoading(false);
                commonStore.setSnackBar("error", "Error occured, please try again")
                return
            }
        });
    }
    const handleShowPassword = () => {
        let opp = !showPassword
        setShowPassword(opp)
    }

    async function checkWebauthn(e) {
        e.preventDefault()
        let id = username;

        if (!id || id.length < 6) {
            console.log("Username length less than 6")
            setError('Invalid login details')
            return false;
        }
        try {
            commonStore.setLoading(true);
            let ret = await commonStore.checkWebauthn(id)
            if (ret === "nf") {
                console.log(`${id}User not found`)
                setError('User not found')
                commonStore.setLoading(false);
                return
            } if (ret === "nr") {
                console.log(`${id} User not registered`)
                setError('Please register your profile')
                commonStore.setLoading(false);
                return
            } else if (ret === 'Blocked') {
                console.log(`${id}User blocked`)
                setError('This account has been blocked, please contact support at +27 71 665 0782.')
                commonStore.setLoading(false);
                return
            } else if (ret === 'Client deactivated') {
                console.log(`${id} Client is deactivated`)
                setError('Apologies, currently cannot access this service')
                commonStore.setLoading(false);
                return
            } else if (ret === 'Terminated') {
                console.log(`${id}User is terminated`)
                setError('This user cannot use this service, please contact support at +27 71 665 0782.')
                commonStore.setLoading(false);
                return
            } else if (ret === "error") {
                console.log(`${id} Error occured getting checking webauth`)
                setUsePassword(true);
                setError('Invalid login details')
                commonStore.setLoading(false);
                return
            }
            else if (ret) {
                console.log(`${id} User found`)
                commonStore.setLoading(false);
                if (commonStore.user.Role === "5d8e0b079f51d42bbece9202" || commonStore.user.Role === "5d8e0b079f51d42bbece9002") {
                    navigate('/employee/home', { page: 'Dashboard' });
                } else if (commonStore.user.Role === "5d8e0b079f51d42bbece9201") {
                    navigate('/admin/home/Overview');
                } else if (commonStore.user.Role === '62bd55b7c5793941427c702d') {
                    navigate('/client/home/Overview');
                }
            } else {
                commonStore.setLoading(false);
                setWebauthn(false)
            }
        }
        catch (e) {
            commonStore.setLoading(false);
            console.error(e)
            setError('Error occured, please try again later')
        }
    }

    const verifyEmployment = async () => {
        //check if labournet and sage employees are still employed
        let user = commonStore.user
        let payrollInfo = user.PayrollInformation || false
        let lastPayrollUpdate = user.LastPayrollUpdate || false
        let employmentStatusPending = user.EmploymentStatus === "Pending" || false
        let payrollUpdatedThisMonth = false
        let currentMonth = new Date().getMonth()
        if (lastPayrollUpdate) {
            let lastPayrollUpdateMonth = new Date(lastPayrollUpdate).getMonth()
            if (lastPayrollUpdateMonth === currentMonth) payrollUpdatedThisMonth = true
        }
        if (payrollInfo) {
            if ((payrollInfo.Provider === "Labour net" || payrollInfo.Provider === "LabourNet") && !payrollUpdatedThisMonth && employmentStatusPending) {
                let verifyLabour = await verifyLabournetEmployee()
                // employee is terminated - Labournet
                if (verifyLabour === false) {
                    setError("Employee cannot use this service")
                    return false
                }
                else return true
            }
            if ((payrollInfo.Provider === "Sage 300" || payrollInfo.Provider === "Sage300") && !payrollUpdatedThisMonth && employmentStatusPending) {
                let verifySage = await verifySageEmployee()
                // employee is terminated - Sage 300
                if (verifySage === false) {
                    setError("Employee cannot use this service")
                    return false
                }
                else return true
            }
        } else return true
    }

    const verifyLabournetEmployee = async () => {
        let res = await commonStore.updateLabournetEmployee(commonStore.user.Client._id, commonStore.user.Username)
        if (res?.status === true) {
            if (res.response.EmploymentStatus === "Terminated") return false
            else if (res.response.EmploymentStatus === "Employed") return true
            else return false
        }
        else return false
    }

    const verifySageEmployee = async () => {
        let res = await commonStore.updateSageEmployee(commonStore.user.Client._id, commonStore.user.Username)
        if (res?.status === true) {
            if (res.response.EmploymentStatus === "Terminated") return false
            else if (res.response.EmploymentStatus === "Employed") return true
            else return false
        }
        else return false
    }

    if (isMobile()) {
        return (
            <div>
                <TopBar />
                <main style={{ justifyContent: 'center', padding: '30px', marginTop: "5vh" }}>
                    <header className='signin-main-header'>Welcome to Level</header>
                    <p className='employee-benefits-assessment-submit-subheader' style={{ marginRight: 'auto', fontSize: '15px', color: '#010143a8' }}>
                        Wellness benefits that support employees in moments that matter.
                    </p>
                    {webauthn ?
                        <div className='signin-inputs-container'>
                            <div className='flex-column'>
                                <label className='signin-input-label'>ID number or passport / Email</label>
                                <input className='signin-input-item'
                                    onChange={(e) => handleUsernameOnChange(e.target.value)}
                                    value={username} width='100%' placeholder={"ID Number / Email"}
                                />
                            </div>
                            {
                                error !== "" &&
                                <ErrorCard type='error' message={error} />
                            }
                            <button className='signin-sign-in-btn' onClick={checkWebauthn}>Sign In</button>
                            {usePassword &&
                                <button className='signin-sign-in-btn' onClick={() => UsePassword()}>Use Password</button>
                            }
                            <button className='signin-register-in-btn' onClick={() => { navigate('/signup') }}>Register</button>

                        </div>
                        //NOT WEBAUTH
                        :
                        <div className='signin-inputs-container'>
                            <div className='flex-column'>
                                <label className='signin-input-label'>ID number or passport / Email</label>
                                <input className='signin-input-item'
                                    onChange={(e) => handleUsernameOnChange(e.target.value)}
                                    value={username} width='100%' placeholder={"ID Number / Email"}
                                />
                            </div>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Password</label>
                                    <label style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        className='signin-input-label'
                                        onClick={handleShowPassword}>Show</label>
                                </div>
                                <input className='signin-input-item'
                                    onChange={(e) => handlePasswordOnChange(e.target.value)}
                                    value={password} width='100%' placeholder={"Please enter your password"}
                                    type={`${showPassword ? 'text' : 'password'}`}
                                />
                            </div>
                            {
                                error !== "" &&
                                <ErrorCard type='error' message={error} />
                            }
                            <a className='forgot-password-text' onClick={() => resetPassword()}>Forgot password?</a>
                            <button className='signin-sign-in-btn' onClick={signIn}>Signin</button>
                            <div style={{ margin: 'auto', fontFamily: 'Inter' }}>OR</div>
                            <button className='signin-register-in-btn' onClick={() => { navigate('/signup') }}>Register</button>
                        </div>

                    }
                    <div className='disclamer-text'>
                        By using Level you agree to our <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> & <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    </div>
                </main>
            </div>
        );
    } else {
        return (
            <div style={{ display: 'flex' }}>
                <div>
                    <img src={LandingImage} style={{ height: '100vh' }} />
                </div>
                <main style={{ justifyContent: 'center', padding: '35px 200px 0px 100px' }}>
                    <header className='signin-main-header'>Welcome to Level</header>
                    <p className='employee-benefits-assessment-submit-subheader' style={{ marginRight: 'auto', fontSize: '15px', color: '#010143a8' }}>
                        Wellness benefits that support employees in moments that matter.
                    </p>
                    {webauthn ?
                        <div className='signin-inputs-container'>
                            <form className='flex-column' onSubmit={checkWebauthn}>
                                <label className='signin-input-label'>ID number or passport / Email</label>
                                <input className='signin-input-item'
                                    onChange={(e) => handleUsernameOnChange(e.target.value)}
                                    value={username} width='100%' placeholder={"ID Number / Email"}
                                />
                            </form>
                            <button className='signin-sign-in-btn' onClick={checkWebauthn}>Sign In</button>
                            {usePassword &&
                                <button className='signin-sign-in-btn' onClick={() => UsePassword()}>Use Password</button>
                            }
                            <button className='signin-register-in-btn' onClick={() => { navigate('/signup') }}>Register</button>
                        </div>
                        :
                        <form onSubmit={signIn} className='signin-inputs-container'>
                            <div className='flex-column'>
                                <label className='signin-input-label'>ID number or passport / Email</label>
                                <input className='signin-input-item'
                                    onChange={(e) => handleUsernameOnChange(e.target.value)}
                                    value={username} width='100%' placeholder={"ID Number / Email"}
                                />
                            </div>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Password</label>
                                    <label style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        className='signin-input-label'
                                        onClick={handleShowPassword}>Show</label>
                                </div>
                                <input className='signin-input-item'
                                    onChange={(e) => handlePasswordOnChange(e.target.value)}
                                    value={password} width='100%' placeholder={"Please enter your password"}
                                    type={`${showPassword ? 'text' : 'password'}`}
                                />
                            </div>

                            <a className='forgot-password-text' onClick={() => resetPassword()}>Forgot password?</a>
                            <button className='signin-sign-in-btn' onClick={signIn}>Signin</button>
                            <div style={{ margin: 'auto', fontFamily: 'Inter' }}>OR</div>
                            <button className='signin-register-in-btn' onClick={() => { navigate('/signup') }}>Register</button>
                        </form>

                    }
                    {error !== "" &&
                        <ErrorCard type='error' message={error} />}
                    <div className='disclamer-text'>
                        By using Level you agree to our <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> & <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    </div>
                </main>
            </div>
        );
    }
})

export default SignIn
