import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Navbar, Input, Button } from '../../../components/basic/index'
import { BlueAddIcon } from '../../../assets/icons/index'
import ClientBenefitsReport from './benefitsReport';
import { AutohideSnackbar } from '../../../components/MUI';

import './styles.scss';
const XLSX = require('xlsx');

const ClientAdminAssessments = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [client, setClient] = useState({});
    const [visible, setVisible] = useState("home")
    const [assessmentTaken, setAssessmentTaken] = useState(false)
    const [assessLinkCopied, setAssessLinkCopied] = useState(false)
    const [benefitsReport, setBenefitsReport] = useState(null)

    const URL = process.env.REACT_APP_FRONTEND_URL

    useEffect(() => {
        reset()
        getAssessments()
    }, [])

    const getClient = async() => {
        try {
            commonStore.setLoading(true)
            let latest = await commonStore.getClientByObjectId(commonStore.user.Client._id)
            commonStore.setLoading(false)
            if (latest) {
                commonStore.setClient(latest)
                await getAssessments()
            }
            else commonStore.setSnackBar("error", "Error occured, please try again")
        }
        catch(e) {
            console.error(e)
        }
    }

    const getAssessments = async () => {
        try {
            setClient(commonStore.client)
            if (client?.BenefitsAssessment?.EmployerAssessment) {
                setAssessmentTaken(true)
            }
            if (client?.BenefitsAssessment?.OptToSendToEmployees) {
                commonStore.setLoading(true)
                let ret = await commonStore.getJointBenefitsReport(client._id)
                commonStore.setLoading(false)
                if (!ret) {
                    console.log("Error retrieving benefits reports")
                    commonStore.setSnackBar("error", "Error occured")
                    return
                } else {
                    setBenefitsReport(ret)
                    return
                }
            } else {
                console.log("Didnt opt")
                return
            }
        } catch (error) {
            console.error(error)
            commonStore.setSnackBar("error", "Error occured")
        }
        commonStore.setLoading(false)

    }

    const handleOnCreateAssessmentClick = () => {

        navigate({ pathname: `/client/benefits/assessments`, search: client._id })
    }

    const reset = () => {
        forceUpdate()
    };

    const copyAssessLink = (link) => {
        navigator.clipboard.writeText(link)
        setAssessLinkCopied(true)
    }

    const handleViewBenefitsReport = async () => {
        try {
            commonStore.setLoading(true);

            if (benefitsReport !== null) {
                commonStore.setLoading(false);
                setBenefitsReport(benefitsReport);
                setVisible("benefits-report");
            } else {
                if (client.BenefitsAssessment.OptToSendToEmployees) {
                    const ret = await commonStore.getJointBenefitsReport(client._id);
                    commonStore.setLoading(false);
                    if (!ret) {
                        commonStore.setSnackBar("error", "Error occured, please try again")
                        return;
                    }
                    setBenefitsReport(ret);
                    setVisible("benefits-report");
                } else {
                    commonStore.setLoading(false);
                    commonStore.setSnackBar("error", "Error occured, no employees have completed assessment")
                }
            }
        } catch (error) {
            commonStore.setLoading(false);
            console.error("Error fetching benefits report:", error);
            commonStore.setSnackBar("error", "Error occured, please try again")
        }
    };

    const handleBackToSingleEmployer = () => {
        setVisible("home")
    }

    if (isMobile()) {
        return (
            <div>
                <Navbar />
                <main>
                    <div>

                    </div>

                </main>
            </div>
        );
    } else {
        return (
            <div style={{ background: "#F4F5FA", width: '100%', height: '100%' }}>
                {/* MAIN SECTION */}
                {visible === "home" &&
                    <main>
                        <header className='admin-dash-option-header'>{`Assessments`}</header>
                        <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <header className='admin-dash-sub-header'>Benefits assessment</header>
                                <header className='admin-dash-sub-header'><span><i style={{ cursor: 'pointer' }} onClick={getClient} class="fa-solid fa-arrow-rotate-right"></i></span></header>
                            </div>
                            <p>{`${assessmentTaken ? "Assessment completed" : "Benefits assessments not completed"}`}</p>
                            {client.BenefitsAssessment ?
                                <>
                                    <div className='admin-dash-client-assessments-section'>
                                        <div className='admin-dash-client-details-text small'>
                                            {`Date taken: 
                                                ${client.BenefitsAssessment.EmployerAssessment.DateTaken ?
                                                    client.BenefitsAssessment.EmployerAssessment.DateTaken : "Not set"}`}
                                        </div>
                                        {client.BenefitsAssessment.EmployeeAssessments &&
                                            <div className='admin-dash-blue-clients-text normal'
                                                onClick={handleViewBenefitsReport}>
                                                {`View benefits report`}
                                            </div>
                                        }
                                        <br />
                                    </div>
                                    {
                                        client.BenefitsAssessment.OptToSendToEmployees &&
                                        <>
                                            <br />
                                            {client.BenefitsAssessment.EmployeeAssessments ?
                                                <p>{`${client.BenefitsAssessment.EmployeeAssessments.length} employee(s) have taken the assessment [Deadline: ${commonStore.formatDate(client.BenefitsAssessment.Deadline) }]`}</p>
                                                :
                                                <p>{`0 employees have taken the assessment [Deadline: ${commonStore.formatDate(client.BenefitsAssessment.Deadline)}]`}</p>

                                            }
                                            <div className='admin-dash-client-details-text small'>
                                                EMPLOYEE assessment link
                                            </div>

                                            {(new Date() < new Date(client.BenefitsAssessment.Deadline)) ?
                                                <div className='admin-dash-blue-clients-text normal'
                                                onClick={() => copyAssessLink(`${URL}/employees/benefits/assessment?cli=${client._id}`)}>
                                                {`${assessLinkCopied ? "Copied!" : "Copy to clipboard"}`}
                                            </div> :
                                                <div className='admin-dash-client-details-text small' style={{color: 'red'}}>
                                                    Deadline passed for employee assessments
                                                </div>
                                            }
                                        </>

                                    }
                                    <br />
                                </>
                                :
                                //IF NO ASSESSMENT SUBMITTED

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', gap: '2px' }}>
                                        <img src={BlueAddIcon} style={{ width: '16px' }} />
                                        <div className='admin-dash-blue-clients-text'
                                            style={{ fontSize: '12px' }}
                                            onClick={() => handleOnCreateAssessmentClick()}>
                                            {`Create new assessment`}
                                        </div>
                                    </div>
                                </div>

                            }
                        </main>
                    </main>
                }
                {visible === "benefits-report" &&
                    <div id="adminBenefitsReport">
                        <ClientBenefitsReport
                            data={{ client: client, report: benefitsReport }}
                            handleBackToSingleEmployer={handleBackToSingleEmployer}
                        />
                    </div>
                }

            </div>
        );
    }
})

export default ClientAdminAssessments
