import React, { useState, useEffect } from 'react';
import { useLinkClickHandler, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, TopBar, Input, ErrorCard } from '../../../components/basic/index'
import { AssessmentIcon } from '../../../assets/icons/index'

import './style.scss';

const VerifyId = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [error, setError] = useState('')

    const [contact, setContact] = useState('');
    const [tries, setTries] = useState(0);
    const [Question, setQuestion] = useState({});
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [questionSet, setQuestionSet] = useState('');
    const [questionsAvailable, setQuestionsAvailable] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [errorType, setErrorType] = useState('');

    function contactChanged(e) {
        setError('')
        setContact(e.target.value);
    }

    function confirmNumber(e) {
        e.preventDefault()

        if (tries >= 3) {
            return
        }
        if (contact.length < 10) {
            setError('Please enter valid contact number')
            return
        }
        commonStore.setLoading(true);
        commonStore.getQuestions(contact).then((ret) => {
            if (ret === 'No Results Found') {
                console.log('No results found for IDNumber');
                navigate('/idverificationfailed');
                commonStore.setLoading(false);
                return
            }
            if (!ret) {
                //console.log('Failed to fetch questions');
                setError('An error occured, please try again later.');
                commonStore.setLoading(false);
                return //No questions
            }
            //console.log(ret);
            setError('');
            setQuestion(ret.Question);
            let qsts = []
            let ansr = [];
            for (let i = 0; i < ret.QuestionDocument.length; i++) {
                let questionObject = {
                    question: ret.QuestionDocument[i].Question,
                    answers: ret.QuestionDocument[i].Answers,
                    questionId: ret.QuestionDocument[i].ProductAuthenticationQuestionID
                }
                qsts.push(questionObject);
                ansr.push(-1);

            }
            setQuestions(qsts);
            setAnswers(ansr);
            setCurrentQuestion(0);
            setQuestionSet(ret.questionSetId);
            setQuestionsAvailable(true);
            commonStore.setLoading(false);
            return
        })


    }

    function answerSelected(e) {
        setError('');
        let questionNum = parseInt(e.target.id.substring(0, 1));
        let answerNum = parseInt(e.target.id.slice(-1));
        let ans = answers
        ans[questionNum] = answerNum;
        setAnswers(ans);
        forceUpdate();

    }

    function verifyAnswers() {

        for (let i = 0; i < answers.length; i++) {
            if (answers[i] === -1) {
                return false
            }
        }
        return true
    }

    function nextQuestion() {
        setCurrentQuestion(currentQuestion + 1);
    }

    function submitAnswers() {
        let verified = verifyAnswers();

        if (!verified) {
            setError('Please complete all answers.');
            return
        }
        let questionObject = Question;
        commonStore.setLoading(true);
        for (let i = 0; i < answers.length; i++) {
            questionObject.Result.QuestionDocument[i].Answers.AnswerDocument[answers[i]].IsEnteredAnswerYN = true;
        }

        commonStore.validateQuestions(questionSet, questionObject).then((ret) => {
            if (ret) {
                commonStore.setLoading(false);
                console.log("Answered correctly")
                //update user

                navigate('/employee/account/secure');
                return
            } else {
                let trs = tries + 1;
                setTries(tries + 1);
                if (trs < 3) {
                    setError('Unable to verify your identity, please try again.');
                    setQuestionsAvailable(false);
                    setQuestions([]);
                    setQuestionSet('');
                    setAnswers([]);
                    setQuestions({});
                    setCurrentQuestion(0);
                    commonStore.setLoading(false);
                    return
                } else {
                    commonStore.setLoading(false);
                    navigate('/idverificationfailed');
                    return
                }
            }
        });
    }

    useEffect(() => {
    }, [])

    if (isMobile()) {
        return (
            <div>
                <TopBar />
                <main style={{ alignItems: 'center' }}>
                    <img src={AssessmentIcon} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                    <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                        Verify your identity
                    </p>
                    <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                        To verify your identity we will require you to do answer a few a questions.
                    </p>
                    {(!questionsAvailable) ?
                        <form style={{ width: '100%' }} onSubmit={confirmNumber}>
                            <input className='signin-input-item'
                                type='text'
                                id='contact'
                                value={contact}
                                placeholder='Contact number'
                                onChange={contactChanged}
                                style={{ width: '100%' }}
                            />
                            <br />

                            {error.length > 0 ? <ErrorCard type='error' message={error} /> : null}
                            <p className='signin-sign-in-btn' block size="lg" onClick={confirmNumber}>Next</p>
                        </form> :

                        <form style={{ overflow: 'scroll' }}>
                            {questions.map((quest, i) => {
                                if (currentQuestion !== i) {
                                    return
                                }
                                return (
                                    <div key={i} style={{ display: 'flex', gap: '7px', flexDirection: 'column' }}>
                                        <hr style={{ width: '100%' }} />
                                        <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                                            {quest.question}
                                        </p>
                                        <div onChange={answerSelected}>
                                            {quest.answers.AnswerDocument.map((ans, j) => {
                                                return (
                                                    <div key={`a${j}`} style={{ margin: 'auto' }}>
                                                        <input className="checkbox-tools" name={`answer-${i}`} type="radio" id={`${i}-answer-${j}`} value={JSON.stringify(ans)} />
                                                        <label style={{ lineHeight: '1.5' }} htmlFor={`${i}-answer-${j}`}>{ans.Answer}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                            {(currentQuestion === (questions.length - 1) && answers[(questions.length - 1)] !== -1) ?
                                <p className='signin-sign-in-btn' onClick={submitAnswers}>Submit answers</p> :
                                null
                            }
                            {(currentQuestion < (questions.length - 1) && answers[currentQuestion] !== -1) ?
                                <p className='signin-sign-in-btn' onClick={nextQuestion}>Next question</p> :
                                null
                            }


                        </form>}
                </main>
            </div>
        );
    } else {
        return (
            <div>
                <TopBar />
                <main style={{ alignItems: 'center' }}>
                    <img src={AssessmentIcon} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                    <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                        Verify your identity
                    </p>
                    <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                        To verify your identity we will require you to do answer a few a questions.
                    </p>
                    {(!questionsAvailable) ?
                        <form style={{ width: '100%' }} onSubmit={confirmNumber}>
                            <input className='signin-input-item'
                                type='text'
                                id='contact'
                                value={contact}
                                placeholder='Contact number'
                                onChange={contactChanged}
                                style={{ width: '100%' }}
                            />
                            <br />

                            {error.length > 0 ? <ErrorCard type='error' message={error} /> : null}
                            <p className='signin-sign-in-btn' block size="lg" onClick={confirmNumber}>Next</p>
                        </form> :

                        <form style={{ overflow: 'scroll' }}>
                            {questions.map((quest, i) => {
                                if (currentQuestion !== i) {
                                    return
                                }
                                return (
                                    <div key={i} style={{ display: 'flex', gap: '7px', flexDirection: 'column' }}>
                                        <hr style={{ width: '100%' }} />
                                        <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                                            {quest.question}
                                        </p>
                                        <div onChange={answerSelected}>
                                            {quest.answers.AnswerDocument.map((ans, j) => {
                                                return (
                                                    <div key={`a${j}`} style={{ margin: 'auto' }}>
                                                        <input className="checkbox-tools" name={`answer-${i}`} type="radio" id={`${i}-answer-${j}`} value={JSON.stringify(ans)} />
                                                        <label style={{ lineHeight: '1.5' }} htmlFor={`${i}-answer-${j}`}>{ans.Answer}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                            {(currentQuestion === (questions.length - 1) && answers[(questions.length - 1)] !== -1) ?
                                <p className='signin-sign-in-btn' onClick={submitAnswers}>Submit answers</p> :
                                null
                            }
                            {(currentQuestion < (questions.length - 1) && answers[currentQuestion] !== -1) ?
                                <p className='signin-sign-in-btn' onClick={nextQuestion}>Next question</p> :
                                null
                            }
                        </form>}
                </main>
            </div>
        );
    }
})

export default VerifyId
