import React from 'react';
import MultipleChoice from "../multipleChoice";
import BaselineAssessmentQuestions from "./employeeBaselineAssessmentQuestions";

const EmployeeBaseLineAssessment = ({ questionNumber, onOptionChange, language }) => {
    // Destructure questions from the imported BaselineAssessmentQuestions
    const { Demographic, EmploymentLength, HouseholdBudget, HouseholdBudgetDiscipline, Spend, Save, Borrow, Plan, HouseholdIncome, FinancialStress, FinancialStressImpact } = BaselineAssessmentQuestions;

    // Determine which question to display based on the questionNumber prop
    let selectedQuestion;
    switch (questionNumber) {
        case 1:
            selectedQuestion = Demographic;
            break;
        case 2:
            selectedQuestion = EmploymentLength;
            break;
        case 3:
            selectedQuestion = HouseholdBudget;
            break;
        case 4:
            selectedQuestion = HouseholdBudgetDiscipline;
            break;
        case 5:
            selectedQuestion = Spend[0]
            break;
        case 6:
            selectedQuestion = Spend[1]
            break;
        case 7:
            selectedQuestion = Save[0]
            break;
        case 8:
            selectedQuestion = Save[1];
            break;
        case 9:
            selectedQuestion = Borrow[0]
            break;
        case 10:
            selectedQuestion = Borrow[1]
            break;
        case 11:
            selectedQuestion = Plan[0]
            break;
        case 12:
            selectedQuestion = Plan[1]
            break;
        case 13:
            selectedQuestion = HouseholdIncome;
            break;
        case 14:
            selectedQuestion = FinancialStress;
            break;
        case 15:
            selectedQuestion = FinancialStressImpact;
            break;
        default:
            // Handle unexpected question numbers
            console.error(`Unexpected question number: ${questionNumber}`);
            return null; // Don't render anything in case of an error
    }

    let questionKey = 'question'
    if (language === 'isiZulu') questionKey = 'zuluQuestion'
    if (language === 'sesotho') questionKey = 'sesothoQuestion'
    let selectedQuestionText = selectedQuestion[questionKey]
    selectedQuestionText = selectedQuestionText?.length > 0 ? selectedQuestionText : selectedQuestion['question']
    const { answerPrefix, options: selectedQuestionOptions, category } = selectedQuestion;

    return (
        <div>
            <MultipleChoice
                question={selectedQuestion.question}
                questionText={selectedQuestionText}
                answerPrefix={answerPrefix}
                questionNumber={questionNumber}
                options={selectedQuestionOptions}
                onOptionChange={onOptionChange}
                category={category}
                language={language}
            />
        </div>
    );
};

export default EmployeeBaseLineAssessment;
