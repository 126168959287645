import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Link } from 'react-router-dom';
import { Button, Navbar } from '../../components/basic/index';
import ClientAdminAssessments from './assessments';
import ClientAdminEmployees from './user';
import ConfirmClientBenefits from './benefits/confirm'
import ClientBenefits from './benefits/consume';
import ClientInvoices from './invoices';
import NonResponsivePage from '../nonResponsive';

import {
    LevelBlueWhiteLogo,
    SideBarOverviewIcon,
    SideBarBenefitsIcon,
    SideBarSettingsIcon,
    SideBarUsersIcon,
    SideBarReportsIcon,
    GreyProfileIcon,
    DropDownArrowDown,
    LogoutIcon,
    OrangeHourGlass
} from '../../assets/icons'

import './styles.scss';


const ClientAdminHome = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const user = commonStore.user
    const { section } = useParams()

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const location = useLocation();
    const refresh = location.state?.refresh;

    const [sideBarOptionClicked, setSideBarOptionClicked] = useState(section ? section : "Overview")
    const [client, setClient] = useState(null)
    const [displayDash, setDisplayDash] = useState(false)
    const [agreementSigned, setAgreementSigned] = useState(false)
    const [payrollLinked, setPayrollLinked] = useState(false)
    const [productsSelected, setProductsSelected] = useState(false)
    const [assessmentComplete, setAssessmentComplete] = useState(false)
    const [userPermissions, setUserPermissions] = useState(commonStore.user.Permissions || [])

    useEffect(() => {
        getClient()
    }, [])

    useEffect(() => {
        getClient()
    }, [refresh])

    useEffect(() => {
        setSideBarOptionClicked(section)
    }, [section])

    const getClient = async () => {
        if (refresh) {
            commonStore.setLoading(true)
            let usr = await commonStore.getUserByUsername(commonStore.user.Username)
            commonStore.setLoading(false)
            if (usr) {
                commonStore.setClient(usr.Client)
                dashChecks(usr.Client)
            }
            else {
                commonStore.logout()
                navigate('/*')
            }
        }
        else {
            commonStore.setClient(user.Client)
            dashChecks(user.Client)
        }

    };

    const dashChecks = async (cli) => {
        commonStore.setLoading(true);
        try {
            let productsAvail = false;
            if (cli && cli._id === user.Client._id) {
                productsAvail = cli.AvailableProducts.length > 0;
                if (productsAvail) {
                } else {
                    console.log("No selected products array");
                }
                await getAgreement("Privacy policy", cli);
            } else {
                throw new Error("Client ID mismatch or invalid client data");
            }
            setProductsSelected(productsAvail);
        } catch (error) {
            console.error("Error during dashboard checks:", error);
            commonStore.logout();
            navigate('/signin');
            alert("Error occurred");
        } finally {
            commonStore.setLoading(false);
            setDisplayDash(true)
        }
    };

    const getAgreement = async (type, cli) => {
        try {
            commonStore.setLoading(true)
            const res = await commonStore.getAgreementsByClient(user.Client._id);
            let localAgreementSigned = false;

            if (Array.isArray(res) && res.length > 0) {
                res.forEach((item) => {
                    if (item.Type === type) {
                        localAgreementSigned = true;
                        if (cli.PayrollInformation?.Verified) {
                            setPayrollLinked(true);
                        }
                        if (cli.BenefitsAssessment) {
                            setAssessmentComplete(true);
                        }
                    }
                });
            }

            setAgreementSigned(localAgreementSigned);

            if (!localAgreementSigned) {
                navigate('/client/agreement');
            }
        } catch (error) {
            console.error("Error fetching agreements:", error);
            alert("Error occurred");
        } finally {
            commonStore.setLoading(false);
        }
    };


    const handleSideBarOptionsOnClick = (e) => {
        let id = e.currentTarget.id
        if (id === "Benefits") {
            id = "Benefits-Consume"
            let products = commonStore.client.AvailableProducts
            if (products.length > 0) {
                products.forEach((item) => {
                    if (!item.ClientHasConfirmed) {
                        //if atleast one benefit has not been confirmed. force to confirm? not sure about this logic. LOG IT
                        id = "Benefits-Confirm"
                    }
                })
            }

        }
        navigate(`/client/home/${id}`)
    }

    if (isMobile()) {
        return (
            <div>
                <NonResponsivePage/>
            </div>
        );
    } else {
        return (
            <div>
                {/* TOP BAR */}
                <Navbar />
                <div style={{ display: "flex" }}>
                    {/* SIDE BAR */}
                    {!productsSelected ? <div id="admin-dash-sidebar">
                        <section className='admin-dash-sidebar-container'>
                            <div className='admin-dash-sidebar-container-items-container'>
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarOverviewIcon} />
                                    <div
                                        id='Overview'
                                        className={`admin-dash-sidebar-text`}
                                    >Overview</div>
                                </div>
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarBenefitsIcon} />
                                    <div
                                        id='Benefits'
                                        className={`admin-dash-sidebar-text  grayed`}
                                    >Benefits</div>
                                </div>
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarBenefitsIcon} />
                                    <div
                                        id='Assessments'
                                        className={`admin-dash-sidebar-text grayed`}
                                    >Assessments</div>
                                </div>
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarUsersIcon} />
                                    <div
                                        id='Employees'
                                        className={`admin-dash-sidebar-text grayed`}
                                    >Employees</div>
                                </div>
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarReportsIcon} />
                                    <div
                                        id='Invoices'
                                        className={`admin-dash-sidebar-text grayed`}
                                    >Invoices</div>
                                </div>
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={LogoutIcon} />
                                    <div onClick={() => { commonStore.logout(); navigate('/signin'); }}
                                        className={`admin-dash-sidebar-text`}
                                    >Logout</div>
                                </div>
                            </div>

                        </section>
                    </div> : <div id="admin-dash-sidebar">
                        <section className='admin-dash-sidebar-container'>
                            <div className='admin-dash-sidebar-container-items-container'>
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarOverviewIcon} style={{ filter: sideBarOptionClicked === 'Overview' ? 'brightness(0) saturate(100%)' : 'none' }} />
                                    <div onClick={handleSideBarOptionsOnClick}
                                        id='Overview'
                                        className={`admin-dash-sidebar-text ${sideBarOptionClicked === 'Overview' ? 'bold' : null}`}
                                    >Overview</div>
                                </div>
                                {userPermissions.includes("view-benefits") &&
                                    <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                        <img src={SideBarBenefitsIcon} style={{ filter: sideBarOptionClicked.split("-")[0] === 'Benefits' ? 'brightness(0) saturate(100%)' : 'none' }} />
                                        <div onClick={handleSideBarOptionsOnClick}
                                            id='Benefits'
                                            className={`admin-dash-sidebar-text ${sideBarOptionClicked.split("-")[0] === 'Benefits' ? 'bold' : null}`}
                                        >Benefits</div>
                                    </div>
                                }
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarBenefitsIcon} style={{ filter: sideBarOptionClicked === 'Assessments' ? 'brightness(0) saturate(100%)' : 'none' }} />
                                    <div onClick={handleSideBarOptionsOnClick}
                                        id='Assessments'
                                        className={`admin-dash-sidebar-text ${sideBarOptionClicked === 'Assessments' ? 'bold' : null}`}
                                    >Assessments</div>
                                </div>
                                {userPermissions.includes("view-users") &&
                                    <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                        <img src={SideBarUsersIcon} style={{ filter: sideBarOptionClicked === 'Employees' ? 'brightness(0) saturate(100%)' : 'none' }} />
                                        <div onClick={handleSideBarOptionsOnClick}
                                            id='Employees'
                                            className={`admin-dash-sidebar-text ${sideBarOptionClicked === 'Employees' ? 'bold' : null}`}
                                        >Employees</div>
                                    </div>
                                }
                                {userPermissions.includes("view-invoices") &&
                                    <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarReportsIcon} style={{ filter: sideBarOptionClicked === 'Invoices' ? 'brightness(0) saturate(100%)' : 'none' }} />
                                    <div onClick={handleSideBarOptionsOnClick}
                                        id='Invoices'
                                        className={`admin-dash-sidebar-text ${sideBarOptionClicked === 'Invoices' ? 'bold' : null}`}
                                    >Invoices</div>
                                </div>}
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={LogoutIcon} />
                                    <div onClick={() => { commonStore.logout(); navigate('/signin'); }}
                                        className={`admin-dash-sidebar-text ${sideBarOptionClicked === 'Logout' ? 'bold' : null}`}
                                    >Logout</div>
                                </div>
                            </div>

                        </section>
                    </div>}
                    {/* MAIN SECTION */}
                    {sideBarOptionClicked === 'Overview' &&
                        displayDash ? <div style={{ height: '90vh', width: '100%', background: "#F4F5FA", overflowY: 'scroll' }}>
                        <div style={{ width: '100%' }}>
                            <main>
                                <header className='admin-dash-option-header'>{`Overview`}</header>
                                <main style={{ padding: '35px' }}>
                                    {/* TOP DIV */}

                                    {!assessmentComplete ?
                                        <div className='employer-dash-overview-benefits-container'>
                                            <div className='admin-dash-sub-header'>
                                                Benefits
                                            </div>
                                            <p>You have not completed the Employer benefits assessment yet.</p>
                                            <Button onClick={() => navigate({ pathname: `/client/benefits/assessments`, search: commonStore.user.Client._id })} className='employer-dash-overview-benefits-button'>
                                                Start assessment
                                            </Button>
                                        </div> : null
                                    }
                                    <br />
                                    {!productsSelected ?
                                        <div style={{ background: '#fff', borderRadius: '24px', padding: "20px" }}>
                                            <div className='admin-dash-sub-header'>
                                                Benefits
                                            </div>
                                            <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                                                <img src={OrangeHourGlass} />
                                                <p>We are still processing your benefits and will let you know as soon as they are ready.</p>
                                            </div>
                                        </div> : null
                                    }
                                    <br />
                                    {!payrollLinked ?
                                        <div style={{ background: '#fff', borderRadius: '24px', padding: "20px", gap:'8px', display:'flex', flexDirection: 'column' }}>
                                            <div className='admin-dash-sub-header'>
                                                Payroll information
                                            </div>
                                            <p>Connect to your payroll to load employee data.</p>
                                            <Button width="fit-content" type='purpleInvis' onClick={() => navigate('/client/activate/payroll')}>
                                                Connect payroll
                                            </Button>
                                        </div> : null
                                    }

                                </main>
                            </main>
                        </div>
                    </div> : null

                    }
                    {sideBarOptionClicked === 'Assessments' &&
                        <div style={{ height: '90vh', width: '100%', overflowY: 'scroll' }}>
                            <ClientAdminAssessments />
                        </div>
                    }
                    {sideBarOptionClicked === 'Employees' &&
                        <div style={{ height: '90vh', width: '100%', overflowY: 'scroll' }}>
                            <ClientAdminEmployees />
                        </div>
                    }
                    {sideBarOptionClicked === 'Benefits-Confirm' &&
                        <div style={{ height: '90vh', width: '100%', overflowY: 'scroll' }}>
                            <ConfirmClientBenefits />
                        </div>
                    }
                    {sideBarOptionClicked === 'Benefits-Consume' &&
                        <div style={{ height: '90vh', width: '100%', overflowY: 'scroll' }}>
                            <ClientBenefits />
                        </div>
                    }
                    {sideBarOptionClicked === 'Invoices' &&
                        <div style={{ height: '90vh', width: '100%', overflowY: 'scroll' }}>
                            <ClientInvoices />
                        </div>
                    }
                </div>

            </div>
        );
    }
})

export default ClientAdminHome
