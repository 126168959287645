import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { ErrorCard } from '../../../components/basic';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import './styles.scss';
import { common } from '@mui/material/colors';

const EmployeeCheckout = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [cart, setCart] = useState([])
    const [authMethod, setAuthMethod] = useState('webauth')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        getCartItems()
    }, [commonStore.cart])

    const getCartItems = () => {
        setCart(commonStore.cart)
        forceUpdate()
    }

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to remove this item, this cannot be undone")) {
            commonStore.removeCartItem(id)
            forceUpdate()
        }
    }

    const handleCheckout = async (e) => {
        e.preventDefault()
        if (window.confirm(`Are you sure you want to checkout these ${cart.length} item(s)`)) {
            commonStore.setLoading(true)
            let auth = false
            //authenticate
            if (authMethod === 'webauth') {
                try {
                    let authenticate = await commonStore.authenticateTransactionWebauthn()
                    if (authenticate === 'Password') {
                        setAuthMethod('password')
                        commonStore.setLoading(false);
                    } else if (authenticate === true) {
                        auth = true
                    } else {
                        setError('Authentication Failed');
                        commonStore.setLoading(false);
                    }
                } catch (error) {
                    console.error(error)
                    commonStore.setLoading(false)
                    setError("Error occured, please try again")
                    return
                }
            }
            else if (authMethod === "password") {
                let ret = await checkPassword()
                if (ret) {
                    auth = true
                }
            }

            if (auth) {
                cart.forEach((item) => {
                    if (item.Product === "Flexible pay") {
                        //check flexible pay
                        requestBankAdvance(item)
                    }
                })
            }
        }
    }

    const checkPassword = async () => {
        if (!password || password.length < 5) {
            setError("Invalid password")
            return
        }
        try {
            commonStore.setLoading(true)
            const ret = await commonStore.authenticateTransactionPassword(password)
            if (ret === true) {
                commonStore.setLoading(false);
                return true
            } else {
                setError('Authentication Failed');
                commonStore.setLoading(false);
                return false
            }
        } catch (error) {
            console.error(error)
            setError('Authentication Failed');
            commonStore.setLoading(false);
            return false
        }

    }

    const requestBankAdvance = async (advance) => {
        try {
            let data = {
                amount: parseInt(advance.Amount),
                bank: advance.BankAccount
            }
            // return true
            commonStore.setLoading(true)
            let res = await commonStore.bankAccountAdvance(commonStore.user.Username, data)
            commonStore.setLoading(false)

            if (res.marketplace && res.flexiblePay) {
                setError("Success")
                navigate('/employee/checkout/success')
                return true
            }
            else {
                commonStore.setLoading(false)
                setError("Error occured, please try again")
                return false
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            setError("Error occured, please try again")
            return false
        }
    }

    const handlePasswordChange = (e) => {
        setError("")
        setPassword(e.currentTarget.value)
    }

    if (isMobile()) {
        return (
            <div>
                <main style={{ minHeight: '100vh' }}>
                    <header className='employee-dash-header'>Your cart</header>
                    <div onClick={() => navigate(-1)}>Back</div>
                    {cart.length > 0 ?
                        cart.map((crt) => (
                            <>
                                <div className='cart-container' key={crt.Id} id={crt.Id}>
                                    <div className='cart-container-item'>
                                        <p>{`Product: `}<span>{crt.Product}</span></p>
                                    </div>
                                    <div className='cart-container-item'>
                                        <p>{`Description: `}<span>{crt.Description}</span></p>
                                    </div>
                                    <div className='cart-container-item'>
                                        <p>{`Amount: `}<span>{`R ${crt.Amount}`}</span></p>
                                    </div>
                                    <div className='cart-container-item'>
                                        <p>{`Total amount( + fee): `}<span>{`R ${crt.TotalAmount}`}</span></p>
                                    </div>
                                    <div className='cart-container-item'>
                                        <p>{`Deduction: `}<span>{crt.Deduction}</span></p>
                                    </div>
                                    <div className='cart-container-item'>
                                        <p>{`Payout to: `}<span>{`${crt.BankAccount.BankName} ... ${crt.BankAccount.AccountNumber.slice(-4)}`}</span></p>
                                    </div>
                                    <div className='cart-container-item'>
                                        <p><span>{crt.NextPay}</span></p>
                                    </div>
                                    <p style={{ color: 'red' }} onClick={() => handleDelete(crt.Id)}>Remove</p>
                                </div>
                                <br />
                            </>

                        ))
                        :
                        <div>
                            <p>No items in cart</p>
                        </div>
                    }
                    {authMethod === "password" &&
                        <>
                            <p>Please authenticate to checkout your items</p>
                            <form onSubmit={handleCheckout}>
                                <TextField
                                    style={{ fontFamily: "Inter" }}
                                    fullWidth
                                    value={password}
                                    onChange={handlePasswordChange}
                                    id="outlined-password-input"
                                    label="Password"
                                    type="password"
                                // autoComplete="current-password"
                                />
                            </form>
                        </>

                    }
                    {error && <ErrorCard type='error' message={error} />}
                    {cart.length > 0 &&
                        <button className='flexible-pay-dash-btn' onClick={handleCheckout}>{"Check out -->"}</button>
                    }
                </main>
            </div>
        );
    } else {
        return (
            <div>
                <main style={{ alignItems: 'center' }}>
                    <header className='employee-benefits-assessment-submit-header'>Checkout</header>
                </main>
            </div>
        );
    }
})

export default EmployeeCheckout
