import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const UserDoughnutChart = ({ current, total, width = '200px', height = '200px', currentColor, totalColor }) => {
    const data = {
        // labels: ['Active', 'Total'],
        datasets: [
            {
                label: 'Users',
                data: [current.length, total.length - current.length],
                backgroundColor: [currentColor, totalColor],
                hoverBackgroundColor: [currentColor, totalColor],
            },
        ],
    };

    const options = {
        plugins: {
            tooltip: {
                enabled: false
            },
            datalabels: {
                display: false
            }
        }
    };
    const percentage = (current.length / total.length) * 100

    return (
        <div style={{ width: width, height: height, float: 'left', position: 'relative' }}>
            <div className='benefits-report-donut-perc-text purple'
                style={{ width: '100%', height: '40px', position: 'absolute', top: '50%', left: '0', lineHeight: '19px', textAlign: 'center', zIndex: '100', fontSize: '20px' }}>
                {`${percentage.toFixed(2)} %`}
            </div>
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default UserDoughnutChart;
