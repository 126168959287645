import axios from 'axios';
import storageUtil from '../stores/storageUtil';

// #region Config
let middleware_base = process.env.REACT_APP_MIDDLEWARE_URL;
axios.defaults.baseURL = middleware_base;

const getAccessToken = () => {
  const loginToken = (storageUtil.hasItem('tkn')) ? storageUtil.getItem('tkn') : '';
  return loginToken;
};
// #endregion

// #region Axios Middleware
let middlewareRequest = axios.create({
  baseUrl: middleware_base,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': "*",
    Accept: 'application/json',
  }
});

let middlewareFormDataRequest = axios.create({
  baseUrl: middleware_base,
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json, text/csv',
    'Access-Control-Allow-Origin': "*",
    'Authorization': `Bearer ${getAccessToken()}`
  }
});

// #endregion

// #region Auth
const Auth = {
  getToken: (data) => middlewareRequest.post('/auth/token', data),
  isRegistered: (data) => middlewareRequest.post('/auth/register', data),
  setPassword: (id, data) => middlewareRequest.patch(`/auth/register/password/${id}`, data),
  setEmployerAdminPassword: (id, data) => middlewareRequest.patch(`/auth/register/employer/admin/password/${id}`, data),
  getWebauthn: (IDNumber) => middlewareRequest.get(`/auth/register/webauthn/${IDNumber}`),
  setWebauthn: (id, data) => middlewareRequest.patch(`/auth/register/webauthn/${id}`, data),
  passwordLogin: (data) => middlewareRequest.post('/auth/login/password', data),
  checkWebauthn: (IDNumber) => middlewareRequest.get(`/auth/login/webauthn/${IDNumber}`),
  webauthnLogin: (data) => middlewareRequest.post('/auth/login/webauthn', data),
  changePassword: (id, data) => middlewareRequest.patch(`/auth/update/password/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  addPassword: (id, data) => middlewareRequest.put(`/auth/update/password/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  addWebauthn: (id, data) => middlewareRequest.put(`/auth/update/webauthn/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getQuestions: (id, data) => middlewareRequest.post(`/auth/verifyid/questions/${id}`, data),
  verifyQuestion: (data) => middlewareRequest.post(`/auth/verifyid/questions`, data),
  getSessionToken: (data) => middlewareRequest.post('/auth/verifyid/token', data),
  verifySelfie: (id, data) => middlewareRequest.post(`/auth/verifyid/selfie/${id}`, data),
  getIdPhoto: (id) => middlewareRequest.post(`/auth/verifyid/photo/${id}`),
  generatePasswordReset: (id) => middlewareRequest.get(`/auth/password/reset/${id}`),
  generateAdminPasswordReset: (id) => middlewareRequest.get(`/auth/admin/password/reset/${id}`),
  resetPassword: (jwtkn, data) => middlewareRequest.put('/auth/password/reset', data, { headers: { 'Authorization': `Bearer ${jwtkn}` } }),
  getClientActivationLink: (id) => middlewareRequest.get(`/auth/client/activation/link/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  sendClientActivationLink: (id, data) => middlewareRequest.post(`/auth/client/activation/link/mail/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region ID Verification
const IDVerification = {
  getIDVerificationByID: (id) => middlewareRequest.get(`/idverification/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createIDVerification: (id, data) => middlewareRequest.post(`/idverification/admin/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } })
}
// #endregion

// #region DataAccess
const DataAccess = {
  createDataAccessPermission: (data) => middlewareRequest.post('/dataAccessPermission', data)
};
// #endregion

// #region Agreement
const Agreement = {
  createAgreement: (data) => middlewareRequest.post('/agreement', data),
  getAgreementsByUsername: (username) => middlewareRequest.get(`/agreement/username/${username}`),
  getAgreementsByClient: (objectid) => middlewareRequest.get(`/agreement/client/${objectid}`)
};
// #endregion

// #region Advance
const Advance = {
  process: (id, data) => middlewareRequest.post(`/advance/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  processManual: (id, data) => middlewareRequest.post(`/advance/admin/manual/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  processManualOzow: (id, data) => middlewareRequest.post(`/advance/admin/ozow/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  processBankAccount: (id, data) => middlewareRequest.post(`/advance/bankaccount/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateTransactionStatus: (data) => middlewareRequest.patch(`/advance/admin/status`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvancesForClientAndDate: (id, data) => middlewareRequest.post(`/advance/client/date/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvances: () => middlewareRequest.get(`/advance`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvanceHistory: (id) => middlewareRequest.get(`/advance/history/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  checkOzowPayoutStatus: (id) => middlewareRequest.get(`/advance/ozow/status/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvanceHistoryForClient: (id) => middlewareRequest.get(`/advance/client/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  importAdvances: (data) => middlewareRequest.post(`/advance/import`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Invoice
const Invoice = {
  getInvoices: () => middlewareRequest.get(`invoice/get/all`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getInvoicesNoPop: () => middlewareRequest.get(`invoice/get/all/nopop`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getInvoicesByClient: (id) => middlewareRequest.get(`/invoice/get/client/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createInvoice: (data) => middlewareRequest.post(`/invoice`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createBulkInvoices: (data) => middlewareRequest.post(`/invoice/bulk`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getInvoiceByObjectId: (id) => middlewareRequest.get(`/invoice/get/objectid/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateInvoice: (id, data) => middlewareRequest.patch(`/invoice/update/objectid/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  cancelInvoice: (id, data) => middlewareRequest.patch(`/invoice/cancel/objectid/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createBulkMarketplaceMembershipInvoices: (data) => middlewareRequest.post(`/invoice/bulk/create/marketplace`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Client
const Client = {
  submitEmployerBenefitsAssessment: (data) => middlewareRequest.post(`/clients`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getJointBenefitsReport: (clientId) => middlewareRequest.get(`/client/benefits/assessment/joint/report/${clientId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getSingleBenefitsReport: (clientId) => middlewareRequest.get(`/client/benefits/assessment/single/report/${clientId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getClients: () => middlewareRequest.get('/client', { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getClientByObjectId: (id) => middlewareRequest.get(`/client/objectid/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateClientById: (id, data) => middlewareRequest.patch(`/client/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createClient: (data) => middlewareRequest.post(`/client`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  activateClientBenefits: (id, data) => middlewareRequest.post(`/client/benefits/activate/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  confirmBenefits: (id, data) => middlewareRequest.patch(`/client/admin/benefits/confirm/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Bankaccounts
const Bankaccounts = {
  getBankAccounts: (id) => middlewareRequest.get(`/BankAccount/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createBankAccount: (data) => middlewareRequest.post(`/BankAccount`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  deleteBankAccount: (id) => middlewareRequest.delete(`/BankAccount/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  addBankAccount: (id, data) => middlewareRequest.post(`/BankAccount/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateBankAccount: (data) => middlewareRequest.patch(`/BankAccount`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Reporting
const Reporting = {
  getAdminBulkReporting: () => middlewareRequest.get(`/reporting/admin/bulk`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getUserSignUpsPerDate: (data) => middlewareRequest.post(`/reporting/usersSignedUp/dates`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Employees
const Employees = {
  getEmployee: (id) => middlewareRequest.get(`/employees/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getEmployees: () => middlewareRequest.get(`/employees`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateEmployee: (id, data) => middlewareRequest.patch(`/employees/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  loadLabourNetInfo: (id) => middlewareRequest.post(`/employees/payroll/labournet/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  loadPaySpaceInfo: (id) => middlewareRequest.post(`/employees/payroll/payspace/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  loadSapInfo: (id) => middlewareRequest.patch(`/employees/populateSap/${id}`),
  loadSageInfo: (id) => middlewareRequest.patch(`/employees/payroll/populateSage/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  loadCSVFee: (id) => middlewareRequest.patch(`/employees/populateCsvFee/${id}`),
  getEmployeeByBusinessEntity: (id) => middlewareRequest.get(`/employees/businessentity/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  uploadCSV: (data) => middlewareRequest.post(`/employees/csv`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  checkIfCSVExists: (data) => middlewareRequest.post(`/employees/csv/exists`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateCSV: (data) => middlewareRequest.patch(`/employees/csv`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createEmployee: (data) => middlewareRequest.post(`/employees`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  deleteEmployee: (id) => middlewareRequest.delete(`/employees/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createEmployeeBaselineAssessment: (data) => middlewareRequest.post(`/baselineassessment`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getBaselineAssessmentsById: (id) => middlewareRequest.get(`/baselineassessment/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getBaselineAssessments: () => middlewareRequest.get(`/baselineassessment`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  submitEmployeeBenefitsAssessment: (id, data) => middlewareRequest.post(`/user/benefit/assessment/${id}`, data),
  formatCsv: (data) => middlewareFormDataRequest.post(`/user/upload/csv/convert`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  bulkUploadEmployees: (id, data) => middlewareRequest.patch(`/user/bulk/populate/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } })
};
// #endregion

//region Payroll
const Payroll = {
  linkSimplepay: (data, id) => middlewareRequest.patch(`/client/import/simplepay/all/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  linkSage: (data, id) => middlewareRequest.patch(`/client/import/sage/all/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  linkLabournet: (data, id) => middlewareRequest.patch(`/client/import/labournet/all/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  linkPayspace: (data, id) => middlewareRequest.patch(`/client/import/payspace/all/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getPayspaceClients: (data) => middlewareRequest.post(`/client/payspace/clients`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getSimplePayClients: (data) => middlewareRequest.post(`/client/simplepay/clients`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getLabourNetClients: (data) => middlewareRequest.post(`/client/labournet/clients`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  importLabourNetEmployee: (clientId, id) => middlewareRequest.get(`/user/labournet/import/${clientId}/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  importSageEmployee: (clientId, id) => middlewareRequest.get(`/user/sage/import/${clientId}/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  bulkWriteAdvancesToPayslip: (data) => middlewareRequest.post(`/payroll/advances/write`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
}

const Logs = {
  getAllPayrollLogs: () => middlewareRequest.get(`/payroll/logs/get/all`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
}

//end region

// #region Employeeadvancehistories
const Employeeadvancehistories = {
  getHistory: (id) => middlewareRequest.get(`calculateAmountAvailable/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Employeecontacts
const Employeecontacts = {
  update: (id, data) => middlewareRequest.patch(`/employeeContact/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } })
};
// #endregion

// #region Employeeinfos
const Employeeinfos = {
  update: (id, data) => middlewareRequest.patch(`/employeeInfo/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } })
};
// #endregion

// #region Errors
const Errors = {
  getErrors: () => middlewareRequest.get(`/errors`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateErrors: (id, data) => middlewareRequest.patch(`/errors/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Whatsapp
const Whatsapp = {
  getWhatsappToken: (id) => middlewareRequest.get(`whatsapp/token/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Contact Submissions
const ContactSubmission = {
  createContactSubmission: (data) => middlewareRequest.post(`/contactsubmission`, data),
  getContactSubmissionById: (id) => middlewareRequest.get(`/contactsubmission/id/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateContactSubmissionById: (id, data) => middlewareRequest.patch(`/contactsubmission/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getContactSubmissios: (id) => middlewareRequest.get(`/contactsubmission`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region User 
const User = {
  getAllUsers: () => middlewareRequest.get(`/user`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAllUsersNoPop: () => middlewareRequest.get(`/user/nopop`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getUserByRole: (role) => middlewareRequest.get(`/users/role/${role}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getClientUsers: (clientId) => middlewareRequest.get(`/user/client/${clientId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getClientAdminUsers: (clientId) => middlewareRequest.get(`/user/client/admin/${clientId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getClientUsersNoPop: (clientId) => middlewareRequest.get(`/user/client/nopop/${clientId}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateUserByUsername: (id, data) => middlewareRequest.patch(`/user/username/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateUserByIDNumber: (id, data) => middlewareRequest.patch(`/user/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getUserByUsernameManualAuth: (id, token) => middlewareRequest.get(`/user/username/${id}`, { headers: { 'Authorization': `Bearer ${token}` } }),
  getUserByUsername: (id) => middlewareRequest.get(`/user/username/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createUser: (data) => middlewareRequest.post(`/user`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  verifyUserBankAccount: (id, data) => middlewareRequest.patch(`/user/bank/verify/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } })
};

const Transactions = {
  getAllTransactions: () => middlewareRequest.get(`/transaction`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getRangedTransactions: (data) => middlewareRequest.post(`/transaction/ranged`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getRangedTransactionsNoPop: (data) => middlewareRequest.post(`/transaction/ranged/nopop`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAllTransactionsNoPop: () => middlewareRequest.get(`/transaction/nopop`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAllTransactionsByClient: (id) => middlewareRequest.get(`/transaction/client/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateTransactionByObjectId: (id, data) => middlewareRequest.patch(`/transaction/objectid/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateTransactionByTransactionId: (id, data) => middlewareRequest.patch(`/transaction/transactionid/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getTransactionByObjectId: (id) => middlewareRequest.get(`/transaction/objectid/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createTransaction: (data) => middlewareRequest.post(`/transaction`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getTransactionsByIdNumber: (id) => middlewareRequest.get(`/transaction/id/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Flexible pay 
const FlexiblePay = {
  //user
  createUser: (data) => middlewareRequest.post(`/flexiblepay/user`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAllUsers: () => middlewareRequest.get(`/flexiblepay/user`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAllUsersNoPop: () => middlewareRequest.get(`/flexiblepay/user/nopop`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAllUsersByClient: (id) => middlewareRequest.get(`/flexiblepay/user/client/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getUser: (id) => middlewareRequest.get(`/flexiblepay/get/user/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateUser: (id, data) => middlewareRequest.patch(`/flexiblepay/update/user/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  //client
  getClients: () => middlewareRequest.get(`/flexiblepay/client`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getClient: (id) => middlewareRequest.get(`/flexiblepay/get/client/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateClient: (id, data) => middlewareRequest.patch(`/flexiblepay/update/client/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  //advance
  processAdminManualAdvance: (data) => middlewareRequest.post(`/flexiblepay/admin/manual/advance`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  calculateAmountAvailable: (id, data) => middlewareRequest.post(`/flexiblepay/amount/available/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  bankAccountAdvance: (id, data) => middlewareRequest.post(`/flexiblepay/advance/bankaccount/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  adminBankAccountAdvance: (id, data) => middlewareRequest.post(`/flexiblepay/admin/advance/bankaccount/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAllAdvances: () => middlewareRequest.get(`/flexiblepay/advance`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAllAdvancesNoPop: () => middlewareRequest.get(`/flexiblepay/advance/nopop`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAllAdvancesByClient: (id) => middlewareRequest.get(`/flexiblepay/advance/client/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),

  updateAdvanceByTransactionId: (id, data) => middlewareRequest.patch(`/flexiblepay/advance/transactionid/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  //these 4 arents in middleware
  updateAdvanceByObjectId: (id, data) => middlewareRequest.patch(`/flexiblepay/advance/objectid/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvanceByObjectId: (id) => middlewareRequest.get(`/flexiblepay/advance/objectid/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createAdvance: (data) => middlewareRequest.post(`/flexiblepay/advance`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvancesByIdNumber: (id) => middlewareRequest.get(`/flexiblepay/advance/id/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  //reporting
  getBulkFlexiblePayAdminReporting: () => middlewareRequest.get(`/reporting/admin/flexiblepay/bulk`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getBulkFlexiblePayClientReporting: (mpClient, fpClient) => middlewareRequest.get(`/reporting/client/flexiblepay/bulk/${mpClient}/${fpClient}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  generateBulkInvoices: (data) => middlewareRequest.post(`/flexiblepay/invoice/bulk`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),

};
// #endregion

// #region Employeesalaries
const Employeesalaries = {
  updateSalary: (id, data) => middlewareRequest.patch(`/employeesalary/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Ozow
const Ozow = {
  getBalance: () => middlewareRequest.get(`/ozow/balance`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getPayoutStatus: (id) => middlewareRequest.get(`/ozow/payout/status/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
}
// #endregion

// #region Config
const Config = {
  getAppVersion: () => middlewareRequest.get(`/config/version`),
}
// #endregion

export {
  Auth,
  DataAccess,
  Advance,
  Client,
  Bankaccounts,
  Employees,
  Employeeadvancehistories,
  Employeecontacts,
  Employeeinfos,
  Employeesalaries,
  User,
  Reporting,
  Errors,
  ContactSubmission,
  Whatsapp,
  Agreement,
  IDVerification,
  Invoice,
  Ozow,
  Payroll,
  Config,
  FlexiblePay,
  Transactions,
  Logs
};