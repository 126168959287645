import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useState, useEffect } from 'react';

export default function AutohideSnackbar({ open, content, type, setSnackBarClose }) {

    const handleParentClose = () => {
        setSnackBarClose()
    }

    const [isOpen, setIsOpen] = useState(open)

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsOpen(false)
        handleParentClose()
    };

    return (
        <div>
            <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {`${content}!`}
                </Alert>
            </Snackbar>
        </div>
    );
}

