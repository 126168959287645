import React from 'react';
import { Bar } from 'react-chartjs-2'
import 'chart.js/auto'

const BarChartPillarScores = ({ report, backgroundColor, id }) => {

    const pillarScoresChartData = {
        labels: ['Physical', 'Emotional', 'Financial', 'Social'],
        datasets: [{
            label: 'Employee percentage',
            data: [
                report.EmployeePerc.physical * 100,
                report.EmployeePerc.emotional * 100,
                report.EmployeePerc.financial * 100,
                report.EmployeePerc.social * 100
            ],
            backgroundColor: [
                'rgba(255, 125, 185, 1)',
            ],
            borderRadius: 10,
            barThickness: 30,
            borderWidth: 2
        },
        {
            label: 'Employer percentage',
            data: [
                report.EmployerPerc.physical * 100,
                report.EmployerPerc.emotional * 100,
                report.EmployerPerc.financial * 100,
                report.EmployerPerc.social * 100
            ],
            backgroundColor: [
                'rgba(185, 221, 254, 1)',
            ],
            borderRadius: 10,
            barThickness: 30,
            borderWidth: 2
        }
        ]
    }
    const barChartOptions = {
        layout: {
            padding: 20
        },
        scales: {
            y: {
                grid: {
                    display: true,
                    color: 'rgb(255 255 255 / 34%)'
                },
                title: {
                    display: true,
                    text: "Percentage",
                    color: 'white',
                    font: {
                        size: 12,
                        family: 'Inter'
                    }
                },
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 20,
                    color: 'white',
                    font: {
                        size: 12,
                        family: 'Inter'
                    },
                    callback: function (value) {
                        return value + '%'; // Append '%' to each tick value
                    }
                },
            },
            x: {
                layout: {
                    padding: {
                        left: 5
                    }
                },
                ticks: {
                    color: 'white',
                    font: {
                        size: 12,
                        family: 'Inter'
                    },

                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: 'white',
                    font: {
                        size: 12,
                        family: 'Inter'
                    }
                }
            },
            datalabels: {
                display: false
            }
        },
    }

    return <div style={{ backgroundColor: backgroundColor, borderRadius: '50px', width: '50%' }} id={id}>
        <Bar data={pillarScoresChartData} options={barChartOptions} />
    </div>;
};

export default BarChartPillarScores;