
const permissions = {
    levelAdminPermissions: [
        { id: "create-users", title: "Create users" },
        { id: "view-users", title: "View users" },
        { id: "view-user-details", title: "View users' details" },
        { id: "view-benefits", title: "View benefits" },
        { id: "edit-benefit-controls", title: "Edit benefit controls" },
        { id: "edit-users", title: "Edit users" },
        { id: "create-clients", title: "Create clients" },
        { id: "view-clients", title: "View clients" },
        { id: "edit-clients", title: "Edit clients" },
        { id: "create-admin-users", title: "Create admin user" },
        { id: "edit-permissions", title: "Edit permissions" },
        { id: "view-reports", title: "View reporting data" },
        { id: "edit-reporting", title: "Edit reporting data (invoices, transactions ...)" },
        { id: "generate-invoices", title: "Generate invoices" },
    ],
    employerAdminPermissions: [
        { id: "create-users", title: "Create users" },
        { id: "view-users", title: "View users" },
        { id: "view-user-details", title: "View users' details" },
        { id: "edit-users", title: "Edit users" },
        { id: "update-payroll", title: "Update payroll" },
        { id: "view-benefits", title: "View benefits" },
        { id: "edit-benefit-controls", title: "Edit benefit controls" },
        { id: "view-client", title: "View clients" },
        { id: "edit-client", title: "Edit clients" },
        { id: "create-admin-users", title: "Create admin users" },
        { id: "edit-permissions", title: "Edit admin permissions" },
        { id: "view-invoices", title: "View company invoices" },
    ]
}

export default permissions