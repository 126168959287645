import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { LandingImage } from '../../assets/icons';
import { ErrorCard, TopBar } from '../../components/basic';

import './style.scss';

const ResetPassword = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [searchParams] = useSearchParams();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [j, setJ] = useState('');
    const [t, setT] = useState('');

    useEffect(() => {
        let query = {
            j: searchParams.get('j'),
            t: searchParams.get('t')
        }
        if (!query.j) {
            console.log("No jwt")
            setError('Invalid reset link.');
        } else if (!query.t) {
            console.log("No token")
            setError('Invalid reset link.');
        } else {
            setJ(query.j);
            setT(query.t);
        }
    }, []);

    function newPasswordChange(e) {
        setError('');
        setSuccess('');
        setNewPassword(e.target.value);
    }

    function confirmPasswordChange(e) {
        setError('');
        setSuccess('');
        setConfirmPassword(e.target.value);
    }

    function doValidation() {
        if (newPassword === '') {
            setError('Please enter a new password.');
            return false
        }

        let hasUpperCase = /[A-Z]/.test(newPassword);
        let hasLowerCase = /[a-z]/.test(newPassword);
        let hasNumber = /\d/.test(newPassword);
        let hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword);

        if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar) {
            setError("Password should include at least one uppercase letter, one lowercase letter, one number, and one special character");
            return false
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return false
        }
        return true
    }

    async function changePasswordClicked() {
        if (t === '') {
            setError('Invalid reset link.');
            console.log("No token")
            return
        }
        if (j === '') {
            setError('Invalid reset link.');
            console.log("No jwt")
            return
        }
        if (doValidation()) {
            let ret = await commonStore.resetPassword(j, t, newPassword)
            if (ret.status === false) {
                setError("Error occured resetting password, please contact support");
                return
            } else {
                setSuccess('Your password has been changed');
                setPasswordChanged(true);
                return
            }
        }
    }
    function goToLogin() {
        navigate('/signin');
    }

    if (isMobile()) {
        return (
            <div>
                <TopBar />
                <main style={{ justifyContent: 'center', padding: '30px', marginTop: "5vh" }}>
                    <header className='signin-main-header'>Reset password</header>

                    <div className='signin-inputs-container'>
                        <div className='flex-column'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label className='signin-input-label'>New password</label>
                            </div>
                            <input className='signin-input-item'
                                width='100%' placeholder={"Enter new password"}
                                type="password" id="newPassword" value={newPassword} onChange={newPasswordChange} required
                            />
                        </div>
                        <div className='flex-column'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label className='signin-input-label'>Confirm password</label>
                            </div>
                            <input className='signin-input-item'
                                width='100%' placeholder={"Confirm your password"}
                                type="password" id="confirmPassword" value={confirmPassword} onChange={confirmPasswordChange} required
                            />
                        </div>
                        {(!passwordChanged) ?
                            <button className='signin-sign-in-btn' onClick={changePasswordClicked}>Reset password</button> :
                            <button className='signin-sign-in-btn' onClick={goToLogin}>Proceed to login</button>
                        }
                    </div>
                    {error !== "" && <ErrorCard type='error' message={error} />}
                    {success !== "" && <ErrorCard type='success' message={success} />}
                </main>
            </div>

        );
    } else {
        return (
            <div>
                <div style={{ display: 'flex' }}>
                    <div>
                        <img src={LandingImage} style={{ height: '100vh' }} />
                    </div>
                    <main style={{ justifyContent: 'center', marginTop: '10vh', padding: '100px 200px 0px 100px' }}>
                        <header className='signin-main-header'>Reset password</header>
                        <div className='signin-inputs-container'>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>New password</label>
                                </div>
                                <input className='signin-input-item'
                                    width='100%' placeholder={"Enter new password"}
                                    type="password" id="newPassword" value={newPassword} onChange={newPasswordChange} required
                                />
                            </div>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Confirm password</label>
                                </div>
                                <input className='signin-input-item'
                                    width='100%' placeholder={"Confirm your password"}
                                    type="password" id="confirmPassword" value={confirmPassword} onChange={confirmPasswordChange} required
                                />
                            </div>
                            {(!passwordChanged) ?
                                <button className='signin-sign-in-btn' onClick={changePasswordClicked}>Reset password</button> :
                                <button className='signin-sign-in-btn' onClick={goToLogin}>Proceed to login</button>
                            }
                        </div>
                        {error !== "" && <ErrorCard type='error' message={error} />}
                        {success !== "" && <ErrorCard type='success' message={success} />}
                    </main>
                </div>
            </div>
        );
    }
})

export default ResetPassword