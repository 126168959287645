import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { useSearchParams } from 'react-router-dom';
import { Button, Navbar, Input, InputOptions } from '../../../components/basic/index'
import questions from '../../../components/assessments/employerBenefitsAssessments'
import './styles.scss'
import { AutohideSnackbar } from '../../../components/MUI';

const EmployerBenefitsAssessment = observer(() => {

    const commonStore = useStore()
    const navigate = useNavigate()

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    // const [questionNumber, setQuestionNumber] =
    //     useState(commonStore.user.Role !== "5d8e0b079f51d42bbece9201" ? 1 : 2)
    const [questionNumber, setQuestionNumber] = useState(0)
    const [isQuestionAnswered, setIsQuestionAnswered] = useState(false)
    const [questionAnswerPairs, setQuestionAnswerPairs] = useState([])
    //answers

    const [multiChoiceSelected, setMultiChoiceSelected] = useState([])
    const [multiChoiceSelectedIds, setMultiChoiceSelectedIds] = useState([])
    const [inputEntered, setInputEntered] = useState('')

    const [assessmentComplete, setAssessmentComplete] = useState(false)
    const [submitContactVisible, setSubmitContactVisible] = useState(false)
    const [benefitsBudget, setBenefitsBudget] = useState("")
    const [wantedBenefitsBudget, setWantedBenefitsBudget] = useState("")

    //submit
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [textArea, setTextArea] = useState('')

    //cleint id
    const [searchParams] = useSearchParams();
    const clientId = searchParams.toString().replace(/=+$/, '')

    const reset = () => {
        setMultiChoiceSelected([])
        setMultiChoiceSelectedIds([])
        setInputEntered('')
        setIsQuestionAnswered(false)
        setTextArea('')
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    const handleBackToDashboard = () => {
        if (commonStore.user.Role === "62bd55b7c5793941427c702d") {
            navigate('/client/home/Benefits', { state: { refresh: false } })
        }
        else if (commonStore.user.Role === "5d8e0b079f51d42bbece9201") {
            navigate('/admin/home/Overview', { state: { refresh: false } })
        }
        else {
            navigate('/signin')
        }
    }

    const goBack = () => {
        reset()
        let back = questionNumber - 1

        if (back < 0) {
            return
        }
        else {
            setQuestionNumber(back)
        }
    }

    useEffect(() => {
        reset()
    }, [questionNumber])

    const nextQuestion = () => {
        let answered = false;
        let pair;
        console.log(questions[questionNumber])
        console.log(inputEntered)
        console.log(isQuestionAnswered)
        let temp
        let skipq13 = false
        let blankq13 = {}

        if (

            (questions[questionNumber].questionType === 'input' ||
                questions[questionNumber].questionType === 'oneOption' ||
                questions[questionNumber].questionType === 'dropDown') &&
            inputEntered !== '' && questions[questionNumber].questionNumber !== 12 &&
            questions[questionNumber].questionNumber !== 13 &&
            isQuestionAnswered
        ) {
            pair = {
                [`Question ${questions[questionNumber].questionNumber}`]: inputEntered,
            };
            console.log(pair)
            answered = true;
        }

        else if (

            questions[questionNumber].questionType === 'oneOption' && inputEntered !== '' && isQuestionAnswered &&
            questions[questionNumber].questionNumber === 13 && wantedBenefitsBudget !== 0 && inputEntered === "Yes") {
            pair = {
                [`Question ${questions[questionNumber].questionNumber}`]: `${inputEntered}, Budget: ${wantedBenefitsBudget}`,
            };
            answered = true;
        }

        else if (
            questions[questionNumber].questionType === 'oneOption' && inputEntered !== '' && isQuestionAnswered &&
            questions[questionNumber].questionNumber === 13 && inputEntered === "No") {
            pair = {
                [`Question ${questions[questionNumber].questionNumber}`]: `${inputEntered}`,
            };
            answered = true;
        }

        else if (

            questions[questionNumber].questionType === 'oneOption' && inputEntered !== '' && isQuestionAnswered &&
            questions[questionNumber].questionNumber === 12 && benefitsBudget !== 0 && inputEntered === "Yes") {
            pair = {
                [`Question ${questions[questionNumber].questionNumber}`]: `${inputEntered}, Budget: ${benefitsBudget}`,
            };
            answered = true;
            skipq13 = true

            blankq13 = {
                [`Question ${questions[questionNumber + 1].questionNumber}`]: ``,
            };
        }

        else if (
            questions[questionNumber].questionType === 'oneOption' && inputEntered !== '' && isQuestionAnswered &&
            questions[questionNumber].questionNumber === 12 && inputEntered === "No") {
            pair = {
                [`Question ${questions[questionNumber].questionNumber}`]: `${inputEntered}`,
            };
            answered = true;
        }
        else if (
            questions[questionNumber].questionType === 'multiCheck' &&
            (multiChoiceSelected.length > 0 || textArea !== '') &&
            isQuestionAnswered
        ) {
            //if entered other benefits
            if (textArea !== '') {
                let curr = multiChoiceSelected
                curr.push(textArea)
                setMultiChoiceSelected(curr)
            }
            pair = {
                [`Question ${questions[questionNumber].questionNumber}`]: multiChoiceSelected,
            };
            answered = true;
        }

        if (answered) {
            let current = questionNumber;

            // Update questionAnswerPairs or replace existing item
            setQuestionAnswerPairs((prevPairs) => {
                const existingPairIndex = prevPairs.findIndex(
                    (p) => Object.keys(p)[0] === Object.keys(pair)[0]
                );

                if (existingPairIndex !== -1) {
                    // Replace existing pair
                    const updatedPairs = [...prevPairs];
                    updatedPairs[existingPairIndex] = pair;
                    return updatedPairs;
                } else {
                    // Add new pair
                    temp = [...prevPairs, pair]
                    return [...prevPairs, pair];
                }
            });
            //add blank question 13
            if (skipq13) {
                setQuestionAnswerPairs((prevPairs) => {
                    const existingPairIndex = prevPairs.findIndex(
                        (p) => Object.keys(p)[0] === Object.keys(blankq13)[0]
                    );

                    if (existingPairIndex !== -1) {
                        // Replace existing pair
                        const updatedPairs = [...prevPairs];
                        updatedPairs[existingPairIndex] = blankq13;
                        return updatedPairs;
                    } else {
                        // Add new pair
                        temp = [...prevPairs, blankq13]
                        return [...prevPairs, blankq13];
                    }
                });
            }

            reset();
            forceUpdate()
            if (current === 13) {
                setSubmitContactDetails(temp);
            }
            else {
                if (skipq13) {
                    setQuestionNumber(current + 2);
                }
                else {
                    setQuestionNumber(current + 1);
                }

            }
        } else {
            console.log('error, answer all required questions');
        }
    };

    const onInputChange = (value) => {

        setInputEntered(value)
        if (value.length > 0) {
            setIsQuestionAnswered(true)
        }
        else {
            setIsQuestionAnswered(false)
        }
    }

    const handleChecked = (checked, answer, id) => {
        if (checked) {
            // Add the new ID to multiChoiceSelectedIds
            let newArrayids = [...multiChoiceSelectedIds, id];
            // Add the new answer to multiChoiceSelected
            let newArray = [...multiChoiceSelected, answer];

            setIsQuestionAnswered(true);
            setMultiChoiceSelected(newArray);
            setMultiChoiceSelectedIds(newArrayids);
        } else {
            // Remove the ID from multiChoiceSelectedIds
            let newArrayids = multiChoiceSelectedIds.filter((item) => item !== id);
            // Remove the answer from multiChoiceSelected
            let newArray = multiChoiceSelected.filter((item) => item !== answer);

            setIsQuestionAnswered(newArray.length === 0 ? false : true);
            setMultiChoiceSelected(newArray);
            setMultiChoiceSelectedIds(newArrayids);
        }
    };

    const setSubmitContactDetails = (latest) => {
        submitAssessment(latest)
    }

    const submitAssessment = async (latest) => {
        commonStore.setLoading(false)
        let newObject = {};
        latest.forEach((item, index) => {
            const key = `Question${index + 1}`;
            const entryValue = Object.values(item)[0];
            let value = entryValue;
            newObject[key] = { question: questions[index].questionTitle, answer: value };
        });

        let client = {}
        console.log(newObject)
        newObject.DateTaken = new Date()

        if (newObject["Question14"].answer === "No") {
            client = {
                BenefitsAssessment: {
                    EmployerAssessment: newObject,
                    OptToSendToEmployees: false
                }
            }
        }
        else if (newObject["Question14"].answer === "Yes") {
            //set deadline if opted for sending to employees
            let date = new Date()
            let oneWeekLater = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000); // Adding 7 days

            client = {
                BenefitsAssessment: {
                    EmployerAssessment: newObject,
                    OptToSendToEmployees: true,
                    StartDate: date,
                    Deadline: oneWeekLater
                }
            }
        }
        console.log(commonStore.user.Client._id)
        try {
            commonStore.setLoading(true)
            let res = await commonStore.updateClientById(commonStore.user.Client._id, client)
            console.log(res)
            commonStore.setLoading(false)
            if (res?._id === commonStore.user.Client._id) {
                commonStore.setClient(res)
                if (newObject["Question14"].answer === "No") {
                    commonStore.setSnackBar("success", "Submitted, please view results on dashboard")
                    handleBackToDashboard()
                }
                else if (newObject["Question14"].answer === "Yes") {
                    commonStore.setSnackBar("success", "Submitted")
                    navigate(`/client/assessment/add?cli=${commonStore.user.Client._id}`)
                }
            }
            else {
                commonStore.setSnackBar("error", "Error occured")
                reset()
            }
        }
        catch (error) {
            console.error(error)
            commonStore.setSnackBar("error", "Error occured")
        }

    }

    const handleFirstNameOnChange = (value) => {
        setFirstName(value)
    }

    const handleLastNameOnChange = (value) => {
        setLastName(value)
    }

    const handleEmailOnChange = (value) => {
        setEmail(value)
    }

    const handleMobileNumberOnChange = (value) => {
        setMobileNumber(value)
    }

    const onTextAreaChange = (value) => {
        setTextArea(value)
        if (value) {
            setIsQuestionAnswered(true)
        }
        else {
            setIsQuestionAnswered(false)
        }
    }

    const handleBenefitsBudgetChange = (e) => {
        setBenefitsBudget(e.target.value)
    }

    const handleWantedBenefitsBudgetChange = (e) => {
        setWantedBenefitsBudget(e.target.value)
    }

    return (
        <div>
            <Navbar />
            {!submitContactVisible &&
                <main >
                    <AutohideSnackbar content={commonStore.snackBarDetails.content} open={commonStore.snackBarDetails.open} type={commonStore.snackBarDetails.type} setSnackBarClose={commonStore.resetSnackBar} />

                    <div>
                        <header className='employee-benefits-assessment-header'>Employer Benefits Assessments</header>
                        <header className='employee-benefits-assessment-question-header'>Question {questionNumber + 1}/14</header>
                        <div className='employee-benefits-assessment-question-container'>
                            <div style={{ width: '100%' }}>
                                <hr style={{ width: '100%' }} />
                                <p className='employee-benefits-assessment-question-title'>{questions[questionNumber].questionTitle}</p>

                                <div className='employee-benefits-assessment-multiple-choice-container'>

                                    {/* INPUT SECTION */}
                                    {questions[questionNumber].questionType === 'input' && !assessmentComplete &&
                                        <Input onChange={(e) =>
                                            onInputChange(e.target.value,
                                                `${questions[questionNumber].questionNumber}`)}
                                            value={inputEntered}
                                            style={{ width: '100%' }}
                                            placeholder={questions[questionNumber].placeHolder} />
                                    }

                                    {/* INPUT SECTION */}

                                    {/* ONE OPTION SECTION */}
                                    {questions[questionNumber].questionType === 'oneOption' && !assessmentComplete &&
                                        <>
                                            <div className='employee-benefits-assessment-options-container'>
                                                {questions[questionNumber].options.map((opt) => (
                                                    <div key={opt.optionNumber}
                                                        onClick={() =>
                                                            onInputChange(opt.optionTitle,
                                                                `${questions[questionNumber].questionNumber}`)}
                                                        value={inputEntered}
                                                        className={`employee-benefits-assessment-options-item ${inputEntered === opt.optionTitle ? 'selected' : ''}`}>
                                                        {opt.optionTitle}
                                                    </div>
                                                ))}
                                            </div>
                                            {inputEntered === "Yes" && questions[questionNumber].questionNumber === 12 &&
                                                <div className='employee-benefits-assessment-employee-benefits-budget-container'>
                                                    <header style={{ marginBottom: '0px' }} className='employee-benefits-assessment-question-title'>
                                                        How much is your employee benefits budget?
                                                    </header>
                                                    <input onChange={(e) => { handleBenefitsBudgetChange(e) }} className='employee-benefits-assessment-employee-benefits-budget-input'
                                                        placeholder='Enter amount' value={benefitsBudget} />
                                                </div>
                                            }
                                            {inputEntered === "Yes" && questions[questionNumber].questionNumber === 13 &&
                                                <div className='employee-benefits-assessment-employee-benefits-budget-container'>
                                                    <header style={{ marginBottom: '0px' }} className='employee-benefits-assessment-question-title'>
                                                        How much would you like to allocate to your benefits budget?
                                                    </header>
                                                    <input onChange={(e) => { handleWantedBenefitsBudgetChange(e) }} className='employee-benefits-assessment-employee-benefits-budget-input'
                                                        placeholder='Enter amount' value={wantedBenefitsBudget} />
                                                </div>
                                            }
                                        </>
                                    }
                                    {/* ONE OPTION SECTION */}

                                    {/* DROPDOWN */}
                                    {questions[questionNumber].questionType === 'dropDown' && !assessmentComplete &&

                                        <InputOptions
                                            options={questions[questionNumber].options}
                                            value={inputEntered === "" ? "Select option" : inputEntered}
                                            onChange={(e) =>
                                                onInputChange(e.optionTitle,
                                                    `${questions[questionNumber].questionNumber}`)} />
                                    }
                                    {/* DROPDOWN */}

                                    {/* MULTIPLE CHOICE SECTION */}

                                    {questions[questionNumber].questionType === 'multiCheck' && !assessmentComplete && (
                                        <div>
                                            {questions[questionNumber].options.map((category) => (
                                                <div key={category.id} className='employee-benefits-assessment-multiple-choice-choices-container'>
                                                    <header className='employee-benefits-assessment-multiple-choice-choices-header'>{category.questionTitle}</header>
                                                    {category.options.map((item) => (
                                                        <div className='employee-benefits-assessment-multiple-choice-choices-item' key={item.id}>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                <input
                                                                    type='checkbox'
                                                                    id={`${category.id}${item.id}`}
                                                                    className='employee-benefits-assessment-multiple-choice-choices-item-checkbox'
                                                                    onChange={(event) => handleChecked(event.target.checked, item.title, `${category.id}${item.id}`)}
                                                                    checked={multiChoiceSelectedIds.includes(`${category.id}${item.id}`)} // set checked attribute here
                                                                />
                                                                <label htmlFor={`${category.id}${item.id}`}>{item.title}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                            <header className='employee-benefits-assessment-multiple-choice-choices-header'>Other</header>
                                            <Input value={textArea} onChange={(event) => onTextAreaChange(event.target.value)}
                                                type='textarea' placeholder='Please specify other benefits' />
                                        </div>
                                    )}

                                    {/* MULTIPLE CHOICE SECTION */}

                                </div>

                            </div>

                            <div className='two-buttons-container-pri-purinv'>
                                {((questionNumber - 1) >= 0) ?
                                    <Button onClick={() => goBack()} type='purpleInvis'>Back</Button>
                                    :
                                    <div></div>
                                }
                                {isQuestionAnswered && !assessmentComplete && <Button onClick={() => nextQuestion()}>Next</Button>}
                                {assessmentComplete && <Button onClick={() => setSubmitContactDetails()}>Nextt</Button>}
                            </div>
                        </div>
                    </div>
                </main>}

            {submitContactVisible &&
                <main>
                    <header className='employee-benefits-assessment-submit-header'>Thank you for your input</header>
                    <p className='employee-benefits-assessment-submit-subheader'>Please provide us with a few details below and we will get in touch with you.</p>
                    <div className='employee-benefits-assessment-submit-inputs-container'>
                        <Input onChange={(e) => handleFirstNameOnChange(e.target.value)} value={firstName} width='45%' placeholder={"First name"} />
                        <Input onChange={(e) => handleLastNameOnChange(e.target.value)} value={lastName} width='45%' placeholder={"Last name"} />
                        <Input onChange={(e) => handleEmailOnChange(e.target.value)} value={email} width='45%' placeholder={"Company email"} />
                        <Input onChange={(e) => handleMobileNumberOnChange(e.target.value)} value={mobileNumber} width='45%' placeholder={"Mobile number"} />
                    </div>
                    <Button width={'100%'} onClick={() => submitAssessment(questionAnswerPairs)}>Submit</Button>
                </main>

            }
        </div>
    );
})

export default EmployerBenefitsAssessment;
