import React, { useState, useEffect } from 'react';
import { useLinkClickHandler, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, TopBar, Input, ErrorCard } from '../../../components/basic/index'
import { ScanFaceIcon } from '../../../assets/icons/index'
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import './style.scss';

const AWS = require('aws-sdk');
AWS.config.logger = console;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_COGNITO_ID,
    secretAccessKey: process.env.REACT_APP_COGNITO_KEY,
    region: 'us-east-1',
});
// AWS.config.getCredentials((err) => {
//     if (err) console.log(err.stack); // credentials not loaded
//     else {
//         console.log("Access key:", AWS.config.credentials.accessKeyId);
//         console.log("Secret access key:", AWS.config.credentials.secretAccessKey);
//     }
// });

const FaceRec = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [error, setError] = useState('')

    const [beginFaceRec, setBeginFaceRec] = useState(true);
    const [idPhoto, setIdPhoto] = useState(null)
    const [SessionId, setSessionId] = useState('')
    const [showLiveness, setShowLiveness] = useState(false)


    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_COGNITO_ID,
            secretAccessKey: process.env.REACT_APP_COGNITO_KEY,
            region: 'us-east-1',
        });
    }, [])

    const handleNext = async () => {
        //get home affairs id photo
        try {
            commonStore.setLoading(true)
            let photo = await commonStore.getIdPhoto(commonStore.user.IDNumber)
            commonStore.setLoading(false)
            if (photo.IDPhoto) {
                setIdPhoto(photo.IDPhoto)

                //generate 4 digit
                const randomNumber = Math.floor(1000 + Math.random() * 9000);

                // Convert the number to a string
                const fourDigitString = String(randomNumber);

                const Rekognition = new AWS.Rekognition;
                const params = {
                    ClientRequestToken: fourDigitString,
                    KmsKeyId: process.env.REACT_APP_KMS_KEY_ID,
                    Settings: {
                        AuditImagesLimit: 2
                    }
                };

                Rekognition.createFaceLivenessSession(params, (err, data) => {
                    if (err) {
                        commonStore.setLoading(false)
                        console.log('Error initiating liveness:', err)
                        navigate('/employee/verifyid')
                        return
                    }
                    else {
                        console.log("createFaceLivenessSession result:", data)
                        let sessionId = data.SessionId
                        setSessionId(sessionId)
                        setShowLiveness(true)
                        commonStore.setLoading(false)
                        return
                    }
                })

            }
            else {
                commonStore.setLoading(false)
                console.log('Error getting id photo')
                navigate('/employee/verifyid')
                return
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            navigate('/employee/verifyid')
            return
        }

    }

    const handleAnalysisComplete = async () => {
        commonStore.setLoading(true)
        try {
            const Rekognition = new AWS.Rekognition
            Rekognition.getFaceLivenessSessionResults(({ SessionId: SessionId }), (err, data) => {
                if (err) {
                    commonStore.setLoading(false)
                    console.log('Error completing liveness session:', err)
                    navigate('/employee/verifyid')
                    return
                }
                else {
                    setShowLiveness(false)
                    if (data.Confidence < 85) {
                        navigate('/employee/verifyid')
                        return
                    }
                    const binaryString = window.atob(idPhoto)
                    const bytes = new Uint8Array(binaryString.length)
                    for (let i = 0; i < binaryString.length; i++) {
                        bytes[i] = binaryString.charCodeAt(i)
                    }

                    const compareParams = {
                        SourceImage: {
                            Bytes: data.AuditImages[0].Bytes.buffer
                        },
                        TargetImage: {
                            Bytes: bytes
                        },
                        SimilarityThreshold: 70
                    }
                    Rekognition.compareFaces(compareParams, (err, response) => {
                        if (err) {
                            commonStore.setLoading(false)
                            console.log('Error initiating comparing faces:', err)
                            navigate('/employee/verifyid')
                            return
                        }
                        else {
                            console.log(response)
                            if (response.FaceMatches.length === 0) {
                                commonStore.setLoading(false)
                                console.log('No faces found when comparing faces:', err)
                                navigate('/employee/verifyid')
                                return
                            }
                            else {
                                if (response.FaceMatches[0].Similarity < 85) {
                                    commonStore.setLoading(false)
                                    console.log('Faces do not match:', err)
                                    navigate('/employee/verifyid')
                                    return
                                }
                                else {
                                    console.log("Faces match!")
                                    navigate('/employee/account/secure')
                                    return
                                }
                            }
                        }
                    })
                }
            })
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            navigate('/employee/verifyid')
            return
        }

    }

    const cancelFaceRec = () => {
        navigate('/employee/verifyid')
        return
    }

    const onErrorFaceRec = (error) => {
        console.log(error)
    }

    if (isMobile()) {
        return (
            <div>
                <TopBar />
                <main style={{ alignItems: 'center' }}>
                    {!showLiveness ?
                        <><img src={ScanFaceIcon} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                            <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                                Verify your identity
                            </p>
                            <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                                To verify your identity we will require you to do a liveness test of your face. Please ensure that your face is clearly visible.
                            </p>
                            <p style={{ width: '100%' }} onClick={handleNext} className='signin-sign-in-btn'>Next</p></>
                        :
                        <div className='face-liveness-container'>
                            <FaceLivenessDetector
                                // disableInstrustionScreen={true}
                                sessionId={SessionId}
                                region='us-east-1'
                                onAnalysisComplete={handleAnalysisComplete}
                                onUserCancel={cancelFaceRec}
                                onError={(error) => onErrorFaceRec(error)} />
                        </div>
                    }
                </main>
            </div>
        );
    } else {
        return (
            <div>
                <TopBar />
                <main style={{ alignItems: 'center' }}>
                    <header className='employee-benefits-assessment-submit-header'>Verify your identity</header>
                </main>
            </div>
        );
    }
})

export default FaceRec
