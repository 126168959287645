import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    layouts,
} from 'chart.js';

// Register the required components
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    ChartDataLabels
);

const DoughnutChartBenefitsDemographic = ({ report, entity, labels, id }) => {

    const options = {
        layout: {
            padding: 30
        },
        plugins: {
            legend: {
                display: false
            },
            // tooltips: {
            //     enabled: false,
            //     mode: 'index',
            //     position: 'nearest',
            //     intersect: false,
            // }
            datalabels: {
                display: true,
                color: 'white',
                padding: 5,
                borderRadius: 3,

                backgroundColor: 'gray',
                formatter: (value, context) => {
                    const dataArray = context.chart.data.datasets[0].data;
                    const total = dataArray.reduce((acc, curr) => acc + curr, 0);
                    const percentage = ((value / total) * 100).toFixed(0);
                    if (percentage > 0) {
                        if (`${context.chart.data.labels[context.dataIndex]}` === 'Medium-term (6 to 12 months)') {
                            return `6-12 months +: ${percentage}%`;
                        }
                        else if (`${context.chart.data.labels[context.dataIndex]}` === 'More than 10 years') {
                            return `10 years +: ${percentage}%`;
                        }
                        else if (`${context.chart.data.labels[context.dataIndex]}` === 'Short-term (less than 6 months)') {
                            return `Less 6 months: ${percentage}%`;
                        }
                        else if (`${context.chart.data.labels[context.dataIndex]}` === 'Long-term (more than 12 months)') {
                            return `12 months +: ${percentage}%`;
                        }
                        else if (`${context.chart.data.labels[context.dataIndex]}` === 'Less than 1 year') {
                            return `Less 1 year: ${percentage}%`;
                        }
                        else if (`${context.chart.data.labels[context.dataIndex]}` === 'Prefer not to say') {
                            return `Other: ${percentage}%`;
                        }
                        else if (`${context.chart.data.labels[context.dataIndex]}` === 'Transgender') {
                            return `Trans: ${percentage}%`;
                        }
                        else {
                            return `${context.chart.data.labels[context.dataIndex]}: ${percentage}%`;
                        }
                    }
                    else return null

                },
                font: {
                    weight: 'bold',
                    size: 10,
                    family: 'Inter'
                }
            }
        }
    }

    const chartData = {
        labels: labels,
        datasets: [
            {
                data: labels.map((lbl) => {
                    return report.Demographics[entity][lbl]
                }),
                backgroundColor: [
                    'rgba(255, 99, 132, 1)', // Red
                    'rgba(54, 162, 235, 1)', // Blue
                    'rgba(255, 206, 86, 1)', // Yellow
                    'rgba(75, 192, 192, 1)',  // Green
                    'rgba(50, 192, 69, 1)',
                    'rgba(75, 12, 152, 1)',
                    'rgba(75, 12, 102, 1)'
                ],
            },
        ],
    };

    return <div id={id} style={{width:'250px'}}>
        <Doughnut data={chartData} options={options} />
    </div>
};

export default DoughnutChartBenefitsDemographic;
