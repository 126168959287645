import React, { useState, useEffect } from 'react';
import { useLinkClickHandler, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { ErrorCard } from '../../components/basic/index'
import { BackArrow, EditPenPinkIcon, PinkXIcon, PasswordIcon } from '../../assets/icons/index'

import './styles.scss';

const Profile = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [error, setError] = useState('')
    const [editVisible, setEditVisible] = useState("")
    const [ogUser, setOgUser] = useState(null)
    const [tempUser, setTempUser] = useState(null)
    const [userChanged, setUserChanged] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [passwordSetSuccess, setPasswordSetSuccess] = useState(false)
    const [detailUpdateSuccess, setDetailUpdateSuccess] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)

    const banks = [
        { id: 1, bankName: "ABSA", branchCode: '632005' },
        { id: 2, bankName: "Access Bank", branchCode: '' },
        { id: 3, bankName: "Absa Bank", branchCode: '632005' },
        { id: 4, bankName: "African Bank", branchCode: '430000' },
        { id: 5, bankName: "Capitec Bank", branchCode: '470010' },
        { id: 6, bankName: "Discovery Bank", branchCode: '679000' },
        { id: 7, bankName: "FNB", branchCode: '250655' },
        { id: 8, bankName: "Nedbank", branchCode: '198765' },
        { id: 9, bankName: "Investec", branchCode: '580105' },
        { id: 10, bankName: "Nedbank", branchCode: '198765' },
        { id: 11, bankName: "Sasfin Bank", branchCode: '683000' },
        { id: 12, bankName: "Standard Bank", branchCode: '051001' },
        { id: 13, bankName: "TymeBank", branchCode: '678910' }
    ]

    const bankTypes = [
        { id: '01', type: "Savings" },
        { id: '02', type: "Current / cheque" },
        { id: '03', type: "Transmission" },
        { id: '04', type: "Bond" },
        { id: '05', type: "Subscription share" },
        { id: '06', type: "Unknown" }
    ]

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        loadUserData()
    }, [])

    const loadUserData = async () => {
        try {
            commonStore.setLoading(true)
            let user = await commonStore.getUserByUsername(commonStore.user.Username)
            commonStore.setLoading(false)
            if (user) {
                commonStore.setUser(user)
                if (commonStore.user.Role === "5d8e0b079f51d42bbece9201" || commonStore.user.Role === "62bd55b7c5793941427c702d") {
                    setIsAdmin(true)
                }
            }
            setOgUser(JSON.parse(JSON.stringify(user)))
            setTempUser(JSON.parse(JSON.stringify(user)))
            setEditVisible("false")
            forceUpdate()
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.logout()
            navigate('./signin')
        }
    }

    const handleOnDetailsChange = (e) => {
        let id = e.currentTarget.id
        let value = e.currentTarget.value
        let key1, key2
        let temp = { ...tempUser }

        //bank
        if (id.split(",").length > 1) {
            if (key2 === "BranchCode") {
                return
            }
            key1 = id.split(",")[0]
            key2 = id.split(",")[1]

            let bank = tempUser[key1] || {}
            if (value === "" && bank[key2]) {
                //e.g if AccountNumber = "" remove it
                delete bank[key2]
            }
            else {
                //else if not empty string then add it
                if (key2 === 'Type') {

                }
                bank[key2] = value
                if (key2 === "BankName") {

                    //set branchcode according to bank name
                    let branchCode = banks.filter((bnk) => {
                        return bnk.bankName === value
                    })
                    bank["BranchCode"] = branchCode[0].branchCode
                }
            }
            if (Object.keys(bank).length === 0 && temp[key1]) {
                //dont add empty bank object
                delete temp[key1]
            }
            else {
                //add bank object to user
                temp[key1] = bank
            }

        }
        else {
            temp[id] = value
        }

        //compare
        if (JSON.stringify(temp) !== JSON.stringify(ogUser)) {
            setUserChanged(true)
        }
        else {
            setUserChanged(false)
        }

        setTempUser(temp)
        forceUpdate()

    }

    const verifyBank = async (type, bank) => {
        if (!bank.AccountNumber || !bank.BankName || !bank.BranchCode || !bank.Type) {
            alert("Please enter all details")
            return
        }
        let args = {
            type: type,
            bank: bank,
            verifiedBy: `${commonStore.user.Username} through profile`,
            role: commonStore.user.Role
        }
        commonStore.setLoading(true)
        try {
            let res = await commonStore.verifyUserBankAccount(commonStore.user.Username, args)
            commonStore.setLoading(false)
            if (res.success) {
                commonStore.setSnackBar("success", "Bank account added")
                setDetailUpdateSuccess(true)
                resetEditSection()
                loadUserData()
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Failed to verify bank, please confirm details")
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }
    }

    const resetEditSection = () => {
        setError("")
        setOldPassword("")
        setNewPassword("")
        setConfirmNewPassword("")
        setEditVisible("false")
        setTempUser(JSON.parse(JSON.stringify(ogUser)))
        setUserChanged(false)
        forceUpdate()
    }

    const handleUpdateUser = async () => {
        commonStore.setLoading(true)

        try {
            let updates = tempUser
            delete updates["__v"]
            delete updates["_id"]
            delete updates["Password"]
            delete updates["SalaryAmount"]

            let res = await commonStore.updateUserByUsername(commonStore.user.Username, tempUser)

            if (res) {
                commonStore.setLoading(false)
                setDetailUpdateSuccess(true)
                resetEditSection()
                loadUserData()
            }
            else {
                commonStore.setLoading(false)
                resetEditSection()
                setError("Error occured updating details, please try again")
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
        }
    }

    const handleBack = () => {
        if (commonStore.user.Role === "5d8e0b079f51d42bbece9201") {
            navigate('/admin/home/Overview')
        }
        else if (commonStore.user.Role === "5d8e0b079f51d42bbece9202") {
            navigate('/employee/home')
        }
        else if (commonStore.user.Role === "5d8e0b079f51d42bbece9002") {
            navigate('/employee/home')
        }
        else if (commonStore.user.Role === "62bd55b7c5793941427c702d") {
            navigate('/client/home/Overview')
        }
    }

    const handleSetEdit = (section) => {
        setEditVisible(section)
    }

    const handlePasswordChange = (e) => {
        setError("")
        let id = e.currentTarget.id
        let value = e.currentTarget.value
        switch (id) {
            case "oldPassword":
                setOldPassword(value)
                break
            case "newPassword":
                setNewPassword(value)
                break
            case "confirmNewPassword":
                setConfirmNewPassword(value)
                break
            default:
                break
        }
    }

    const handleSetPassword = async () => {
        if (tempUser.Password) {
            if (!oldPassword || !newPassword || !confirmNewPassword) {
                setError("Please enter all details")
                return
            }
        } else {
            if (!newPassword || !confirmNewPassword) {
                setError("Please enter all details")
                return
            }
        }
        if (newPassword !== confirmNewPassword) {
            setError("New passwords do not match")
            return
        }
        let hasUpperCase = /[A-Z]/.test(newPassword);
        let hasLowerCase = /[a-z]/.test(newPassword);
        let hasNumber = /\d/.test(newPassword);
        let hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword);

        if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar) {
            setError("Password should include at least one uppercase letter, one lowercase letter, one number, and one special character");
            return;
        }
        else {
            commonStore.setLoading(true)
            if (tempUser.Password) {
                //change old
                try {
                    let data = {
                        oldPassword: oldPassword,
                        newPassword: newPassword
                    }
                    let change = await commonStore.changePassword(data)
                    commonStore.setLoading(false)
                    if (change) {
                        setPasswordSetSuccess(true)
                        resetEditSection()
                        loadUserData()
                    }
                    else {
                        setOldPassword("")
                        setNewPassword("")
                        setConfirmNewPassword("")
                        setError("Error occured setting password, please try again")
                    }
                } catch (error) {
                    commonStore.setLoading(false)
                    setOldPassword("")
                    setNewPassword("")
                    setConfirmNewPassword("")
                    setError("Error occured setting password, please try again")
                }
            }
            else {
                //set new
                try {
                    let args = {
                        newPassword: newPassword
                    }
                    let add = await commonStore.addPassword(args)
                    commonStore.setLoading(false)
                    if (add) {
                        resetEditSection()
                        setPasswordSetSuccess(true)
                        loadUserData()
                    }
                    else {
                        setOldPassword("")
                        setNewPassword("")
                        setConfirmNewPassword("")
                        setError("Error occured setting password, please try again")
                    }
                } catch (error) {
                    commonStore.setLoading(false)
                    setOldPassword("")
                    setNewPassword("")
                    setConfirmNewPassword("")
                    setError("Error occured setting password, please try again")
                }
            }

        }
    }

    const addAuthenticationClicked = async () => {
        let ret = await commonStore.addWebauthn()
        if (ret !== true) {
            commonStore.setSnackBar('warning', 'Unable to add device authentication')
            return
        }
        commonStore.setSnackBar('success', 'Device added')
    }

    if (isMobile()) {
        return (
            <div>
                <main style={{ gap: '0px' }}>
                    {editVisible === "false" &&
                        <div>
                            <div style={{ marginBottom: '10px', cursor: 'pointer' }} className='back-arrow-btn' onClick={handleBack}>
                                <img src={BackArrow} />
                                <div>Back</div>
                            </div>
                            <br />
                            <div className='employee-dash-header'>Profile</div>
                            {passwordSetSuccess && <div className='profile-update-success'>
                                <div>
                                    <div style={{ fontSize: '13px', color: '#EA2199', marginBottom: '5px', fontWeight: '500' }}>Password change</div>
                                    <div style={{ fontSize: '13px', color: '#EA2199', marginBottom: '5px' }}>Your password has been updated successfully.</div>
                                </div>
                                <img className='cursor-pointer' src={PinkXIcon} onClick={() => setPasswordSetSuccess(false)} />
                            </div>}
                            {detailUpdateSuccess && <div className='profile-update-success'>
                                <div>
                                    <div style={{ fontSize: '13px', color: '#EA2199', marginBottom: '5px', fontWeight: '500' }}>Personal details change</div>
                                    <div style={{ fontSize: '13px', color: '#EA2199', marginBottom: '5px' }}>Your personal details have been updated successfully.</div>
                                </div>
                                <img className='cursor-pointer' src={PinkXIcon} onClick={() => setDetailUpdateSuccess(false)} />
                            </div>}
                            <br />
                            <div style={{ textAlign: 'left' }} className='employee-dash-header subheader'>Personal details</div>
                            <br />
                            <div className='flex-column' style={{ gap: '5px' }}>
                                <div>
                                    <div className='profile-details label'>First name</div>
                                    <div className='profile-details value'>{`${tempUser.FirstName ? tempUser.FirstName : "Not set"}`}</div>
                                </div>

                                <div>
                                    <div className='profile-details label'>Last name</div>
                                    <div className='profile-details value'>{`${tempUser.LastName ? tempUser.LastName : "Not set"}`}</div>
                                </div>

                                <div>
                                    <div className='profile-details label'>Email address</div>
                                    <div className='profile-details value'>{`${tempUser.WorkEmail ? tempUser.WorkEmail : "Not set"}`}</div>
                                </div>

                                <div>
                                    <div className='profile-details label'>Mobile number</div>
                                    <div className='profile-details value'>{`${tempUser.MobileNumber ? tempUser.MobileNumber : "Not set"}`}</div>
                                </div>

                                {!isAdmin && <div>
                                    <div className='profile-details label'>Monthly income (Nett)</div>
                                    <div className='profile-details value'>{`${tempUser.SalaryAmount ? `R${tempUser.SalaryAmount}` : "Not set (Contact admin to set salary)"}`}</div>
                                </div>}

                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }} onClick={() => handleSetEdit('personal')}>
                                    <img src={EditPenPinkIcon} style={{ height: '20px' }} />
                                    <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}
                                    >Edit personal details</div>
                                </div>
                                <hr style={{ width: '100%', color: '#74738D' }} />

                                {!isAdmin && (
                                    <div className="text-left">
                                        <div className="employee-dash-header subheader">Primary bank account</div>

                                        {tempUser.PrimaryBankAccount ? (
                                            <div className="flex flex-col gap-1 mt-2">
                                                <div>
                                                    <div className="profile-details label">Bank</div>
                                                    <div className="profile-details value">
                                                        {tempUser.PrimaryBankAccount.BankName || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Account number</div>
                                                    <div className="profile-details value">
                                                        {tempUser.PrimaryBankAccount.AccountNumber || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Account type</div>
                                                    <div className="profile-details value">
                                                        {tempUser.PrimaryBankAccount.Type || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Branch code</div>
                                                    <div className="profile-details value">
                                                        {tempUser.PrimaryBankAccount.BranchCode || "Not set"}
                                                    </div>
                                                </div>

                                                {!tempUser.PrimaryBankAccount.Verified && (
                                                    <div className="flex items-center gap-2 cursor-pointer" onClick={() => handleSetEdit('primary')}>
                                                        <img src={EditPenPinkIcon} className="h-5" alt="Edit Icon" />
                                                        <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>Edit primary bank account</div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div onClick={() => handleSetEdit('primary')} className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>
                                                Add a primary bank account
                                            </div>
                                        )}

                                        <hr className="w-full text-gray-400 mt-4" />
                                    </div>
                                )}

                                {!isAdmin && (
                                    <div className="text-left">
                                        <div className="employee-dash-header subheader">Secondary bank account</div>

                                        {tempUser.SecondaryBankAccount ? (
                                            <div className="flex flex-col gap-1 mt-2">
                                                <div>
                                                    <div className="profile-details label">Bank</div>
                                                    <div className="profile-details value">
                                                        {tempUser.SecondaryBankAccount.BankName || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Account number</div>
                                                    <div className="profile-details value">
                                                        {tempUser.SecondaryBankAccount.AccountNumber || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Account type</div>
                                                    <div className="profile-details value">
                                                        {tempUser.SecondaryBankAccount.Type || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Branch code</div>
                                                    <div className="profile-details value">
                                                        {tempUser.SecondaryBankAccount.BranchCode || "Not set"}
                                                    </div>
                                                </div>

                                                <div className="flex items-center gap-2 cursor-pointer" onClick={() => handleSetEdit('secondary')}>
                                                    <img src={EditPenPinkIcon} className="h-5" alt="Edit Icon" />
                                                    <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>Edit secondary bank account</div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div onClick={() => handleSetEdit('secondary')} className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>
                                                Add a secondary bank account
                                            </div>
                                        )}

                                        <hr className="w-full text-gray-400 mt-4" />
                                    </div>
                                )}
                                <div style={{ textAlign: 'left' }} className='employee-dash-header subheader'>Password</div>
                                {tempUser.Password ?
                                    <div onClick={() => handleSetEdit("change-password")} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                        <img src={PasswordIcon} />
                                        <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>Change password</div>
                                    </div> :
                                    <div onClick={() => handleSetEdit("set-password")} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                        <img src={PasswordIcon} />
                                        <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>Set password</div>
                                    </div>
                                }
                                <hr style={{ width: '100%', color: '#74738D' }} />
                                <div style={{ textAlign: 'left' }} className='employee-dash-header subheader'>Device authentication</div>
                                <div onClick={() => addAuthenticationClicked()} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                    <img src={PasswordIcon} />
                                    <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>Add device authentication</div>
                                </div>
                            </div>
                        </div>
                    }
                    {editVisible === 'personal' &&
                        <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='employee-dash-header'>Edit details</div>
                                <img className='cursor-pointer' onClick={resetEditSection} src={PinkXIcon} />
                            </div>

                            <br />
                            <div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>First name</label>
                                    <input value={tempUser.FirstName}
                                        id='FirstName'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='profile-changes-input'
                                        placeholder='First name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Last Name</label>
                                    <input value={tempUser.LastName}
                                        id='LastName'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='profile-changes-input'
                                        placeholder='Last Name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Work email</label>
                                    <input value={tempUser.WorkEmail}
                                        id='WorkEmail'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='profile-changes-input'
                                        placeholder='Work email'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Personal email</label>
                                    <input value={tempUser.PrivateEmail}
                                        id='PrivateEmail'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='profile-changes-input'
                                        placeholder='Personal Email'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Mobile number</label>
                                    <input value={tempUser.MobileNumber}
                                        id='MobileNumber'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='profile-changes-input'
                                        placeholder='Mobile number'
                                    />
                                </div>
                                <br />
                                <div className='flex-column' style={{ gap: '3px' }}>
                                    {
                                        userChanged &&
                                        <button className='profile-changes-btn confirm'
                                            onClick={handleUpdateUser}>
                                            Save changes
                                        </button>
                                    }
                                    <button className='profile-changes-btn cancel' onClick={resetEditSection}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    }
                    {editVisible === 'change-password' &&
                        <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='employee-dash-header'>Change password</div>
                                <img className='cursor-pointer' onClick={resetEditSection} src={PinkXIcon} />
                            </div>

                            <br />
                            <div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Old password</label>
                                    <input value={oldPassword}
                                        id='oldPassword'
                                        onChange={(e) => { handlePasswordChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='Old password'
                                        type="password"
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>New password</label>
                                    <input value={newPassword}
                                        id='newPassword'
                                        onChange={(e) => { handlePasswordChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='New password'
                                        type="password"
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Confirm new password</label>
                                    <input value={confirmNewPassword}
                                        id='confirmNewPassword'
                                        onChange={(e) => { handlePasswordChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='Confirm new password'
                                        type="password"
                                    />
                                </div>

                                <br />

                                <div className='flex-column' style={{ gap: '3px', marginTop: '10vh' }}>
                                    {error === "" ? null : <ErrorCard type='error' message={error} />}
                                    <button className='profile-changes-btn confirm'
                                        onClick={() => handleSetPassword()}>
                                        Save changes
                                    </button>
                                    <button className='profile-changes-btn cancel' onClick={resetEditSection}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    }
                    {editVisible === 'set-password' &&
                        <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='employee-dash-header'>Set a password</div>
                                <img className='cursor-pointer' onClick={resetEditSection} src={PinkXIcon} />
                            </div>
                            <br />
                            <div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>New password</label>
                                    <input value={newPassword}
                                        id='newPassword'
                                        onChange={(e) => { handlePasswordChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='New password'
                                        type="password"
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Confirm new password</label>
                                    <input value={confirmNewPassword}
                                        id='confirmNewPassword'
                                        onChange={(e) => { handlePasswordChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='Confirm new password'
                                        type="password"
                                    />
                                </div>
                                <br />
                                <div className='flex-column' style={{ gap: '3px', marginTop: '10vh' }}>
                                    {error === "" ? null : <ErrorCard type='error' message={error} />}
                                    <button className='profile-changes-btn confirm'
                                        onClick={() => handleSetPassword()}>
                                        Save changes
                                    </button>
                                    <button className='profile-changes-btn cancel' onClick={resetEditSection}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    }
                    {editVisible === 'primary' &&
                        <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='employee-dash-header'>Edit / Add Primary bank account</div>
                                <img className='cursor-pointer' onClick={resetEditSection} src={PinkXIcon} />
                            </div>
                            <br />
                            <div>
                                <div className='flex-column'>

                                    <label className='profile-details label indented'>Select bank</label>
                                    <select value={tempUser.PrimaryBankAccount ? tempUser.PrimaryBankAccount.BankName || '' : ''}
                                        className='profile-dropdown' id="PrimaryBankAccount,BankName"
                                        onChange={handleOnDetailsChange}>
                                        <option value="">Bank name</option>
                                        {
                                            banks.map((bnk, index) => (
                                                <option key={index} value={bnk.bankName}>{bnk.bankName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Select account type</label>
                                    <select value={tempUser.PrimaryBankAccount ? tempUser.PrimaryBankAccount.Type || '' : ''}
                                        className='profile-dropdown' id="PrimaryBankAccount,Type"
                                        onChange={(e) => { handleOnDetailsChange(e) }}>
                                        <option>Account type</option>
                                        {
                                            bankTypes.map((typ, index) => (
                                                <option key={index} value={typ.type}>{typ.type}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Branch code</label>
                                    <input value={tempUser.PrimaryBankAccount ? tempUser.PrimaryBankAccount.BranchCode || '' : ''}
                                        id='SecondaryBankAccount,BranchCode'
                                        className='profile-changes-input'
                                        placeholder='Branch code'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Account number</label>
                                    <input value={tempUser.PrimaryBankAccount ? tempUser.PrimaryBankAccount.AccountNumber || '' : ''}
                                        id='PrimaryBankAccount,AccountNumber'
                                        onChange={(e) => { handleOnDetailsChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='Account number'
                                    />
                                </div>

                                <br />
                                <div className='flex-column' style={{ gap: '3px' }}>
                                    {
                                        userChanged &&
                                        <button className='profile-changes-btn confirm'
                                            onClick={() => verifyBank("primary", tempUser.PrimaryBankAccount)}>
                                            Save changes
                                        </button>
                                    }
                                    <button className='profile-changes-btn cancel' onClick={resetEditSection}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    }
                    {editVisible === 'secondary' &&
                        <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='employee-dash-header'>Add / Replace Secondary bank account</div>
                                <img className='cursor-pointer' onClick={resetEditSection} src={PinkXIcon} />
                            </div>
                            <br />
                            <div>
                                <div className='flex-column'>

                                    <label className='profile-details label indented'>Select bank</label>
                                    <select value={tempUser.SecondaryBankAccount ? tempUser.SecondaryBankAccount.BankName || '' : ''}
                                        className='profile-dropdown' id="SecondaryBankAccount,BankName"
                                        onChange={handleOnDetailsChange}>
                                        <option value="">Bank name</option>
                                        {
                                            banks.map((bnk, index) => (
                                                <option key={index} value={bnk.bankName}>{bnk.bankName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Select account type</label>
                                    <select value={tempUser.SecondaryBankAccount ? tempUser.SecondaryBankAccount.Type || '' : ''}
                                        className='profile-dropdown' id="SecondaryBankAccount,Type"
                                        onChange={(e) => { handleOnDetailsChange(e) }}>
                                        <option>Account type</option>
                                        {
                                            bankTypes.map((typ, index) => (
                                                <option key={index} value={typ.type}>{typ.type}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Branch code</label>
                                    <input value={tempUser.SecondaryBankAccount ? tempUser.SecondaryBankAccount.BranchCode || '' : ''}
                                        id='SecondaryBankAccount,BranchCode'
                                        className='profile-changes-input'
                                        placeholder='Branch code'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Account number</label>
                                    <input value={tempUser.SecondaryBankAccount ? tempUser.SecondaryBankAccount.AccountNumber || '' : ''}
                                        id='SecondaryBankAccount,AccountNumber'
                                        onChange={(e) => { handleOnDetailsChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='Account number'
                                    />
                                </div>

                                <br />
                                <div className='flex-column' style={{ gap: '3px' }}>
                                    {
                                        userChanged &&
                                        <button className='profile-changes-btn confirm'
                                            onClick={() => verifyBank("secondary", tempUser.SecondaryBankAccount)}>
                                            Save changes
                                        </button>
                                    }
                                    <button className='profile-changes-btn cancel' onClick={resetEditSection}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    }
                </main>
            </div>
        );
    } else {
        return (
            <div>
                <main style={{ gap: '0px' }}>
                    {editVisible === "false" &&
                        <div>
                            <div style={{ marginBottom: '10px', cursor: 'pointer' }} className='back-arrow-btn' onClick={handleBack}>
                                <img src={BackArrow} />
                                <div>Back</div>
                            </div>
                            <br />
                            <div className='employee-dash-header'>Profile</div>
                            {passwordSetSuccess && <div className='profile-update-success'>
                                <div>
                                    <div style={{ fontSize: '13px', color: '#EA2199', marginBottom: '5px', fontWeight: '500' }}>Password change</div>
                                    <div style={{ fontSize: '13px', color: '#EA2199', marginBottom: '5px' }}>Your password has been updated successfully.</div>
                                </div>
                                <img className='cursor-pointer' src={PinkXIcon} onClick={() => setPasswordSetSuccess(false)} />
                            </div>}
                            {detailUpdateSuccess && <div className='profile-update-success'>
                                <div>
                                    <div style={{ fontSize: '13px', color: '#EA2199', marginBottom: '5px', fontWeight: '500' }}>Personal details change</div>
                                    <div style={{ fontSize: '13px', color: '#EA2199', marginBottom: '5px' }}>Your personal details have been updated successfully.</div>
                                </div>
                                <img className='cursor-pointer' src={PinkXIcon} onClick={() => setDetailUpdateSuccess(false)} />
                            </div>}
                            <br />
                            <div className='flex flex-row gap-16'>
                                <div>
                                    <div style={{ textAlign: 'left' }} className='employee-dash-header subheader'>Personal details</div>
                                    <br />
                                    <div>
                                        <div className='profile-details label'>First name</div>
                                        <div className='profile-details value'>{`${tempUser.FirstName ? tempUser.FirstName : "Not set"}`}</div>
                                    </div>

                                    <div>
                                        <div className='profile-details label'>Last name</div>
                                        <div className='profile-details value'>{`${tempUser.LastName ? tempUser.LastName : "Not set"}`}</div>
                                    </div>

                                    <div>
                                        <div className='profile-details label'>Email address</div>
                                        <div className='profile-details value'>{`${tempUser.WorkEmail ? tempUser.WorkEmail : "Not set"}`}</div>
                                    </div>

                                    <div>
                                        <div className='profile-details label'>Mobile number</div>
                                        <div className='profile-details value'>{`${tempUser.MobileNumber ? tempUser.MobileNumber : "Not set"}`}</div>
                                    </div>
                                    {!isAdmin && <div>
                                        <div className='profile-details label'>Monthly income (Nett)</div>
                                        <div className='profile-details value'>{`${tempUser.SalaryAmount ? `R${tempUser.SalaryAmount}` : "Not set (Contact admin to set salary)"}`}</div>
                                    </div>}
                                    <br />
                                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }} onClick={() => handleSetEdit('personal')}>
                                        <img src={EditPenPinkIcon} style={{ height: '20px' }} />
                                        <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}
                                        >Edit personal details</div>
                                    </div>
                                </div>
                                {!isAdmin && (
                                    <div >
                                        <div style={{ textAlign: 'left' }} className="employee-dash-header subheader">Primary bank account</div>
                                        <br />
                                        {tempUser.PrimaryBankAccount ? (
                                            <div className="flex flex-col gap-1 mt-2">
                                                <div>
                                                    <div className="profile-details label">Bank</div>
                                                    <div className="profile-details value">
                                                        {tempUser.PrimaryBankAccount.BankName || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Account number</div>
                                                    <div className="profile-details value">
                                                        {tempUser.PrimaryBankAccount.AccountNumber || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Account type</div>
                                                    <div className="profile-details value">
                                                        {tempUser.PrimaryBankAccount.Type || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Branch code</div>
                                                    <div className="profile-details value">
                                                        {tempUser.PrimaryBankAccount.BranchCode || "Not set"}
                                                    </div>
                                                </div>
                                                <br />
                                                {!tempUser.PrimaryBankAccount.Verified && (
                                                    <div className="flex items-center gap-2 cursor-pointer" onClick={() => handleSetEdit('primary')}>
                                                        <img src={EditPenPinkIcon} className="h-5" alt="Edit Icon" />
                                                        <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>Edit primary bank account</div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div onClick={() => handleSetEdit('primary')} className='admin-dash-blue-clients-text' style={{ cursor: 'pointer', fontSize: '12px', color: '#EA2199' }}>
                                                Add a primary bank account
                                            </div>
                                        )}
                                    </div>

                                )}
                                {!isAdmin && (
                                    <div>
                                        <div style={{ textAlign: 'left' }} className="employee-dash-header subheader">Secondary bank account</div>
                                        <br />
                                        {tempUser.SecondaryBankAccount ? (
                                            <div className="flex flex-col gap-1 mt-2">
                                                <div>
                                                    <div className="profile-details label">Bank</div>
                                                    <div className="profile-details value">
                                                        {tempUser.SecondaryBankAccount.BankName || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Account number</div>
                                                    <div className="profile-details value">
                                                        {tempUser.SecondaryBankAccount.AccountNumber || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Account type</div>
                                                    <div className="profile-details value">
                                                        {tempUser.SecondaryBankAccount.Type || "Not set"}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="profile-details label">Branch code</div>
                                                    <div className="profile-details value">
                                                        {tempUser.SecondaryBankAccount.BranchCode || "Not set"}
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="flex items-center gap-2 cursor-pointer" onClick={() => handleSetEdit('secondary')}>
                                                    <img src={EditPenPinkIcon} className="h-5" alt="Edit Icon" />
                                                    <div className='admin-dash-blue-clients-text' style={{ cursor: 'pointer', fontSize: '12px', color: '#EA2199' }}>Edit secondary bank account</div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='admin-dash-blue-clients-text' onClick={() => handleSetEdit('secondary')} style={{ cursor: 'pointer', fontSize: '12px', color: '#EA2199' }}>
                                                Add a secondary bank account
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>


                            <div className='flex-column' style={{ gap: '5px' }}>

                                <hr style={{ width: '100%', color: '#74738D' }} />

                                <div style={{ textAlign: 'left' }} className='employee-dash-header subheader'>Password</div>
                                {tempUser.Password ?
                                    <div onClick={() => handleSetEdit("change-password")} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                        <img src={PasswordIcon} />
                                        <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>Change password</div>
                                    </div> :
                                    <div onClick={() => handleSetEdit("set-password")} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                        <img src={PasswordIcon} />
                                        <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>Set password</div>
                                    </div>
                                }
                                <hr style={{ width: '100%', color: '#74738D' }} />
                                <div style={{ textAlign: 'left' }} className='employee-dash-header subheader'>Device authentication</div>
                                <div onClick={() => addAuthenticationClicked()} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                    <img src={PasswordIcon} />
                                    <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: '#EA2199' }}>Add device authentication</div>
                                </div>
                            </div>
                        </div>
                    }
                    {editVisible === 'personal' &&
                        <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='employee-dash-header'>Edit details</div>
                                <img className='cursor-pointer' onClick={resetEditSection} src={PinkXIcon} />
                            </div>

                            <br />
                            <div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>First name</label>
                                    <input value={tempUser.FirstName}
                                        id='FirstName'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='profile-changes-input'
                                        placeholder='First name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Last Name</label>
                                    <input value={tempUser.LastName}
                                        id='LastName'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='profile-changes-input'
                                        placeholder='Last Name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Work email</label>
                                    <input value={tempUser.WorkEmail}
                                        id='WorkEmail'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='profile-changes-input'
                                        placeholder='Work email'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Personal email</label>
                                    <input value={tempUser.PrivateEmail}
                                        id='PrivateEmail'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='profile-changes-input'
                                        placeholder='Personal Email'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Mobile number</label>
                                    <input value={tempUser.MobileNumber}
                                        id='MobileNumber'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='profile-changes-input'
                                        placeholder='Mobile number'
                                    />
                                </div>
                                <br />
                                <div className='flex-column' style={{ gap: '3px' }}>
                                    {
                                        userChanged &&
                                        <button className='profile-changes-btn confirm'
                                            onClick={handleUpdateUser}>
                                            Save changes
                                        </button>
                                    }
                                    <button className='profile-changes-btn cancel' onClick={resetEditSection}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    }
                    {editVisible === 'change-password' &&
                        <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='employee-dash-header'>Change password</div>
                                <img className='cursor-pointer' onClick={resetEditSection} src={PinkXIcon} />
                            </div>

                            <br />
                            <div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Old password</label>
                                    <input value={oldPassword}
                                        id='oldPassword'
                                        onChange={(e) => { handlePasswordChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='Old password'
                                        type="password"
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>New password</label>
                                    <input value={newPassword}
                                        id='newPassword'
                                        onChange={(e) => { handlePasswordChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='New password'
                                        type="password"
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Confirm new password</label>
                                    <input value={confirmNewPassword}
                                        id='confirmNewPassword'
                                        onChange={(e) => { handlePasswordChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='Confirm new password'
                                        type="password"
                                    />
                                </div>

                                <br />

                                <div className='flex-column' style={{ gap: '3px', marginTop: '10vh' }}>
                                    {error === "" ? null : <ErrorCard type='error' message={error} />}
                                    <button className='profile-changes-btn confirm'
                                        onClick={() => handleSetPassword()}>
                                        Save changes
                                    </button>
                                    <button className='profile-changes-btn cancel' onClick={resetEditSection}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    }
                    {editVisible === 'set-password' &&
                        <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='employee-dash-header'>Set a password</div>
                                <img className='cursor-pointer' onClick={resetEditSection} src={PinkXIcon} />
                            </div>
                            <br />
                            <div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>New password</label>
                                    <input value={newPassword}
                                        id='newPassword'
                                        onChange={(e) => { handlePasswordChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='New password'
                                        type="password"
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Confirm new password</label>
                                    <input value={confirmNewPassword}
                                        id='confirmNewPassword'
                                        onChange={(e) => { handlePasswordChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='Confirm new password'
                                        type="password"
                                    />
                                </div>
                                <br />
                                <div className='flex-column' style={{ gap: '3px', marginTop: '10vh' }}>
                                    {error === "" ? null : <ErrorCard type='error' message={error} />}
                                    <button className='profile-changes-btn confirm'
                                        onClick={() => handleSetPassword()}>
                                        Save changes
                                    </button>
                                    <button className='profile-changes-btn cancel' onClick={resetEditSection}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    }
                    {editVisible === 'primary' &&
                        <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='employee-dash-header'>Edit / Add Primary bank account</div>
                                <img className='cursor-pointer' onClick={resetEditSection} src={PinkXIcon} />
                            </div>
                            <br />
                            <div>
                                <div className='flex-column'>

                                    <label className='profile-details label indented'>Select bank</label>
                                    <select value={tempUser.PrimaryBankAccount ? tempUser.PrimaryBankAccount.BankName || '' : ''}
                                        className='profile-dropdown' id="PrimaryBankAccount,BankName"
                                        onChange={handleOnDetailsChange}>
                                        <option value="">Bank name</option>
                                        {
                                            banks.map((bnk, index) => (
                                                <option key={index} value={bnk.bankName}>{bnk.bankName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Select account type</label>
                                    <select value={tempUser.PrimaryBankAccount ? tempUser.PrimaryBankAccount.Type || '' : ''}
                                        className='profile-dropdown' id="PrimaryBankAccount,Type"
                                        onChange={(e) => { handleOnDetailsChange(e) }}>
                                        <option>Account type</option>
                                        {
                                            bankTypes.map((typ, index) => (
                                                <option key={index} value={typ.type}>{typ.type}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Branch code</label>
                                    <input value={tempUser.PrimaryBankAccount ? tempUser.PrimaryBankAccount.BranchCode || '' : ''}
                                        id='SecondaryBankAccount,BranchCode'
                                        className='profile-changes-input'
                                        placeholder='Branch code'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Account number</label>
                                    <input value={tempUser.PrimaryBankAccount ? tempUser.PrimaryBankAccount.AccountNumber || '' : ''}
                                        id='PrimaryBankAccount,AccountNumber'
                                        onChange={(e) => { handleOnDetailsChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='Account number'
                                    />
                                </div>

                                <br />
                                <div className='flex-column' style={{ gap: '3px' }}>
                                    {
                                        userChanged &&
                                        <button className='profile-changes-btn confirm'
                                            onClick={() => verifyBank("primary", tempUser.PrimaryBankAccount)}>
                                            Save changes
                                        </button>
                                    }
                                    <button className='profile-changes-btn cancel' onClick={resetEditSection}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    }
                    {editVisible === 'secondary' &&
                        <div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='employee-dash-header'>Add / Replace Secondary bank account</div>
                                <img className='cursor-pointer' onClick={resetEditSection} src={PinkXIcon} />
                            </div>
                            <br />
                            <div>
                                <div className='flex-column'>

                                    <label className='profile-details label indented'>Select bank</label>
                                    <select value={tempUser.SecondaryBankAccount ? tempUser.SecondaryBankAccount.BankName || '' : ''}
                                        className='profile-dropdown' id="SecondaryBankAccount,BankName"
                                        onChange={handleOnDetailsChange}>
                                        <option value="">Bank name</option>
                                        {
                                            banks.map((bnk, index) => (
                                                <option key={index} value={bnk.bankName}>{bnk.bankName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Select account type</label>
                                    <select value={tempUser.SecondaryBankAccount ? tempUser.SecondaryBankAccount.Type || '' : ''}
                                        className='profile-dropdown' id="SecondaryBankAccount,Type"
                                        onChange={(e) => { handleOnDetailsChange(e) }}>
                                        <option>Account type</option>
                                        {
                                            bankTypes.map((typ, index) => (
                                                <option key={index} value={typ.type}>{typ.type}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Branch code</label>
                                    <input value={tempUser.SecondaryBankAccount ? tempUser.SecondaryBankAccount.BranchCode || '' : ''}
                                        id='SecondaryBankAccount,BranchCode'
                                        className='profile-changes-input'
                                        placeholder='Branch code'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='profile-details label indented'>Account number</label>
                                    <input value={tempUser.SecondaryBankAccount ? tempUser.SecondaryBankAccount.AccountNumber || '' : ''}
                                        id='SecondaryBankAccount,AccountNumber'
                                        onChange={(e) => { handleOnDetailsChange(e) }}
                                        className='profile-changes-input'
                                        placeholder='Account number'
                                    />
                                </div>

                                <br />
                                <div className='flex-column' style={{ gap: '3px' }}>
                                    {
                                        userChanged &&
                                        <button className='profile-changes-btn confirm'
                                            onClick={() => verifyBank("secondary", tempUser.SecondaryBankAccount)}>
                                            Save changes
                                        </button>
                                    }
                                    <button className='profile-changes-btn cancel' onClick={resetEditSection}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    }
                </main>
            </div>
        );
    }
})

export default Profile
