import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DateRange } from '../../../../components/Next UI';
import { getLocalTimeZone } from "@internationalized/date";
import allBenefits from '../../../../components/benefits';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { FlexiblePayInvoice, MarketMembershipInvoice } from '../../../../components/pdf';
import { Input } from '../../../../components/basic';
import { PinkXIcon } from '../../../../assets/icons';
import { AutohideSnackbar } from '../../../../components/MUI';

import './styles.scss';

const AdminReportingInvoices = observer(({ data, setVisible, refresh }) => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [visibleChild, setVisibleChild] = useState('home');
    const [productFilter, setProductFilter] = useState('all');
    const [statusFilter, setStatusFilter] = useState('all');
    const [clientFilter, setClientFilter] = useState('all');
    const [dateRange, setDateRange] = useState([new Date(Date.now() - 25 * 24 * 60 * 60 * 1000), new Date()]);
    const [invoices, setInvoices] = useState( []);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [clients, setClients] = useState(data.clients || []);
    const [users, setUsers] = useState(data.users || []);
    const [editVisible, setEditVisible] = useState("");
    const [bulkInvoiceType, setBulkInvoiceType] = useState("");
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarType, setSnackBarType] = useState("");
    const [snackBarContent, setSnackBarContent] = useState("");
    const [invoiceCancelNote, setInvoiceCancelNote] = useState("");
    const [enterCancelInvoiceNote, setEnterCancelInvoiceNote] = useState(false);
    const [openInvoiceActions, setOpenInvoiceActions] = useState(null);
    const [userPermissions, setUserPermissions] = useState(commonStore.user.Permissions || []);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const invoicesPerPage = 100;
    const transactionStatuses = ["Paid", "Pending", "Cancelled"];

    useEffect(() => {
        handleFilterInvoices(invoices);
    }, [clientFilter, productFilter, dateRange, statusFilter, invoices]);

    useEffect(() => {
        loadAllData();
    }, []);

    const loadAllData = async () => {
        try {
            commonStore.setLoading(true)
            const res = await commonStore.getInvoicesNoPop()
            if (res) {
                setInvoices(res)
                handleFilterInvoices(res)
            }
        } catch (error) {
            console.error("Error loading invoices:", error)
        } finally {
            commonStore.setLoading(false)
        }
    };

    const handleFilterInvoices = (_invoices) => {
        let filtered = _invoices.filter((inv) => {
            if (
                (inv.Client === clientFilter || clientFilter === "all") &&
                (inv.Product === productFilter || productFilter === "all") &&
                (inv.Status === statusFilter || statusFilter === "all") &&
                (new Date(inv.InvoiceDate) >= dateRange[0] && new Date(inv.InvoiceDate) <= dateRange[1])
            ) 
            return true;
            else return false;
        })
        filtered.sort((a, b) => new Date(b.InvoiceDate) - new Date(a.InvoiceDate))
        setFilteredInvoices(filtered)
        forceUpdate()
        setCurrentPage(1)
    };


    const handleSetVisible = (state) => {
        setVisible(state);
    };

    const resetSnackBar = () => {
        setSnackBarOpen(false);
        setSnackBarType("");
        setSnackBarContent("");
    };
    const setSnackBarDetails = (type, message) => {
        setSnackBarOpen(true);
        setSnackBarType(type);
        setSnackBarContent(message);
    };

    const handleSelectProductFilter = (e) => {
        setProductFilter(e.target.value);
    };
    const handleSelectStatusFilter = (e) => {
        setStatusFilter(e.target.value);
    };
    const handleSelectClientFilter = (e) => {
        setClientFilter(e.target.value);
    };

    const handleDateRangeOnChange = (date) => {
        setDateRange([
            date.start.toDate(getLocalTimeZone()),
            date.end.toDate(getLocalTimeZone())
        ]);
    };

    const getUserObjByUn = (username) => {
        let user = users.find((user) => user.Username === username);
        return user || {};
    };

    const getUserObj = (id) => {
        let user = users.find((user) => user._id === id);
        return user || {};
    };

    const getClientObj = (id) => {
        let client = clients.find((cli) => cli._id === id);
        return client || {};
    };

    const reset = () => {
        setEditVisible("");
        setVisibleChild("home");
        setInvoiceCancelNote("");
        setEnterCancelInvoiceNote(false);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "Cancelled": return 'red';
            case "Pending": return 'orange';
            case "Paid": return 'green';
            default: return 'black';
        }
    };

    const handleGenerateFlexiblePayInvoicePdf = async (invoice) => {
        let invoiceTransactions = invoice.Transactions.map((trans) => {
            let latestAdvance = trans.AdvanceIds.sort((a, b) => new Date(b.Date) - new Date(a.Date))[0];
            return {
                ...trans,
                FirstName: getUserObjByUn(trans.Username).FirstName,
                LastName: getUserObjByUn(trans.Username).LastName,
                Date: latestAdvance.Date
            };
        });
        console.log(invoiceTransactions);

        // Create a PDF blob using the pdf function from @react-pdf/renderer
        const pdfBlob = await pdf(<FlexiblePayInvoice transactions={invoiceTransactions} invoice={invoice} client={getClientObj(invoice.Client)} />).toBlob();

        // Create a link element, set its href to the PDF blob, and trigger a download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `${getClientObj(invoice.Client)?.Name}_${invoice.InvoiceNumber}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleGenerateMarketplaceInvoicePdf = async (invoice) => {
        // Create a PDF blob using the pdf function from @react-pdf/renderer
        const pdfBlob = await pdf(<MarketMembershipInvoice invoice={invoice} client={getClientObj(invoice.Client)} />).toBlob();

        // Create a link element, set its href to the PDF blob, and trigger a download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `${getClientObj(invoice.Client)?.Name}_${invoice.InvoiceNumber}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleCreateInvoicesClick = () => {
        setEditVisible("bulk-invoices");
    };
    const resetEditSection = () => {
        setEditVisible("");
        setBulkInvoiceType("");
    };

    const handleInvoiceOpen = (invoiceId) => {
        reset();
        setOpenInvoiceActions((prev) => (prev === invoiceId ? null : invoiceId));
    };

    const handleCreateBulkInvoices = async () => {
        if (!userPermissions.includes("generate-invoices")) {
            commonStore.setSnackBar("warning", "You are not permitted to perform this action");
            return;
        }
        if (bulkInvoiceType === "marketplaceMembership") {
            let res = await commonStore.createBulkMarketplaceMembershipInvoices();
            if (res.invoices?.created) {
                setSnackBarDetails("success", `${res.invoices.created.length} invoices Generated`);
                setOpenInvoiceActions(null);
                loadAllData();
            }
            else if (res?.status) {
                setSnackBarDetails("info", `${res.message || "Error occured"}`);
            }
            else {
                setSnackBarDetails("error", `Error generating invoices`);
            }
        }
        else if (bulkInvoiceType === "flexiblePay") {
            await bulkWriteAdvancesToPayslip();
            await generateBulkFlexiblePayInvoices();
            setOpenInvoiceActions(null);
        }
        resetEditSection();
    };

    const bulkWriteAdvancesToPayslip = async () => {
        let payslipRes = await commonStore.bulkWriteAdvancesToPayslip();
        console.log(payslipRes);
        if (payslipRes?.status) {
            setSnackBarDetails("success", `${payslipRes.data.createPayrollLogs?.created?.length || 0} logs created, ${payslipRes.data.updatedTransactions?.updated?.modifiedCount || 0} advances updated. Please check logs for more details`);
        } else if (payslipRes?.data?.message) {
            setSnackBarDetails("info", `${payslipRes.data.message}`);
        } else {
            setSnackBarDetails("error", `Error occurred`);
        }
    };

    const generateBulkFlexiblePayInvoices = async () => {
        let invoicesRes = await commonStore.generateBulkFlexiblePayInvoices({});
        console.log(invoicesRes);
        if (invoicesRes?.status) {
            setSnackBarDetails("success", `${invoicesRes.data.createInvoices?.created?.length || 0} invoices created, ${invoicesRes.data.updatedTransactions?.updated?.modifiedCount || 0} advances updated.`);
            loadAllData();
        } else if (invoicesRes?.data?.message) {
            setSnackBarDetails("info", `${invoicesRes.data.message}`);
        } else {
            setSnackBarDetails("error", `Error occurred`);
        }
    };

    const handleGenerateInvoice = (inv) => {
        switch (inv.Product) {
            case "Flexible pay":
                handleGenerateFlexiblePayInvoicePdf(inv);
                break;
            case "Level finance marketplace":
                handleGenerateMarketplaceInvoicePdf(inv);
                break;
            default:
                setSnackBarDetails("error", `Error generating pdf`);
                break;
        }
    };

    const handleSelectBulkInvoiceType = (e) => {
        setBulkInvoiceType(e.currentTarget.id);
    };

    const handleCancelInvoice = async (e) => {
        if (!userPermissions.includes("edit-reporting")) {
            commonStore.setSnackBar("warning", "You are not permitted to perform this action");
            return;
        }
        if (!window.confirm("Are you sure you want to cancel this invoice")) {
            return;
        }
        let id = e.currentTarget.id;
        let note = { date: new Date(), user: commonStore.user?.Username, note: invoiceCancelNote || "No note" };
        let update = { Status: "Cancelled", Notes: note };
        let res = await commonStore.cancelInvoice(id, update);
        console.log(res);
        if (res.status) {
            if (res.response.transactions === false) setSnackBarDetails("warning", `Invoice cancelled, but transactions not updated. Requires actions!!`);
            else setSnackBarDetails("success", `Invoice successfully cancelled`);
            reset();
            setOpenInvoiceActions(null);
            loadAllData();
        }
        else {
            setSnackBarDetails("error", `Error occurred`);
        }
    };

    const handleSetInvoicePending = async (e) => {
        if (!userPermissions.includes("edit-reporting")) {
            commonStore.setSnackBar("warning", "You are not permitted to perform this action");
            return;
        }
        if (!window.confirm("Are you sure you want to set this invoice as pending")) {
            return;
        }
        let id = e.currentTarget.id;
        let note = { date: new Date(), user: commonStore.user?.Username, note: `Manually marked as pending` };
        let update = { Status: "Pending", Notes: note };
        let res = await commonStore.updateInvoice(id, update);
        console.log(res);
        if (res.status) {
            setSnackBarDetails("success", `Invoice set as pending`);
            reset();
            setOpenInvoiceActions(null);
            loadAllData();
        }
        else {
            setSnackBarDetails("error", `Error occurred`);
        }
    };

    const handleSetInvoicePaid = async (e) => {
        if (!userPermissions.includes("edit-reporting")) {
            commonStore.setSnackBar("warning", "You are not permitted to perform this action");
            return;
        }
        if (!window.confirm("Are you sure you want to set this invoice as paid")) {
            return;
        }
        let id = e.currentTarget.id;
        let note = { date: new Date(), user: commonStore.user?.Username, note: `Manually marked as paid` };
        let update = { Status: "Paid", Notes: note };
        let res = await commonStore.updateInvoice(id, update);
        console.log(res);
        if (res.status) {
            setSnackBarDetails("success", `Invoice set as paid`);
            reset();
            setOpenInvoiceActions(null);
            loadAllData();
        }
        else {
            setSnackBarDetails("error", `Error occurred`);
        }
    };

    const handleCancelInvoiceNoteChange = (e) => {
        setInvoiceCancelNote(e.currentTarget.value);
    };

    // Calculate total pages
    const totalPages = Math.ceil(filteredInvoices.length / invoicesPerPage);

    // Get current invoices
    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <>
                {visibleChild === "home" &&
                    <div>
                        <AutohideSnackbar content={snackBarContent} open={snackBarOpen} type={snackBarType} setSnackBarClose={resetSnackBar} />
                        {editVisible === 'bulk-invoices' &&
                            <main style={{ borderRadius: '15px', background: '#80808033', height: 'fit-content' }}>
                                <div>
                                    <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                        Create bulk invoices
                                    </header>
                                    <br />
                                    <div className='flex gap-1 margin-2 items-center'>
                                        <div id="flexiblePay" onClick={handleSelectBulkInvoiceType} className={`client-benefits-filter-item ${bulkInvoiceType === "flexiblePay" ? 'selected' : null}`}>
                                            Flexible Pay
                                        </div>
                                        <div id="marketplaceMembership" onClick={handleSelectBulkInvoiceType} className={`client-benefits-filter-item ${bulkInvoiceType === "marketplaceMembership" ? 'selected' : null}`}>
                                            Marketplace membership
                                        </div>
                                    </div>
                                    <br />
                                    {bulkInvoiceType === 'flexiblePay' &&
                                        <p className='text-[red]'>
                                            Please note this will write all advances to payslip and create all flexible pay invoices for this month.
                                        </p>
                                    }
                                    {bulkInvoiceType === 'marketplaceMembership' &&
                                        <p className='text-[red]'>
                                            Please note this will generate Marketplace membership invoices for all clients.
                                        </p>
                                    }
                                    <br />
                                    <div className='flex justify-between'>
                                        <button className='admin-dash-back-arrow-btn' onClick={reset}>Cancel</button>
                                        {bulkInvoiceType &&
                                            <button className='admin-dash-back-arrow-btn'
                                                style={{ background: 'black', color: 'white' }}
                                                id='userDetails'
                                                onClick={handleCreateBulkInvoices}>
                                                Create
                                            </button>}
                                    </div>
                                </div>
                                <br />
                            </main>
                        }

                        <div style={{ background: "#FFFFFF", borderRadius: '20px' }}>
                            <div className='flex justify-between margin-0.5'>
                                <p style={{ fontSize: '15px', color: 'gray', marginLeft:'3px' }}>
                                    {`Total: ${filteredInvoices.length} filtered INVOICES`}<i style={{ cursor: 'pointer' }} onClick={loadAllData} class="fa-solid fa-arrow-rotate-right"></i></p>
                                {editVisible !== "bulk-invoices" &&
                                    <button onClick={handleCreateInvoicesClick}
                                        className='admin-dash-add-btn'>
                                        Create invoices
                                    </button>}
                            </div>
                            <br />
                            {/* TOP DIV */}
                            <div className='admin-reporting-fitler-container'>
                                <hr />
                                <div className='flex gap-3 items-center flex-wrap gap-1'>
                                    <DateRange value={dateRange} onChange={handleDateRangeOnChange} />
                                    {clients.length > 0 &&
                                        <>
                                            <div style={{ borderLeft: '1px solid #000' }}></div>
                                            <div className='flex gap-2'>
                                                {/* CLIENTS */}
                                                <FormControl style={{ width: '200px' }}>
                                                    <InputLabel id="client-select-label">Clients</InputLabel>
                                                    <Select labelId="client-select-label" id="client-select"
                                                        value={clientFilter} label="Filter" onChange={handleSelectClientFilter} style={{ fontSize: '13px' }}>
                                                        <MenuItem value={'all'}>All clients</MenuItem>
                                                        {clients.map((cli) => (
                                                            <MenuItem value={cli._id} key={cli._id}>{cli.Name}</MenuItem>
                                                        ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                                {/* STATUS */}
                                                <FormControl style={{ width: '200px' }}>
                                                    <InputLabel id="status-select-label">Status</InputLabel>
                                                    <Select labelId="status-select-label" id="status-select"
                                                        value={statusFilter} label="Filter" onChange={handleSelectStatusFilter} style={{ fontSize: '13px' }}>
                                                        <MenuItem value={'all'}>All statuses</MenuItem>
                                                        {transactionStatuses.map((stat) => (
                                                            <MenuItem value={stat} key={stat}>{stat}</MenuItem>
                                                        ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                                {/* PRODUCTS */}
                                                <FormControl style={{ width: '200px' }}>
                                                    <InputLabel id="product-select-label">Benefits</InputLabel>
                                                    <Select labelId="product-select-label" id="product-select"
                                                        value={productFilter} label="Filter" onChange={handleSelectProductFilter} style={{ fontSize: '13px' }}>
                                                        <MenuItem value={'all'}>All benefits</MenuItem>
                                                        <MenuItem value={'Level finance marketplace'}>Marketplace</MenuItem>
                                                        {allBenefits.map((ben) => (
                                                            <MenuItem value={ben.Name} key={ben.Name}>{ben.Name}</MenuItem>
                                                        ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <br />
                            {invoices.length > 0 &&
                                (<div className='flex-column gap-2'>
                                    {/* Pagination Controls */}
                                    <div className='employee-benefits-assessment-submit-header' style={{ width: '100%', fontSize: '20px', display: 'flex', justifyContent: 'space-evenly', padding: '20px 0px' }}>
                                        <div className='cursor-pointer' onClick={() => paginate(currentPage - 1)} style={{ visibility: currentPage === 1 ? 'hidden' : 'visible' }}>{"<"}</div>
                                        <span>Page {currentPage} of {totalPages}</span>
                                        <div className='cursor-pointer' onClick={() => paginate(currentPage + 1)} style={{ visibility: currentPage === totalPages ? 'hidden' : 'visible' }}>{">"}</div>
                                    </div>
                                    {currentInvoices.map((inv, index) => (
                                        <div key={index} className='reports-invoice-item-container'>
                                            {/* Invoice details */}
                                            <div className='reports-invoice-item-container-section-1'>
                                                <div>
                                                    <p className='text-[16px] text-bold' >{`${getClientObj(inv.Client)?.Name || "No Client name"}`}</p>
                                                    <p className='text-[gray]'>{`${inv.Product}`}</p>
                                                    <p>Invoice Number: {inv.InvoiceNumber || "No Invoice number"}</p>
                                                </div>
                                                <p style={{ color: getStatusColor(inv.Status) }}>{inv.Status || "No status"}</p>
                                                <p>Total amount (incl VAT): R {inv.TotalIncl?.toLocaleString() || "No total amount"}</p>
                                                <p>{`${new Date(inv.InvoiceDate)?.toLocaleDateString()}` || "No date"}</p>
                                                <div className='flex flex-row gap-3'>
                                                    <p id={inv._id} className='cursor-pointer text-[red] font-size-5'
                                                        onClick={() => handleGenerateInvoice(inv)}>
                                                        <i className="fa-regular fa-file-pdf"></i>
                                                    </p>
                                                </div>
                                                <p id={inv._id} onClick={() => handleInvoiceOpen(inv._id)} className='text-[15px] cursor-pointer'>{"..."}</p>
                                            </div>
                                            {openInvoiceActions === inv._id && (
                                                <>
                                                    <div className='invoice-actions-section'>
                                                        {inv.Notes?.length > 0 &&
                                                            <div className='flex gap-1'>
                                                                <div className='flex gap-1 flex-column'>
                                                                    <p>Notes: </p>
                                                                    {inv.Notes.map((nt, idx) => (
                                                                        <p key={idx} className='text-[11px] font-light'>{`${new Date(nt.date).toLocaleDateString()} - ${nt.user} - ${nt.note}`}</p>
                                                                    ))}
                                                                </div>
                                                            </div>}
                                                        <div>
                                                            {(inv.Status !== "Paid" && inv.Status !== "Cancelled") && <div>
                                                                <p className='text-[red] cursor-pointer' onClick={() => setEnterCancelInvoiceNote(true)}>Cancel invoice</p>
                                                            </div>}
                                                            {(inv.Status !== "Paid" && inv.Status !== "Cancelled") && <div>
                                                                <p id={openInvoiceActions} className='text-[green] cursor-pointer' onClick={handleSetInvoicePaid}>Mark as Paid</p>
                                                            </div>}
                                                            {inv.Status !== "Cancelled" && <div>
                                                                <p className='cursor-pointer' onClick={() => { }}>Email <i className="fa-regular fa-envelope"></i></p>
                                                            </div>}

                                                        </div>

                                                    </div>
                                                    {enterCancelInvoiceNote && (
                                                        <div className='flex gap-1'>
                                                            <div className="flex flex-row gap-4">
                                                                <Input width='fit-content' placeholder={"Cancellation reason"} value={invoiceCancelNote} onChange={handleCancelInvoiceNoteChange} />
                                                                <button id={openInvoiceActions} onClick={handleCancelInvoice}
                                                                    className='admin-dash-add-btn'>
                                                                    Enter
                                                                </button>
                                                            </div>
                                                            <img className='cursor-pointer' onClick={reset} src={PinkXIcon} />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    ))}
                                    <div className='employee-benefits-assessment-submit-header' style={{ width: '100%', fontSize: '20px', display: 'flex', justifyContent: 'space-evenly', padding: '20px 0px' }}>
                                        <div className='cursor-pointer' onClick={() => paginate(currentPage - 1)} style={{ visibility: currentPage === 1 ? 'hidden' : 'visible' }}>{"<"}</div>
                                        <span>Page {currentPage} of {totalPages}</span>
                                        <div className='cursor-pointer' onClick={() => paginate(currentPage + 1)} style={{ visibility: currentPage === totalPages ? 'hidden' : 'visible' }}>{">"}</div>
                                    </div>

                                </div>)
                            }
                            <br />
                        </div>
                    </div>
                }
            </>
        );
    }
});

export default AdminReportingInvoices;
