import React, { useState } from 'react';
import { FlexNavHomeOn, FlexNavManageOff, FlexNavManageOn, WhiteXIcon, FlexNavHomeOff } from '../../assets/icons/index'
import { useStore } from '../../stores/Store';
import './styles.scss'
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'is-mobile';

const FlexiblePayBottomNav = ({ setPage }) => {

    const navigate = useNavigate()
    const [selected, setSelected] = useState("Home")

    const handleSelected = (e) => {
        let sel = e.currentTarget.id
        setSelected(sel)
        setPage(sel)
    }

    return (
        <div style={{ border: 'none', padding: '8px 30px', width: '100%', height: 'auto', position: 'sticky', bottom: 0, background: '#0C0C0C', zIndex: 10, borderRadius: '15px', display: 'flex', flexDirection: 'column' }} className='admin-dash-topbar'>
            <p style={{ margin: 0, color: '#c5c3c3', fontSize: '10px', fontWeight: 100 }}>FlexiblePay</p>
            <hr style={{ width: '100%', color: '#c5c3c3' }} />
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div id="Home" onClick={handleSelected} className='flex-column' style={{ alignItems: 'center' }}>
                    <img src={selected === "Home" ? FlexNavHomeOn : FlexNavHomeOff} style={{ width: '26px' }} />
                    <div className={`bottom-nav-label-flexible-pay ${selected === "Home" && 'selected'}`}>Home</div>
                </div>
                <div id="Manage" onClick={handleSelected} className='flex-column' style={{ alignItems: 'center' }}>
                    <img src={selected === "Manage" ? FlexNavManageOn : FlexNavManageOff} style={{ width: '26px' }} />
                    <div className={`bottom-nav-label-flexible-pay ${selected === "Manage" && 'selected'}`}>Manage</div>
                </div>
                <div id="Exit" onClick={() => navigate('/employee/home')} className='flex-column' style={{ alignItems: 'center' }}>
                    <img src={WhiteXIcon} style={{ width: '26px' }} />
                    <div className='bottom-nav-label-flexible-pay'>Exit</div>
                </div>
            </div>
        </div>
    );
};

export default FlexiblePayBottomNav;
