import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Link } from 'react-router-dom';
import { LandingImage } from '../../../assets/icons';
import { Button, Navbar, Input, ErrorCard, TopBar } from '../../../components/basic/index'

import './styles.scss';

const ConfirmClientAccount = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const location = useLocation();

    const [userId, setUserId] = useState('');
    const [jwt, setJwt] = useState('');

    //use useLocation need jwt and identifier, sign token payload is resetpassword token
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [errorType, setErrorType] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        console.log(queryParams)
        const jwtToken = queryParams.get('tk');
        const holder = queryParams.get('holder');

        if (!jwtToken || !holder) {
            navigate('/error');
        } else {
            setUserId(holder);
            setJwt(jwtToken)
        }
    }, [location, navigate]);

    const createAccount = async () => {

        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        } else if (password) {
            // Check the strength of the password
            if (password.length < 8) {
                setError("Password must be at least 8 characters long");
                console.log(error)
                return;
            }

            let hasUpperCase = /[A-Z]/.test(password);
            let hasLowerCase = /[a-z]/.test(password);
            let hasNumber = /\d/.test(password);
            let hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

            if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar) {
                setError("Password should include at least one uppercase letter, one lowercase letter, one number, and one special character");
                setErrorType('password chars')

                return;
            }
        }
        else {
            setError("invalid details");
            return;
        }
        commonStore.setLoading(true)
        try {
            let ret = await commonStore.setEmployerAdminPassword(userId, password, jwt)
            commonStore.setLoading(false)
            if (ret) {
                console.log(ret)
                navigate(`/client/home/Overview`)
            }
            else {
                console.log(ret)
            }
        } catch (error) {
            console.error(error)
            setError("Error occured, please try again")
        }



    }

    const handlePasswordOnChange = (value) => {
        setPassword(value)
        setError('')
        setErrorType('')
    }

    const handleConfirmPasswordOnChange = (value) => {
        setConfirmPassword(value)
        setError('')
        setErrorType('')
    }

    if (isMobile()) {
        return (
            <div>
                <div>
                    <TopBar />
                    <main style={{ justifyContent: 'center', marginTop: '5vh', padding: '20px' }}>
                        <header className='signin-main-header'>Welcome back!</header>
                        <p className='employee-benefits-assessment-submit-subheader' style={{ marginRight: 'auto', fontSize: '15px', color: '#010143a8' }}>
                            Complete your user account by securing it with a password.
                        </p>
                        <div className='signin-inputs-container' style={{ width: '100%' }}>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Password</label>
                                </div>
                                <input className='signin-input-item'
                                    onChange={(e) => handlePasswordOnChange(e.target.value)}
                                    value={password} width='100%' placeholder={"Please enter your password"}
                                    type='password'
                                />
                            </div>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Confirm password</label>
                                </div>
                                <input className='signin-input-item'
                                    onChange={(e) => handleConfirmPasswordOnChange(e.target.value)}
                                    value={confirmPassword} width='100%' placeholder={"Please confirm your password"}
                                    type='password'
                                />
                            </div>
                            <button className='signin-sign-in-btn' onClick={() => createAccount()}>Confirm account</button>
                        </div>

                        <div className='disclamer-text'>
                            By using Level you agree to our <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> & <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        </div>
                        {
                            error !== "" &&
                            <ErrorCard type='error' message={error} />
                        }
                    </main>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <div style={{ display: 'flex' }}>
                    <div>
                        <img src={LandingImage} style={{ height: '100vh' }} />
                    </div>
                    <main style={{ justifyContent: 'center', marginTop: '10vh', padding: '100px 200px 0px 100px' }}>
                        <header className='signin-main-header'>Welcome back!</header>
                        <p className='employee-benefits-assessment-submit-subheader' style={{ marginRight: 'auto', fontSize: '15px', color: '#010143a8' }}>
                            Complete your user account by securing it with a password.
                        </p>
                        <div className='signin-inputs-container'>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Password</label>
                                </div>
                                <input className='signin-input-item'
                                    onChange={(e) => handlePasswordOnChange(e.target.value)}
                                    value={password} width='100%' placeholder={"Please enter your password"}
                                    type='password'
                                />
                            </div>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Confirm password</label>
                                </div>
                                <input className='signin-input-item'
                                    onChange={(e) => handleConfirmPasswordOnChange(e.target.value)}
                                    value={confirmPassword} width='100%' placeholder={"Please confirm your password"}
                                    type='password'
                                />
                            </div>
                            <button className='signin-sign-in-btn' onClick={() => createAccount()}>Confirm account</button>
                        </div>

                        <div className='disclamer-text'>
                            By using Level you agree to our <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> & <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        </div>
                        {
                            error !== "" &&
                            <ErrorCard type='error' message={error} />
                        }
                    </main>
                </div>
            </div>
        );
    }
})

export default ConfirmClientAccount
