import React, { useState, useEffect } from 'react';
import { useLinkClickHandler, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button } from '../../../components/basic/index'
import { BackArrow } from '../../../assets/icons/index'
import { RadialChartBasline } from '../../../components/charts';
import { EmployeeBaslineResults } from '../../../components/index'
import NonResponsivePage from '../../nonResponsive';
import './styles.scss';

const EmployeeHome = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [user, setUser] = useState(null)
    const [preBaselineRequired, setPreBaselineRequired] = useState(true)
    const [preBaselineAssessment, setPreBaselineAssessment] = useState({})
    const [postBaselineRequired, setPostBaselineRequired] = useState(true)
    const [postBaselineAssessment, setPostBaselineAssessment] = useState({})
    const [baselineType, setBaselineType] = useState('pre')
    const [visible, setVisible] = useState('home')
    const [lastUpdatedDate, setLastUpdatedDate] = useState(null)
    const [scoreChange, setScoreChange] = useState(0)

    useEffect(() => {
        getUser()
    }, [])
    console.log("sdsdfs")

    const getUser = async () => {
        let _preBaselineRequired = true
        let _preBaselineAssessment = {}
        let _postBaselineRequired = true
        let _postBaselineAssessment = {}
        let _lastUpdatedDate = null
        let _scoreChange = 0
        try {
            let res = commonStore.user
            if (res) {
                commonStore.setUser(res)
                setUser(res)
                //baseline
                if (res.FinancialHealth) {
                    if (res.FinancialHealth.PreBaselineAssessment) {
                        let pre = res.FinancialHealth.PreBaselineAssessment
                        _preBaselineAssessment = pre
                        _preBaselineRequired = false
                        _lastUpdatedDate = new Date(pre.DateTaken).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric'
                        })

                        if (res.FinancialHealth.PostBaselineAssessment) {
                            let post = res.FinancialHealth.PostBaselineAssessment
                            _postBaselineAssessment = post
                            _postBaselineRequired = false

                            let preOverall = pre.OverallScore || 0
                            let postOverall = post.OverallScore || 0
                            console.log(preOverall, postOverall)
                            _scoreChange = Number(preOverall - postOverall) || 0
                            _lastUpdatedDate = post.DateTaken || null
                            if (_lastUpdatedDate) _lastUpdatedDate = new Date(_lastUpdatedDate).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric'
                            })
                        }
                    }
                }
            }
            else {
                commonStore.setLoading(false)
                commonStore.logout()
                navigate('/*')
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            commonStore.logout()
            navigate('/*')
        }
        setPreBaselineAssessment(_preBaselineAssessment)
        setPreBaselineRequired(_preBaselineRequired)
        setPostBaselineAssessment(_postBaselineAssessment)
        setPostBaselineRequired(_postBaselineRequired)
        setScoreChange(_scoreChange)
        console.log(_lastUpdatedDate)
        setLastUpdatedDate(_lastUpdatedDate)
        forceUpdate()
    }

    const handleOpenFinHealth = () => {
        setVisible("fin-health")
    }

    if (isMobile()) {
        return (
            <main>
                {visible === 'home' &&
                    <div>
                        <header className='employee-dash-header'>Home</header>
                        <br />
                        <div className='employee-dash-home-widget-container'>
                            {
                                preBaselineRequired ?
                                    <div className='employee-dash-baseline-completed-widget'>
                                        <div className='employee-dash-home-widget-header'>Financial health score</div>
                                        <br />
                                        <div className='employee-dash-warning-widget'>
                                            <div className='employee-dash-warning-widget'>
                                                Complete baseline assessment so that we can continue providing you the best financial services tailored for you.
                                            </div>
                                        </div>
                                        <br />
                                        <Button onClick={() => navigate(`/employee/baseline?id=${baselineType}`)} width='100%' fontSize='13px'>Start assessment</Button>
                                    </div>
                                    :
                                    <div className='employee-dash-baseline-completed-widget' onClick={handleOpenFinHealth}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div className='employee-dash-home-widget-header'>Financial health score</div>
                                            <div className='font-bold text-[25px] cursor-pointer' onClick={handleOpenFinHealth}>...</div>
                                        </div>
                                        <div className=''>
                                            <RadialChartBasline overallScore={preBaselineAssessment.OverallScore} />
                                            <p className='text-center text-[#E66261]' style={{ color: `${scoreChange > 0 ? 'green' : 'red'}` }}>
                                                {`${scoreChange > 0 ? "+" : ""}${scoreChange?.toFixed(2)} points`}
                                            </p>
                                            {lastUpdatedDate &&
                                                <p className='text-center'>{`Last taken on ${lastUpdatedDate || ''}`}</p>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                }

                {visible === "fin-health" &&
                    <div>
                        <div style={{ marginBottom: '10px' }} className='back-arrow-btn' onClick={() => setVisible("home")}>
                            <img src={BackArrow} />
                            <div>Back</div>
                        </div>
                        <br />
                        <header className='employee-dash-header'>Financial health score</header>
                        <br />
                        <br />
                        <EmployeeBaslineResults
                            SpendScore={preBaselineAssessment.SpendScore}
                            SaveScore={preBaselineAssessment.SaveScore}
                            PlanScore={preBaselineAssessment.PlanScore}
                            BorrowScore={preBaselineAssessment.BorrowScore}
                            OverallScore={preBaselineAssessment.OverallScore} />
                    </div>
                }
            </main>
        );
    } else {
        return (
            <main>
                <NonResponsivePage />
            </main>
        );
    }
})

export default EmployeeHome;

