import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import { ErrorCard } from '../../../../components/basic';
import { SuccessIcon } from '../../../../assets/icons';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import './styles.scss';
import { common } from '@mui/material/colors';

const EmployeeCheckoutSuccess = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    if (isMobile()) {
        return (
            <div>
                <main style={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ width: '200px' }} src={SuccessIcon} />
                    <p>Your withdrawal was successful. Please note that the amount might take a few hours / days to reflect in yout bank account.</p>
                    <button className='flexible-pay-dash-btn' onClick={() => navigate('/employee/home')}>{"Back to dashboard"}</button>
                </main>
            </div>
        );
    } else {
        return (
            <div>
                <main style={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ width: '200px' }} src={SuccessIcon} />
                    <p>Your withdrawal was successful. Please note that the amount might take a few hours / days to reflect in yout bank account.</p>
                    <button className='flexible-pay-dash-btn' onClick={() => navigate('/employee/home')}>{"Back to dashboard"}</button>
                </main>
            </div>
        );
    }
})

export default EmployeeCheckoutSuccess
