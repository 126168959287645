import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store'
import { isMobile } from 'is-mobile';
import allBenefits from '../../../../components/benefits';
import ClientBenefitsFlexiblePay from './flexiblepay';

import './styles.scss';

const ClientBenefits = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [client, setClient] = useState('');
    const [filter, setFilter] = useState('all')
    const [visible, setVisible] = useState("Home")
    const [activatedBenefits, setActivatedBenefits] = useState([])
    const [userPermissions, setUserPermissions] = useState(commonStore.user.Permissions || [])

    const resetVisible = () => {
        setVisible("Home")
    }

    const handleSelectFilter = (e) => {
        setFilter(e.currentTarget.id)
    }

    useEffect(() => {
        getClient()
    }, [])

    const getClient = async () => {
        try {
            setClient(commonStore.client)
            forceUpdate()
            let cli = commonStore.client
            if (cli.AvailableProducts?.length > 0) {
                let active = cli.AvailableProducts.filter((item) => item.ClientHasConfirmed).map((item) => item.id)
                setActivatedBenefits(active)
            }
        } catch (error) {
            console.error("Error fetching client:", error);
            commonStore.logout();
            navigate('/signin');
            commonStore.setSnackBar("error", "Error occured")
        }
    };

    const handleBenefitClick = (ben) => {
        if (!userPermissions.includes("edit-benefit-controls")) {
            commonStore.setSnackBar("warning", "You are not permitted to view benefits information")
            return
        }
        if (activatedBenefits.length > 0 && !activatedBenefits.includes(ben.id)) {
            commonStore.setSnackBar("warning", "This benefit is not activated")
            return
        }
        switch (ben.Name) {
            case "Flexible pay":
                setVisible(ben.Name)
                break
            case "Credit builder":
                setVisible(ben.Name)
                break
            default:
                break
        }
    }

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <div>
                {visible === 'Home' &&
                    <main style={{ background: "#F4F5FA", width: '100%', height: '90vh' }}>
                        <header className='admin-dash-option-header'>{`Benefits`}</header>
                        <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                            <div className='client-benefits-filter-section'>
                                <div id="all" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "all" ? 'selected' : null}`}>
                                    All
                                </div>
                                <div id="financial" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "financial" ? 'selected' : null}`}>
                                    Financial
                                </div>
                                <div id="physical" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "physical" ? 'selected' : null}`}>
                                    Physical
                                </div>
                                <div id="emotional" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "emotional" ? 'selected' : null}`}>
                                    Emotional
                                </div>
                                <div id="social" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "social" ? 'selected' : null}`}>
                                    Social
                                </div>
                            </div>

                            <br />

                            <div>
                                <div className='client-benefits-container-wrap' style={{ gap: '10px' }}>
                                    {allBenefits.filter((ben) => {
                                        if (filter === "all") {
                                            return ben
                                        }
                                        else if (filter === ben.type) {
                                            return ben
                                        }
                                    }).map((ben) => (
                                        <div key={ben.id} onClick={() => handleBenefitClick(ben)} className='employee-dash-explore-benefit-container' style={{ width: '250px', cursor: 'pointer', height: '140px' }}>
                                            <div className='flex flex-row justify-between w-full items-center'>
                                                <div style={{ color: '#010143', fontSize: '14px', fontWeight: 600 }}>{ben.Name}</div>
                                                <img src={ben.image} className='employee-dash-benefit-img' alt='benefit logo' />
                                            </div>
                                            <div className='confirm-employee-benefits-benefit-description-card-description-text-container'>
                                                <p style={{ margin: 0, fontSize: '11px' }} className='confirm-employee-benefits-benefit-description-card-type-text'>
                                                    {ben.description}
                                                </p>
                                            </div>
                                            {activatedBenefits.length > 0 && activatedBenefits.includes(ben.id) &&
                                                <div style={{ padding: '5px 8px', borderRadius: '10px', background: '#E3FFE2', marginLeft: 'auto' }}>
                                                    <div style={{ color: '#2CB573', fontSize: '10px', fontWeight: 500 }}>Active</div>
                                                </div>
                                            }
                                        </div>

                                    ))
                                    }
                                </div>
                            </div>
                        </main>

                    </main>}
                {visible === "Flexible pay" &&
                    <ClientBenefitsFlexiblePay backHome={resetVisible} />
                }
            </div>

        );
    }
})

export default ClientBenefits
