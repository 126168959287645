import React, { useState, useEffect } from 'react';
import { useLinkClickHandler, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, TopBar, Input, ErrorCard } from '../../../components/basic/index'
import { SuccessIcon } from '../../../assets/icons/index'

import './style.scss';

const AuthSuccess = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [error, setError] = useState('')
    const [displayProfile, setDisplayProfile] = useState(false)
    const [client, setClient] = useState(null)

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
    }, [])

    const handleNext = async () => {
        try {
            commonStore.setLoading(true)
            let res = await commonStore.getClientByObjectId(commonStore.user.Client._id)
            commonStore.setLoading(false);
            setClient(res)
            setDisplayProfile(true)
            return
        } catch (error) {
            commonStore.setLoading(false);
            console.log(error)
            setError("Error occured.")
            return
        }
    }

    const handleConfirmProfile = () => {
        navigate('/employee/home')
        return
    }

    if (isMobile()) {
        return (
            <div>
                <TopBar />
                {!displayProfile ?
                    <main style={{ alignItems: 'center' }}>
                        <img src={SuccessIcon} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                        <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                            Authentication successful!
                        </p>
                        <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                            Your account has been authenticated, please confirm your details and get ready to LEVEL up.
                        </p>
                        <p style={{ width: '100%' }} className='signin-sign-in-btn' onClick={handleNext}>Next</p>
                    </main> :
                    <main>
                        <p className='signin-main-header' style={{ fontSize: '30px' }}>
                            Confirm your profile
                        </p>
                        <div>
                            <div style={{ color: '#74738D' }}>Full name</div>
                            <div style={{ color: '#001949' }}>{`${commonStore.user.FirstName} ${commonStore.user.LastName}`}</div>
                            <hr style={{ width: '100%', color: '#74738D' }} />
                            <div style={{ color: '#74738D' }}>Monthly income (Nett)</div>
                            <div style={{ color: '#001949' }}>{`R${commonStore.user.SalaryAmount}`}</div>
                            <hr style={{ width: '100%', color: '#74738D' }} />
                            <div style={{ color: '#74738D' }}>Employer</div>
                            <div style={{ color: '#001949' }}>{`${client.Name ? client.Name : "N/A"}`}</div>
                        </div>
                        <p style={{ width: '100%' }} className='signin-sign-in-btn' onClick={handleConfirmProfile}>Confirm</p>
                    </main>
                }
            </div>
        );
    } else {
        return (
            <div>
                <TopBar />
                {!displayProfile ?
                    <main style={{ alignItems: 'center' }}>
                        <img src={SuccessIcon} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                        <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                            Authentication successful!
                        </p>
                        <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                            Your account has been authenticated, please confirm your details and get ready to LEVEL up.
                        </p>
                        <p style={{ width: '100%' }} className='signin-sign-in-btn' onClick={handleNext}>Next</p>
                    </main> :
                    <main>
                        <p className='signin-main-header' style={{ fontSize: '30px' }}>
                            Confirm your profile
                        </p>
                        <div>
                            <div style={{ color: '#74738D' }}>Full name</div>
                            <div style={{ color: '#001949' }}>{`${commonStore.user.FirstName} ${commonStore.user.LastName}`}</div>
                            <hr style={{ width: '100%', color: '#74738D' }} />
                            <div style={{ color: '#74738D' }}>Monthly income (Nett)</div>
                            <div style={{ color: '#001949' }}>{`R${commonStore.user.SalaryAmount}`}</div>
                            <hr style={{ width: '100%', color: '#74738D' }} />
                            <div style={{ color: '#74738D' }}>Employer</div>
                            <div style={{ color: '#001949' }}>{`${client.Name ? client.Name : "N/A"}`}</div>
                        </div>
                        <p style={{ width: '100%' }} className='signin-sign-in-btn' onClick={handleConfirmProfile}>Confirm</p>
                    </main>
                }
            </div>
        );
    }
})

export default AuthSuccess
