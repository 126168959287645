import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Navbar } from '../../components/basic/index'

import './styles.scss';

const NonResponsivePage = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    useEffect(() => {
    }, [])

    if (isMobile()) {
        return (
            <div>
                <Navbar />
                <main style={{ alignItems: 'center', justifyContent:'center', display:'flex' }}>
                    <header style={{ fontSize: '24px' }} className='employee-benefits-assessment-submit-header'>Not available</header>
                    <br/>
                    <p className='text-[15px]'>Please access this page from your PC.</p>
                </main>
            </div>
        );
    } else {
        return (
            <div>
                <Navbar />
                <main style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <header style={{ fontSize: '24px' }} className='employee-benefits-assessment-submit-header'>Not available</header>
                    <br />
                    <p className='text-[15px]'>Please access this page from your mobile device.</p>
                </main>
            </div>
        );
    }
})

export default NonResponsivePage
