import './styles.scss'

const RadialChartBasline = ({ overallScore }) => {

    return (
        <svg viewBox="13 0 100 75" xmlns="http://www.w3.org/2000/svg" className='radial-chart-baseline-svg'>
            <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0%" stopColor="#3D2DED" />
                    <stop offset="100%" stopColor="#B138FB" />
                </linearGradient>
            </defs>

            {/* Background Grey Path */}
            <path className="basline-radial-chart-grey" d="M22 61A40 40 0 11104 61" fill="none" stroke="#e0e0e0" strokeWidth="3" />

            {/* Animated Blue Path */}
            <path
                className="basline-radial-chart-blue"
                fill="none"
                stroke="#009B9E"
                strokeWidth="3"
                strokeDasharray="130"
                strokeDashoffset="130" // Start fully hidden
                style={{
                    transition: "stroke-dashoffset 2s ease-out",
                    strokeDashoffset: `${130 - (130 * (parseInt(overallScore) / parseInt(100)))}` // End value
                }}
                d="M22 61A40 40 0 11104 61"
            />
            <text
                className="basline-radial-chart-withdrawTxtSub"
                x="64%"
                y="80%"
                dominantBaseline="middle"
                textAnchor="middle"
            >
                Average score
            </text>
            <text
                className="basline-radial-chart-withdrawTxtHead"
                x="64%"
                y="65%"
                dominantBaseline="middle"
                textAnchor="middle"
            >
                {`${overallScore.toFixed(0)}`}
            </text>

            {/* Description */}
            {/* <text
                className="basline-radial-chart-withdrawTxtSub"
                x="64%"
                y="80%"
                dominantBaseline="middle"
                textAnchor="middle"
            >
                Average score
            </text> */}
        </svg>
    );
};

export default RadialChartBasline;
