import React from "react";
import { Page, Text, Document, StyleSheet, View, Font, Image } from "@react-pdf/renderer";
import { LevelBlueWhiteLogoPng } from "../../assets/icons";
// Register the Roboto font
Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: 'https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxP.ttf', // Roboto Regular
            fontStyle: 'normal',
            fontWeight: 400,
        },
        {
            src: 'https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', // Roboto Bold
            fontWeight: 700,
        },
    ],
});

const BORDER_COLOR = '#bfbfbf';
const BORDER_STYLE = 'solid';
const COLN_WIDTH = 100 / 7;

const styles = StyleSheet.create({
    body: {
        padding: 20,
        fontFamily: 'Roboto',
    },
    header: {
        fontSize: 18,
        marginBottom: 20,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop: 10,
    },
    tableRow: {
        flexDirection: "row",
    },
    tableColHeader: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        backgroundColor: '#010143',
        color: '#FFF',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        fontWeight: 'bold',
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    tableCell: {
        margin: 5,
        fontSize: 10,
        textAlign: 'center',
    },
    HeaderTextRight: {
        fontSize: 11,
        textAlign: 'right',
        fontFamily: 'Roboto',
    },
    logo: {
        width: 100,
    },
    tableCellTotal: {
        margin: 5,
        fontWeight: 700,
        fontSize: 11,
        fontFamily: 'Roboto',
    },
    tableCellTotalWhite: {
        margin: 5,
        textAlign: 'center',
        fontWeight: 700,
        fontSize: 11,
        fontFamily: 'Roboto',
        color: '#fff'
    },
    totalTableCol: {
        width: ((100 / 7) * 5) + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        fontFamily: 'Roboto',
    },
    totalTableColAmount: {
        backgroundColor: "#010143",
        borderColor: "#010143",
        borderBottomColor: "#010143",
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        fontFamily: 'Roboto',
    },
});

const TransactionsReportPDF = ({ transactions, totals }) => {
    console.log(totals)
    return (
        <Document>
            <Page style={styles.body}>
                <View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <View>
                            <Text style={styles.HeaderText}>Level Finance Statement</Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', alignItems: 'flex-end' }}>
                            <Image style={styles.logo} src={LevelBlueWhiteLogoPng} />
                            <Text> </Text>
                            <Text style={styles.HeaderTextRight}>Level Finance (PTY) LTD</Text>
                            <Text style={styles.HeaderTextRight}>Reg: 2018/579476/07</Text>
                            <Text style={styles.HeaderTextRight}>VAT: 4700306949</Text>
                            <Text style={styles.HeaderTextRight}>1st Floor, Building 4</Text>
                            <Text style={styles.HeaderTextRight}>Clearwater Office Park</Text>
                            <Text style={styles.HeaderTextRight}>Cnr Christian de Wet</Text>
                            <Text style={styles.HeaderTextRight}>and Millenium Road</Text>
                            <Text style={styles.HeaderTextRight}>Strubensvalley, 1735</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>First Name</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Last Name</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>ID/passport</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Date</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Product</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Amount Excl</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Amount Incl</Text>
                        </View>
                    </View>

                    {/* Table Data */}
                    {transactions.map((trans, index) => {
                        return (
                            <View key={index} style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{trans.firstName}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{trans.lastName}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{trans.username}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{trans.date}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{trans.product}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{`R ${trans.amountExcl.toFixed(2)}`}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{`R ${trans.amountIncl.toFixed(2)}`}</Text>
                                </View>
                            </View>
                        );
                    })}
                    <View style={styles.tableRow}>
                        <View style={styles.totalTableCol}>
                            <Text style={styles.tableCellTotal}>Grand Totals:</Text>
                        </View>
                        <View style={styles.totalTableColAmount}>
                            <Text style={styles.tableCellTotalWhite}>{`R ${totals.totalExcl.toFixed(2)}`}</Text>
                        </View>
                        <View style={styles.totalTableColAmount}>
                            <Text style={styles.tableCellTotalWhite}>{`R ${totals.totalIncl.toFixed(2)}`}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default TransactionsReportPDF;
