const codes = {
    Status: {
        1: "PayoutReceived",
        2: "Verification",
        3: "SubmittedForProcessing",
        4: "PayoutProcessingError",
        5: "PayoutComplete",
        6: "PayoutPendingInvestigation",
        90: "PayoutReturned",
        99: "PayoutCancelled"
    },
    SubStatus: {
        100: "Payout_Unclassified",
        101: "Payout_ValidationFailed",
        201: "Verification_Pending",
        202: "Verification_Failed",
        203: "Verification_Success",
        204: "Verification_Error",
        205: "Verification_AccountNumberDecryptionFailed",
        301: "SubmittedForProcessing_PayoutAddedToBatch",
        302: "SubmittedForProcessing_PayoutSubmittedToBank",
        303: "SubmittedForProcessing_PayoutSubmittedToPpi",
        401: "PayoutProcessingError_PayoutRejected",
        402: "PayoutProcessingError_PayoutCancelled",
        403: "PayoutProcessingError_Insufficient_Balance",
        404: "PayoutProcessingError_PayoutInternalError",
        405: "PayoutProcessingError_InvalidAccountNumber",
        601: "PayoutPendingInvestigation_AmountMismatch",
        9001: "PayoutReturned_Unpaid",
        9901: "Cancellation_AddedToBatch",
        9902: "Cancellation_SubmittedToBank",
        9903: "Cancellation_RejectedByBank",
        9904: "Cancellation_AccountNumberValidationFailed"
    },
    ErrorMessage: "Error message generated when validating the request."
};

export default codes
