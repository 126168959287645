import React, { useState, useEffect } from 'react';
import { useLinkClickHandler, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, TopBar, Input, ErrorCard } from '../../../components/basic/index'
import { IdVerified } from '../../../assets/icons/index'

import './style.scss';

const SecureAccount = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [error, setError] = useState('')
    const [authSuccess, setAuthSuccess] = useState(false)

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
    }, [])

    const handleNext = async () => {
        try {
            if (window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable) {
                if (commonStore.user.Username) {
                    commonStore.setLoading(true);
                    let ret = await commonStore.registerWebauthn(commonStore.user.Username)
                    commonStore.setLoading(false);
                    if (ret === true) {
                        navigate('/employee/account/confirm');
                        return
                    } else {
                        navigate('/employee/auth/password');
                        return
                    }
                } else {
                    commonStore.setLoading(false);
                    console.log('ID not available');
                    commonStore.setSnackBar('error', 'There was an error authenticating your account, please contact support at +27 71 665 0782.')
                    commonStore.logout()
                    navigate('/*');
                    return
                }
            } else {
                commonStore.setLoading(false);
                navigate('/employee/auth/password');
                return
            }
        } catch (error) {
            commonStore.setLoading(false);
            console.log(error)
            navigate('/employee/auth/password');
            return
        }

    }

    const handleConfirmProfile = () => {

    }

    if (isMobile()) {
        return (
            <div>
                <TopBar />
                {!authSuccess ?
                    <main style={{ alignItems: 'center' }}>
                        <img src={IdVerified} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                        <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                            Id verified!
                        </p>
                        <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                            Your identity has been verified. Next you will need to secure your user account.
                        </p>
                        <p style={{ width: '100%' }} className='signin-sign-in-btn' onClick={handleNext}>Proceed</p>
                    </main> :
                    <main style={{ alignItems: 'center' }}>
                        <img src={IdVerified} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                        <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                            Authentication added!
                        </p>
                        <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                            Your account is now protected. You will use this to confirm your identity going forward.
                        </p>
                        <p className='signin-sign-in-btn' onClick={handleConfirmProfile}>Next</p>
                    </main>
                }
            </div>
        );
    } else {
        return (
            <div>
                <TopBar />
                {!authSuccess ?
                    <main style={{ alignItems: 'center' }}>
                        <img src={IdVerified} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                        <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                            Id verified!
                        </p>
                        <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                            Your identity has been verified. Next you will need to secure your user account.
                        </p>
                        <p style={{ width: '100%' }} className='signin-sign-in-btn' onClick={handleNext}>Proceed</p>
                    </main> :
                    <main style={{ alignItems: 'center' }}>
                        <img src={IdVerified} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                        <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                            Authentication added!
                        </p>
                        <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                            Your account is now protected. You will use this to confirm your identity going forward.
                        </p>
                        <p className='signin-sign-in-btn' onClick={handleConfirmProfile}>Next</p>
                    </main>
                }
            </div>
        );
    }
})

export default SecureAccount
