import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import { DateRange } from '../../../../components/Next UI';
import { getLocalTimeZone } from "@internationalized/date";
import { FormControl, InputLabel, MenuItem, Select, Button, Card, CardContent, Typography, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import './styles.scss';

const AdminReportingLogs = observer(() => {
    let navigate = useNavigate();
    const commonStore = useStore();
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [dateRange, setDateRange] = useState([new Date(Date.now() - 25 * 24 * 60 * 60 * 1000), new Date()]);
    const [currentPage, setCurrentPage] = useState(1);
    const [logTypeFilter, setLogTypeFilter] = useState('all');
    const [expandedLog, setExpandedLog] = useState(null);
    const logsPerPage = 50; // Number of logs per page

    useEffect(() => {
        loadAllData();
    }, []);

    useEffect(() => {
        handleFilterLogs();
    }, [logTypeFilter, dateRange]);

    const handleFilterLogs = () => {
        const filtered = logs.filter((log) => {
            if ((log.Type === logTypeFilter || logTypeFilter === "all") &&
                (new Date(log.Date) >= dateRange[0] && new Date(log.Date) <= dateRange[1])) {
                return true;
            }
            return false;
        });
        setFilteredLogs(filtered);
        setCurrentPage(1);
    };

    const logTypes = [
        "Deductions",
    ];

    const loadAllData = async () => {
        try {
            const res = await commonStore.getAllPayrollLogs();
            if (res) {
                setLogs(res);
                setFilteredLogs(res); // Initially show all logs
            }
        } catch (error) {
            console.error("Error loading logs:", error);
        }
    };

    const handleSelectLogTypeFilter = (e) => {
        setLogTypeFilter(e.target.value);
        setCurrentPage(1); // Reset to first page when filter changes
    };

    const handleDateRangeOnChange = (date) => {
        const startDate = date.start.toDate(getLocalTimeZone());
        const endDate = date.end.toDate(getLocalTimeZone());
        endDate.setHours(23, 59, 59, 999); // End of the end day

        setDateRange([startDate, endDate]);
        setCurrentPage(1); // Reset to first page when date range changes
    };

    const toggleExpandLog = (index) => {
        setExpandedLog(expandedLog === index ? null : index);
    };

    // Calculate total pages based on filtered logs
    const totalPages = Math.ceil(filteredLogs.length / logsPerPage);

    // Get logs for the current page
    const indexOfLastLog = currentPage * logsPerPage;
    const indexOfFirstLog = indexOfLastLog - logsPerPage;
    const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);

    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <div style={{ background: "#F4F5FA", width: '100%' }}>
            {isMobile() ? (
                <div style={{ padding: '10px' }}>Mobile view is not supported for logs display</div>
            ) : (
                <>
                    <div style={{ background: "#FFFFFF", borderRadius: '20px', padding: '20px' }}>
                        <div className='flex justify-between margin-0.5'>
                            <p style={{ fontSize: '15px', color: 'gray' }}>{`Total: ${filteredLogs.length} filtered logs`}</p>
                        </div>
                        <div className='admin-reporting-filter-container'>
                            <hr />
                            <div className='flex gap-3 items-center flex-wrap gap-1'>
                                <DateRange value={dateRange} onChange={handleDateRangeOnChange} />
                            </div>
                            <FormControl style={{ width: '200px', marginTop: '20px' }}>
                                <InputLabel id="log-type-select-label">Log types</InputLabel>
                                <Select
                                    labelId="log-type-select-label"
                                    id="log-type-select"
                                    value={logTypeFilter}
                                    label="Filter"
                                    onChange={handleSelectLogTypeFilter}
                                    style={{ fontSize: '13px' }}
                                >
                                    <MenuItem value={'all'}>All types</MenuItem>
                                    {logTypes.map((type) => (
                                        <MenuItem value={type} key={type}>{type}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <br />
                        {/* Pagination Controls */}
                        <div className='employee-benefits-assessment-submit-header' style={{ width: '100%', fontSize: '20px', display: 'flex', justifyContent: 'space-evenly', padding: '20px 0px' }}>
                            <div className='cursor-pointer' onClick={() => paginate(currentPage - 1)} style={{ visibility: currentPage === 1 ? 'hidden' : 'visible' }}>{"<"}</div>
                            <span>Page {currentPage} of {totalPages}</span>
                            <div className='cursor-pointer' onClick={() => paginate(currentPage + 1)} style={{ visibility: currentPage === totalPages ? 'hidden' : 'visible' }}>{">"}</div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            {currentLogs.length > 0 ? (
                                currentLogs.map((log, index) => (
                                    <div key={index} className='reports-invoice-item-container' style={{ marginBottom: '10px' }}>
                                        <div className='flex justify-between'>
                                            <div>
                                                <p>{`Type: ${log.Type}`}</p>
                                                <p>{`Date: ${new Date(log.Date).toLocaleDateString()}`}</p>
                                                <p>{`Source: ${log.Source}`}</p>
                                            </div>
                                            <IconButton onClick={() => toggleExpandLog(index)}>
                                                {expandedLog === index ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        </div>
                                        {expandedLog === index && (
                                            <div style={{ marginTop: '10px' }}>
                                                <pre style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px', overflowX: 'auto' }}>
                                                    {JSON.stringify(log, null, 2)}
                                                </pre>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p style={{ fontSize: '15px', color: 'gray' }}>No logs found for the selected criteria.</p>
                            )}
                        </div>
                        {/* Pagination Controls */}
                        <div className='employee-benefits-assessment-submit-header' style={{ width: '100%', fontSize: '20px', display: 'flex', justifyContent: 'space-evenly', padding: '20px 0px' }}>
                            <div className='cursor-pointer' onClick={() => paginate(currentPage - 1)} style={{ visibility: currentPage === 1 ? 'hidden' : 'visible' }}>{"<"}</div>
                            <span>Page {currentPage} of {totalPages}</span>
                            <div className='cursor-pointer' onClick={() => paginate(currentPage + 1)} style={{ visibility: currentPage === totalPages ? 'hidden' : 'visible' }}>{">"}</div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
});

export default AdminReportingLogs;
