import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropDownArrowDown } from '../../assets/icons';

const GenericCustomSelect = ({
    width = '100%',
    background = "#fff",
    textColor = '#010143',
    borderRadius = '16px',
    fontSize = '17px',
    padding = '16px',
    fontFamily = 'Inter',
    fontWeight = '400',
    fontStyle = 'normal',
    lineHeight = 'normal',
    placeHolder = "Select option",
    options = [],
    onChange,
    value
}) => {
    const [isActive, setIsActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [hoveredOption, setHoveredOption] = useState('');

    const selectStyle = {
        color: textColor,
        fontFamily: fontFamily,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        borderRadius: borderRadius,
        background: background,
        border: isActive ? '1px solid #ccc' : '1px solid #ccc',
        fontStyle: fontStyle,
        fontSize: fontSize,
        padding: padding,
        width: width,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        transition: '0.3s ease- out'
    };

    const toggleDropdown = () => {
        setIsActive(!isActive);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option.optionTitle);
        setIsActive(false);
        onChange && onChange(option);
    };

    return (
        <div onClick={toggleDropdown} style={selectStyle}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {value}
                <img src={DropDownArrowDown} alt="Down Arrow" />
            </div>
            {
                isActive && (
                    <div style={{ position: 'relative', zIndex: 1 }}>
                        <hr />
                        {options.map((option) => (
                            <div
                                key={option.key}
                                onClick={() => handleOptionClick(option)}
                                onMouseOver={() => setHoveredOption(option.key)}
                                onMouseOut={() => setHoveredOption('')}
                                style={{
                                    cursor: 'pointer',
                                    padding: '8px 0px',
                                    color: hoveredOption === option.key ? '#EA2199' : '#010143'
                                }}
                            >
                                {option.name}
                            </div>
                        ))}
                    </div>
                )
            }
        </div >
    );
};

GenericCustomSelect.propTypes = {
    textColor: PropTypes.string,
    borderRadius: PropTypes.string,
    fontSize: PropTypes.string,
    padding: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    width: PropTypes.string,
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.string,
    fontStyle: PropTypes.string,
    lineHeight: PropTypes.string,
};

export default GenericCustomSelect;
