import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import { DatePickerComponent, DateRange } from '../../../../components/Next UI'; // Import DateRange
import { OzowPayoutCodes } from '../../../../components/errorCodes';
import { getLocalTimeZone } from "@internationalized/date";
import { IOSToggle } from '../../../../components/basic';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { TransactionsReportPDF } from '../../../../components/pdf';
import * as XLSX from 'xlsx';

import './styles.scss';

const AdminReportingHome = observer(({ data, setVisible, refresh }) => {
    let navigate = useNavigate();
    const commonStore = useStore();
    const [visibleChild, setVisibleChild] = useState('home');
    const [transactions, setTransactions] = useState([]);
    const [userPermissions, setUserPermissions] = useState(commonStore.user.Permissions || []);
    const [openTransactionActions, setOpenTransactionActions] = useState(null);
    const [pdfTransactions, setPdfTransactions] = useState([]);
    const [clients, setClients] = useState(data.clients || [])
    const [users, setUsers] = useState(data.users || [])

    // States for date selection and toggle
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [isDaily, setIsDaily] = useState(true); // Toggle between daily and monthly

    const reset = () => setVisibleChild("home");

    const getStatusColor = (status) => {
        switch (status) {
            case "Failed": return 'red';
            case "Pending": return 'orange';
            case "Paid": return 'green';
            default: return 'black';
        }
    };

    const getUserObj = (id) => {
        let user = users.find((user) => user._id === id)
        return user || {}
    }
    const getClientObj = (id) => {
        let client = clients.find((cli) => cli._id === id)
        return client || {}
    }

    const handleTransactionOpen = (id) => {
        reset();
        setOpenTransactionActions(prev => (prev === id ? null : id));
    };

    const loadAllData = async () => {
        try {
            commonStore.setLoading(true);
            let startDate, endDate;

            if (isDaily) {
                // Daily range
                startDate = new Date(selectedDate);
                startDate.setHours(0, 0, 0, 0);
                endDate = new Date(selectedDate);
                endDate.setHours(23, 59, 59, 999);
            } else {
                // Monthly range
                startDate = new Date(dateRange[0]);
                startDate.setHours(0, 0, 0, 0);
                endDate = new Date(dateRange[1]);
                endDate.setHours(23, 59, 59, 999);
            }

            const res = await commonStore.getRangedTransactionsNoPop({ startDate, endDate });
            if (res) {
                let sorted = []
                if (res?.length > 0) sorted = res?.sort((a, b) => new Date(b.Date) - new Date(a.Date))
                setTransactions(sorted)
            }
        } catch (error) {
            console.error("Error loading transactions:", error);
        } finally {
            commonStore.setLoading(false);
        }
    };

    useEffect(() => {
        loadAllData();
        setPdfTransactions([])
    }, [selectedDate, dateRange, isDaily]);

    const handlePreparePDFData = () => {
        const _transactions = transactions.map((trans) => {
            const user = getUserObj(trans.User);
            let vat = trans.VAT || 0
            let transFees = trans.TransferFee || 0
            let fees = trans.Fee || 0
            let amount = trans.Amount || 0
            if (trans.Status === "Paid") {
                return {
                    firstName: user.FirstName || 'Unknown',
                    lastName: user.LastName || 'Unknown',
                    username: user.Username || 'Unknown',
                    date: new Date(trans.Date).toLocaleDateString(),
                    amountExcl: amount,
                    amountIncl: Number(amount + fees + vat + transFees) || 0,
                    product: trans.Product || '',
                };
            }

        }).filter(trans => trans !== undefined && trans !== null);
        if (_transactions.length === 0) {
            commonStore.setSnackBar("warning", "No data to generate pdf statement");
            return;
        }
        let totals = { totalExcl: 0, totalIncl: 0 }
        _transactions.forEach((trans) => {
            totals.totalExcl += trans.amountExcl
            totals.totalIncl += trans.amountIncl
        })
        let data = { _transactions, totals }

        setPdfTransactions(data);
        console.log(data)
    };

    const handleGetOzowPayoutStatus = async (trans) => {
        let payoutId = trans.TransactionID;
        if (!payoutId) {
            commonStore.setSnackBar("warning", "No Payout ID/ Transaction ID");
            return;
        }
        let status = await commonStore.getOzowPayoutStatus(payoutId);
        if (status?.id) {
            let errorString = `${OzowPayoutCodes.Status[status.payoutStatus.status] || ""} - ${OzowPayoutCodes.SubStatus[status.payoutStatus.subStatus] || ""} - ${status.payoutStatus.errorMessage || ""}`;
            console.log(errorString);
            commonStore.setSnackBar("info", errorString);
        } else {
            commonStore.setSnackBar("warning", "Could not retrieve payout status");
        }
    };

    const handleDateRangeOnChange = (date) => {
        let startDate = date.start.toDate(getLocalTimeZone());
        let endDate = date.end.toDate(getLocalTimeZone());
        endDate.setHours(23, 59, 59, 999); // End of the end day

        // Calculate the difference in days
        const dayDifference = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

        if (dayDifference > 61) {
            commonStore.setSnackBar("warning", "Range date should be between 1 to 61 days");
        } else {
            setDateRange([startDate, endDate]);
        }
    };
    const handleGenerateExcel = () => {
        const data = transactions.map((trans) => {
            const user = getUserObj(trans.User);
            if (trans.Status === "Paid") {
                return {
                    'First Name': user.FirstName || 'Unknown',
                    'Last Name': user.LastName || 'Unknonwn',
                    'ID number/ Passport': user.Username || 'Unknonwn',
                    'Date': new Date(trans.Date).toLocaleDateString(),
                    'Amount Excl': trans.Amount,
                    'Product': trans.Product
                };
            }

        }).filter(trans => trans !== undefined && trans !== null);
        if (data.length === 0) {
            commonStore.setSnackBar("warning", "No data to generate excel");
            return;
        }

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, `Level Finance ${commonStore.formatDate(dateRange[0])} - ${commonStore.formatDate(dateRange[1])} statement.xlsx`);
    };

    if (isMobile()) {
        return <div style={{ background: "#F4F5FA", width: '100%' }}></div>;
    } else {
        return (
            <>
                {visibleChild === "home" &&
                    <div>
                        <div style={{ background: "#FFFFFF", borderRadius: '20px' }}>
                            <div className='flex justify-between margin-0.5'>
                                <p style={{ fontSize: '15px', color: 'gray' }}>{`Total: ${transactions.length} transactions for selected date`}</p>
                                {!isDaily && 
                                <div className='flex flex-row gap-2'>
                                        {pdfTransactions?._transactions?.length > 0 ? (
                                        <PDFDownloadLink
                                                document={<TransactionsReportPDF transactions={pdfTransactions._transactions} totals={pdfTransactions.totals} />}
                                            fileName={`Level Finance ${commonStore.formatDate(dateRange[0])} - ${commonStore.formatDate(dateRange[1])} statement.pdf`}
                                            className='font-size-2 cursor-pointer'
                                            style={{ fontSize: '13px', color: 'red', textDecoration: 'none' }}
                                        >
                                            {({ blob, url, loading, error }) =>
                                                loading ? 'Preparing document...' :
                                                    <p style={{ fontSize: '13px', color: 'red' }} className='font-size-2 cursor-pointer'>Download PDF <i className="fa-regular fa-file-pdf"></i></p>
                                            }
                                        </PDFDownloadLink>
                                    ) : (
                                        <p onClick={handlePreparePDFData} style={{ fontSize: '13px', color: 'red' }} className='font-size-2 cursor-pointer'>
                                            {`Generate PDF `}<i className="fa-regular fa-file-pdf"></i>
                                        </p>
                                    )}

                                    <p onClick={handleGenerateExcel} style={{ fontSize: '13px', color: 'green' }} className='font-size-2 cursor-pointer'>
                                        {`Generate Excel `}<i className="fa-regular fa-file-excel"></i>
                                    </p>
                                </div>}
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <IOSToggle checked={!isDaily} onChange={() => setIsDaily(!isDaily)} sx={{ m: 1 }} />
                                        }
                                        label={"Monthly"}
                                    />
                                </FormGroup>
                                
                            </div>
                            <br />
                            <div className='flex gap-3 items-center flex-wrap gap-1'>
                                {isDaily ? (
                                    <DatePickerComponent
                                        label="Select a date"
                                        value={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                    />
                                ) : (
                                    <DateRange
                                        label="Select date range"
                                        value={dateRange}
                                        onChange={handleDateRangeOnChange}
                                    />
                                )}
                            </div>
                            <br />
                            {transactions.map((trans, index) => (
                                <>
                                    <div key={index} className='reports-invoice-item-container'>
                                        <div className='reports-invoice-item-container-section-1'>
                                            <div>
                                                <p>{`${getUserObj(trans.User)?.FirstName || "----"} ${getUserObj(trans.User)?.LastName || "----"}`}</p>
                                                <p>{`${getUserObj(trans.User)?.Username || "No username"}`}</p>
                                                <p>{`${getClientObj(trans.Client)?.Name || "No client"}`}</p>
                                            </div>
                                            <div>
                                                <p style={{ color: `${getStatusColor(trans.Status)}` }}>{trans.Status || "No status"}</p>
                                                {trans.TransactionID && <p className='text-[#00B8AD]' onClick={() => handleGetOzowPayoutStatus(trans)} style={{ margin: 0, cursor: 'pointer' }}>Get OZOW status<i class="fa-solid fa-spinner"></i></p>}
                                            </div>
                                            <p>{`R ${trans.TotalAmount?.toLocaleString() || "No total amount"}`}</p>
                                            <p>{new Date(trans.Date)?.toLocaleDateString() || "No date"}</p>
                                            <p id={trans._id} onClick={() => handleTransactionOpen(trans._id)} className='text-[15px] cursor-pointer'>{"..."}</p>
                                        </div>
                                        {openTransactionActions === trans._id && (
                                            <div className='invoice-actions-section'>
                                                {trans.Notes?.length > 0 && (
                                                    <div className='flex gap-1'>
                                                        <div className='flex gap-1 flex-column'>
                                                            <p>Notes: </p>
                                                            {trans.Notes.map((nt, idx) => (
                                                                <p key={idx} className='text-[11px] font-light'>{`${new Date(nt.date).toLocaleDateString()} - ${nt.user} - ${nt.note}`}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                                <p>{`Amount excl: `}<span className='text-[11px] font-light'>{trans.Amount}</span></p>
                                                <p>{`Transfer fee: `}<span className='text-[11px] font-light'>{trans.TransferFee || 0}</span></p>
                                                <p>{`Bank account: `}<span className='text-[11px] font-light'>{trans.BankAccount ? `${trans.BankAccount.BankName} ...${trans.BankAccount.AccountNumber?.slice(-3)}` : "None"}</span></p>
                                                <p>{`Payslip status: `}<span className='text-[11px] font-light'>{trans.PayslipStatus?.status || "Not written"}</span></p>
                                                <p>{`Invoice status: `}<span className='text-[11px] font-light'>{trans.InvoiceStatus?.appended ? "Added to invoice" : "Not in invoice"}</span></p>
                                                <p>{`Description: `}<span className='text-[11px] font-light'>{trans.Description || ""}</span></p>
                                                <p>{`Message: `}<span className='text-[11px] font-light'>{trans.Message || ""}</span></p>
                                                <p>{`Transaction ID: `}<span className='text-[11px] font-light'>{trans.TransactionID || ""}</span></p>
                                                <p>{`Reference: `}<span className='text-[11px] font-light'>{trans.Reference || ""}</span></p>
                                            </div>
                                        )}
                                    </div>
                                    <br />
                                </>
                            ))}
                            <br />
                        </div>
                    </div>
                }
            </>
        );
    }
});

export default AdminReportingHome;
