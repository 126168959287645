import React, { useState, useEffect } from 'react';
import { useLinkClickHandler, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, TopBar, Input, ErrorCard } from '../../../components/basic/index'
import { IdVerified } from '../../../assets/icons/index'

import './style.scss';

const PasswordAuth = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [error, setError] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false);

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
    }, [])

    const handleConfirmPassword = async () => {
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        } else if (password) {
            // Check the strength of the password
            if (password.length < 8) {
                setError("Password must be at least 8 characters long");
                console.log(error)
                return;
            }

            let hasUpperCase = /[A-Z]/.test(password);
            let hasLowerCase = /[a-z]/.test(password);
            let hasNumber = /\d/.test(password);
            let hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

            if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar) {
                setError("Password should include at least one uppercase letter, one lowercase letter, one number, and one special character");

                return;
            }
        }
        //set password
        try {
            commonStore.setLoading(true);
            let res = await commonStore.register(commonStore.user.Username, password)
            if (res) {
                commonStore.setLoading(false);
                navigate('/employee/home')
                return
            }
        } catch (error) {
            commonStore.setLoading(false);
            console.log(error)
            setError("There was an error authenticating your account, please contact support at +27 71 665 0782.")
            return
        }
    }

    const handlePasswordOnChange = (value) => {
        setPassword(value)
        setError('')
    }

    const handleConfirmPasswordOnChange = (value) => {
        setConfirmPassword(value)
        setError('')
    }

    const handleShowPassword = () => {
        let opp = !showPassword
        setShowPassword(opp)
    }

    if (isMobile()) {
        return (
            <div>
                <TopBar />
                <main style={{ alignItems: 'center' }}>
                    <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                        Set password
                    </p>
                    <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                        Please enter a password to secure your user account.
                    </p>
                    <div className='signin-inputs-container' style={{ width: '100%' }}>
                        <div className='flex-column'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label className='signin-input-label'>Password</label>
                                <label style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    className='signin-input-label'
                                    onClick={handleShowPassword}>Show</label>
                            </div>
                            <input className='signin-input-item'
                                onChange={(e) => handlePasswordOnChange(e.target.value)}
                                value={password} width='100%' placeholder={"Please enter your password"}
                                type={`${showPassword ? 'text' : 'password'}`}
                            />
                        </div>
                        <div className='flex-column'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label className='signin-input-label'>Confirm password</label>
                            </div>
                            <input className='signin-input-item'
                                onChange={(e) => handleConfirmPasswordOnChange(e.target.value)}
                                value={confirmPassword} width='100%' placeholder={"Please confirm your password"}
                                type='password'
                            />
                        </div>
                    </div>
                    {error !== "" ? <ErrorCard message={`${error}`} type={'error'} /> : null}
                    <p style={{ width: '100%' }} className='signin-sign-in-btn' onClick={handleConfirmPassword}>Confirm</p>
                </main>
            </div>
        );
    } else {
        return (
            <div>
                <TopBar />
                <main style={{ alignItems: 'center' }}>
                    <p className='signin-main-header' style={{ fontSize: '30px', textAlign: 'center' }}>
                        Set password
                    </p>
                    <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                        Please enter a password to secure your user account.
                    </p>
                    <div className='signin-inputs-container' style={{ width: '100%' }}>
                        <div className='flex-column'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label className='signin-input-label'>Password</label>
                                <label style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    className='signin-input-label'
                                    onClick={handleShowPassword}>Show</label>
                            </div>
                            <input className='signin-input-item'
                                onChange={(e) => handlePasswordOnChange(e.target.value)}
                                value={password} width='100%' placeholder={"Please enter your password"}
                                type={`${showPassword ? 'text' : 'password'}`}
                            />
                        </div>
                        <div className='flex-column'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label className='signin-input-label'>Confirm password</label>
                            </div>
                            <input className='signin-input-item'
                                onChange={(e) => handleConfirmPasswordOnChange(e.target.value)}
                                value={confirmPassword} width='100%' placeholder={"Please confirm your password"}
                                type='password'
                            />
                        </div>
                    </div>
                    {error !== "" ? <ErrorCard message={`${error}`} type={'error'} /> : null}
                    <p style={{ width: '100%' }} className='signin-sign-in-btn' onClick={handleConfirmPassword}>Confirm</p>
                </main>
            </div>
        );
    }
})

export default PasswordAuth
