import { FlexiblePayLogo, SavingsClubBlueVertical, CareFlexLogoOrange, BlankLogoIcon, FlexiblePayLogo2 } from "../../assets/icons"

const allBenefits = [
    {
        id: '9ce9mM2YYa',
        active: true,
        type: "financial",
        Name: "Flexible pay",
        image: FlexiblePayLogo2,
        description: 'A benefit that allows your employees to access a portion of their earned pay when they need it.',
        shortDescription: 'Access your pay',
        tags: [
            "salary advance", "earned wage access", "flexible payment", "employee benefits", "financial flexibility",
            "instant pay", "on demand pay", "payroll integration", "financial wellbeing", "cash flow",
            "early salary", "pay day", "payroll solutions", "immediate funds", "employee support",
            "financial health", "emergency funds", "salary withdrawal", "HR solutions", "employee engagement"
        ]
    },
    {
        id: 'PLVaS5nGUX',
        active: false,
        type: "physical",
        Name: "CareFlex (CNPL)",
        image: CareFlexLogoOrange,
        description: 'Flexible health care - care now pay later',
        shortDescription: 'Care now pay later',
        tags: [
            "healthcare", "care now pay later", "CNPL", "medical financing", "flexible healthcare",
            "health benefits", "employee wellness", "deferred payments", "physical wellbeing", "health expenses",
            "health support", "care access", "medical assistance", "flexible payments", "health services",
            "health finance", "wellness support", "employee care", "health solutions", "emergency care"
        ]
    },
    {
        id: 'ivueNHllM1',
        active: false,
        type: "financial",
        Name: "Savings club",
        image: SavingsClubBlueVertical,
        description: 'Gamified savings',
        shortDescription: 'Gamified savings',
        tags: [
            "savings", "gamified savings", "group savings", "financial goals", "employee savings",
            "saving rewards", "financial health", "goal setting", "personal finance", "savings challenges",
            "community savings", "money management", "automated savings", "saving plans", "reward system",
            "team savings", "saving habits", "financial education", "savings club", "gamification"
        ]
    },
    {
        id: 'YTTPm80L7o',
        active: false,
        type: "financial",
        Name: "Credit builder",
        image: BlankLogoIcon,
        description: 'Build your credit',
        shortDescription: 'Build your credit',
        tags: [
            "credit score", "credit building", "financial health", "credit solutions", "improve credit",
            "credit repair", "financial literacy", "credit education", "loan eligibility", "credit management",
            "employee credit", "credit growth", "financial support", "credit history", "financial wellbeing",
            "credit access", "credit tracking", "financial stability", "credit improvement", "employee benefits"
        ]
    }
];


export default allBenefits