import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, TopBar, Input, ErrorCard, Navbar } from '../../../../components/basic/index'
import { FlexiblePayLogo, PinkXIcon } from '../../../../assets/icons/index'
import allBenefits from '../../../../components/benefits/index'

import './styles.scss';

const EmployeeBenefitsInfo = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const location = useLocation();

    const [error, setError] = useState('')
    const [client, setClient] = useState({})
    const [currentBenefit, setCurrentBenefit] = useState({})
    const [clientHasLoadedBenefit, setClientHasLoadedBenefit] = useState(false)


    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');

        if (!id) {
            navigate('/error');
        } else {
            const ben = allBenefits.find(item => item.id === id);
            getClient(ben)
        }
    }, []);

    const getClient = async (benefit) => {
        commonStore.setLoading(true);
        let loaded = false;

        try {
            let res = commonStore.user.Client

            if (res) {
                commonStore.setLoading(false);
                setClient(res);
                if (res.AvailableProducts.length > 0) {
                    res.AvailableProducts.forEach(ben => {
                        if (ben.id === benefit.id && ben.ClientHasConfirmed) {
                            loaded = true;
                        }
                    });
                }
            } else {
                commonStore.setLoading(false);
                setError("Error occurred, please try again");
            }
        } catch (error) {
            commonStore.setLoading(false);
            console.error(error);
            setError("Error occurred, please try again");
        }

        setClientHasLoadedBenefit(loaded);
        setCurrentBenefit(benefit);

        forceUpdate()
    };

    const handleActivateFlexiblePay = async () => {
        commonStore.setLoading(true)
        try {
            let user = commonStore.user
            let args = {
                ActivatedDate: new Date(),
                MarketplaceUserId: user._id,
                Client: client.AvailableProducts.filter((i) => i.id === currentBenefit.id)[0].FlexiblePayClientId,
                Username: user.Username,
                IDNumber: user.Username,
                FirstName: user.FirstName,
                LastName: user.LastName,
                Blocked: false,
                Active: true,
            }

            let res = await commonStore.activateFlexiblePayUser(args)
            commonStore.setLoading(false)
            if (res.Username === commonStore.user.Username) {
                navigate('/employee/flexiblepay')
            }
            else if (res === "User already exists") {
                navigate('/employee/flexiblepay')
            }
            else {
                commonStore.setLoading(false)
                setError("Error occured, please try again")
                navigate('/employee/home')
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            setError("Error occured, please try again")
            navigate('/employee/home')
        }
    }

    if (isMobile()) {
        return (
            <main style={{ alignItems: 'center' }}>
                {error !== "" && <ErrorCard type='error' message={error} />}
                {currentBenefit.Name === "Flexible pay" &&
                    <>
                        <img src={PinkXIcon} onClick={() => navigate('/employee/home')} style={{ marginLeft: 'auto' }} />
                        <img src={currentBenefit.image} style={{ width: '200px', height: "200px", margin: 'auto' }} />
                        <div className='confirm-employee-benefits-benefit-description-card-description-text-container'>
                            <p className='confirm-employee-benefits-benefit-description-card-type-text' style={{ fontSize: '16px' }}>
                                {currentBenefit.description}
                            </p>
                        </div>
                        {clientHasLoadedBenefit ?
                            <div style={{ padding: '5px 8px', borderRadius: '10px', background: '#E3FFE2' }}>
                                <div style={{ color: '#2CB573', fontSize: '15px', fontWeight: 500 }}>
                                    Your employer has allowed access to this benefit!
                                </div>
                            </div> :
                            <div style={{ padding: '5px 8px', borderRadius: '10px', background: '#FBF0CF' }}>
                                <div style={{ color: '#C45A1C', fontSize: '15px', fontWeight: 500 }}>
                                    We are processing your request for this benefit
                                </div>
                            </div>
                        }
                        <br />
                        <br />
                        {clientHasLoadedBenefit ?
                            <div style={{ bottom: 0 }}>
                                <Button width='100%' fontSize='16px' onClick={handleActivateFlexiblePay}>Activate this benefit</Button>
                            </div>
                            : null
                        }
                    </>
                }

            </main>
        );
    } else {
        return (
            <div>
                <Navbar />
                <main style={{ alignItems: 'center' }}>
                    {
                        currentBenefit.Name === "Flexible pay" &&
                        <div>
                            <div><img src={FlexiblePayLogo} /></div>
                        </div>
                    }

                </main>
            </div>
        );
    }
})

export default EmployeeBenefitsInfo
