import React, { useState } from "react";
import { DatePicker } from "@nextui-org/react";
import { parseDate, getLocalTimeZone } from "@internationalized/date";
import { useDateFormatter } from "@react-aria/i18n";

export default function NextUIDatePicker({ value, onChange }) {
    const [val, setVal] = useState(value instanceof Date ? parseDate(value.toISOString().split('T')[0]) : parseDate(value));

    let formatter = useDateFormatter({ dateStyle: "long" });

    const handleChange = (newValue) => {
        const dateValue = newValue instanceof Date ? newValue : new Date(newValue); // Ensure it's a Date object
        setVal(parseDate(dateValue.toISOString().split('T')[0]));
        onChange(dateValue);
    };

    return (
        <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-y-2">
                <DatePicker
                    label="Select a date"
                    value={val}
                    onChange={handleChange}
                />
                <p className="text-default-500 text-sm">
                    Selected date:{" "}
                    {val ? formatter.format(val.toDate(getLocalTimeZone())) : "--"}
                </p>
            </div>
        </div>
    );
}
