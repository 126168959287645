import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CustomButton = ({
  type = "default",
  margin,
  primaryGradientBackgroundColor = 'linear-gradient(96deg, #FB3887 0%, #8A2DED 100%), #D9D9D9',
  hoverColorPrimary = 'linear-gradient(96deg, #8A2DED 0%, #FB3887 100%), #D9D9D9',
  textColor = '#ffffff',
  borderRadius = '10px',
  fontSize = '14px',
  padding = '9px 28px',
  cursor = 'pointer',
  fontFamily = 'Inter',
  fontWeight = '400',
  fontStyle = 'normal',
  lineHeight = 'normal',
  border = 'none',
  borderPurple = '1px solid #5D0070',
  onClick,
  width,
  children
}) => {

  type = "purpleInvis"

  const [isHovered, setIsHovered] = useState(false);
  const [bluePurpleGradient, setBluePurpleGradient] = useState(true);
  const [purpleInvis, setPurpleInvis] = useState(false);

  useEffect(() => {
    if (type === "purpleInvis") {
      setPurpleInvis(true);
      setBluePurpleGradient(false);

    }
  }, [type]);

  let bg = primaryGradientBackgroundColor;

  if (isHovered && bluePurpleGradient) {
    bg = primaryGradientBackgroundColor;
  } else if (!isHovered && bluePurpleGradient) {
    bg = hoverColorPrimary;
  } else if (!isHovered && purpleInvis) {
    bg = '#5D0070';
    border = borderPurple
    textColor = '#ffffff'
  } else if (isHovered && purpleInvis) {
    bg = '#ffffff';
    border = borderPurple
    textColor = '#5D0070'
  }

  const buttonStyle = {
    background: bg,
    color: textColor,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    lineHeight: lineHeight,
    borderRadius: borderRadius,
    border: border,
    fontStyle: fontStyle,
    fontSize: fontSize,
    padding: padding,
    cursor: cursor,
    width: width,
    margin: margin
  };

  return (
    <button
      style={buttonStyle}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

CustomButton.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  textColor: PropTypes.string,
  borderRadius: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  fontSize: PropTypes.string,
  padding: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default CustomButton;
