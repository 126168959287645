import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store'
import { isMobile } from 'is-mobile';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DateRange } from '../../../components/Next UI';
import { getLocalTimeZone } from "@internationalized/date";
import allBenefits from '../../../components/benefits';
import { pdf } from '@react-pdf/renderer';
import { FlexiblePayInvoice, MarketMembershipInvoice } from '../../../components/pdf';

import './styles.scss';

const ClientInvoices = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [visibleChild, setVisibleChild] = useState('home')
    const [productFilter, setProductFilter] = useState('all')
    const [statusFilter, setStatusFilter] = useState('all')
    const [invoices, setInvoices] = useState([])
    const [filteredInvoices, setFilteredInvoices] = useState([])
    const [users, setUsers] = useState([])

    const transactionStatuses = ["Paid", "Pending"]

    useEffect(() => {
        handleFilterInvoices()
    }, [productFilter, statusFilter, invoices])

    useEffect(() => {
        refresh()
    }, [])

    const refresh = async () => {
        let fetchUsers = false
        let _invoices = await getInvoices()
        if (_invoices?.length > 0) {
            setInvoices(_invoices)
            forceUpdate()
            _invoices.forEach((inv) => {
                console.log("Inv", inv)
                //if theres no need to fetch users then don't
                if (inv.Product === "Flexible pay") fetchUsers = true
            })
        }
        if (fetchUsers) await getUsers()
    }

    const getInvoices = async () => {
        let res = await commonStore.getInvoicesByClient(commonStore.client._id)
        if (res) return res
        else {
            commonStore.setSnackBar("error", "Error occured")
            navigate("/client/home/Overview")
        }
    }

    const getUsers = async () => {
        let res = await commonStore.getClientUsers(commonStore.client._id)
        if (res) setUsers(res)
        else {
            commonStore.setSnackBar("error", "Error occured")
            navigate("/client/home/Overview")
        }
    }

    const handleFilterInvoices = () => {
        console.log('ran filter')
        let filtered = invoices?.sort((a, b) => new Date(b.EndDate) - new Date(a.EndDate)) || []
        filtered = invoices.filter((inv) => {
            if ((inv.Product === productFilter || productFilter === "all")
                &&
                (inv.Status === statusFilter || statusFilter === "all")
                &&
                (inv.Status !== "Cancelled")) {
                return true
            }
            else {
                return false
            }

        })
        setFilteredInvoices(filtered)
        forceUpdate()
    }

    const handleSelectProductFilter = (e) => {
        setProductFilter(e.target.value)
    }
    const handleSelectStatusFilter = (e) => {
        setStatusFilter(e.target.value)
    }

    const getUserObjByUn = (username) => {
        let user = users.find((user) => user.Username === username)
        return user || {}
    }

    const getStatusColor = (status) => {
        switch (status) {
            case "Cancelled": return 'red'
            case "Pending": return 'orange'
            case "Paid": return 'green'
        }
    }

    const handleGenerateFlexiblePayInvoicePdf = async (invoice) => {
        let invoiceTransactions = invoice.Transactions.map((trans) => {
            let latestAdvance = trans.AdvanceIds.sort((a, b) => new Date(b.Date) - new Date(a.Date))[0]
            return {
                ...trans, ...
                {
                    FirstName: getUserObjByUn(trans.Username).FirstName,
                    LastName: getUserObjByUn(trans.Username).LastName,
                    Date: latestAdvance.Date
                }
            }
        })
        console.log(invoiceTransactions)
        const pdfBlob = await pdf(<FlexiblePayInvoice transactions={invoiceTransactions} invoice={invoice} client={commonStore.client} />).toBlob();

        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `${commonStore.client.Name}_${invoice.InvoiceNumber}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleGenerateMarketplaceInvoicePdf = async (invoice) => {
        const pdfBlob = await pdf(<MarketMembershipInvoice invoice={invoice} client={commonStore.client} />).toBlob();

        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `${commonStore.client.Name}_${invoice.InvoiceNumber}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleGenerateInvoice = (inv) => {
        switch (inv.Product) {
            case "Flexible pay":
                handleGenerateFlexiblePayInvoicePdf(inv)
                break
            case "Level finance marketplace":
                handleGenerateMarketplaceInvoicePdf(inv)
                break
            default:
                commonStore.setSnackBar("error", `Error generating pdf`)
                break
        }
    }

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <div style={{ background: "#F4F5FA", width: '100%', height: '100%' }}>
                {visibleChild === "home" &&
                    <main >
                        <header className='admin-dash-option-header'>{`Invoices`}</header>
                        <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                            <div className='flex justify-between margin-0.5 gap-2'>
                                <p style={{ fontSize: '15px', color: '#EA2199' }}>{`Total: ${filteredInvoices.length} filtered INVOICES`}</p>
                                <i style={{ cursor: 'pointer' }} onClick={refresh} class="fa-solid fa-arrow-rotate-right"></i>
                            </div>
                            <br />
                            {/* TOP DIV */}
                            <div className='admin-reporting-fitler-container'>
                                <hr />
                                <div className='flex gap-3 flex-wrap gap-1'>
                                    <>
                                        <div className='flex gap-2'>
                                            {/* STATUS */}
                                            <FormControl style={{ width: '200px' }}>
                                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                                    value={statusFilter} label="Filter" onChange={handleSelectStatusFilter} height='35px' style={{ fontSize: '13px' }}>
                                                    <MenuItem value={'all'}>All statuses</MenuItem>
                                                    {transactionStatuses.map((stat) => (
                                                        <MenuItem value={stat} key={stat}>{stat}</MenuItem>
                                                    ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            {/* PRODUCTS */}
                                            <FormControl style={{ width: '200px' }}>
                                                <InputLabel id="demo-simple-select-label">Benefits</InputLabel>
                                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                                    value={productFilter} label="Filter" onChange={handleSelectProductFilter} height='35px' style={{ fontSize: '13px' }}>
                                                    <MenuItem value={'all'}>All benefits</MenuItem>
                                                    <MenuItem value={'Level finance marketplace'}>Marketplace</MenuItem>
                                                    {allBenefits.map((ben) => (
                                                        <MenuItem value={ben.Name} key={ben.Name}>{ben.Name}</MenuItem>
                                                    ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </>
                                </div>
                            </div>
                            <br />
                            {filteredInvoices.length > 0 ?
                                (<div className='flex-column gap-2'>
                                    {filteredInvoices.map((inv, index) => (
                                        <div key={index} className='reports-invoice-item-container'>
                                            {/* Invoice details */}
                                            <div className='reports-invoice-item-container-section-1'>
                                                <div>
                                                    <p className='text-[15px] text-bold'>{`${inv.Product}`}</p>
                                                    <p>Invoice Number: {inv.InvoiceNumber || "No Invoice number"}</p>
                                                </div>
                                                <p style={{ color: getStatusColor(inv.Status) }}>{inv.Status || "No status"}</p>
                                                <p>Total amount (incl VAT): R {inv.TotalIncl?.toLocaleString() || "No total amount"}</p>
                                                <p>{`${commonStore.formatDate(inv.InvoiceDate)}` || "No date"}</p>
                                                <div className='flex flex-row gap-3'>
                                                    <p id={inv._id} className='cursor-pointer text-[red] font-size-8'
                                                        onClick={() => handleGenerateInvoice(inv)}>
                                                        <i class="fa-solid fa-download"></i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>)
                                :
                                (
                                    <p>No invoices</p>
                                )
                            }
                            <br />
                        </main>
                    </main>
                }
            </div>
        );
    }
})

export default ClientInvoices
