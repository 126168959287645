import React, { useState, useEffect } from 'react';
import { useLinkClickHandler, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Navbar, Input, ErrorCard } from '../../../components/basic/index'
import { TCsPayroll1 } from '../../../assets/icons/index'
import EmployerPrivacyPolicy from '../../../components/Legals/employerPrivacyPolicy';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf'

import './styles.scss';

const EmployerTerms = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    //base 64
    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const pdfUrl = 'https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf';


    const [agreed, setAgreed] = useState(false);

    const [error, setError] = useState('');
    const [errorType, setErrorType] = useState('');

    const handleNext = () => {
        commonStore.setLoading(true)
        commonStore.createAgreement("Privacy policy").then((res) => {
            console.log(res)
            if (res) {
                commonStore.setLoading(false)
                //success, check payroll
                navigate('/client/activate/payroll')
            }
            else {
                console.log('error')
                commonStore.setLoading(false)
                setAgreed(!agreed)
            }
        })
    }

    const handleDownloadPolicy = () => {

    }

    const handleOnCheckboxChange = (value) => {
        setAgreed(value)
    }

    useEffect(() => {
    }, [])

    if (isMobile()) {
        return (
            <div>
                {/* TOP BAR */}
                <Navbar />
                <main style={{ alignItems: 'center' }}>
                    <div className='confirm-employee-benefits-progress-container'>
                        <img src={TCsPayroll1} />
                    </div>

                    <div className='employer-agreement-paragraph' style={{ lineHeight: '1.3' }}>
                        <h1>LEVEL FINANCE PRIVACY AND PERSONAL INFORMATION POLICY</h1>
                        <p>
                            Level Finance (Pty) Ltd. (reg: 2018/576746/07) (“Level Finance”) adheres to the highest
                            standards of protecting your personal information when you use
                            <a href="https://www.levelfinance.co.za/">https://www.levelfinance.co.za/</a> or any of its related websites, applications or platforms
                            (collectively, “the Website”) or any Level Finance services (“Services”). As such, we have
                            created this specific and detailed Privacy Policy for you to read and appreciate exactly how we
                            safeguard your personal information and respect your privacy (“Policy”).
                        </p>
                        <ul>
                            <li>Please note that Level Finance is a private limited liability company duly registered and
                                operating in accordance with the laws of the Republic of South Africa.</li>
                            <li>For more information regarding your personal information lawfully stored or used by
                                the Website or Level Finance, please contact <a href="mailto:info@levelfinance.co.za">info@levelfinance.co.za</a> who will gladly
                                assist.</li>
                            <li>Please ensure that you read all the provisions below, and any other Level Finance rules
                                and policies which may apply from time to time and made available to you, to
                                understand all of your, and our, rights and duties.</li>
                        </ul>

                        <h2>1. Purpose of this Privacy Policy</h2>
                        <p>
                            This Privacy Policy aims to give you information on how Level Finance collects
                            and processes your personal data through any form of your engagement with
                            Level Finance such as your engagement with us when contracting or
                            corresponding with us, when using our Services, accessing or using the
                            Website, or providing us with your personal information in any other way.
                        </p>
                        <p>
                            This Privacy Policy complies with and facilitates the obligations required from,
                            the South African Protection of Personal Information Act, No. 4 of 2013
                            (“POPI”), as amended.
                        </p>
                        <ul>
                            <li>Users with citizenships from jurisdictions other than South Africa,
                                please note that Level Finance complies with all South African data
                                protection laws when processing your personal information
                                pursuant to the Services as we are a South African entity operating
                                in the South African market. Should foreign law be applicable in
                                any regard to your use of the Services and/or the Website in any
                                way, including how we may process your personal information,
                                please contact Level Finance at <a href="mailto:info@levelfinance.co.za">info@levelfinance.co.za</a> and we will
                                gladly engage you on its application and your rights.</li>
                        </ul>
                        <p>
                            It is important that you read this Privacy Policy together with any other privacy
                            policy or fair processing notice we may provide on specific occasions when we
                            are collecting or processing personal data about you so that you are fully aware
                            of how and why we are using your data. This Privacy Policy supplements the
                            other notices and is not intended to override them.
                        </p>
                        <p>
                            We do not process the personal information of children when a data
                            subject user is below the age of 18 (eighteen). Do not provide us with any
                            such information, where same is considered a material breach of these
                            Terms.
                        </p>

                        <h2>2. Responsible Party and Operator Role</h2>
                        <p>
                            In some circumstances, Level Finance is the “responsible party” (as defined in
                            POPI) and is responsible for your personal information (collectively referred to
                            as “we”, “us” or “our” in this Policy) in instances where we decide the processing
                            operations concerning your personal information.
                        </p>
                        <p>
                            Sometimes we also operate as an “operator” (as defined in POPI) of personal
                            information on behalf of a third-party responsible party, where that responsible
                            party’s privacy terms will apply. The terms "user", “you", “data subject” and
                            “your” are used interchangeably in this Policy and refer to all persons accessing
                            the Website or engaging with Level Finance for any reason whatsoever.
                        </p>
                        <p>
                            We have appointed a data representative at Level Finance who is responsible
                            for overseeing questions about this Policy. If you have any questions about this
                            Policy, including any requests to exercise your legal rights, please contact the
                            representative using the details set out below.
                        </p>
                        <p>
                            Our full details are:
                        </p>
                        <ul>
                            <li>Full name of legal entity: Level Finance (Pty) Ltd.</li>
                            <li>Name of data representative: Sadia Musa</li>
                            <li>Email address: <a href="mailto:info@levelfinance.co.za">info@levelfinance.co.za</a></li>
                            <li>Postal address: 15 Ackerman Street, Albertsville, Randburg, Gauteng</li>
                            <li>Telephone number: +27 11 584 8329</li>
                        </ul>
                        <p>
                            You have the right to make a complaint at any time to the South African data
                            regulator’s office (Information Regulator’s Office of South Africa). We would,
                            however, appreciate the chance to deal with your concerns before you
                            approach any such regulator, so please contact us in the first instance.
                        </p>

                        <h2>3. Changes to the Privacy Policy and Your Duty to Inform us of Changes</h2>
                        <p>
                            Should your personal information change, please immediately inform us and
                            provide us with updates to your personal information as soon as reasonably
                            possible to enable us to update it. Level Finance will, however, not be able to
                            update any personal information of yours attained from another responsible
                            party, where should you want to update same, you must approach the relevant
                            responsible party to do so. Level Finance is under no obligation to ensure that
                            your personal information or other information supplied by you is correct.
                        </p>
                        <p>
                            You understand and agree that changes in your personal information may
                            affect whether we may provide you with our Services or not, and as such,
                            any changes to your personal information (such as your employer, your
                            employment status and/or other credit information) must be brought to
                            our attention immediately, which may or may not subsequently affect how
                            we provide the Services to you.
                        </p>

                        <h2>4. Third-Party Links on Website or otherwise</h2>
                        <p>
                            The Website may include links to third-party websites, plug-ins, and
                            applications. Clicking on those links or enabling those connections may allow
                            third parties to collect or share data about you. We do not control these third-
                            party websites and are not responsible for their privacy statements or terms.
                            When you leave our Website or engage with such third parties, we encourage
                            you to read the distinct privacy policy of every third-party you engage with.
                        </p>

                        <h2>5. Personal information we collect from you:</h2>
                        <p>
                            Personal data, or personally identifiable information, means any information
                            about both natural and/or juristic entities (i.e. people and companies), from
                            which that entity can be identified. It does not include data where the identity
                            has been removed (anonymous data).
                        </p>
                        <p>
                            Should you decide to register with or function as a user on the Website and/or
                            use any Level Finance Services, you thereby expressly consent to, and opt-in to
                            Level Finance collecting, collating, processing, and using ("processing") the
                            following types of information about you when you use the Website:
                        </p>
                        <ul>
                            <li>Identity Data: name, ID number, nationality, address, age, date of birth,
                                gender, race, marital status, portrait photograph, company name,
                                company registration number, department name, cost centre, main
                                industry sector,</li>
                            <li>Contact Data: email address, mobile number, physical address(s),
                                phone number(s);</li>
                            <li>Employment Data: job title, hire date; employment status</li>
                            <li>Financial Data: salary frequency, salary amount, salary pay date,
                                garnishee order details, credit status; banking data; debtor(s) data;
                                credit bureau data;</li>
                            <li>Profile Data: user IDs and passwords, service preferences and contact
                                preferences;</li>
                            <li>Technical Data: cookies, the user’s Internet Protocol (“IP”) address,
                                browser type, web beacons, geo-location information, embedded web
                                links, and other commonly used information-gathering tools;</li>
                            <li>Social Media Data: all information accessible on your publicly available
                                profile such as images, photos, photo tags, likes, followers, comments,
                                posts and stories;</li>
                            <li>Transaction Data: details about payments to and from you, contracts,
                                contractual terms, contract fees, signups, subscriptions, invoices and
                                other details of products and services you have obtained from us, or
                                provide to us;</li>
                            <li>Usage Data: information about how you use our company, Website,
                                surveys, events, and Services; and</li>
                            <li>Marketing and Communications Data: preferences in receiving
                                notices and marketing from us and our third parties and your
                                communication preferences.</li>
                        </ul>
                        <p>
                            We also collect, use, and share Aggregated Data such as statistical or demographic
                            data for any purpose. Aggregated Data may be derived from your personal data but is
                            not considered personal data in law as this data does not directly or indirectly reveal
                            your identity. For example, we may aggregate your Usage Data to calculate the
                            percentage of users accessing a specific Website feature. However, if we combine or
                            connect Aggregated Data with your personal data so that it can directly or indirectly
                            identify you, we treat the combined data as personal data which will be used in
                            accordance with this Privacy Policy.
                        </p>
                        <p>
                            Where we need to collect personal data by law, or under the terms of a contract we
                            have with you and you fail to provide that data when requested, we may not be able
                            to perform the contract we have or are trying to enter into with you (for example, to
                            provide you with services or allow you to provide us with your services). In this case,
                            we may have to cancel Website-access or Services you have with us, but we will notify
                            you if this is the case at the time.
                        </p>
                        <p>
                            You warrant that the personal information disclosed to Level Finance is directly from
                            you as the user on the Website or in connection to the Services, and all such personal
                            information is lawfully yours to provide. You also warrant that any personal information
                            provided to us from a third-party responsible party, was attained from you lawfully and
                            provided to us with your express consent to the relevant responsible party to do so.
                        </p>
                        <p>
                            You may choose to provide additional personal information to us, in which event you
                            agree to provide accurate and current information, and, generally, not to impersonate
                            or misrepresent any person or entity or falsely state or otherwise misrepresent your
                            affiliation with anyone or anything.
                        </p>

                        <h2>6. When do we collect your personal information:</h2>
                        <p>
                            We use different methods to collect data from and about you, including through:
                        </p>
                        <ul>
                            <li>Direct interactions: You may give us your Identity, Contact, Social Media,
                                Transaction, Technical, Usage, Marketing and Communications, Profile and
                                Financial Data by filling in various Level Finance forms, Website forms or by
                                corresponding with us by phone, email or otherwise. This includes personal
                                data you provide when you:
                                <ul>
                                    <li>use our Services;</li>
                                    <li>use our Website;</li>
                                    <li>contract with us;</li>
                                    <li>consult with us;</li>
                                    <li>complete forms;</li>
                                    <li>interact with us via social media;</li>
                                    <li>provide any services to us as a service provider or independent
                                        contractor on contract with us;</li>
                                    <li>request information to be sent to you;</li>
                                    <li>subscribe to our newsletter;</li>
                                    <li>give us some feedback.</li>
                                </ul>
                            </li>
                            <li>Automated technologies or interactions: As you interact with our Website,
                                we may automatically collect Technical Data and Usage Data about your
                                equipment, browsing actions and patterns. We may collect this personal data
                                by using cookies, server logs and other similar technologies. We may also
                                receive Technical Data about you if you visit other websites employing our
                                cookies.</li>
                            <li>Third parties or publicly available sources: We may receive personal data
                                about you from various third parties and public sources as set out below:
                                <ul>
                                    <li>analytics providers Google Analytics, LinkedIn Analytics based in USA;</li>
                                    <li>social networks Facebook, Instagram, LinkedIn, Twitter, YouTube,
                                        TikTok based in USA;</li>
                                    <li>survey data providers SurveyMonkey, Microsoft Forms based in USA;</li>
                                    <li>marketing platforms SEO, mobile, email, Facebook, Instagram,
                                        LinkedIn, Twitter, YouTube, TikTok based in USA, South Africa,
                                        Mauritius, and Zambia;</li>
                                    <li>search information providers MailChimp, XDS, Experian, TransUnion,
                                        Department of Home Affairs based in USA, South Africa, various other
                                        regions/countries;</li>
                                    <li>providers of technical and/or payment services Ture ID based in USA.</li>
                                </ul>
                            </li>
                        </ul>

                        <h2>7. How we use your personal information:</h2>
                        <p>
                            We will only use your personal data when the law allows us to and for legitimate
                            reasons, which you hereby expressly understand and consent to. Most
                            commonly, we will use your personal data in the following circumstances:
                        </p>
                        <ul>
                            <li>where we have your express consent to do so (where your acceptance
                                of this Privacy Policy constitutes your informed and active consent);</li>
                            <li>where we need to consult with you or perform on the Services contract
                                we are about to enter into or have entered into with you;</li>
                            <li>where it is necessary for our legitimate interests (or those of a third
                                party) and your interests and fundamental rights do not override those
                                interests; and/or</li>
                            <li>where we need to comply with a legal or regulatory obligation.</li>
                        </ul>
                        <p>Purposes for which we will use your personal data:</p>
                        <p>
                            We have set out below, in a table format, a description of all the ways
                            we plan to use your personal data, and which of the legal bases we rely
                            on to do so. We have also identified what our legitimate interests are,
                            where appropriate, and which exact External Third Parties your personal
                            data is handed to for the same reasons.
                        </p>
                        <p>
                            Note that we may process your personal data for more than one lawful
                            ground depending on the specific purpose for which we are using your
                            data. Please contact us if you need details about the specific legal
                            ground we are relying on to process your personal data where more
                            than one ground has been set out in the table below.
                        </p>
                        <table border="1" cellpadding="5" cellspacing="0">
                            <tr>
                                <th>PURPOSE / ACTIVITY</th>
                                <th>TYPE OF DATA</th>
                                <th>LAWFUL BASIS FOR PROCESSING INCLUDING BASIS OF LEGITIMATE INTEREST</th>
                                <th>SPECIFIC EXTERNAL THIRD PARTY TO WHOM THE INFORMATION IS PROVIDED (IF ANY)</th>
                            </tr>
                            <tr>
                                <td>To engage with you after you have contacted us requesting an engagement via the Website or otherwise</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Marketing and Communications</td>
                                <td>(a) Express consent<br />(b) Performance of a contract with you<br />(c) Necessary for our legitimate interests (to keep our records updated and to study how users utilize our services, as well as to develop our services and grow our organisation)</td>
                                <td>Freshdesk based in the USA<br />AWS based in the USA</td>
                            </tr>
                            <tr>
                                <td>To provide you with our Services as contracted (as a client)</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Social Media<br />(d) Usage<br />(e) Transaction<br />(f) Marketing & Communications<br />(g) Financial Data</td>
                                <td>(a) Performance of a contract with you<br />(b) Express consent<br />(c) Necessary to comply with a legal obligation</td>
                                <td>Freshdesk based in the USA<br />AWS based in the USA<br />Department of Home Affairs based in South Africa<br />Payroll providers (Sage, SimplePay, SAP) based in various regions/countries<br />Google and LinkedIn Analytics based in the USA</td>
                            </tr>
                            <tr>
                                <td>To contract with you as an Operator and/or other third-party service provider to Level Finance</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Financial<br />(d) Transaction</td>
                                <td>(a) Performance of a contract with you<br />(b) Express consent<br />(c) Necessary to comply with a legal obligation</td>
                                <td>Freshdesk based in the USA<br />AWS based in the USA<br />Department of Home Affairs based in South Africa<br />Payroll providers (Sage, SimplePay, SAP) based in various regions/countries<br />Google and LinkedIn Analytics based in the USA</td>
                            </tr>
                            <tr>
                                <td>To allow you to use the Website</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Social Media<br />(d) Usage<br />(e) Technical</td>
                                <td>(a) Performance of a contract with you<br />(b) Express consent</td>
                                <td>Google Analytics based in the USA<br />Wix based in Israel</td>
                            </tr>
                            <tr>
                                <td>To provide it to our authorised third-party service providers who need your personal data to provide their private services to you</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Financial<br />(e) Transaction</td>
                                <td>(a) Performance of a contract with you<br />(b) Necessary for our legitimate interests (to provide you with the Services you have contracted from the authorised third-party, and to develop our services and grow our organisation)<br />(c) Express consent</td>
                                <td>Freshdesk based in the USA<br />Payroll providers (Sage, SimplePay, SAP) based in various regions/countries<br />OZOW and Efficacy payments are based in South Africa</td>
                            </tr>
                            <tr>
                                <td>To process and service your payment for any services rendered by Level Finance or its service providers<br />To manage payments, fees, and charges</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Financial<br />(d) Transaction</td>
                                <td>(a) Performance of a contract with you<br />(b) Necessary for our legitimate interests (to make or receive necessary organisation payments)<br />(c) Express consent</td>
                                <td>Xero based in New Zealand<br />Payroll providers (Sage, SimplePay, SAP) based in various regions/countries<br />AWS based in the USA</td>
                            </tr>
                            <tr>
                                <td>To manage our relationship with you which may include notifying you about changes to our terms or Privacy Policy or Services</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Marketing and Communications</td>
                                <td>(a) Performance of a contract with you<br />(b) Necessary to comply with a legal obligation<br />(c) Necessary for our legitimate interests (to keep our records updated and to study how users utilize our Services)<br />(d) Express consent</td>
                                <td>AWS, WhatsApp based in the USA<br />Mobile operators based in South Africa</td>
                            </tr>
                            <tr>
                                <td>To administer and protect our organisation and our Website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Usage</td>
                                <td>(a) Necessary for our legitimate interests (for running our organisation, provision of administration and IT services, network security, to prevent fraud and in the context of an organisation restructuring exercise)<br />(b) Necessary to comply with a legal obligation<br />(c) Express consent</td>
                                <td>Freshdesk based in the USA<br />AWS based in the USA<br />Google Analytics based in the USA<br />Wix based in Israel<br />Elitehost based in South Africa</td>
                            </tr>
                            <tr>
                                <td>To provide you with direct and user-specific marketing, make suggestions and recommendations to you about services that may be of interest to you</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Technical<br />(d) Usage<br />(e) Profile</td>
                                <td>(a) Necessary for our legitimate interests (to develop our services and grow our organisation)<br />(b) Express consent</td>
                                <td>MailChimp based in the USA<br />Survey Monkey based in the USA</td>
                            </tr>
                        </table>

                        <h2>8. Marketing</h2>
                        <p>
                            We strive to provide you with choices regarding certain personal data uses,
                            particularly around marketing and advertising. To manifest your rights attached
                            to any marketing sent to you as an existing customer, please use the in-built
                            prompts provided on those communications, or contact us.
                        </p>
                        <p>
                            You will receive marketing communications from us if you have accepted this
                            Policy, requested information from us, or have participated in any Level Finance
                            Service, and in each case, you have not opted-out of receiving that marketing.
                        </p>

                        <h2>9. Opting Out</h2>
                        <p>
                            You can ask us to stop sending you marketing messages at any time by
                            contacting us at any time and requesting us to cease or change your marketing
                            preferences.
                        </p>
                        <p>
                            Where you opt-out of receiving these marketing messages, this opt-out will not
                            apply to other personal data of yours which we process for another lawful basis.
                        </p>

                        <h2>10. Original and updated purposes for processing:</h2>
                        <p>
                            We will only use your personal information for the purposes for which we
                            collected it, unless we reasonably consider that we need to use it for another
                            reason and that reason is compatible with the original purpose. If you wish to
                            get an explanation as to how the processing for the new purpose is compatible
                            with the original purpose, please contact us. If we need to use your personal
                            information for an unrelated purpose, we will notify you and we will explain the
                            legal basis which allows us to do so.
                        </p>

                        <h2>11. Disclosure of personal information</h2>
                        <p>
                            We may have to share your personal data with the parties set out below for the
                            purposes set out in the table above.
                        </p>
                        <ul>
                            <li>Internal third parties (entities within the Level Finance group);</li>
                            <li>External third parties;</li>
                            <li>Specific third parties listed in the table above; and/or</li>
                            <li>Third parties to whom we may choose to sell, transfer, or merge parts
                                of our organisation or our assets. Alternatively, we may seek to acquire
                                other organisations or merge with them. If a change happens to our
                                organisation, then the new owners may use your personal data in the
                                same way as set out in this Privacy Policy.</li>
                        </ul>
                        <p>
                            We require all third parties to respect the security of your personal data and to
                            treat it in accordance with the law. We do not allow our third-party service
                            providers to use your personal data for their own purposes and only permit
                            them to process your personal data for specified purposes and in accordance
                            with our instructions and standards.
                        </p>

                        <h2>12. International Transfer of personal information:</h2>
                        <p>
                            We may share your personal information within the Level Finance group of
                            companies and this may involve transferring and processing your data outside
                            of South Africa.
                        </p>
                        <p>
                            Whenever we transfer your personal information out of the country, we ensure
                            a similar degree of protection is afforded to it by ensuring at least one of the
                            following safeguards is implemented:
                        </p>
                        <ul>
                            <li>we will only transfer your personal information to countries that have
                                been deemed to provide an adequate level of protection for personal
                                information;</li>
                            <li>We will always have a contract in place covering the processing of data
                                and service provision between the parties; and</li>
                            <li>We will only provide your personal data to an entity that processes
                                personal information at standards equal to or better than ours; or</li>
                            <li>We will only transfer your personal data to countries that have been
                                deemed to provide an adequate level of protection for personal data by
                                the South African Information Regulator’s office; or</li>
                            <li>where we use certain service providers, we may use specific contracts
                                approved by the European Commission which give personal information
                                the same protection it has in Europe under the GDPR.</li>
                        </ul>

                        <h2>13. How we treat your personal information:</h2>
                        <p>
                            We will ensure that all of our employees, third party service providers, divisions
                            and partners (including their employees and third-party service providers)
                            having access to your personal information are bound by appropriate and
                            legally binding confidentiality obligations and process your personal
                            information at standards equal to or higher than Level Finance’s in relation to
                            your personal information.
                        </p>
                        <p>We will:</p>
                        <ul>
                            <li>treat your personal information as strictly confidential, save where we
                                are entitled to share it as set out in this Policy;</li>
                            <li>take appropriate technical, security and organisational measures to
                                ensure that your personal information is kept secure and is protected
                                against unauthorised or unlawful processing, accidental loss,
                                destruction or damage, alteration, disclosure or access;</li>
                            <li>provide you with reasonable access to your personal information to
                                view and/or update personal details;</li>
                            <li>promptly notify you if we become aware of any unauthorised use,
                                disclosure or processing of your personal information;</li>
                            <li>provide you with reasonable evidence of our compliance with our
                                obligations under this Policy on reasonable notice and request; and</li>
                            <li>upon your request, promptly correct, transfer, return or destroy any and
                                all of your personal information in our possession or control, save for
                                that which we are legally obliged or entitled to retain (acknowledging
                                that some Website and/or Service functionality might be lost if certain
                                personal information is amended or destroyed).</li>
                        </ul>

                        <h2>14. Data Retention</h2>
                        <p>
                            We will not retain your personal information longer than the period for which
                            it was originally needed, unless we are required by law to do so, or you consent
                            to us retaining such information for a longer period. In some circumstances,
                            other applicable national laws require us to retain your data beyond your
                            request for its deletion, or beyond your direct engagement with Level Finance.
                            As such, we may retain your personal information in adherence with compulsory
                            instructions from other applicable national laws, notwithstanding your
                            application to have it deleted or amended.
                        </p>
                        <p>
                            Whilst we will do all things reasonably necessary to protect your rights of
                            privacy, we cannot guarantee or accept any liability whatsoever for
                            unauthorised or unlawful disclosures of your personal information, whilst in our
                            possession, made by third parties who are not subject to our control, unless
                            such disclosure is as a result of our gross negligence or fraud.
                        </p>

                        <h2>15. Data Security:</h2>
                        <p>
                            We have put in place appropriate security measures to prevent your personal
                            data from being accidentally lost, used, or accessed in an unauthorised way,
                            altered or disclosed by using SQL and Bitlocker encryption and storing all
                            information securely in Microsoft Azure. In addition, we limit access to your
                            personal data to those persons and other third parties who have a legitimate
                            need to know. They will only process your personal data on our instruction and
                            they are subject to a duty of confidentiality.
                        </p>
                        <p>
                            We have put in place procedures to deal with any suspected personal data
                            breach and will notify you and any applicable regulator of a breach where we
                            are legally required to do so.
                        </p>

                        <h2>16. Cookies provision:</h2>
                        <p>
                            The Website may make use of “cookies” to automatically collect information
                            and data through the standard operation of the Internet servers. “Cookies” are
                            small text files a website can use (and which we may use) to recognise repeat
                            users, facilitate the user’s on-going access to and use of a website and allow a
                            website to track usage behaviour and compile aggregate data that will allow
                            the website operator to improve the functionality of the website and its
                            content, and to display more focused advertising to a user by way of third party
                            tools. The type of information collected by cookies is not used to personally
                            identify you.
                        </p>
                        <p>
                            If you do not want information collected through the use of cookies, there is a
                            simple procedure in most browsers that allows you to deny or accept the cookie
                            feature. Please note that cookies may be necessary to provide you with certain
                            features available on our Website, and thus if you disable the cookies on your
                            browser you may not be able to use those features, and your access to our
                            Website will therefore be limited. If you do not disable “cookies”, you are
                            deemed to consent to our use of any personal information collected using
                            those cookies, subject to the provisions of this Policy and the Website’s other
                            policies.
                        </p>

                        <h2>17. User rights and obligations:</h2>
                        <p>
                            The user is entitled to request access to any relevant personal information held
                            by Level Finance and where such access is necessary for you to exercise and/or
                            protect any of the user’s rights. For any personal information held by any third-
                            party responsible party, the user must approach that responsible party for the
                            realisation of the user’s personal information rights with them, and not with
                            Level Finance.
                        </p>
                        <p>
                            Under POPI, you have rights in relation to your personal information. Please
                            contact us to find out more about, or manifest, these rights:
                        </p>
                        <ul>
                            <li>request access to your personal data;</li>
                            <li>request correction of your personal data;</li>
                            <li>request erasure of your personal data;</li>
                            <li>object to the processing of your personal data;</li>
                            <li>request a restriction of processing your personal data;</li>
                            <li>request transfer of your personal data; and/or</li>
                            <li>right to withdraw consent.</li>
                        </ul>
                        <p>
                            You will not have to pay a fee to access your personal information (or to
                            exercise any of the other rights). However, we may charge a reasonable fee if
                            your request is clearly unfounded, repetitive, or excessive. Alternatively, we may
                            refuse to comply with your request in these circumstances.
                        </p>
                        <p>
                            We may need to request specific information from you to help us confirm your
                            identity and ensure your right to access your personal information (or to
                            exercise any of your other rights). This is a security measure to ensure that
                            personal information is not disclosed to any person who has no right to receive
                            it. We may also contact you to ask you for further information in relation to
                            your request to speed up our response.
                        </p>
                        <p>
                            We try to respond to all legitimate requests within one month. Occasionally it
                            may take us longer than a month if your request is particularly complex or you
                            have made a number of requests. In this case, we will notify you and keep you
                            updated.
                        </p>
                        <p>
                            Users acknowledge that any content provided by users on the Website,
                            including via a messaging system, enters an open, public forum, and is not
                            confidential, where the author of which will be liable for that content, and not
                            Level Finance.
                        </p>
                        <p>
                            Users understand that there are risks involved in sharing personal information.
                            By disclosing personal information such as the user’s name and email address,
                            users acknowledge and understand that this information may be collected and
                            used by a third party to communicate with you.
                        </p>
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <input className='employee-benefits-assessment-multiple-choice-choices-item-checkbox'
                            type='checkbox' id='ts&csCheckbox'
                            checked={agreed}
                            onChange={(e) => handleOnCheckboxChange(e.target.checked)}
                        />
                        <label style={{ color: '#5B5B5B' }} htmlFor='ts&csCheckbox'>
                            {'By checking this box, I confirm that I have read, understood, and agree to the Level finance '}
                            <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">privacy policy</a>.
                        </label>
                    </div>
                    {agreed && <Button onClick={handleNext} width={'100%'}>I agree</Button>}
                </main>
            </div>
        );
    } else {
        return (
            <div>
                {/* TOP BAR */}
                <Navbar />
                <main style={{ alignItems: 'center' }}>
                    <div className='confirm-employee-benefits-progress-container'>
                        <img src={TCsPayroll1} />
                    </div>

                    <div className='employer-agreement-paragraph' style={{ lineHeight: '1.3' }}>
                        <h1>LEVEL FINANCE PRIVACY AND PERSONAL INFORMATION POLICY</h1>
                        <p>
                            Level Finance (Pty) Ltd. (reg: 2018/576746/07) (“Level Finance”) adheres to the highest
                            standards of protecting your personal information when you use
                            <a href="https://www.levelfinance.co.za/">https://www.levelfinance.co.za/</a> or any of its related websites, applications or platforms
                            (collectively, “the Website”) or any Level Finance services (“Services”). As such, we have
                            created this specific and detailed Privacy Policy for you to read and appreciate exactly how we
                            safeguard your personal information and respect your privacy (“Policy”).
                        </p>
                        <ul>
                            <li>Please note that Level Finance is a private limited liability company duly registered and
                                operating in accordance with the laws of the Republic of South Africa.</li>
                            <li>For more information regarding your personal information lawfully stored or used by
                                the Website or Level Finance, please contact <a href="mailto:info@levelfinance.co.za">info@levelfinance.co.za</a> who will gladly
                                assist.</li>
                            <li>Please ensure that you read all the provisions below, and any other Level Finance rules
                                and policies which may apply from time to time and made available to you, to
                                understand all of your, and our, rights and duties.</li>
                        </ul>

                        <h2>1. Purpose of this Privacy Policy</h2>
                        <p>
                            This Privacy Policy aims to give you information on how Level Finance collects
                            and processes your personal data through any form of your engagement with
                            Level Finance such as your engagement with us when contracting or
                            corresponding with us, when using our Services, accessing or using the
                            Website, or providing us with your personal information in any other way.
                        </p>
                        <p>
                            This Privacy Policy complies with and facilitates the obligations required from,
                            the South African Protection of Personal Information Act, No. 4 of 2013
                            (“POPI”), as amended.
                        </p>
                        <ul>
                            <li>Users with citizenships from jurisdictions other than South Africa,
                                please note that Level Finance complies with all South African data
                                protection laws when processing your personal information
                                pursuant to the Services as we are a South African entity operating
                                in the South African market. Should foreign law be applicable in
                                any regard to your use of the Services and/or the Website in any
                                way, including how we may process your personal information,
                                please contact Level Finance at <a href="mailto:info@levelfinance.co.za">info@levelfinance.co.za</a> and we will
                                gladly engage you on its application and your rights.</li>
                        </ul>
                        <p>
                            It is important that you read this Privacy Policy together with any other privacy
                            policy or fair processing notice we may provide on specific occasions when we
                            are collecting or processing personal data about you so that you are fully aware
                            of how and why we are using your data. This Privacy Policy supplements the
                            other notices and is not intended to override them.
                        </p>
                        <p>
                            We do not process the personal information of children when a data
                            subject user is below the age of 18 (eighteen). Do not provide us with any
                            such information, where same is considered a material breach of these
                            Terms.
                        </p>

                        <h2>2. Responsible Party and Operator Role</h2>
                        <p>
                            In some circumstances, Level Finance is the “responsible party” (as defined in
                            POPI) and is responsible for your personal information (collectively referred to
                            as “we”, “us” or “our” in this Policy) in instances where we decide the processing
                            operations concerning your personal information.
                        </p>
                        <p>
                            Sometimes we also operate as an “operator” (as defined in POPI) of personal
                            information on behalf of a third-party responsible party, where that responsible
                            party’s privacy terms will apply. The terms "user", “you", “data subject” and
                            “your” are used interchangeably in this Policy and refer to all persons accessing
                            the Website or engaging with Level Finance for any reason whatsoever.
                        </p>
                        <p>
                            We have appointed a data representative at Level Finance who is responsible
                            for overseeing questions about this Policy. If you have any questions about this
                            Policy, including any requests to exercise your legal rights, please contact the
                            representative using the details set out below.
                        </p>
                        <p>
                            Our full details are:
                        </p>
                        <ul>
                            <li>Full name of legal entity: Level Finance (Pty) Ltd.</li>
                            <li>Name of data representative: Sadia Musa</li>
                            <li>Email address: <a href="mailto:info@levelfinance.co.za">info@levelfinance.co.za</a></li>
                            <li>Postal address: 15 Ackerman Street, Albertsville, Randburg, Gauteng</li>
                            <li>Telephone number: +27 11 584 8329</li>
                        </ul>
                        <p>
                            You have the right to make a complaint at any time to the South African data
                            regulator’s office (Information Regulator’s Office of South Africa). We would,
                            however, appreciate the chance to deal with your concerns before you
                            approach any such regulator, so please contact us in the first instance.
                        </p>

                        <h2>3. Changes to the Privacy Policy and Your Duty to Inform us of Changes</h2>
                        <p>
                            Should your personal information change, please immediately inform us and
                            provide us with updates to your personal information as soon as reasonably
                            possible to enable us to update it. Level Finance will, however, not be able to
                            update any personal information of yours attained from another responsible
                            party, where should you want to update same, you must approach the relevant
                            responsible party to do so. Level Finance is under no obligation to ensure that
                            your personal information or other information supplied by you is correct.
                        </p>
                        <p>
                            You understand and agree that changes in your personal information may
                            affect whether we may provide you with our Services or not, and as such,
                            any changes to your personal information (such as your employer, your
                            employment status and/or other credit information) must be brought to
                            our attention immediately, which may or may not subsequently affect how
                            we provide the Services to you.
                        </p>

                        <h2>4. Third-Party Links on Website or otherwise</h2>
                        <p>
                            The Website may include links to third-party websites, plug-ins, and
                            applications. Clicking on those links or enabling those connections may allow
                            third parties to collect or share data about you. We do not control these third-
                            party websites and are not responsible for their privacy statements or terms.
                            When you leave our Website or engage with such third parties, we encourage
                            you to read the distinct privacy policy of every third-party you engage with.
                        </p>

                        <h2>5. Personal information we collect from you:</h2>
                        <p>
                            Personal data, or personally identifiable information, means any information
                            about both natural and/or juristic entities (i.e. people and companies), from
                            which that entity can be identified. It does not include data where the identity
                            has been removed (anonymous data).
                        </p>
                        <p>
                            Should you decide to register with or function as a user on the Website and/or
                            use any Level Finance Services, you thereby expressly consent to, and opt-in to
                            Level Finance collecting, collating, processing, and using ("processing") the
                            following types of information about you when you use the Website:
                        </p>
                        <ul>
                            <li>Identity Data: name, ID number, nationality, address, age, date of birth,
                                gender, race, marital status, portrait photograph, company name,
                                company registration number, department name, cost centre, main
                                industry sector,</li>
                            <li>Contact Data: email address, mobile number, physical address(s),
                                phone number(s);</li>
                            <li>Employment Data: job title, hire date; employment status</li>
                            <li>Financial Data: salary frequency, salary amount, salary pay date,
                                garnishee order details, credit status; banking data; debtor(s) data;
                                credit bureau data;</li>
                            <li>Profile Data: user IDs and passwords, service preferences and contact
                                preferences;</li>
                            <li>Technical Data: cookies, the user’s Internet Protocol (“IP”) address,
                                browser type, web beacons, geo-location information, embedded web
                                links, and other commonly used information-gathering tools;</li>
                            <li>Social Media Data: all information accessible on your publicly available
                                profile such as images, photos, photo tags, likes, followers, comments,
                                posts and stories;</li>
                            <li>Transaction Data: details about payments to and from you, contracts,
                                contractual terms, contract fees, signups, subscriptions, invoices and
                                other details of products and services you have obtained from us, or
                                provide to us;</li>
                            <li>Usage Data: information about how you use our company, Website,
                                surveys, events, and Services; and</li>
                            <li>Marketing and Communications Data: preferences in receiving
                                notices and marketing from us and our third parties and your
                                communication preferences.</li>
                        </ul>
                        <p>
                            We also collect, use, and share Aggregated Data such as statistical or demographic
                            data for any purpose. Aggregated Data may be derived from your personal data but is
                            not considered personal data in law as this data does not directly or indirectly reveal
                            your identity. For example, we may aggregate your Usage Data to calculate the
                            percentage of users accessing a specific Website feature. However, if we combine or
                            connect Aggregated Data with your personal data so that it can directly or indirectly
                            identify you, we treat the combined data as personal data which will be used in
                            accordance with this Privacy Policy.
                        </p>
                        <p>
                            Where we need to collect personal data by law, or under the terms of a contract we
                            have with you and you fail to provide that data when requested, we may not be able
                            to perform the contract we have or are trying to enter into with you (for example, to
                            provide you with services or allow you to provide us with your services). In this case,
                            we may have to cancel Website-access or Services you have with us, but we will notify
                            you if this is the case at the time.
                        </p>
                        <p>
                            You warrant that the personal information disclosed to Level Finance is directly from
                            you as the user on the Website or in connection to the Services, and all such personal
                            information is lawfully yours to provide. You also warrant that any personal information
                            provided to us from a third-party responsible party, was attained from you lawfully and
                            provided to us with your express consent to the relevant responsible party to do so.
                        </p>
                        <p>
                            You may choose to provide additional personal information to us, in which event you
                            agree to provide accurate and current information, and, generally, not to impersonate
                            or misrepresent any person or entity or falsely state or otherwise misrepresent your
                            affiliation with anyone or anything.
                        </p>

                        <h2>6. When do we collect your personal information:</h2>
                        <p>
                            We use different methods to collect data from and about you, including through:
                        </p>
                        <ul>
                            <li>Direct interactions: You may give us your Identity, Contact, Social Media,
                                Transaction, Technical, Usage, Marketing and Communications, Profile and
                                Financial Data by filling in various Level Finance forms, Website forms or by
                                corresponding with us by phone, email or otherwise. This includes personal
                                data you provide when you:
                                <ul>
                                    <li>use our Services;</li>
                                    <li>use our Website;</li>
                                    <li>contract with us;</li>
                                    <li>consult with us;</li>
                                    <li>complete forms;</li>
                                    <li>interact with us via social media;</li>
                                    <li>provide any services to us as a service provider or independent
                                        contractor on contract with us;</li>
                                    <li>request information to be sent to you;</li>
                                    <li>subscribe to our newsletter;</li>
                                    <li>give us some feedback.</li>
                                </ul>
                            </li>
                            <li>Automated technologies or interactions: As you interact with our Website,
                                we may automatically collect Technical Data and Usage Data about your
                                equipment, browsing actions and patterns. We may collect this personal data
                                by using cookies, server logs and other similar technologies. We may also
                                receive Technical Data about you if you visit other websites employing our
                                cookies.</li>
                            <li>Third parties or publicly available sources: We may receive personal data
                                about you from various third parties and public sources as set out below:
                                <ul>
                                    <li>analytics providers Google Analytics, LinkedIn Analytics based in USA;</li>
                                    <li>social networks Facebook, Instagram, LinkedIn, Twitter, YouTube,
                                        TikTok based in USA;</li>
                                    <li>survey data providers SurveyMonkey, Microsoft Forms based in USA;</li>
                                    <li>marketing platforms SEO, mobile, email, Facebook, Instagram,
                                        LinkedIn, Twitter, YouTube, TikTok based in USA, South Africa,
                                        Mauritius, and Zambia;</li>
                                    <li>search information providers MailChimp, XDS, Experian, TransUnion,
                                        Department of Home Affairs based in USA, South Africa, various other
                                        regions/countries;</li>
                                    <li>providers of technical and/or payment services Ture ID based in USA.</li>
                                </ul>
                            </li>
                        </ul>

                        <h2>7. How we use your personal information:</h2>
                        <p>
                            We will only use your personal data when the law allows us to and for legitimate
                            reasons, which you hereby expressly understand and consent to. Most
                            commonly, we will use your personal data in the following circumstances:
                        </p>
                        <ul>
                            <li>where we have your express consent to do so (where your acceptance
                                of this Privacy Policy constitutes your informed and active consent);</li>
                            <li>where we need to consult with you or perform on the Services contract
                                we are about to enter into or have entered into with you;</li>
                            <li>where it is necessary for our legitimate interests (or those of a third
                                party) and your interests and fundamental rights do not override those
                                interests; and/or</li>
                            <li>where we need to comply with a legal or regulatory obligation.</li>
                        </ul>
                        <p>Purposes for which we will use your personal data:</p>
                        <p>
                            We have set out below, in a table format, a description of all the ways
                            we plan to use your personal data, and which of the legal bases we rely
                            on to do so. We have also identified what our legitimate interests are,
                            where appropriate, and which exact External Third Parties your personal
                            data is handed to for the same reasons.
                        </p>
                        <p>
                            Note that we may process your personal data for more than one lawful
                            ground depending on the specific purpose for which we are using your
                            data. Please contact us if you need details about the specific legal
                            ground we are relying on to process your personal data where more
                            than one ground has been set out in the table below.
                        </p>
                        <table border="1" cellpadding="5" cellspacing="0">
                            <tr>
                                <th>PURPOSE / ACTIVITY</th>
                                <th>TYPE OF DATA</th>
                                <th>LAWFUL BASIS FOR PROCESSING INCLUDING BASIS OF LEGITIMATE INTEREST</th>
                                <th>SPECIFIC EXTERNAL THIRD PARTY TO WHOM THE INFORMATION IS PROVIDED (IF ANY)</th>
                            </tr>
                            <tr>
                                <td>To engage with you after you have contacted us requesting an engagement via the Website or otherwise</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Marketing and Communications</td>
                                <td>(a) Express consent<br />(b) Performance of a contract with you<br />(c) Necessary for our legitimate interests (to keep our records updated and to study how users utilize our services, as well as to develop our services and grow our organisation)</td>
                                <td>Freshdesk based in the USA<br />AWS based in the USA</td>
                            </tr>
                            <tr>
                                <td>To provide you with our Services as contracted (as a client)</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Social Media<br />(d) Usage<br />(e) Transaction<br />(f) Marketing & Communications<br />(g) Financial Data</td>
                                <td>(a) Performance of a contract with you<br />(b) Express consent<br />(c) Necessary to comply with a legal obligation</td>
                                <td>Freshdesk based in the USA<br />AWS based in the USA<br />Department of Home Affairs based in South Africa<br />Payroll providers (Sage, SimplePay, SAP) based in various regions/countries<br />Google and LinkedIn Analytics based in the USA</td>
                            </tr>
                            <tr>
                                <td>To contract with you as an Operator and/or other third-party service provider to Level Finance</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Financial<br />(d) Transaction</td>
                                <td>(a) Performance of a contract with you<br />(b) Express consent<br />(c) Necessary to comply with a legal obligation</td>
                                <td>Freshdesk based in the USA<br />AWS based in the USA<br />Department of Home Affairs based in South Africa<br />Payroll providers (Sage, SimplePay, SAP) based in various regions/countries<br />Google and LinkedIn Analytics based in the USA</td>
                            </tr>
                            <tr>
                                <td>To allow you to use the Website</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Social Media<br />(d) Usage<br />(e) Technical</td>
                                <td>(a) Performance of a contract with you<br />(b) Express consent</td>
                                <td>Google Analytics based in the USA<br />Wix based in Israel</td>
                            </tr>
                            <tr>
                                <td>To provide it to our authorised third-party service providers who need your personal data to provide their private services to you</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Financial<br />(e) Transaction</td>
                                <td>(a) Performance of a contract with you<br />(b) Necessary for our legitimate interests (to provide you with the Services you have contracted from the authorised third-party, and to develop our services and grow our organisation)<br />(c) Express consent</td>
                                <td>Freshdesk based in the USA<br />Payroll providers (Sage, SimplePay, SAP) based in various regions/countries<br />OZOW and Efficacy payments are based in South Africa</td>
                            </tr>
                            <tr>
                                <td>To process and service your payment for any services rendered by Level Finance or its service providers<br />To manage payments, fees, and charges</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Financial<br />(d) Transaction</td>
                                <td>(a) Performance of a contract with you<br />(b) Necessary for our legitimate interests (to make or receive necessary organisation payments)<br />(c) Express consent</td>
                                <td>Xero based in New Zealand<br />Payroll providers (Sage, SimplePay, SAP) based in various regions/countries<br />AWS based in the USA</td>
                            </tr>
                            <tr>
                                <td>To manage our relationship with you which may include notifying you about changes to our terms or Privacy Policy or Services</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Marketing and Communications</td>
                                <td>(a) Performance of a contract with you<br />(b) Necessary to comply with a legal obligation<br />(c) Necessary for our legitimate interests (to keep our records updated and to study how users utilize our Services)<br />(d) Express consent</td>
                                <td>AWS, WhatsApp based in the USA<br />Mobile operators based in South Africa</td>
                            </tr>
                            <tr>
                                <td>To administer and protect our organisation and our Website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Usage</td>
                                <td>(a) Necessary for our legitimate interests (for running our organisation, provision of administration and IT services, network security, to prevent fraud and in the context of an organisation restructuring exercise)<br />(b) Necessary to comply with a legal obligation<br />(c) Express consent</td>
                                <td>Freshdesk based in the USA<br />AWS based in the USA<br />Google Analytics based in the USA<br />Wix based in Israel<br />Elitehost based in South Africa</td>
                            </tr>
                            <tr>
                                <td>To provide you with direct and user-specific marketing, make suggestions and recommendations to you about services that may be of interest to you</td>
                                <td>(a) Identity<br />(b) Contact<br />(c) Technical<br />(d) Usage<br />(e) Profile</td>
                                <td>(a) Necessary for our legitimate interests (to develop our services and grow our organisation)<br />(b) Express consent</td>
                                <td>MailChimp based in the USA<br />Survey Monkey based in the USA</td>
                            </tr>
                        </table>

                        <h2>8. Marketing</h2>
                        <p>
                            We strive to provide you with choices regarding certain personal data uses,
                            particularly around marketing and advertising. To manifest your rights attached
                            to any marketing sent to you as an existing customer, please use the in-built
                            prompts provided on those communications, or contact us.
                        </p>
                        <p>
                            You will receive marketing communications from us if you have accepted this
                            Policy, requested information from us, or have participated in any Level Finance
                            Service, and in each case, you have not opted-out of receiving that marketing.
                        </p>

                        <h2>9. Opting Out</h2>
                        <p>
                            You can ask us to stop sending you marketing messages at any time by
                            contacting us at any time and requesting us to cease or change your marketing
                            preferences.
                        </p>
                        <p>
                            Where you opt-out of receiving these marketing messages, this opt-out will not
                            apply to other personal data of yours which we process for another lawful basis.
                        </p>

                        <h2>10. Original and updated purposes for processing:</h2>
                        <p>
                            We will only use your personal information for the purposes for which we
                            collected it, unless we reasonably consider that we need to use it for another
                            reason and that reason is compatible with the original purpose. If you wish to
                            get an explanation as to how the processing for the new purpose is compatible
                            with the original purpose, please contact us. If we need to use your personal
                            information for an unrelated purpose, we will notify you and we will explain the
                            legal basis which allows us to do so.
                        </p>

                        <h2>11. Disclosure of personal information</h2>
                        <p>
                            We may have to share your personal data with the parties set out below for the
                            purposes set out in the table above.
                        </p>
                        <ul>
                            <li>Internal third parties (entities within the Level Finance group);</li>
                            <li>External third parties;</li>
                            <li>Specific third parties listed in the table above; and/or</li>
                            <li>Third parties to whom we may choose to sell, transfer, or merge parts
                                of our organisation or our assets. Alternatively, we may seek to acquire
                                other organisations or merge with them. If a change happens to our
                                organisation, then the new owners may use your personal data in the
                                same way as set out in this Privacy Policy.</li>
                        </ul>
                        <p>
                            We require all third parties to respect the security of your personal data and to
                            treat it in accordance with the law. We do not allow our third-party service
                            providers to use your personal data for their own purposes and only permit
                            them to process your personal data for specified purposes and in accordance
                            with our instructions and standards.
                        </p>

                        <h2>12. International Transfer of personal information:</h2>
                        <p>
                            We may share your personal information within the Level Finance group of
                            companies and this may involve transferring and processing your data outside
                            of South Africa.
                        </p>
                        <p>
                            Whenever we transfer your personal information out of the country, we ensure
                            a similar degree of protection is afforded to it by ensuring at least one of the
                            following safeguards is implemented:
                        </p>
                        <ul>
                            <li>we will only transfer your personal information to countries that have
                                been deemed to provide an adequate level of protection for personal
                                information;</li>
                            <li>We will always have a contract in place covering the processing of data
                                and service provision between the parties; and</li>
                            <li>We will only provide your personal data to an entity that processes
                                personal information at standards equal to or better than ours; or</li>
                            <li>We will only transfer your personal data to countries that have been
                                deemed to provide an adequate level of protection for personal data by
                                the South African Information Regulator’s office; or</li>
                            <li>where we use certain service providers, we may use specific contracts
                                approved by the European Commission which give personal information
                                the same protection it has in Europe under the GDPR.</li>
                        </ul>

                        <h2>13. How we treat your personal information:</h2>
                        <p>
                            We will ensure that all of our employees, third party service providers, divisions
                            and partners (including their employees and third-party service providers)
                            having access to your personal information are bound by appropriate and
                            legally binding confidentiality obligations and process your personal
                            information at standards equal to or higher than Level Finance’s in relation to
                            your personal information.
                        </p>
                        <p>We will:</p>
                        <ul>
                            <li>treat your personal information as strictly confidential, save where we
                                are entitled to share it as set out in this Policy;</li>
                            <li>take appropriate technical, security and organisational measures to
                                ensure that your personal information is kept secure and is protected
                                against unauthorised or unlawful processing, accidental loss,
                                destruction or damage, alteration, disclosure or access;</li>
                            <li>provide you with reasonable access to your personal information to
                                view and/or update personal details;</li>
                            <li>promptly notify you if we become aware of any unauthorised use,
                                disclosure or processing of your personal information;</li>
                            <li>provide you with reasonable evidence of our compliance with our
                                obligations under this Policy on reasonable notice and request; and</li>
                            <li>upon your request, promptly correct, transfer, return or destroy any and
                                all of your personal information in our possession or control, save for
                                that which we are legally obliged or entitled to retain (acknowledging
                                that some Website and/or Service functionality might be lost if certain
                                personal information is amended or destroyed).</li>
                        </ul>

                        <h2>14. Data Retention</h2>
                        <p>
                            We will not retain your personal information longer than the period for which
                            it was originally needed, unless we are required by law to do so, or you consent
                            to us retaining such information for a longer period. In some circumstances,
                            other applicable national laws require us to retain your data beyond your
                            request for its deletion, or beyond your direct engagement with Level Finance.
                            As such, we may retain your personal information in adherence with compulsory
                            instructions from other applicable national laws, notwithstanding your
                            application to have it deleted or amended.
                        </p>
                        <p>
                            Whilst we will do all things reasonably necessary to protect your rights of
                            privacy, we cannot guarantee or accept any liability whatsoever for
                            unauthorised or unlawful disclosures of your personal information, whilst in our
                            possession, made by third parties who are not subject to our control, unless
                            such disclosure is as a result of our gross negligence or fraud.
                        </p>

                        <h2>15. Data Security:</h2>
                        <p>
                            We have put in place appropriate security measures to prevent your personal
                            data from being accidentally lost, used, or accessed in an unauthorised way,
                            altered or disclosed by using SQL and Bitlocker encryption and storing all
                            information securely in Microsoft Azure. In addition, we limit access to your
                            personal data to those persons and other third parties who have a legitimate
                            need to know. They will only process your personal data on our instruction and
                            they are subject to a duty of confidentiality.
                        </p>
                        <p>
                            We have put in place procedures to deal with any suspected personal data
                            breach and will notify you and any applicable regulator of a breach where we
                            are legally required to do so.
                        </p>

                        <h2>16. Cookies provision:</h2>
                        <p>
                            The Website may make use of “cookies” to automatically collect information
                            and data through the standard operation of the Internet servers. “Cookies” are
                            small text files a website can use (and which we may use) to recognise repeat
                            users, facilitate the user’s on-going access to and use of a website and allow a
                            website to track usage behaviour and compile aggregate data that will allow
                            the website operator to improve the functionality of the website and its
                            content, and to display more focused advertising to a user by way of third party
                            tools. The type of information collected by cookies is not used to personally
                            identify you.
                        </p>
                        <p>
                            If you do not want information collected through the use of cookies, there is a
                            simple procedure in most browsers that allows you to deny or accept the cookie
                            feature. Please note that cookies may be necessary to provide you with certain
                            features available on our Website, and thus if you disable the cookies on your
                            browser you may not be able to use those features, and your access to our
                            Website will therefore be limited. If you do not disable “cookies”, you are
                            deemed to consent to our use of any personal information collected using
                            those cookies, subject to the provisions of this Policy and the Website’s other
                            policies.
                        </p>

                        <h2>17. User rights and obligations:</h2>
                        <p>
                            The user is entitled to request access to any relevant personal information held
                            by Level Finance and where such access is necessary for you to exercise and/or
                            protect any of the user’s rights. For any personal information held by any third-
                            party responsible party, the user must approach that responsible party for the
                            realisation of the user’s personal information rights with them, and not with
                            Level Finance.
                        </p>
                        <p>
                            Under POPI, you have rights in relation to your personal information. Please
                            contact us to find out more about, or manifest, these rights:
                        </p>
                        <ul>
                            <li>request access to your personal data;</li>
                            <li>request correction of your personal data;</li>
                            <li>request erasure of your personal data;</li>
                            <li>object to the processing of your personal data;</li>
                            <li>request a restriction of processing your personal data;</li>
                            <li>request transfer of your personal data; and/or</li>
                            <li>right to withdraw consent.</li>
                        </ul>
                        <p>
                            You will not have to pay a fee to access your personal information (or to
                            exercise any of the other rights). However, we may charge a reasonable fee if
                            your request is clearly unfounded, repetitive, or excessive. Alternatively, we may
                            refuse to comply with your request in these circumstances.
                        </p>
                        <p>
                            We may need to request specific information from you to help us confirm your
                            identity and ensure your right to access your personal information (or to
                            exercise any of your other rights). This is a security measure to ensure that
                            personal information is not disclosed to any person who has no right to receive
                            it. We may also contact you to ask you for further information in relation to
                            your request to speed up our response.
                        </p>
                        <p>
                            We try to respond to all legitimate requests within one month. Occasionally it
                            may take us longer than a month if your request is particularly complex or you
                            have made a number of requests. In this case, we will notify you and keep you
                            updated.
                        </p>
                        <p>
                            Users acknowledge that any content provided by users on the Website,
                            including via a messaging system, enters an open, public forum, and is not
                            confidential, where the author of which will be liable for that content, and not
                            Level Finance.
                        </p>
                        <p>
                            Users understand that there are risks involved in sharing personal information.
                            By disclosing personal information such as the user’s name and email address,
                            users acknowledge and understand that this information may be collected and
                            used by a third party to communicate with you.
                        </p>
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <input className='employee-benefits-assessment-multiple-choice-choices-item-checkbox'
                            type='checkbox' id='ts&csCheckbox'
                            checked={agreed}
                            onChange={(e) => handleOnCheckboxChange(e.target.checked)}
                        />
                        <label style={{ color: '#5B5B5B' }} htmlFor='ts&csCheckbox'>
                            {'By checking this box, I confirm that I have read, understood, and agree to the Level finance '}
                            <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">privacy policy</a>.
                        </label>
                    </div>
                    {agreed && <Button onClick={handleNext} width={'75%'}>I agree</Button>}
                </main>
            </div>
        );
    }
})

export default EmployerTerms
