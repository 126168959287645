import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';

const Input = styled(MuiInput)`
  width: 42px;
`;

function InputSlider({ value, min = 0, max = 100, onChange }) {
    const [internalValue, setInternalValue] = React.useState(value || min);

    const handleSliderChange = (event, newValue) => {
        setInternalValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value === '' ? 0 : Number(event.target.value);
        setInternalValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    // const handleBlur = () => {
    //     if (internalValue < min) {
    //         setInternalValue(min);
    //     } else if (internalValue > max) {
    //         setInternalValue(max);
    //     }
    // };

    React.useEffect(() => {
        setInternalValue(value);
    }, [value]);

    return (
        <Box sx={{ width: 250 }}>
            <Typography fontFamily="Inter" fontSize='13px' id="input-slider" gutterBottom>
                Percentage %
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Slider
                        value={typeof internalValue === 'number' ? internalValue : 0}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        min={min}
                        max={max}
                    // color={'success'}
                    />
                </Grid>
                <Grid item>
                    <Input
                        value={internalValue}
                        size="small"
                        onChange={handleInputChange}
                        // onBlur={handleBlur}
                        inputProps={{
                            fontFamily: 'Inter',
                            step: 10,
                            min: min,
                            max: max,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default InputSlider;
