import React from 'react';
import PropTypes from 'prop-types';

const ErrorCard = ({ type, message }) => {
    let cardColor = '';
    let bgColor = '';

    switch (type) {
        case 'success':
            cardColor = 'green';
            bgColor = '#d3f7d3'; // Light green
            break;
        case 'warning':
            cardColor = 'orange';
            bgColor = '#fdf5e6'; // Light orange-yellowish
            break;
        case 'error':
            cardColor = 'red';
            bgColor = '#ffcccc'; // Light bright red
            break;
        default:
            cardColor = 'black';
            bgColor = '#f0f0f0'; // Default light gray
    }

    let errorStyle = {
        color: cardColor,
        backgroundColor: bgColor,
        padding: '10px',
        borderRadius: '13px',
        fontSize: '13px',
        fontWeight: '600',
        margin: '10px 0px'
    }

    return (
        <div className={`error-card ${type}`} style={errorStyle}>
            <div>{message}</div>
        </div>
    );
};

ErrorCard.propTypes = {
    type: PropTypes.oneOf(['success', 'warning', 'error']),
    message: PropTypes.string.isRequired,
};

export default ErrorCard;
