

const questions = [

    {
        questionNumber: 1,
        questionType: 'oneOption',
        questionTitle: 'How would you describe your work arrangement?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Contract'
            },
            {
                optionNumber: 2,
                optionTitle: 'Permanent'
            }
        ]
    },
    {
        questionNumber: 2,
        questionType: 'oneOption',
        questionTitle: 'What is the typical duration of a contract?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Short-term (less than 6 months)'
            },
            {
                optionNumber: 2,
                optionTitle: 'Medium-term (6 to 12 months)'
            },
            {
                optionNumber: 3,
                optionTitle: 'Long-term (more than 12 months)'
            }
        ]
    },
    {
        questionNumber: 3,
        questionType: 'oneOption',
        questionTitle: 'What is the average duration of your employment at your current company?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Less than 1 year'
            },
            {
                optionNumber: 2,
                optionTitle: '1 to 5 years'
            }, {
                optionNumber: 3,
                optionTitle: '5 to 10 years'
            }, {
                optionNumber: 4,
                optionTitle: 'More than 10 years'
            }
        ]
    },
    {
        questionNumber: 4,
        questionType: 'oneOption',
        questionTitle: 'What age range do you fall under?',
        options: [
            {
                optionNumber: 1,
                optionTitle: '18 - 34'
            },
            {
                optionNumber: 2,
                optionTitle: '35 - 54'
            }, {
                optionNumber: 3,
                optionTitle: '55 - 64'
            }, {
                optionNumber: 4,
                optionTitle: '65 and above'
            }
        ]
    },
    {
        questionNumber: 5,
        questionType: 'dropDown',
        questionTitle: 'Which gender identity do you most closely identify with?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Male'
            },
            {
                optionNumber: 2,
                optionTitle: 'Female'
            },
            {
                optionNumber: 3,
                optionTitle: 'Non-binary'
            },
            {
                optionNumber: 4,
                optionTitle: 'Genderqueer'
            },
            {
                optionNumber: 5,
                optionTitle: 'Transgender'
            },
            {
                optionNumber: 6,
                optionTitle: 'Prefer not to say'
            },
        ]
    },
    {
        questionNumber: 6,
        questionType: 'dropDown',
        questionTitle: 'How often are you off at work due to physical illness in a month? ',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Never'
            },
            {
                optionNumber: 2,
                optionTitle: 'Hardly ever'
            },
            {
                optionNumber: 3,
                optionTitle: 'Once every 3 months'
            },
            {
                optionNumber: 4,
                optionTitle: 'One or two times a month'
            },
            {
                optionNumber: 5,
                optionTitle: 'More than two times a month'
            }
        ]
    },
    {
        questionNumber: 7,
        questionType: 'dropDown',
        questionTitle: 'How often are you off at work due to mental health issues in a month?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Never'
            },
            {
                optionNumber: 2,
                optionTitle: 'Hardly ever'
            },
            {
                optionNumber: 3,
                optionTitle: 'Once every 3 months'
            },
            {
                optionNumber: 4,
                optionTitle: 'One or two times a month'
            },
            {
                optionNumber: 5,
                optionTitle: 'More than two times a month'
            }
        ]
    },
    {
        questionNumber: 8,
        questionType: 'dropDown',
        questionTitle: 'How often do you have money problems in the month?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Never'
            },
            {
                optionNumber: 2,
                optionTitle: 'Hardly ever'
            },
            {
                optionNumber: 3,
                optionTitle: 'Once every 3 months'
            },
            {
                optionNumber: 4,
                optionTitle: 'One or two times a month'
            },
            {
                optionNumber: 5,
                optionTitle: 'More than two times a month'
            }
        ]
    },
    {
        questionNumber: 9,
        questionType: 'dropDown',
        questionTitle: 'How often do you have issues/problems around family matters?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Never'
            },
            {
                optionNumber: 2,
                optionTitle: 'Hardly ever'
            },
            {
                optionNumber: 3,
                optionTitle: 'Once every 3 months'
            },
            {
                optionNumber: 4,
                optionTitle: 'One or two times a month'
            },
            {
                optionNumber: 5,
                optionTitle: 'More than two times a month'
            }
        ]
    },
    {
        questionNumber: 10,
        questionType: 'multiCheck',
        questionTitle: 'Which of the following benefits provided by your company are you currently utilising? Select all that apply.',
        options: [
            {
                questionTitle: "Traditional health benefits",
                id: 1,
                options: [
                    {
                        id: 1,
                        title: "Medical aid (comprehensive)"
                    },
                    {
                        id: 2,
                        title: "Hospital plan"
                    },
                    {
                        id: 3,
                        title: "International insurance"
                    },
                    {
                        id: 4,
                        title: "Dental"
                    },
                    {
                        id: 5,
                        title: "Vision"
                    },
                    {
                        id: 6,
                        title: "Health screenings"
                    },
                    {
                        id: 7,
                        title: "Chronic Disease management"
                    },
                    {
                        id: 8,
                        title: "Access to on-site and virtual health care services and wellness days"
                    },
                    {
                        id: 9,
                        title: "Employee assistance program (EAP)"
                    }
                ]
            },
            {
                questionTitle: "Non-traditional health benefits",
                id: 2,
                options: [
                    {
                        id: 1,
                        title: "Telemedicine"
                    },
                    {
                        id: 2,
                        title: "Dedicated mental health support"
                    },
                    {
                        id: 3,
                        title: "Maternity"
                    },
                    {
                        id: 4,
                        title: "Caregiving"
                    },
                    {
                        id: 5,
                        title: "Access to parental offerings for first-time parent"
                    },
                    {
                        id: 6,
                        title: "Menopause health support"
                    },
                    {
                        id: 7,
                        title: "Access to digital health apps"
                    },
                    {
                        id: 8,
                        title: "Fitness equipment & accessories"
                    },
                    {
                        id: 9,
                        title: "Fitness and Gym activity memberships"
                    }
                ]
            },
            {
                questionTitle: "Non-traditional financial wellness benefits",
                id: 3,
                options: [
                    {
                        title: "Graduate BNPL",
                        id: 1
                    },
                    {
                        title: "Commute",
                        id: 2
                    },
                    {
                        title: "Food/Data/Utility vouchers",
                        id: 3
                    },
                    {
                        title: "Consumer goods",
                        id: 4
                    },
                    {
                        title: "Solar asset financing",
                        id: 5
                    },
                    {
                        title: "Personal Finance Automation",
                        id: 6
                    },
                    {
                        title: "Savings and investment",
                        id: 7
                    },
                    {
                        title: "Access to earned wages",
                        id: 8
                    }
                ]
            },


            {
                questionTitle: "Traditional financial wellness benefits",
                id: 4,
                options: [
                    {
                        title: "Financial education workshops (Remote/in-person)",
                        id: 1
                    },
                    {
                        title: "Financial coaching",
                        id: 2
                    },
                    {
                        title: "Estate planning",
                        id: 3
                    },
                    {
                        title: "Tax planning",
                        id: 4
                    },
                    {
                        title: "Funeral cover",
                        id: 5
                    },
                    {
                        title: "Life disability cover",
                        id: 6
                    },
                    {
                        title: "Severe illness",
                        id: 7
                    },
                    {
                        title: "Education",
                        id: 8
                    },
                    {
                        title: "Income protection",
                        id: 9
                    },
                    {
                        title: "Spousal cover",
                        id: 10
                    },
                    {
                        title: "Retirement planning",
                        id: 11
                    },
                    {
                        title: "Debt management",
                        id: 12
                    }
                ]

            },
            {
                questionTitle: "Lifestyle benefits",
                id: 5,
                options: [
                    {
                        id: 1,
                        title: "Music & Video streaming services"
                    },
                    {
                        id: 2,
                        title: "Identify theft services"
                    },
                    {
                        id: 3,
                        title: "Travel accommodation"
                    },
                    {
                        id: 4,
                        title: "Massage therapy"
                    }
                ]
            },
            {
                questionTitle: "None",
                id: 6,
                options: [
                    {
                        id: 1,
                        title: "None"
                    }
                ]
            },

        ]
    },
    {
        questionNumber: 11,
        questionType: 'multiCheck',
        questionTitle: 'Of the following benefits, which ones would you be interested in utilising if your company made it available? Please select all that apply.',
        options: [
            {
                questionTitle: "Traditional health benefits",
                id: 1,
                options: [
                    {
                        id: 1,
                        title: "Medical aid (comprehensive)"
                    },
                    {
                        id: 2,
                        title: "Hospital plan"
                    },
                    {
                        id: 3,
                        title: "International insurance"
                    },
                    {
                        id: 4,
                        title: "Dental"
                    },
                    {
                        id: 5,
                        title: "Vision"
                    },
                    {
                        id: 6,
                        title: "Health screenings"
                    },
                    {
                        id: 7,
                        title: "Chronic Disease management"
                    },
                    {
                        id: 8,
                        title: "Access to on-site and virtual health care services and wellness days"
                    },
                    {
                        id: 9,
                        title: "Employee assistance program (EAP)"
                    }
                ]
            },
            {
                questionTitle: "Non-traditional health benefits",
                id: 2,
                options: [
                    {
                        id: 1,
                        title: "Telemedicine"
                    },
                    {
                        id: 2,
                        title: "Dedicated mental health support"
                    },
                    {
                        id: 3,
                        title: "Maternity"
                    },
                    {
                        id: 4,
                        title: "Caregiving"
                    },
                    {
                        id: 5,
                        title: "Access to parental offerings for first-time parent"
                    },
                    {
                        id: 6,
                        title: "Menopause health support"
                    },
                    {
                        id: 7,
                        title: "Access to digital health apps"
                    },
                    {
                        id: 8,
                        title: "Fitness equipment & accessories"
                    },
                    {
                        id: 9,
                        title: "Fitness and Gym activity memberships"
                    }
                ]
            },
            {
                questionTitle: "Non-traditional financial wellness benefits",
                id: 3,
                options: [
                    {
                        title: "Graduate BNPL",
                        id: 1
                    },
                    {
                        title: "Commute",
                        id: 2
                    },
                    {
                        title: "Food/Data/Utility vouchers",
                        id: 3
                    },
                    {
                        title: "Consumer goods",
                        id: 4
                    },
                    {
                        title: "Solar asset financing",
                        id: 5
                    },
                    {
                        title: "Personal Finance Automation",
                        id: 6
                    },
                    {
                        title: "Savings and investment",
                        id: 7
                    },
                    {
                        title: "Access to earned wages",
                        id: 8
                    }
                ]
            },


            {
                questionTitle: "Traditional financial wellness benefits",
                id: 4,
                options: [
                    {
                        title: "Financial education workshops (Remote/in-person)",
                        id: 1
                    },
                    {
                        title: "Financial coaching",
                        id: 2
                    },
                    {
                        title: "Estate planning",
                        id: 3
                    },
                    {
                        title: "Tax planning",
                        id: 4
                    },
                    {
                        title: "Funeral cover",
                        id: 5
                    },
                    {
                        title: "Life disability cover",
                        id: 6
                    },
                    {
                        title: "Severe illness",
                        id: 7
                    },
                    {
                        title: "Education",
                        id: 8
                    },
                    {
                        title: "Income protection",
                        id: 9
                    },
                    {
                        title: "Spousal cover",
                        id: 10
                    },
                    {
                        title: "Retirement planning",
                        id: 11
                    },
                    {
                        title: "Debt management",
                        id: 12
                    }
                ]
            },
            {
                questionTitle: "Lifestyle benefits",
                id: 5,
                options: [
                    {
                        id: 1,
                        title: "Music & Video streaming services"
                    },
                    {
                        id: 2,
                        title: "Identify theft services"
                    },
                    {
                        id: 3,
                        title: "Travel accommodation"
                    },
                    {
                        id: 4,
                        title: "Massage therapy"
                    }
                ]
            },
            {
                questionTitle: "None",
                id: 6,
                options: [
                    {
                        id: 1,
                        title: "None"
                    }
                ]
            },

        ]
    }
]

export default questions;