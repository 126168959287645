import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Link } from 'react-router-dom';
import { GreenCheckCircle, BigLockIcon } from '../../assets/icons';
import { Button, TopBar, ErrorCard, Input } from '../../components/basic/index'
import { AndroidInstallPrompt, IOSInstallPrompt } from '../../shared/components';
import { LandingImage } from '../../assets/icons';

import './style.scss';

const SignIn = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [webauthn, setWebauthn] = useState(true);
    const [usePassword, setUsePassword] = useState(false);
    const [displayPrompt, setDisplayPrompt] = useState(true);
    const [device, setDevice] = useState('');
    const [prompt, setPrompt] = useState({});
    const [error, setError] = useState('');
    const [errorType, setErrorType] = useState('');
    const [userId, setUserId] = useState('');

    const [employerAdmin, setEmployerAdmin] = useState(false)

    const [checked, setChecked] = useState(false)
    const [displayDataAccess, setDisplayDataAccess] = useState(false)
    const [dataAccessAccepted, setDataAccessAccepted] = useState(false)

    function closeInstallPrompt() {
        // let { data } = state;
        setDisplayPrompt(false);
    }

    function installPromptInstall() {
        // let { data } = state;
        prompt.prompt();
        setDisplayPrompt(false);
    }

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (typeof window.PublicKeyCredential !== 'undefined') {
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available) {
                    //Webauthn Available
                    setWebauthn(true);
                } else {
                    //Webauthn not available
                    setWebauthn(false);
                }
            }).catch((e) => {
                //Webauthn not available
                setWebauthn(false);
            })
        } else {
            setWebauthn(false);
        }

        if (isMobile()) { //Install Prompt

            if (window.matchMedia('(display-mode: standalone)').matches) {
                //If already running PWA
                return
            }
            const ua = window.navigator.userAgent;
            if (!!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Mac/i)) {
                //iOS
                loadIosPrompt();
            } else {
                //Android
                window.addEventListener('beforeinstallprompt', (event) => {
                    console.log('[beforeinstallprompt] Called');
                    // Prevent Chrome <= 67 from automatically showing the prompt
                    event.preventDefault();
                    // Stash the event so it can be triggered later.
                    setPrompt(event);
                    // Update the install UI to notify the user app can be installed
                    // document.querySelector('#install-button').disabled = false;
                    loadPrompt();
                });

            }
        }
    }, []);

    function loadPrompt() {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            //If already running PWA
            return
        }
        const ua = window.navigator.userAgent;

        if (!!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Mac/i)) {
            //iOS
            // data.device = 'iOS';
            // setStateData({ data: data});
            return
        } else {
            //Android
            setDevice('Android');
        }
    }

    function loadIosPrompt() {
        setDevice('iOS');
    }

    const handleUsernameOnChange = (value) => {
        setUsername(value)
        setError('')
        setErrorType('')
    }

    const handlePasswordOnChange = (value) => {
        setPassword(value)
        setError('')
        setErrorType('')
    }

    const handleConfirmPasswordOnChange = (value) => {
        setConfirmPassword(value)
        setError('')
        setErrorType('')
    }

    async function getUser() {
        setError("")

        if (!dataAccessAccepted || !checked) {
            setError('Please allow Level finance to access your data to continue')
            setErrorType('invalid')
            return false;
        }

        let id = username;

        if (!id || id.length < 6) {
            setError('Username should be atleast 5 characters long')
            setErrorType('invalid')
            return false;
        }

        try {
            commonStore.setLoading(true);
            let ret = await commonStore.getUserByIdNumberSignUp(id)
            if (ret === "User not found" || !ret) {
                setError('User not found')
                setErrorType('usernotfound')
                commonStore.setLoading(false);
                return
            } else if (ret === "error") {
                setUsePassword(true);
                setError('Invalid login details')
                setErrorType('invalid')
                commonStore.setLoading(false);
                return
            } else if (ret === 'Blocked') {
                setError('This account has been blocked, please contact support at +27 71 665 0782.')
                setErrorType('blocked')
                commonStore.setLoading(false);
                return
            } else if (ret) {
                if (ret.Blocked === true) {
                    setError('This account has been blocked, please contact support at +27 71 665 0782.')
                    setErrorType('blocked')
                    commonStore.setLoading(false);
                    return
                }
                if (ret.Confirmed === true) {
                    setError('User has already registered, please login.')
                    setErrorType('blocked')
                    commonStore.setLoading(false);
                    return
                }
                if (!ret?.Client?.Active && ret?.Role !== "5d8e0b079f51d42bbece9201") {
                    setError('Apologies, cannot register to service.')
                    setErrorType('blocked')
                    commonStore.setLoading(false);
                    return
                }
                commonStore.setUserDetails(ret)

                if (ret.Role === "5d8e0b079f51d42bbece9202" || ret.Role === "5d8e0b079f51d42bbece9002") {
                    //EMPLOYEE
                    //check if employed in payroll
                    let payrollInfo = commonStore.user.PayrollInformation || false
                    if (payrollInfo) {
                        if (payrollInfo.Provider === "Labour net" || payrollInfo.Provider === "LabourNet") {
                            let verifyLabour = await verifyLabournetEmployee()
                            // employee is terminated - Labournet
                            if (verifyLabour === false) {
                                setError("Employee cannot use this service")
                                return
                            }
                        }
                        if (payrollInfo.Provider === "Sage 300" || payrollInfo.Provider === "Sage300") {
                            let verifySage = await verifySageEmployee()
                            // employee is terminated - Sage 300
                            if (verifySage === false) {
                                setError("Employee cannot use this service")
                                return
                            }
                        }
                    }

                    //check agreements signed
                    commonStore.setLoading(true);
                    let res = await commonStore.getAgreementsByUsername(commonStore.user.Username)
                    commonStore.setLoading(false);
                    if (Array.isArray(res)) {
                        if (res.length === 0) {
                            navigate('/employee/agreement')
                            return
                        }
                        else {
                            res.forEach((agr, index) => {
                                if (agr.Type === "Employee privacy policy") {
                                    if (commonStore.user.IDVerification.length > 0) {
                                        //id already verified
                                        navigate('/employee/account/secure')
                                        return
                                    }
                                    else {
                                        if (commonStore.user.Role === "5d8e0b079f51d42bbece9002") {
                                            //demo user
                                            let data = {
                                                Verified: true, IDNumber: commonStore.user.Username, SelfieVerification: false,
                                                Payload: "Demo", Response: "Demo", Date: new Date()
                                            }
                                            commonStore.updateUserByUsername(commonStore.user.Username, { IDVerification: data }).then((res) => {
                                                if (res) {
                                                    navigate('/employee/account/secure');
                                                }
                                            })
                                            return
                                        }
                                        else {
                                            navigate('/employee/verifyid')
                                            return
                                        }

                                    }

                                }
                                else {
                                    if (index === res.length - 1) {
                                        navigate('/employee/agreement')
                                    }
                                }
                            })
                        }
                    }
                    else {
                        navigate('/employee/agreement')
                        return
                    }

                } else if (ret.Role === '62bd55b7c5793941427c702d') {
                    //HR MANAGER
                    commonStore.setUserDetails(ret)
                    setEmployerAdmin(true)
                    commonStore.setLoading(false);
                    return
                }
            } else {
                setWebauthn(false);
                commonStore.setLoading(false);
                return
            }
        } catch (error) {
            commonStore.setLoading(false);
            console.error(error)
            setError('Error occurred')
            setErrorType('invalid')
            return false
        }
    }

    const handleShowPassword = () => {
        let opp = !showPassword
        setShowPassword(opp)
    }

    const verifyLabournetEmployee = async () => {
        let res = await commonStore.updateLabournetEmployee(commonStore.user.Client._id, commonStore.user.Username)
        if (res?.status === true) {
            if (res.response.EmploymentStatus === "Terminated") return false
            else if (res.response.EmploymentStatus === "Employed") return true
            else return false
        }
        else return false
    }

    const verifySageEmployee = async () => {
        let res = await commonStore.updateSageEmployee(commonStore.user.Client._id, commonStore.user.Username)
        if (res?.status === true) {
            if (res.response.EmploymentStatus === "Terminated") return false
            else if (res.response.EmploymentStatus === "Employed") return true
            else return false
        }
        else return false
    }

    const createAccount = async () => {

        if (password !== confirmPassword) {
            setError("Passwords do not match");
            setErrorType('password match')
            return;
        } else if (password) {
            // Check the strength of the password
            if (password.length < 8) {
                setError("Password must be at least 8 characters long");
                setErrorType('password length')
                console.log(error)
                return;
            }

            let hasUpperCase = /[A-Z]/.test(password);
            let hasLowerCase = /[a-z]/.test(password);
            let hasNumber = /\d/.test(password);
            let hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

            if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar) {
                setError("Password should include at least one uppercase letter, one lowercase letter, one number, and one special character");
                setErrorType('password chars')
                return;
            }
        }

        try {
            commonStore.setLoading(true)
            let ret = await commonStore.setEmployerAdminPassword(userId, password)
            commonStore.setLoading(false)
            if (ret) {
                navigate(`/client/home`)
                return
            }
            else {
                commonStore.setLoading(false);
                console.error(error)
                setError('Error occurred')
                setErrorType('invalid')
                return false
            }
        } catch (error) {
            commonStore.setLoading(false);
            console.error(error)
            setError('Error occurred')
            setErrorType('invalid')
            return false
        }

    }
    const handleCheckboxChange = () => {
        setChecked(!checked)
        if (!checked) {
            setDisplayDataAccess(true)
        }
        setError('')
        setErrorType('')
    }

    const handleCancel = () => {
        setChecked(false)
        setDisplayDataAccess(false)
        setError('')
        setErrorType('')
    }

    const handleAcceptDataAccess = () => {
        setDataAccessAccepted(true)
        setDisplayDataAccess(false)
        setError('')
        setErrorType('')
    }

    if (isMobile()) {
        return (
            <div>
                <TopBar />
                <main style={{ justifyContent: 'center', marginTop: '5vh', padding: '30px' }}>
                    {!displayDataAccess &&
                        <><header className='signin-main-header'>Welcome to Level</header>

                            <p className='employee-benefits-assessment-submit-subheader' style={{ marginRight: 'auto', fontSize: '15px', color: '#010143a8' }}>
                                Please confirm your account.
                            </p>
                        </>

                    }
                    {!employerAdmin && !displayDataAccess &&
                        <div className='signin-inputs-container'>
                            <div className='flex-column'>
                                <label className='signin-input-label'>ID number or passport / Email</label>
                                <input className='signin-input-item'
                                    onChange={(e) => handleUsernameOnChange(e.target.value)}
                                    value={username} width='100%' placeholder={"ID Number / Email"}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '7px' }}>
                                <input checked={checked} onChange={handleCheckboxChange} type='checkbox' style={{ width: '15px' }} />
                                <p className='employee-benefits-assessment-submit-subheader' style={{ marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                                    Allow Level finance to access my personal data
                                </p>
                            </div>
                            <button className='signin-sign-in-btn' onClick={() => getUser()}>Register</button>
                        </div>
                    }
                    {displayDataAccess &&
                        <div>
                            <img src={BigLockIcon} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                            <p className='signin-main-header' style={{ fontSize: '30px' }}>
                                We need access to your data.
                            </p>
                            <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                                To register your account, we need to access your personal data listed below
                            </p>
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img style={{ width: '20px' }} src={GreenCheckCircle} />
                                    <div>Full name</div>
                                </div>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img style={{ width: '20px' }} src={GreenCheckCircle} />
                                    <div>Contact information</div>
                                </div>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img style={{ width: '20px' }} src={GreenCheckCircle} />
                                    <div>Salary information</div>
                                </div>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img style={{ width: '20px' }} src={GreenCheckCircle} />
                                    <div>Bank account details</div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p className='signin-sign-in-btn' onClick={handleAcceptDataAccess}>Allow access</p>
                                <br />
                                <button className='signin-register-in-btn' onClick={handleCancel}>Cancel</button>
                            </div>
                        </div>
                    }
                    {
                        employerAdmin && !displayDataAccess &&
                        <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Password</label>
                                    <label style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        className='signin-input-label'
                                        onClick={handleShowPassword}>Show</label>
                                </div>
                                <input className='signin-input-item'
                                    onChange={(e) => handlePasswordOnChange(e.target.value)}
                                    value={password} width='100%' placeholder={"Please enter your password"}
                                    type={`${showPassword ? 'text' : 'password'}`}
                                />
                            </div>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Confirm Password</label>
                                </div>
                                <input className='signin-input-item'
                                    onChange={(e) => handleConfirmPasswordOnChange(e.target.value)}
                                    value={confirmPassword} width='100%' placeholder={"Please confirm your password"}
                                    type={`${showPassword ? 'text' : 'password'}`}
                                />
                            </div>
                            <button className='signin-sign-in-btn' onClick={() => createAccount()}>Register</button>
                        </div>
                    }
                    {!displayDataAccess && <>
                        <button className='signin-register-in-btn' onClick={() => { navigate('/signin') }}>Already registered?</button>
                        <div className='disclamer-text'>
                            By using Level you agree to our <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> & <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        </div>
                    </>}

                    {
                        error !== "" &&
                        <ErrorCard type='error' message={error} />
                    }
                </main>
            </div>
        );
    } else {

        return (
            <div style={{ display: 'flex' }}>
                <div>
                    <img src={LandingImage} style={{ height: '100vh' }} />
                </div>
                <main style={{ justifyContent: 'center', padding: '35px 200px 0px 100px' }}>
                    {!displayDataAccess &&
                        <><header className='signin-main-header'>Welcome to Level</header>

                            <p className='employee-benefits-assessment-submit-subheader' style={{ marginRight: 'auto', fontSize: '15px', color: '#010143a8' }}>
                                Please confirm your account.
                            </p>
                        </>

                    }
                    {!employerAdmin && !displayDataAccess &&
                        <div className='signin-inputs-container'>
                            <div className='flex-column'>
                                <label className='signin-input-label'>ID number or passport / Email</label>
                                <input className='signin-input-item'
                                    onChange={(e) => handleUsernameOnChange(e.target.value)}
                                    value={username} placeholder={"ID Number / Email"}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '7px' }}>
                                <input checked={checked} onChange={handleCheckboxChange} type='checkbox' style={{ width: '15px' }} />
                                <p className='employee-benefits-assessment-submit-subheader' style={{ marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                                    Allow Level finance to access my personal data
                                </p>
                            </div>
                            <button className='signin-sign-in-btn' onClick={() => getUser()}>Register</button>
                        </div>
                    }
                    {displayDataAccess &&
                        <div>
                            <img src={BigLockIcon} style={{ width: '100%', height: '130px', margin: 'auto' }} />
                            <p className='signin-main-header' style={{ fontSize: '30px' }}>
                                We need access to your data.
                            </p>
                            <p className='employee-benefits-assessment-submit-subheader' style={{ textAlign: 'left', marginRight: 'auto', fontSize: '12px', color: '#010143' }}>
                                To register your account, we need to access your personal data listed below
                            </p>
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img style={{ width: '20px' }} src={GreenCheckCircle} />
                                    <div>Full name</div>
                                </div>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img style={{ width: '20px' }} src={GreenCheckCircle} />
                                    <div>Contact information</div>
                                </div>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img style={{ width: '20px' }} src={GreenCheckCircle} />
                                    <div>Salary information</div>
                                </div>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img style={{ width: '20px' }} src={GreenCheckCircle} />
                                    <div>Bank account details</div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p className='signin-sign-in-btn' onClick={handleAcceptDataAccess}>Allow access</p>
                                <br />
                                <button className='signin-register-in-btn' onClick={handleCancel}>Cancel</button>
                            </div>
                        </div>
                    }
                    {
                        employerAdmin && !displayDataAccess &&
                        <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Password</label>
                                    <label style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        className='signin-input-label'
                                        onClick={handleShowPassword}>Show</label>
                                </div>
                                <input className='signin-input-item'
                                    onChange={(e) => handlePasswordOnChange(e.target.value)}
                                    value={password} width='100%' placeholder={"Please enter your password"}
                                    type={`${showPassword ? 'text' : 'password'}`}
                                />
                            </div>
                            <div className='flex-column'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className='signin-input-label'>Confirm Password</label>
                                    <label style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        className='signin-input-label'
                                        onClick={() => handleShowPassword}>Show</label>
                                </div>
                                <input className='signin-input-item'
                                    onChange={(e) => handleConfirmPasswordOnChange(e.target.value)}
                                    value={confirmPassword} width='100%' placeholder={"Please confirm your password"}
                                    type={`${showPassword ? 'text' : 'password'}`}
                                />
                            </div>
                            <button className='signin-sign-in-btn' onClick={() => createAccount()}>Register</button>
                        </div>
                    }
                    {!displayDataAccess && <>
                        <button className='signin-register-in-btn' onClick={() => { navigate('/signin') }}>Already registered?</button>
                        <div className='disclamer-text'>
                            By using Level you agree to our <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> & <a href="https://d2xts8p0gw7i1c.cloudfront.net/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        </div>
                    </>}

                    {
                        error !== "" &&
                        <ErrorCard type='error' message={error} />
                    }
                </main>
            </div>
        );
    }
})

export default SignIn
