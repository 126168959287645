

const questions = [
    {
        questionNumber: 1,
        questionType: 'input',
        questionTitle: 'Please provide your full company name:',
        placeHolder: 'Company name'
    },
    {
        questionNumber: 2,
        questionType: 'input',
        questionTitle: 'Please provide your role within the company',
        placeHolder: 'Your role'

    },
    {
        questionNumber: 3,
        questionType: 'oneOption',
        questionTitle: 'How would you categorize the size of your company based on the number of employees?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Small (1-50 employees)'
            },
            {
                optionNumber: 2,
                optionTitle: 'Medium (51-500 employees)'
            }, {
                optionNumber: 3,
                optionTitle: 'Large (501-5000 employees)'
            }, {
                optionNumber: 4,
                optionTitle: 'Enterprise (5001+ employees)'
            }
        ]
    },
    {
        questionNumber: 4,
        questionType: 'oneOption',
        questionTitle: 'What is the general age distribution of your workforce?',
        options: [
            {
                optionNumber: 1,
                optionTitle: '18 - 34'
            },
            {
                optionNumber: 2,
                optionTitle: '35 - 54'
            }, {
                optionNumber: 3,
                optionTitle: '55 - 64'
            }, {
                optionNumber: 4,
                optionTitle: '65 and above'
            }
        ]
    },
    {
        questionNumber: 5,
        questionType: 'oneOption',
        questionTitle: 'What payroll system do you currently use?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Payspace'
            },
            {
                optionNumber: 2,
                optionTitle: 'Labournet'
            },
            {
                optionNumber: 3,
                optionTitle: 'Sage300'
            },
            {
                optionNumber: 4,
                optionTitle: 'Simplepay'
            },
            {
                optionNumber: 5,
                optionTitle: 'Other'
            }
        ]
    },
    {
        questionNumber: 6,
        questionType: 'dropDown',
        questionTitle: 'How often are your staff off at work in a month due to physical illness?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Never'
            },
            {
                optionNumber: 2,
                optionTitle: 'Hardly ever'
            },
            {
                optionNumber: 3,
                optionTitle: 'Once every 3 months'
            },
            {
                optionNumber: 4,
                optionTitle: 'One or two times a month'
            },
            {
                optionNumber: 5,
                optionTitle: 'More than two times a month'
            }
        ]
    },
    {
        questionNumber: 7,
        questionType: 'dropDown',
        questionTitle: 'How often are your staff off at work in a month due to mental health issues?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Never'
            },
            {
                optionNumber: 2,
                optionTitle: 'Hardly ever'
            },
            {
                optionNumber: 3,
                optionTitle: 'Once every 3 months'
            },
            {
                optionNumber: 4,
                optionTitle: 'One or two times a month'
            },
            {
                optionNumber: 5,
                optionTitle: 'More than two times a month'
            }
        ]
    },
    {
        questionNumber: 8,
        questionType: 'dropDown',
        questionTitle: 'How often do staff come to you or managers to voice their problems financially?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Never'
            },
            {
                optionNumber: 2,
                optionTitle: 'Hardly ever'
            },
            {
                optionNumber: 3,
                optionTitle: 'Once every 3 months'
            },
            {
                optionNumber: 4,
                optionTitle: 'One or two times a month'
            },
            {
                optionNumber: 5,
                optionTitle: 'More than two times a month'
            }
        ]
    },
    {
        questionNumber: 9,
        questionType: 'dropDown',
        questionTitle: 'How often do your staff come to you about issues around family matters?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Never'
            },
            {
                optionNumber: 2,
                optionTitle: 'Hardly ever'
            },
            {
                optionNumber: 3,
                optionTitle: 'Once every 3 months'
            },
            {
                optionNumber: 4,
                optionTitle: 'One or two times a month'
            },
            {
                optionNumber: 5,
                optionTitle: 'More than two times a month'
            }
        ]
    },
    {
        questionNumber: 10,
        questionType: 'multiCheck',
        questionTitle: 'Which of the following benefits are currently provided by your company?',
        options: [
            {
                questionTitle: "Traditional health benefits",
                id: 1,
                options: [
                    {
                        id: 1,
                        title: "Medical aid (comprehensive)"
                    },
                    {
                        id: 2,
                        title: "Hospital plan"
                    },
                    {
                        id: 3,
                        title: "International insurance"
                    },
                    {
                        id: 4,
                        title: "Dental"
                    },
                    {
                        id: 5,
                        title: "Vision"
                    },
                    {
                        id: 6,
                        title: "Health screenings"
                    },
                    {
                        id: 7,
                        title: "Chronic Disease management"
                    },
                    {
                        id: 8,
                        title: "Access to on-site and virtual health care services and wellness days"
                    },
                    {
                        id: 9,
                        title: "Employee assistance program (EAP)"
                    }
                ]
            },
            {
                questionTitle: "Non-traditional health benefits",
                id: 2,
                options: [
                    {
                        id: 1,
                        title: "Telemedicine"
                    },
                    {
                        id: 2,
                        title: "Dedicated mental health support"
                    },
                    {
                        id: 3,
                        title: "Maternity"
                    },
                    {
                        id: 4,
                        title: "Caregiving"
                    },
                    {
                        id: 5,
                        title: "Access to parental offerings for first-time parent"
                    },
                    {
                        id: 6,
                        title: "Menopause health support"
                    },
                    {
                        id: 7,
                        title: "Access to digital health apps"
                    },
                    {
                        id: 8,
                        title: "Fitness equipment & accessories"
                    },
                    {
                        id: 9,
                        title: "Fitness and Gym activity memberships"
                    }
                ]
            },
            {
                questionTitle: "Non-traditional financial wellness benefits",
                id: 4,
                options: [
                    {
                        title: "Graduate BNPL",
                        id: 1
                    },
                    {
                        title: "Commute",
                        id: 2
                    },
                    {
                        title: "Food/Data/Utility vouchers",
                        id: 3
                    },
                    {
                        title: "Consumer goods",
                        id: 4
                    },
                    {
                        title: "Solar asset financing",
                        id: 5
                    },
                    {
                        title: "Personal Finance Automation",
                        id: 6
                    },
                    {
                        title: "Savings and investment",
                        id: 7
                    },
                    {
                        title: "Access to earned wages",
                        id: 8
                    }
                ]
            },
            {
                questionTitle: "Traditional financial wellness benefits",
                id: 5,
                options: [
                    {
                        title: "Financial education workshops (Remote/in-person)",
                        id: 1
                    },
                    {
                        title: "Financial coaching",
                        id: 2
                    },
                    {
                        title: "Estate planning",
                        id: 3
                    },
                    {
                        title: "Tax planning",
                        id: 4
                    },
                    {
                        title: "Funeral cover",
                        id: 5
                    },
                    {
                        title: "Life disability cover",
                        id: 6
                    },
                    {
                        title: "Severe illness",
                        id: 7
                    },
                    {
                        title: "Education",
                        id: 8
                    },
                    {
                        title: "Income protection",
                        id: 9
                    },
                    {
                        title: "Spousal cover",
                        id: 10
                    },
                    {
                        title: "Retirement planning",
                        id: 11
                    },
                    {
                        title: "Debt management",
                        id: 12
                    }
                ]

            },
            {
                questionTitle: "Lifestyle benefits",
                id: 6,
                options: [
                    {
                        id: 1,
                        title: "Music & Video streaming services"
                    },
                    {
                        id: 2,
                        title: "Identify theft services"
                    },
                    {
                        id: 3,
                        title: "Travel accommodation"
                    },
                    {
                        id: 4,
                        title: "Massage therapy"
                    }
                ]
            },
            {
                questionTitle: "None",
                id: 7,
                options: [
                    {
                        id: 1,
                        title: "None"
                    }
                ]
            },

        ]
    },
    {
        questionNumber: 11,
        questionType: 'multiCheck',
        questionTitle: 'Considering your company and objectives regarding the employee value proposition, of the following benefits, which ones would you be interested in offering to your employees?',
        options: [
            {
                questionTitle: "Traditional health benefits",
                id: 1,
                options: [
                    {
                        id: 1,
                        title: "Medical aid (comprehensive)"
                    },
                    {
                        id: 2,
                        title: "Hospital plan"
                    },
                    {
                        id: 3,
                        title: "International insurance"
                    },
                    {
                        id: 4,
                        title: "Dental"
                    },
                    {
                        id: 5,
                        title: "Vision"
                    },
                    {
                        id: 6,
                        title: "Health screenings"
                    },
                    {
                        id: 7,
                        title: "Chronic Disease management"
                    },
                    {
                        id: 8,
                        title: "Access to on-site and virtual health care services and wellness days"
                    },
                    {
                        id: 9,
                        title: "Employee assistance program (EAP)"
                    }
                ]
            },
            {
                questionTitle: "Non-traditional health benefits",
                id: 2,
                options: [
                    {
                        id: 1,
                        title: "Telemedicine"
                    },
                    {
                        id: 2,
                        title: "Dedicated mental health support"
                    },
                    {
                        id: 3,
                        title: "Maternity"
                    },
                    {
                        id: 4,
                        title: "Caregiving"
                    },
                    {
                        id: 5,
                        title: "Access to parental offerings for first-time parent"
                    },
                    {
                        id: 6,
                        title: "Menopause health support"
                    },
                    {
                        id: 7,
                        title: "Access to digital health apps"
                    },
                    {
                        id: 8,
                        title: "Fitness equipment & accessories"
                    },
                    {
                        id: 9,
                        title: "Fitness and Gym activity memberships"
                    }
                ]
            },
            
            {
                questionTitle: "Non-traditional financial wellness benefits",
                id: 4,
                options: [
                    {
                        title: "Graduate BNPL",
                        id: 1
                    },
                    {
                        title: "Commute",
                        id: 2
                    },
                    {
                        title: "Food/Data/Utility vouchers",
                        id: 3
                    },
                    {
                        title: "Consumer goods",
                        id: 4
                    },
                    {
                        title: "Solar asset financing",
                        id: 5
                    },
                    {
                        title: "Personal Finance Automation",
                        id: 6
                    },
                    {
                        title: "Savings and investment",
                        id: 7
                    },
                    {
                        title: "Access to earned wages",
                        id: 8
                    }
                ]
            },


            {
                questionTitle: "Traditional financial wellness benefits",
                id: 5,
                options: [
                    {
                        title: "Financial education workshops (Remote/in-person)",
                        id: 1
                    },
                    {
                        title: "Financial coaching",
                        id: 2
                    },
                    {
                        title: "Estate planning",
                        id: 3
                    },
                    {
                        title: "Tax planning",
                        id: 4
                    },
                    {
                        title: "Funeral cover",
                        id: 5
                    },
                    {
                        title: "Life disability cover",
                        id: 6
                    },
                    {
                        title: "Severe illness",
                        id: 7
                    },
                    {
                        title: "Education",
                        id: 8
                    },
                    {
                        title: "Income protection",
                        id: 9
                    },
                    {
                        title: "Spousal cover",
                        id: 10
                    },
                    {
                        title: "Retirement planning",
                        id: 11
                    },
                    {
                        title: "Debt management",
                        id: 12
                    }
                ]

            },
            {
                questionTitle: "Lifestyle benefits",
                id: 6,
                options: [
                    {
                        id: 1,
                        title: "Music & Video streaming services"
                    },
                    {
                        id: 2,
                        title: "Identify theft services"
                    },
                    {
                        id: 3,
                        title: "Travel accommodation"
                    },
                    {
                        id: 4,
                        title: "Massage therapy"
                    }
                ]
            },
            {
                questionTitle: "None",
                id: 7,
                options: [
                    {
                        id: 1,
                        title: "None"
                    }
                ]
            },

        ]
    },
    {
        questionNumber: 12,
        questionType: 'oneOption',
        questionTitle: 'Does your company currently have a budget specifically allocated for employee benefits?',
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Yes'
            },
            {
                optionNumber: 2,
                optionTitle: 'No'
            }
        ]
    },
    {
        questionNumber: 13,
        questionType: 'oneOption',
        questionTitle: "Would you like to allocate a budget for your employees' benefits consumption?",
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Yes'
            },
            {
                optionNumber: 2,
                optionTitle: 'No'
            }
        ]
    },
    {
        questionNumber: 14,
        questionType: 'oneOption',
        questionTitle: "Would you like to gather your employees' perspectives when it comes to benefits?",
        options: [
            {
                optionNumber: 1,
                optionTitle: 'Yes'
            },
            {
                optionNumber: 2,
                optionTitle: 'No'
            }
        ]
    }
]

export default questions;