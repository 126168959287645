import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { Button, Navbar } from '../../../components/basic/index'
import './styles.scss'

const AddEmployeesForBenefitsAssessment = observer(() => {

    const commonStore = useStore()
    const navigate = useNavigate()
    const location = useLocation();
    const [, updateState] = useState();
    const [link, setLink] = useState("");
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        console.log(queryParams)
        const client = queryParams.get('cli');

        if (!client) {
            navigate('/error');
        } else {
            setLink(
                `${process.env.REACT_APP_FRONTEND_URL}/employees/benefits/assessment?cli=${client}`
            )
        }
    }, [location, navigate]);

    const copyLink = () => {
        navigator.clipboard.writeText(link)
        setCopied(true)
    }

    const handleBackToDashboard = () => {
        if (commonStore.user.Role === "62bd55b7c5793941427c702d") {
            navigate('/client/home/Assessments', { state: { refresh: false } })
        }
        else if (commonStore.user.Role === "5d8e0b079f51d42bbece9201") {
            navigate('/admin/home/Overview', { state: { refresh: false } })
        }
        else {
            navigate('/signin')
        }
    }

    return (
        <div>
            <Navbar />
            <div style={{ height: '90vh', marginTop: '20vh' }}>
                <main>
                    <header className='employee-benefits-assessment-submit-header'>Thank you for your input</header>
                    <div className='employee-benefits-assessment-submit-inputs-container'>
                        <p className='employee-benefits-assessment-submit-subheader' style={{ width: '45%' }}>
                            To better understand your employees' benefit needs, please share this <span style={{ color: '#5D0070', fontWeight: '800' }}>link</span> with them to complete their <span style={{ color: '#5D0070', fontWeight: '800' }}>personalized benefits assessment</span>
                        </p>
                        <p className='employee-benefits-assessment-submit-subheader' style={{ width: '45%' }}>
                            Please note your employees will have a <span style={{ color: '#5D0070', fontWeight: '800' }}>one week</span> deadline to complete the assessment
                        </p>
                        {link !== "" &&
                            <Button width={'22.5%'} onClick={copyLink}>{`${copied ? "Copied!" : "Copy link"}`}</Button>
                        }
                        <hr />
                        {copied &&
                            <Button width={'22.5%'} onClick={handleBackToDashboard}>{`Back to dashboard`}</Button>
                        }
                    </div>
                </main>
            </div>
        </div>
    );
})

export default AddEmployeesForBenefitsAssessment;
