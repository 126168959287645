import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Navbar } from '../../../components/basic/index';
import DoughnutChart from '../../../components/charts/DoughnutGraph';
import BarChart from '../../../components/charts/BarChart';
import { DoughnutChartBenefitsTotalPerc, BarChartPillarScores, DoughnutChartBenefitsDemographic } from '../../../components/charts/index'
import { BenefitsReportPdf } from '../../../components/pdf/index';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { toPng, toSvg, toJpeg } from 'html-to-image';
import html2canvas from 'html2canvas';

import './styles.scss';

const AdminBenefitsReport = observer(({ data, handleBackToSingleEmployer }) => {
    const element = document.getElementById('top');
    const commonStore = useStore();

    useEffect(() => {
        commonStore.setLoading(true)
        setTimeout(() => {
            element?.scrollIntoView({
                behavior: 'smooth'
            });
            //siloed benefits
            getPhysicalEmotionalBenefits()
            getFinancialBenefits()
            getSocialBenefits()
            setFindingsAndRecs(client)
            forceUpdate()
            const element1 = document.getElementById('benefitsReportsEmployerPercentage');
            const element2 = document.getElementById('benefitsReportsEmployeePercentage');
            const element3 = document.getElementById('benefitsReportsPillarScores')
            const element4 = document.getElementById('benefitsReportsAvgAge')
            const element5 = document.getElementById('benefitsReportsAvgGender')
            const element6 = document.getElementById('benefitsReportsAvgEmploymentLength')
            const element7 = document.getElementById('benefitsReportsAvgWorkArrangement')
            const element8 = document.getElementById('physEmoWantDesiredChart')
            const element9 = document.getElementById('financialWantDesiredChart')
            const element10 = document.getElementById('socialWantDesiredChart')
            let obj = {}

            obj.employerTotalPerc = convertToPng(element1) ? convertToPng(element1) : null
            obj.employeeTotalPerc = convertToPng(element2) ? convertToPng(element2) : null
            obj.pillarScores = convertToPng(element4) ? convertToPng(element3) : null
            obj.avgAge = convertToPng(element4) ? convertToPng(element4) : null
            obj.avgGender = convertToPng(element5) ? convertToPng(element5) : null
            obj.avgEmploymentLength = convertToPng(element6) ? convertToPng(element6) : null
            obj.avgWorkArrangement = convertToPng(element7) ? convertToPng(element7) : null
            obj.physEmoWantDesiredChart = convertToPng(element8) ? convertToPng(element8) : null
            obj.financialWantDesiredChart = convertToPng(element9) ? convertToPng(element9) : null
            obj.socialWantDesiredChart = convertToPng(element10) ? convertToPng(element10) : null

            let updated = { ...pdfImages, ...obj }
            setPdfImages(updated)

            let temp = {
                physEmo: getPhysicalEmotionalBenefits(),
                financial: getFinancialBenefits(),
                social: getSocialBenefits()
            }
            let neww = { ...pdfWantDesired, ...temp }
            setPdfWantDesired(neww)
            forceUpdate()
            commonStore.setLoading(false)
            scrollTop()
        }, 1500)

    }, []);
    const convertToPng = async (element) => {
        try {
            const canvas = await html2canvas(element, {
                scale: 2,
                useCORS: true
            });
            return canvas.toDataURL('image/png');
        } catch (error) {
            console.error('Error generating chart image:', error);
            return null;
        }
    };
    // const convertToPng = async (element) => {
    //     console.log(element)
    //     try {
    //         if (element === null || element === undefined) {
    //             return null
    //         }
    //         //no idea what this is but it makes the quality around the edges better. Shout out stackoverflow
    //         let scale = 2;
    //         const dataUrl = await toPng(element, {
    //             width: element.clientWidth * scale,
    //             height: element.clientHeight * scale,
    //             style: { transform: 'scale(' + scale + ')', transformOrigin: 'top left' }
    //         })
    //         console.log(dataUrl)
    //         return dataUrl
    //     } catch (error) {
    //         console.error('Error generating chart image:', error)
    //         return null
    //     }
    // };

    const [pdfImages, setPdfImages] = useState({})
    const [pdfWantDesired, setPdfWantDesired] = useState({})
    const [pdfFindingsRecs, setPdfFindingsRecs] = useState({})

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const getClient = () => {
        let res = commonStore.getClientById().then(() => {
            setClient(res)
        })
    }

    const [client, setClient] = useState(data.client)
    const [report, setReport] = useState(data.report)
    const [selectedSection, setSelectedSection] = useState("Comparative view")

    const [physicalFindings, setPhysicalFindings] = useState({})
    const [emotionalFindings, setEmotionalFindings] = useState({})
    const [financialFindings, setFinancialFindings] = useState({})
    const [socialFindings, setSocialFindings] = useState({})

    const [demographicsConclusion, setDemographicsConclusion] = useState('')
    const [finalConclusion, setFinalConclusion] = useState('')

    let navigate = useNavigate();

    const assessment = client.BenefitsAssessment
    const employerA = assessment.EmployerAssessment
    const employeeA = assessment.EmployeeAssessments

    //findings and recommendations
    const setFindingsAndRecs = (cli) => {
        let ret = {}
        if (cli.BenefitsAssessment.FindingsAndRecommendations) {
            let fr = assessment.FindingsAndRecommendations

            ret = {
                physical: fr.physical || { findings: "", recommendations: "" },
                emotional: fr.emotional || { findings: "", recommendations: "" },
                financial: fr.financial || { findings: "", recommendations: "" },
                social: fr.social || { findings: "", recommendations: "" },
                demographicsConclusion: fr.demographicsConclusion || '',
                finalConclusion: fr.finalConclusion || ''
            }

            setPhysicalFindings(fr.physical || { findings: "", recommendations: "" })
            setEmotionalFindings(fr.emotional || { findings: "", recommendations: "" })
            setFinancialFindings(fr.financial || { findings: "", recommendations: "" })
            setSocialFindings(fr.social || { findings: "", recommendations: "" })
            setDemographicsConclusion(fr.demographicsConclusion || "")
            setFinalConclusion(fr.finalConclusion || "")
        }
        else {
            ret = {
                physical: { findings: "", recommendations: "" },
                emotional: { findings: "", recommendations: "" },
                financial: { findings: "", recommendations: "" },
                social: { findings: "", recommendations: "" },
                demographicsConclusion: '',
                finalConclusion: ''
            }
            setPhysicalFindings({ findings: "", recommendations: "" })
            setEmotionalFindings({ findings: "", recommendations: "" })
            setFinancialFindings({ findings: "", recommendations: "" })
            setSocialFindings({ findings: "", recommendations: "" })
        }
        setPdfFindingsRecs(ret)

        forceUpdate()
        return ret
    }

    const getPhysicalEmotionalBenefits = () => {
        let benefits = []

        Object.keys(report.Recommendations).forEach((key) => {
            if (
                (report.Recommendations[key].type === 'Physical' ||
                    report.Recommendations[key].type === 'Emotional')
            ) {
                let obj = {
                    benefit: key,
                    employerOffered: report.Recommendations[key].employerOffered,
                    employerWanted: report.Recommendations[key].employerWanted,
                    employeeWanted: report.Recommendations[key].employeeWanted,
                    employeeOffered: report.Recommendations[key].employeeOffered
                }
                benefits.push(obj)
            }
        })

        return benefits

    }

    const getFinancialBenefits = () => {
        let benefits = []

        Object.keys(report.Recommendations).forEach((key) => {
            if (
                (report.Recommendations[key].type === 'Financial')
            ) {
                let obj = {
                    benefit: key,
                    employerOffered: report.Recommendations[key].employerOffered,
                    employerWanted: report.Recommendations[key].employerWanted,
                    employeeWanted: report.Recommendations[key].employeeWanted,
                    employeeOffered: report.Recommendations[key].employeeOffered
                }
                benefits.push(obj)
            }
        })
        return benefits

    }

    const getSocialBenefits = () => {
        let benefits = []

        Object.keys(report.Recommendations).forEach((key) => {
            if (
                (report.Recommendations[key].type === 'Social')) {
                let obj = {
                    benefit: key,
                    employerOffered: report.Recommendations[key].employerOffered,
                    employerWanted: report.Recommendations[key].employerWanted,
                    employeeWanted: report.Recommendations[key].employeeWanted,
                    employeeOffered: report.Recommendations[key].employeeOffered
                }
                benefits.push(obj)
            }
        })
        return benefits

    }

    const barChartOptions2 = {
        layout: {
            padding: 20
        },
        scales: {
            y: {
                stacked: true,
                grid: {
                    display: true,
                    color: 'rgb(255 255 255 / 34%)'
                },
                title: {
                    display: true,
                    text: "Percentage",
                    color: 'white',
                    font: {
                        size: 12,
                        family: 'Inter'
                    }
                },
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 10,
                    color: 'white',
                    font: {
                        size: 12,
                        family: 'Inter'
                    },
                    callback: function (value) {
                        return value + '%'; // Append '%' to each tick value
                    }
                },
            },
            x: {
                stacked: true,
                ticks: {
                    autoSkip: false,
                    color: 'white',
                    font: {
                        size: 12,
                        family: 'Inter'
                    },

                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: 'white',
                    font: {
                        size: 12,
                        family: 'Inter'
                    }
                }
            },
            datalabels: {
                display: false
            }
        },
    }
    const physEmoWantDesiredChartData = {
        labels: getPhysicalEmotionalBenefits().filter((item) => {
            return item.employeeOffered > 0 || item.employeeWanted > 0
        }).map((ben) => {
            return ben.benefit
        }),
        datasets: [{
            label: 'I use it!',
            data: getPhysicalEmotionalBenefits().filter((item) => {
                return item.employeeOffered > 0 || item.employeeWanted > 0
            }).map((ben) => {
                return ben.employeeOffered
            }),
            backgroundColor: [
                'rgba(255, 125, 185, 1)',
            ],
            borderRadius: 10,
            barThickness: 30,
            borderWidth: 2
        },
        {
            label: 'I want it!',
            data: getPhysicalEmotionalBenefits().filter((item) => {
                return item.employeeOffered > 0 || item.employeeWanted > 0
            }).map((ben) => {
                return ben.employeeWanted
            }),
            backgroundColor: [
                'rgba(185, 221, 254, 1)',
            ],
            borderRadius: 10,
            barThickness: 30,
            borderWidth: 2
        }
        ]
    }

    const financialWantDesiredChartData = {
        labels: getFinancialBenefits().filter((item) => {
            return item.employeeOffered > 0 || item.employeeWanted > 0
        }).map((ben) => {
            return ben.benefit
        }),
        datasets: [{
            label: 'I use it!',
            data: getFinancialBenefits().filter((item) => {
                return item.employeeOffered > 0 || item.employeeWanted > 0
            }).map((ben) => {
                return ben.employeeOffered
            }),
            backgroundColor: [
                'rgba(255, 125, 185, 1)',
            ],
            borderRadius: 10,
            barThickness: 30,
            borderWidth: 2
        },
        {
            label: 'I want it!',
            data: getFinancialBenefits().filter((item) => {
                return item.employeeOffered > 0 || item.employeeWanted > 0
            }).map((ben) => {
                return ben.employeeWanted
            }),
            backgroundColor: [
                'rgba(185, 221, 254, 1)',
            ],
            borderRadius: 10,
            barThickness: 30,
            borderWidth: 2
        }
        ]
    }

    const socialWantDesiredChartData = {
        labels: getSocialBenefits().filter((item) => {
            return item.employeeOffered > 0 || item.employeeWanted > 0
        }).map((ben) => {
            return ben.benefit
        }),
        datasets: [{
            label: 'I use it!',
            data: getSocialBenefits().filter((item) => {
                return item.employeeOffered > 0 || item.employeeWanted > 0
            }).map((ben) => {
                return ben.employeeOffered
            }),
            backgroundColor: [
                'rgba(255, 125, 185, 1)',
            ],
            borderRadius: 10,
            barThickness: 30,
            borderWidth: 2
        },
        {
            label: 'I want it!',
            data: getSocialBenefits().filter((item) => {
                return item.employeeOffered > 0 || item.employeeWanted > 0
            }).map((ben) => {
                return ben.employeeWanted
            }),
            backgroundColor: [
                'rgba(185, 221, 254, 1)',
            ],
            borderRadius: 10,
            barThickness: 30,
            borderWidth: 2
        }
        ]
    }

    const handleNavOnClick = (val) => {
        const element = document.getElementById(val);
        element?.scrollIntoView({
            behavior: 'smooth'
        });
        setSelectedSection(val)
    }

    const handleReportFindingsChange = (e) => {
        let value = e.currentTarget.value
        let id = e.currentTarget.id
        let pillar = id.split(" ")[0]
        let report = id.split(" ")[1]

        console.log(pillar)
        console.log(report)
        console.log(value)

        if (pillar === 'physical') {
            if (report === 'findings') {
                setPhysicalFindings({ ...physicalFindings, ...{ findings: value } })
            }
            else {
                setPhysicalFindings({ ...physicalFindings, ...{ recommendations: value } })
            }
        }
        if (pillar === 'emotional') {
            if (report === 'findings') {
                setEmotionalFindings({ ...emotionalFindings, ...{ findings: value } })
            }
            else {
                setEmotionalFindings({ ...emotionalFindings, ...{ recommendations: value } })
            }
        }
        if (pillar === 'financial') {
            if (report === 'findings') {
                setFinancialFindings({ ...financialFindings, ...{ findings: value } })
            }
            else {
                setFinancialFindings({ ...financialFindings, ...{ recommendations: value } })
            }
        }
        if (pillar === 'demographics') {
            setDemographicsConclusion(value)
        }
        forceUpdate()

    }

    const handleUpdateReport = () => {
        let object = {}
        object.physical = {}
        object.emotional = {}
        object.financial = {}
        object.social = {}
        object.demographicsConclusion = {}

        if (physicalFindings.findings !== "" && physicalFindings.findings !== " ") {
            object.physical.findings = physicalFindings.findings
        }
        if (physicalFindings.recommendations !== "" && physicalFindings.recommendations !== " ") {
            object.physical.recommendations = physicalFindings.recommendations
        }
        if (emotionalFindings.findings !== "" && emotionalFindings.findings !== " ") {
            object.emotional.findings = emotionalFindings.findings
        }
        if (emotionalFindings.recommendations !== "" && emotionalFindings.recommendations !== " ") {
            object.emotional.recommendations = emotionalFindings.recommendations
        }
        if (financialFindings.findings !== "" && financialFindings.findings !== " ") {
            object.financial.findings = financialFindings.findings
        }
        if (financialFindings.recommendations !== "" && financialFindings.recommendations !== " ") {
            object.financial.recommendations = financialFindings.recommendations
        }
        if (socialFindings.findings !== "" && socialFindings.findings !== " ") {
            object.social.findings = socialFindings.findings
        }
        if (socialFindings.recommendations !== "" && socialFindings.recommendations !== " ") {
            object.social.recommendations = socialFindings.recommendations
        }

        if (demographicsConclusion !== "" && demographicsConclusion !== " ") {
            object.demographicsConclusion = demographicsConclusion
        }

        let og = client.BenefitsAssessment

        let updates = { ...og, ...{ FindingsAndRecommendations: object } }

        console.log("old ", og)
        console.log("new ", updates)
        commonStore.setLoading(true)

        commonStore.updateClientById(client._id, { BenefitsAssessment: updates }).then((res) => {
            if (res._id === client._id) {
                commonStore.setLoading(false)
                console.log(res)
                console.log("Updated findings and recommendations")
                setClient(res)
                setFindingsAndRecs(res)
                forceUpdate()
                commonStore.setSnackBar("success", "Updated")
            }
            else {
                commonStore.setSnackBar("error", "Error occured")
                commonStore.setLoading(false)
            }
        })
        element?.scrollIntoView({
            behavior: 'smooth'
        });
    }

    const handleBackOnClick = () => {
        handleBackToSingleEmployer(client)
    }

    if (isMobile()) {
        return (
            <div>
                <Navbar />
                <main>
                    <div>

                    </div>

                </main>
            </div>
        );
    } else {
        return (
            <>
                {/* TOP BAR */}
                <div className='flex-column' id="top">
                    <div style={{ background: 'rgb(244, 245, 250)', position: 'sticky', top: '0', zIndex: 200, marginBottom: '100px' }}>
                        <div style={{ display: 'flex', marginTop: '5px', marginBottom: '5px', gap: '25px', alignItems: 'center', paddingLeft: '20px' }}>
                            <button className='admin-dash-back-arrow-btn' onClick={handleBackOnClick}>Back</button>
                            <header className='admin-dash-sub-header' style={{ fontSize: '14px' }}>
                                <span className='admin-dash-blue-clients-text normal'>
                                    {'Clients '}
                                </span>
                                {` > ${client.Name} > Benefits report`}
                            </header>

                        </div>
                        {/* TOP SECTION */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#00002A', paddingRight: '20px', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <div
                                    onClick={() => handleNavOnClick("top")}
                                    className={`benefits-report-main-header small nav lighter ${selectedSection === "Comparative view" ? "selected" : null}`}>
                                    Comparative view
                                </div>
                                <div
                                    onClick={() => handleNavOnClick("Demographic data")}
                                    className={`benefits-report-main-header small nav lighter ${selectedSection === "Demographic data" ? "selected" : null}`}>
                                    Demographic data
                                </div>
                                <div
                                    onClick={() => handleNavOnClick("Physical and emotional health")}
                                    className={`benefits-report-main-header small nav lighter ${selectedSection === "Physical and emotional health" ? "selected" : null}`}>
                                    Physical and emotional health
                                </div>
                                <div
                                    onClick={() => handleNavOnClick("Financial health")}
                                    className={`benefits-report-main-header small nav lighter ${selectedSection === "Financial health" ? "selected" : null}`}>
                                    Financial health
                                </div>
                                <div
                                    onClick={() => handleNavOnClick("Social health (Lifestyle)")}
                                    className={`benefits-report-main-header small nav lighter ${selectedSection === "Social health (Lifestyle)" ? "selected" : null}`}>
                                    Social health (Lifestyle)
                                </div>

                            </div>
                            <PDFDownloadLink document={<BenefitsReportPdf
                                client={client}
                                report={report}
                                graphs={pdfImages}
                                wantDesired={pdfWantDesired}
                                findingsRecs={pdfFindingsRecs} />} fileName={`${client.Name} Benefits report ${new Date()}`}>
                                {({ loading }) => (loading ?
                                    <div style={{ fontSize: '14px', color: 'white' }}
                                        className='admin-dash-blue-clients-text normal'>Loading document ...
                                    </div> :
                                    <div style={{ fontSize: '14px', color: 'white' }}
                                        className='admin-dash-blue-clients-text normal'>
                                        Download
                                    </div>)}
                            </PDFDownloadLink>
                        </div>

                    </div>

                    {/* RENDER FOR PDF */}
                    <div>
                        <main >
                            <div id={'Comparative view'}>
                                <br />

                                <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Employer vs. Employee score: A comparative view</header>
                                <br />
                                <br />
                                <div style={{ display: 'flex', gap: '60px', alignItems: 'center' }}>
                                    <div className='benefits-report-copy-container'>
                                        <text className='admin-dash-client-details-text smallbold'>
                                            In evaluating the holistic well-being of an organisation, two
                                            pivotal metrics emerge: the Overall Employer Score and the
                                            Overall Employees Score. These scores serve as vital
                                            indicators, shedding light on the collective health landscape
                                            within a workplace.
                                            The Scores averages responses across four pillars: Physical,
                                            Emotional, Financial, and Social Health, reflecting both the
                                            Employer’s and Employee’s perceptions of organisational
                                            well-being.
                                            Comparing these scores reveals alignment or misalignment
                                            between organisational and individual well-being, guiding
                                            targeted interventions for a healthier workplace.
                                        </text>
                                    </div>
                                    <div style={{ display: 'flex', gap: '20px' }}>
                                        <div>
                                            <DoughnutChartBenefitsTotalPerc
                                                report={report} color={`rgba(255, 125, 185, 1)`}
                                                entity={'Employer'}
                                                id='benefitsReportsEmployerPercentage' />
                                            <br />
                                            <p className='benefits-report-main-header black' style={{ textAlign: 'center', fontSize: '17px' }}>
                                                Employer overall score
                                            </p>
                                        </div>
                                        <div>
                                            <DoughnutChartBenefitsTotalPerc
                                                report={report}
                                                color={`rgba(185, 254, 254, 1)`}
                                                entity={'Employee'}
                                                id='benefitsReportsEmployeePercentage' />
                                            <p className='benefits-report-main-header black' style={{ textAlign: 'center', fontSize: '17px' }}>
                                                Employee overall score
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br />

                            <hr style={{ width: '60%' }} />
                            {/* PILLAR SCORES */}
                            <div>
                                <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Pillar scores</header>
                                <br />
                                <br />
                                <div style={{ display: 'flex', gap: '20px', alignItems: 'center', flexDirection: 'column' }}>
                                    {/* explaination */}
                                    <div className='benefits-report-copy-container'>
                                        <div className='admin-dash-client-details-text smallbold'>
                                            This graph provides a comparative analysis
                                            of the employee and organisational scores
                                            across the four pillars: Physical, Emotional,
                                            Financial, and Social Health.
                                            Each pillar's percentage score illustrates
                                            the perspective of both employees and the
                                            organisation, offering insights into
                                            alignment or divergence in perceptions
                                            regarding well-being priorities within the
                                            workplace.
                                        </div>
                                    </div>
                                    {/* graph */}
                                    <BarChartPillarScores
                                        report={report}
                                        backgroundColor={`rgba(1, 1, 67, 1)`}
                                        id='benefitsReportsPillarScores' />

                                </div>
                                <br />
                                <hr style={{ width: '60%' }} />
                                <br />
                            </div>
                        </main>
                        <main id={'Demographic data'}>
                            <br />
                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Demographics</header>
                            <br />
                            <br />
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10%', justifyContent: 'center' }}>
                                <div className='benefits-report-demographic-item-container ' id='benefitsReportsAvgAge'>
                                    <header style={{ textAlign: 'center', fontSize: '16px' }} className='benefits-report-main-header black'>
                                        Average age
                                    </header>
                                    <br />
                                    <DoughnutChartBenefitsDemographic
                                        report={report}
                                        entity={'age'}
                                        labels={["18 - 34", "35 - 54", "55 - 64", "65 and above"]}
                                    />
                                </div>
                                <div className='benefits-report-demographic-item-container' id='benefitsReportsAvgGender' >
                                    <header style={{ textAlign: 'center', fontSize: '16px' }} className='benefits-report-main-header black'>
                                        Gender identity
                                    </header>
                                    <br />
                                    <DoughnutChartBenefitsDemographic
                                        report={report}
                                        entity={'gender'}
                                        labels={["Female", "Genderqueer", "Male", "Non-binary", "Prefer not to say", "Transgender"]}
                                    />
                                </div>
                                <div className='benefits-report-demographic-item-container' id='benefitsReportsAvgEmploymentLength'>
                                    <header style={{ textAlign: 'center', fontSize: '16px' }} className='benefits-report-main-header black'>
                                        Average employment duration
                                    </header>
                                    <br />
                                    <DoughnutChartBenefitsDemographic
                                        report={report}
                                        entity={'employmentLength'}
                                        labels={["1 to 5 years",
                                            "5 to 10 years",
                                            "Less than 1 year",
                                            "Long-term (more than 12 months)",
                                            "Medium-term (6 to 12 months)",
                                            "More than 10 years",
                                            "Short-term (less than 6 months)"]}
                                    />
                                </div>
                                <div className='benefits-report-demographic-item-container' id='benefitsReportsAvgWorkArrangement'>
                                    <header style={{ textAlign: 'center', fontSize: '16px' }} className='benefits-report-main-header black'>
                                        Work arrangement
                                    </header>
                                    <br />
                                    <DoughnutChartBenefitsDemographic
                                        report={report}
                                        entity={'employment'}
                                        labels={["Contact", "Permanent"]}
                                    />
                                </div>

                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <header style={{ textAlign: 'center' }}
                                    className='benefits-report-main-header black'>
                                    Conclusion
                                </header>
                                <br />
                                <textarea className='benefits-report-findings-textarea'
                                    style={{ backgroundColor: '#00002A', color: 'white' }}
                                    id="demographics conclusion"
                                    onChange={handleReportFindingsChange}
                                    value={demographicsConclusion}
                                />
                                <button style={{ margin: '40px 0px 80px' }}
                                    className='admin-dash-add-btn'
                                    onClick={() => handleUpdateReport()}>Update report
                                </button>
                            </div>

                            <hr style={{ width: '60%' }} />
                            <br />
                        </main>
                        <main id={'Physical and emotional health'}>
                            <br />
                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Benefits consumed VS desired</header>
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'rgb(1, 1, 67)' }} className='benefits-report-copy-container'>
                                <div>
                                    <header style={{ textAlign: 'center' }} className='benefits-report-main-header'>Employer</header>
                                    <br />
                                    <div className='benefits-report-grid-container' style={{ width: '100%' }}>
                                        <div className='benefits-report-grid-item'>
                                            Benefit
                                        </div>
                                        <div className='benefits-report-grid-item'>
                                            Offered
                                        </div>
                                        {
                                            getPhysicalEmotionalBenefits().filter((item) => {
                                                return item.employerOffered > 0 || item.employerWanted > 0
                                            }).map((ben) => {
                                                return (
                                                    <>
                                                        <div className='benefits-report-grid-item'>{ben.benefit}</div>
                                                        {ben.employerOffered > 0 &&
                                                            <div className='benefits-report-grid-item'>Offered</div>
                                                        }
                                                        {ben.employerWanted > 0 &&
                                                            <div className='benefits-report-grid-item'>Want</div>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    <br />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <header style={{ textAlign: 'center' }} className='benefits-report-main-header medium'>Employees</header>
                                    {physEmoWantDesiredChartData.labels.length > 0 ?
                                        <BarChart
                                            data={physEmoWantDesiredChartData}
                                            options={barChartOptions2}
                                            id="physEmoWantDesiredChart"
                                            backgroundColor='#010143' />
                                        :
                                        <div>Your employees do not consume or desire any physical and emotional benefits</div>
                                    }
                                </div>
                            </div>
                            <br />
                            {/*PHYSICAL HEALTH FINDINGS AND RECOMMENDATIONS */}
                            <div>
                                <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Physical health</header>
                                <div style={{ display: 'flex', gap: '50px' }}>
                                    {/* FINDINGS */}
                                    <div style={{ width: '45%' }}>
                                        <img />
                                        <div>
                                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Findings</header>
                                            <br />
                                            <textarea className='benefits-report-findings-textarea'
                                                id="physical findings"
                                                onChange={handleReportFindingsChange}
                                                value={physicalFindings.findings}
                                            />
                                        </div>
                                    </div>
                                    {/* RECOMMENDATIONS */}
                                    <div style={{ width: '45%' }}>
                                        <img />
                                        <div>
                                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Recommendations</header>
                                            <br />
                                            <textarea className='benefits-report-findings-textarea'
                                                id="physical recommendations"
                                                onChange={handleReportFindingsChange}
                                                value={physicalFindings.recommendations}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {/*EMOTIONAL HEALTH FINDINGS AND RECOMMENDATIONS */}
                            <div>
                                <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Emotional health</header>
                                <div style={{ display: 'flex', gap: '50px' }}>
                                    {/* FINDINGS */}
                                    <div style={{ width: '45%' }}>
                                        <img />
                                        <div>
                                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Findings</header>
                                            <br />
                                            <textarea className='benefits-report-findings-textarea'
                                                id="emotional findings"
                                                onChange={handleReportFindingsChange}
                                                value={emotionalFindings.findings}
                                            />
                                        </div>
                                    </div>
                                    {/* RECOMMENDATIONS */}
                                    <div style={{ width: '45%' }}>
                                        <img />
                                        <div>
                                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Recommendations</header>
                                            <br />
                                            <textarea className='benefits-report-findings-textarea'
                                                id="emotional recommendations"
                                                onChange={handleReportFindingsChange}
                                                value={emotionalFindings.recommendations}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button style={{ margin: '40px 0px 80px' }}
                                className='admin-dash-add-btn'
                                onClick={() => handleUpdateReport()}>Update report
                            </button>
                            <hr style={{ width: '60%' }} />
                        </main>
                        <main id={"Financial health"}>
                            <br />
                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Benefits consumed VS desired</header>
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'rgb(1, 1, 67)' }} className='benefits-report-copy-container'>
                                <div>
                                    <header style={{ textAlign: 'center' }} className='benefits-report-main-header'>Employer</header>
                                    <br />
                                    <div className='benefits-report-grid-container' style={{width:'100%'}}>
                                        <div className='benefits-report-grid-item'>
                                            Benefit
                                        </div>
                                        <div className='benefits-report-grid-item'>
                                            Offered
                                        </div>
                                        {
                                            getFinancialBenefits().filter((item) => {
                                                return item.employerOffered > 0 || item.employerWanted > 0
                                            }).map((ben) => {
                                                return (
                                                    <>
                                                        <div className='benefits-report-grid-item'>{ben.benefit}</div>
                                                        {ben.employerOffered > 0 &&
                                                            <div className='benefits-report-grid-item'>Offered</div>
                                                        }
                                                        {ben.employerWanted > 0 &&
                                                            <div className='benefits-report-grid-item'>Want</div>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    <br />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <header style={{ textAlign: 'center' }} className='benefits-report-main-header'>Employees</header>
                                    <br />
                                    {financialWantDesiredChartData.labels.length > 0 ?
                                        <div>
                                            <BarChart
                                                data={financialWantDesiredChartData}
                                                options={barChartOptions2}
                                                id="financialWantDesiredChart"
                                                backgroundColor='#010143' />
                                        </div>
                                        :
                                        <div style={{ textAlign: 'center' }} className='benefits-report-main-header medium'>
                                            Your employees do not consume or desire any financial benefits
                                        </div>
                                    }
                                </div>
                            </div>
                            <br />
                            {/*FINANCIAL FINDINGS AND RECOMMENDATIONS */}
                            <div>
                                <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Financial health</header>
                                <div style={{ display: 'flex', gap: '50px' }}>
                                    {/* FINDINGS */}
                                    <div style={{ width: '45%' }}>
                                        <img />
                                        <div>
                                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Findings</header>
                                            <br />
                                            <textarea className='benefits-report-findings-textarea'
                                                id="financial findings"
                                                onChange={handleReportFindingsChange}
                                                value={financialFindings.findings}
                                            />
                                        </div>
                                    </div>
                                    {/* RECOMMENDATIONS */}
                                    <div style={{ width: '45%' }}>
                                        <img />
                                        <div>
                                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Recommendations</header>
                                            <br />
                                            <textarea className='benefits-report-findings-textarea'
                                                id="financial recommendations"
                                                onChange={handleReportFindingsChange}
                                                value={financialFindings.recommendations}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button style={{ margin: '40px 0px 80px' }}
                                className='admin-dash-add-btn'
                                onClick={() => handleUpdateReport()}>Update report
                            </button>
                            <br />
                            <hr style={{ width: '60%' }} />
                        </main>
                        <main id={'Social health (Lifestyle)'}>
                            <br />
                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Benefits consumed VS desired</header>
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'rgb(1, 1, 67)' }} className='benefits-report-copy-container'>
                                <div>
                                    <header style={{ textAlign: 'center' }} className='benefits-report-main-header'>Employer</header>
                                    <br />
                                    <div className='benefits-report-grid-container' style={{width:'100%'}}>
                                        <div className='benefits-report-grid-item'>
                                            Benefit
                                        </div>
                                        <div className='benefits-report-grid-item'>
                                            Offered
                                        </div>
                                        {
                                            getSocialBenefits().filter((item) => {
                                                return item.employerOffered > 0 || item.employerWanted > 0
                                            }).map((ben) => {
                                                return (
                                                    <>
                                                        <div className='benefits-report-grid-item'>{ben.benefit}</div>
                                                        {ben.employerOffered > 0 &&
                                                            <div className='benefits-report-grid-item'>Offered</div>
                                                        }
                                                        {ben.employerWanted > 0 &&
                                                            <div className='benefits-report-grid-item'>Want</div>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    <br />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <header style={{ textAlign: 'center' }} className='benefits-report-main-header'>Employees</header>
                                    <br />
                                    {socialWantDesiredChartData.labels.length > 0 ?
                                        <div>
                                            <BarChart
                                                data={socialWantDesiredChartData}
                                                options={barChartOptions2}
                                                id="socialWantDesiredChart"
                                                backgroundColor='#010143' />
                                        </div>
                                        :
                                        <div style={{ textAlign: 'center' }} className='benefits-report-main-header medium'>
                                            Your employees do not consume or desire any social benefits
                                        </div>
                                    }
                                </div>
                            </div>
                            <br />
                            {/*SOCIAL FINDINGS AND RECOMMENDATIONS */}
                            <div>
                                <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Social health</header>
                                <div style={{ display: 'flex', gap: '50px' }}>
                                    {/* FINDINGS */}
                                    <div style={{ width: '45%' }}>
                                        <img />
                                        <div>
                                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Findings</header>
                                            <br />
                                            <textarea className='benefits-report-findings-textarea'
                                                id="social findings"
                                                onChange={handleReportFindingsChange}
                                                value={socialFindings.findings}
                                            />
                                        </div>
                                    </div>
                                    {/* RECOMMENDATIONS */}
                                    <div style={{ width: '45%' }}>
                                        <img />
                                        <div>
                                            <header style={{ textAlign: 'center' }} className='benefits-report-main-header black'>Recommendations</header>
                                            <br />
                                            <textarea className='benefits-report-findings-textarea'
                                                id="social recommendations"
                                                onChange={handleReportFindingsChange}
                                                value={socialFindings.recommendations}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button style={{ margin: '40px 0px 80px' }}
                                className='admin-dash-add-btn'
                                onClick={() => handleUpdateReport()}>Update report</button>
                            <br />

                        </main>
                    </div>
                    {/* RENDER FOR PDF END*/}

                </div>

            </>
        );
    }
})

export default AdminBenefitsReport
