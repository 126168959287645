import React from "react";
import { DateRangePicker } from "@nextui-org/react";
import { parseDate, getLocalTimeZone } from "@internationalized/date";
import { useDateFormatter } from "@react-aria/i18n";

export default function DateRange({ value, onChange }) {
    const [val, setVal] = React.useState({
        start: parseDate(value[0].toISOString().split('T')[0]),  // Converting date to string
        end: parseDate(value[1].toISOString().split('T')[0])
    });

    let formatter = useDateFormatter({ dateStyle: "long" });

    return (
        <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-y-2">
                <DateRangePicker
                    label="Select date range"
                    value={val}
                    onChange={(newRange) => {
                        setVal(newRange);
                        onChange(newRange);
                    }}
                />
                <p className="text-default-500 text-sm">
                    Selected date:{" "}
                    {val
                        ? formatter.formatRange(
                            val.start.toDate(getLocalTimeZone()),
                            val.end.toDate(getLocalTimeZone()),
                        )
                        : "--"}
                </p>
            </div>
        </div>
    );
}
