import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../../stores/Store'
import { isMobile } from 'is-mobile';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SearchIcon } from '../../../../../assets/icons/index'
import { InputSlider, IOSToggle, GenericCustomSelect } from '../../../../../components/basic';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { BasicMuiTextField, DateInput, DialogConfirm } from '../../../../../components/MUI';
import { OzowPayoutCodes } from '../../../../../components/errorCodes'

import './styles.scss';
import { TextField } from '@mui/material';

const AdminBenefitsFlexiblePayUsers = observer(({ data, setVisible, refresh }) => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [error, setError] = useState('');
    const [updateUsers, setUpdateUsers] = useState(false)
    const [fpUsers, setFpUsers] = useState(data.fpUsers)
    const [currentUser, setCurrentUser] = useState({})
    const [visibleChild, setVisibleChild] = useState("Users")
    const [activeFilter, setActiveFilter] = useState("All")
    const [searchField, setSearchField] = useState("")
    const [searchFilter, setSearchFilter] = useState("FirstName")
    const [newPerc, setNewPerc] = useState(0)
    const [userBlocked, setUserBlocked] = useState(false)

    const [userBankAccounts, setUserBankAccounts] = useState([])
    const [requestedAmount, setRequestedAmount] = useState("")
    const [primaryBank, setPrimaryBank] = useState(null)
    const [secondaryBank, setSecondaryBank] = useState(null)
    const [selectedBank, setSelectedBank] = useState({})
    const [enterAmountVisible, setEnterAmountVisible] = useState(false)
    const [withdrawalDetails, setWithdrawalDetails] = useState({})
    const [withdrawalVisible, setWithdrawalVisible] = useState(false)
    const [advanceMethod, setAdvanceMethod] = useState("")
    const [manualAdvanceDate, setManualAdvanceDate] = useState(new Date())
    const [manualAdvanceReference, setManualAdvanceReference] = useState("")
    const [processAdvanceNote, setProcessAdvanceNote] = useState("")
    const [dialogContent, setDialogContent] = useState([])

    const [updateAdvanceNoteVisible, setUpdateAdvanceNoteVisible] = useState(false)
    const [updateAdvanceNote, setUpdateAdvanceNote] = useState('')
    const [updateAdvanceDetails, setUpdateAdvanceDetails] = useState({})

    const [immediatePayment, setImmediatePayment] = useState(false)

    const [max, setMax] = useState(0)
    const [available, setAvailable] = useState(null)
    const [history, setHistory] = useState([])

    const [open, setOpen] = React.useState(false);
    const [snackBarType, setSnackBarType] = useState("")
    const [snackBarContent, setSnackBarContent] = useState("")
    const [openAdvanceActions, setOpenAdvanceActions] = useState(null)

    const reset = () => {
        setError('');
        resetAdvanceSections()
        setVisibleChild("Users");
        refresh()
    };

    const resetAdvanceSections = () => {
        setUpdateUsers(false);
        setUserBankAccounts([]);
        setRequestedAmount("");
        setPrimaryBank(null);
        setSecondaryBank(null);
        setSelectedBank({});
        setEnterAmountVisible(false);
        setWithdrawalDetails({});
        setWithdrawalVisible(false);
        setAdvanceMethod("");
        setManualAdvanceDate(new Date());
        setManualAdvanceReference("");
        setProcessAdvanceNote("");
        setDialogContent([]);
        setUpdateAdvanceNoteVisible(false);
        setUpdateAdvanceNote('');
        setUpdateAdvanceDetails({});
        setOpen(false);
    }

    useEffect(() => {
        console.log("Triggered")
        resetAdvanceSections()
        setFpUsers(data.fpUsers)
        forceUpdate()
    }, [data])

    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
    };

    const handleSetVisible = (state) => {
        setVisible(state)
    };

    const handleAdvanceNoteChange = (e) => {
        setProcessAdvanceNote(e.target.value)
    }

    useEffect(() => {

        let amount = Number(requestedAmount)
        let fee = amount * 0.03
        let transferFee = immediatePayment ? Number(15) : Number(7.50)
        let vat = Number(transferFee * 0.15)
        let total = amount + fee + transferFee + vat

        let cartItem = {
            User: currentUser.MarketplaceUserId,
            Client: currentUser.Client?._id,
            IDNumber: currentUser.IDNumber,
            Username: currentUser.Username,
            Description: `Admin ${advanceMethod} withdrawal by ${commonStore.user.Username}`,
            Reference: advanceMethod === "Manual" ? manualAdvanceReference : null,
            TransactionId: advanceMethod === "Manual" ? manualAdvanceReference : null,
            Product: "Flexible pay",
            Date: advanceMethod === "Manual" ? manualAdvanceDate : new Date(),
            Amount: amount,
            Fee: fee,
            TransferFee: transferFee,
            TotalAmount: Number(total),
            VAT: vat,
            BankAccount: advanceMethod === "Bank" ? selectedBank : null,
            DeviceInfo: navigator.userAgent,
        }
        const contentArray = Object.keys(cartItem).map((key) => {
            if (key === "Description" || key === "Reference" || key === "TotalAmount" || key === "Date" || key === "Notes") {
                return { key: key, value: cartItem[key] }
            }
            else if (key === 'BankAccount' && advanceMethod === "Bank" && Object.keys(selectedBank).length > 0) {
                return { key: key, value: `${cartItem[key].BankName} ...${cartItem[key].AccountNumber.slice(-4)}` }
            }
            else {
                return
            }
        }).filter(line => line !== undefined).filter(line => line.value !== null)
        setDialogContent(contentArray)
        setWithdrawalDetails(cartItem)
        forceUpdate()
    }, [selectedBank, manualAdvanceDate, manualAdvanceReference, advanceMethod])

    useEffect(() => {
        handleAmountChange(requestedAmount)
    }, [immediatePayment])


    const getAmountAvailable = async (user) => {

        let res = await commonStore.calculateAmountAvailable(user.Username)
        console.log(res)
        if (res.status === true) {
            let fpData = res.response
            setAvailable(fpData.allowed)
            setMax(fpData.available)
            let hist = []
            if (fpData.history.length > 0) {
                //descending dates, slice apparently creates a shallow copy to avoid mutation
                //that wouldve been useful a month ago
                hist = fpData.history.slice().sort((a, b) => new Date(b.Date) - new Date(a.Date))
            }
            setHistory(hist)
            setCurrentUser(user)
            setNewPerc(fpData.electedAdvancePerc)
            setUserBlocked(user.Blocked)
            forceUpdate()
            setVisibleChild("singular-user")
        }
        else {
            commonStore.setSnackBar('error', res?.response || "Error occured retrieving flexible pay data")
            return
        }
    }

    const handleOnClickUser = async (user) => {
        resetAdvanceSections()
        console.log("Clicked user", user)
        getAmountAvailable(user)
    }

    const handleActiveFilterClick = (e) => {
        setActiveFilter(e.currentTarget.id)
    }

    const handleOnSearchFieldChange = (e) => {
        setSearchField(e.currentTarget.value)
    }

    const handleFilterChange = (e) => {
        setSearchFilter(e.target.value)
    }

    const getStatusColor = (status) => {
        switch (status) {
            case "Failed": return 'red'
            case "Pending": return 'orange'
            case "Paid": return 'green'
        }
    }

    const handleSetPercValue = (val) => {
        setNewPerc(val)
    }

    const handleDateChange = (newDate) => {
        setManualAdvanceDate(newDate)
    };

    const updateSingleUserObject = (newUser) => {
    const updatedUsers = fpUsers.map(user => 
        user.Username === newUser.Username ? { ...user, ...newUser } : user
    )
    setFpUsers(updatedUsers)
    forceUpdate()
    }

    const updateFpUser = async (data) => {
        console.log(data)
        commonStore.setLoading(true)
        try {
            let res = await commonStore.updateFlexiblePayUserByIDNumber(currentUser.Username, data)
            commonStore.setLoading(false)
            console.log(res)
            if (res.IDNumber) {
                commonStore.setSnackBar('success', "User updated")
                handleOnClickUser(res)
                updateSingleUserObject(res)
            }
            else {
                commonStore.setSnackBar('error', "Error occured, please try again")
                commonStore.setLoading(false)
            }
        } catch (error) {
            commonStore.setSnackBar('error', "Error occured, please try again")
            console.error(error)
            commonStore.setLoading(false)
        }
    }

    const handleCheckUserBlocked = (e) => {
        let checked = e.target.checked
        if (checked) {
            if (!window.confirm("Are you sure you want to unblock this user")) {
                return
            }
        }
        if (!checked) {
            if (!window.confirm("Are you sure you want to block this user")) {
                return
            }
        }
        setUserBlocked(e.target.checked)
        updateFpUser({ Blocked: !userBlocked })

    }

    const handleSetImmediatePayment = (e) => {
        setImmediatePayment(e.target.checked)
    }

    const handleCheckBank = () => {
        setError('');
        let options = []
        if (currentUser) {
            if (currentUser.PrimaryBankAccount) {
                setPrimaryBank(currentUser.PrimaryBankAccount)
                let bnk = currentUser.PrimaryBankAccount
                const option = { ...bnk, ...{ name: `${bnk.BankName} ... ${bnk.AccountNumber.slice(-4)}`, key: 'primary' } }
                options.push(option)
                if (currentUser.SecondaryBankAccount) {
                    setSecondaryBank(currentUser.SecondaryBankAccount)
                    let bnk = currentUser.SecondaryBankAccount
                    const option = { ...bnk, ...{ name: `${bnk.BankName} ... ${bnk.AccountNumber.slice(-4)}`, key: 'secondary' } }
                    options.push(option)
                }
                setEnterAmountVisible(false)
                setUserBankAccounts(options)
                forceUpdate()
                // let id = document.getElementById("banks-ref")
                // id.scrollIntoView({ behavior: 'smooth' })
            }
            else {
                commonStore.setSnackBar('warning', "User has no primary bank account")
                return
                //has no primary bank account
            }

        }
    }


    const handleAmountChange = (val) => {
        setError("")
        let formated = val.replace(/^0+/, '')
        setRequestedAmount(formated)

        let amount = Number(formated)
        let fee = amount * 0.03
        let transferFee = immediatePayment ? Number(15) : Number(7.50)
        let totalFees = fee + (transferFee * 0.15) + transferFee
        console.log(amount + totalFees)

        if (amount > 1 && (amount + totalFees) <= available) {
            handleCheckBank()
            setEnterAmountVisible(true)
            return
        }
        else {
            setEnterAmountVisible(false)
            return
        }
    }

    const handleBankChange = async (type) => {
        console.log(type)
        setError("")

        let bank = type === "primary" ? primaryBank : secondaryBank

        if (bank.Verified) {
            setSelectedBank(bank)
            forceUpdate()
            console.log("Selected bank", bank)
            return;
        }

        const isVerified = await verifyBank(type, bank)
        if (isVerified) {
            commonStore.setSnackBar("success", "User's bank account verfied")
            setSelectedBank(bank)
        } else {
            setSelectedBank({})
            commonStore.setSnackBar('error', "Bank verification failed")
            return
        }

        forceUpdate()
        console.log("Selected bank", bank)
    }

    const handleAdvanceMethodChange = (val) => {
        setAdvanceMethod(val)
    }

    const verifyBank = async (type, bank) => {
        if (!bank.AccountNumber || !bank.BankName || !bank.BranchCode || !bank.Type) {
            return false
        }
        let args = {
            type: type,
            bank: bank,
            verifiedBy: `${commonStore.user.Username} through requesting flexible pay advance`,
            role: "5d8e0b079f51d42bbece9202"
        }
        console.log(args)
        commonStore.setLoading(true)
        try {
            let res = await commonStore.verifyUserBankAccount(currentUser.Username, args)
            console.log(res)
            commonStore.setLoading(false)
            if (res.success) {
                return true
            }
            else {
                commonStore.setLoading(false)
                return false
            }
        } catch (error) {
            commonStore.setSnackBar('error', "Error occured")
            console.error(error)
            commonStore.setLoading(false)
            return false
        }
    }

    const handleProcessAdvance = () => {
        handleClose()
        const data = { ...withdrawalDetails, Notes: [{ date: new Date(), user: commonStore.user.Username, note: processAdvanceNote }] }
        console.log(data)
        if (data.BankAccount?.BankName) {
            requestBankAdvance(data)
        }
    }

    const handleCreateManualAdvance = async () => {
        handleClose()
        const data = { ...withdrawalDetails, Notes: [{ date: new Date(), user: commonStore.user.Username, note: processAdvanceNote }], Status: "Paid" }
        console.log(data)
        let res = await commonStore.adminCreateManualAdvance(data)
        console.log(res)
        if (res.status) {
            if (res.data.advance && res.data.transaction) {
                commonStore.setSnackBar('success', "Advance processed")
                resetAdvanceSections()
                await getAmountAvailable(currentUser)
                return true
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar('error', "Error occured, please try again")
                resetAdvanceSections()
                return false
            }
        }
        else {
            commonStore.setLoading(false)
            commonStore.setSnackBar('error', "Error occured, please try again")
            resetAdvanceSections()
            return false
        }
    }

    const requestBankAdvance = async (advance) => {

        console.log(advance);
        let res = await commonStore.adminBankAccountAdvance(currentUser.Username, advance);
        console.log(res);

        if (res.status === true) {
            
            const { marketplace, flexiblePay, ozow } = res.data;

            if (Object.keys(ozow).length > 0) {
                const transactionStatus = ozow.transaction?.Status;

                if (transactionStatus === "Failed") {
                    commonStore.setSnackBar('warning', `Ozow error message: ${ozow?.response?.response?.payoutStatus?.errorMessage || "Empty error message"}, Error code: Status ${ozow?.response?.response?.payoutStatus?.status || ""} ${ozow?.response?.response?.payoutStatus?.statusCode || ""}`);
                } else if (transactionStatus === "Pending") {
                    
                    if (marketplace?.Amount && flexiblePay?.Amount) {
                        commonStore.setSnackBar('success', "Ozow payment processed and saved successfully on Marketplace and Flexible pay");
                    } else if (!marketplace?.Amount && flexiblePay?.Amount) {
                        commonStore.setSnackBar('warning', "Ozow payment processed, error saving details on Marketplace");
                    } else if (marketplace?.Amount && !flexiblePay?.Amount) {
                        commonStore.setSnackBar('warning', "Ozow payment processed, error saving details on Flexible pay");
                    } else {
                        commonStore.setSnackBar('warning', "Ozow payment processed, error saving on Marketplace and Flexible pay");
                    }
                } 
                else commonStore.setSnackBar('error', "Error occurred, please try again");
            } 
            else commonStore.setSnackBar('error', "Error occurred, Ozow payment not processed, please try again");
            await getAmountAvailable(currentUser)
        } 
        else if (typeof res.data === "string") commonStore.setSnackBar('warning', res.data);
        else commonStore.setSnackBar('error', "Error occurred, please try again");
        resetAdvanceSections();
    };


    const handleSetUpdateAdvance = (stat, adv) => {
        if (!window.confirm(`Are you sure you wan to mark this advance as ${stat}`)) {
            return
        }
        else {
            let args = {
                mpId: adv.MarketplaceObjectID,
                fpId: adv._id,
                Status: stat,
            }

            setUpdateAdvanceDetails(args)
            setUpdateAdvanceNoteVisible(true)
            forceUpdate()
            console.log(updateAdvanceDetails)
        }
    }

    const handleUpdateAdvanceNoteChange = (e) => {
        setUpdateAdvanceNote(e.currentTarget.value)
    }

    const handleCancelUpdateAdvance = () => {
        resetAdvanceSections()
    }

    const updateAdvance = async (adv) => {

        let note = { date: new Date(), user: commonStore.user.Username, note: updateAdvanceNote }

        let args = { ...updateAdvanceDetails, Notes: note }
        console.log(args)
        commonStore.setLoading(true)
        try {
            let res1 = await commonStore.updateTransactionByObjectId(args.mpId, args)
            let res2 = await commonStore.updateAdvanceByObjectId(args.fpId, args)
            if (res1 && res2) {
                commonStore.setSnackBar('success', "Advance updated")
                await getAmountAvailable(currentUser)
            }
            else {
                commonStore.setSnackBar('error', "Error occured, please try again")
            }
        } catch (error) {
            commonStore.setSnackBar('error', "Error occured, please try again")
        }
        finally {
            handleCancelUpdateAdvance()
            commonStore.setLoading(false)
        }
    }

    const handleNewAdvanceCancel = () => {
        resetAdvanceSections()
    }

    const handleAdvanceOpen = (advanceId) => {
        setOpenAdvanceActions((prev) => (prev === advanceId ? null : advanceId))
    }

    const handleGetOzowPayoutStatus = async(adv) => {
        let payoutId = adv.TransactionID
        if (!payoutId) {
            commonStore.setSnackBar("warning", "No Payout ID/ Transaction ID")
            return
        }
        let status = await commonStore.getOzowPayoutStatus(payoutId)
        if (status?.id) {
            let errorString = `${OzowPayoutCodes.Status[status.payoutStatus.status] || ""} - ${OzowPayoutCodes.SubStatus[status.payoutStatus.subStatus] || ""} - ${status.payoutStatus.errorMessage || ""}`
            console.log(errorString)
            commonStore.setSnackBar("info", errorString)
        }
        else commonStore.setSnackBar("warning", "Could not retrieve payout status")
    }

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <>
                {visibleChild === "Users" &&
                    <div>
                        <div style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', marginBottom: '20px', gap: '25px', alignItems: 'center' }}>
                                <header className='admin-dash-sub-header' style={{ fontSize: '14px' }}>
                                    <span className='admin-dash-blue-clients-text normal'>
                                        {'Flexible pay'}</span>{` > Active users`}
                                </header>
                            </div>
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <img src={SearchIcon} style={{ width: '20px' }} />
                                <FormControl style={{ width: '200px' }}>
                                    <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={searchFilter}
                                        label="Filter"
                                        onChange={handleFilterChange}
                                        style={{ fontSize: '13px' }}
                                    >
                                        <MenuItem value={"FirstName"}>First name</MenuItem>
                                        <MenuItem value={"LastName"}>Last name</MenuItem>
                                        <MenuItem value={"IDNumber"}>ID number</MenuItem>
                                    </Select>
                                </FormControl>
                                <input className='admin-dash-search-input'
                                    type='search' value={searchField}
                                    onChange={handleOnSearchFieldChange}
                                    placeholder='Search users by ID number/Full name'
                                />
                            </div>
                            <br />
                            {/* FILTER */}
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div id='All'
                                    className={`admin-dash-filter-item ${activeFilter === 'All' ? 'selected' : null}`}
                                    onClick={handleActiveFilterClick}>
                                    All
                                </div>
                                <div id='Active'
                                    className={`admin-dash-filter-item ${activeFilter === 'Active' ? 'selected' : null}`}
                                    onClick={handleActiveFilterClick}>
                                    Active
                                </div>
                                <div id='Pending'
                                    className={`admin-dash-filter-item ${activeFilter === 'Pending' ? 'selected' : null}`}
                                    onClick={handleActiveFilterClick}>
                                    Pending
                                </div>
                                <div id='Deactivated'
                                    className={`admin-dash-filter-item ${activeFilter === 'Deactivated' ? 'selected' : null}`}
                                    onClick={handleActiveFilterClick}>
                                    Deactivated
                                </div>
                            </div>
                            {/* LIST */}
                            <div >
                                {/* SEARCH */}
                                <br />
                                {/* LIST ITEMS */}
                                <div className='admin-dash-users-table-headers'>
                                    <div className='admin-dash-users-table-headers-text left'>FULL NAME</div>
                                    <div style={{ display: 'flex' }}>
                                        <div className='admin-dash-users-table-headers-text right'>
                                            USERNAME
                                        </div>
                                        <div className='admin-dash-users-table-headers-text right'>
                                            STATUS
                                        </div>
                                        <div className='admin-dash-users-table-headers-text right'>
                                            ACTIVATION DATE
                                        </div>
                                        <div className='admin-dash-users-table-headers-text right'>
                                            EMPLOYER
                                        </div>
                                    </div>
                                </div>
                                {fpUsers.length === 0 ? null :
                                    fpUsers.filter(usr => {
                                        if (activeFilter === 'Active' && usr[searchFilter] !== undefined) {
                                            return usr.Blocked === false && usr.ActivatedDate && usr[searchFilter].toLowerCase().includes(searchField);
                                        }
                                        else if (activeFilter === 'Pending' && usr[searchFilter] !== undefined) {
                                            return usr.Blocked === false && !usr.ActivatedDate && usr[searchFilter].toLowerCase().includes(searchField);
                                        }
                                        else if (activeFilter === 'Deactivated' && usr[searchFilter] !== undefined) {
                                            return usr.Blocked === true && usr[searchFilter].toLowerCase().includes(searchField);
                                        }
                                        else {
                                            if (usr[searchFilter] !== undefined) {
                                                return usr[searchFilter].toLowerCase().includes(searchField);
                                            }
                                        }
                                    })
                                        .map((usr, index) => (
                                            <div className='hover' key={usr._id} id={usr._id} onClick={() => handleOnClickUser(usr)}>
                                                <div className='admin-dash-users-table-headers white'>
                                                    <div className='admin-dash-users-table-headers-text left bold'>{usr.FirstName} {usr.LastName}</div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div className='admin-dash-users-table-headers-text right'>
                                                            <div
                                                                className={`admin-dash-users-table-headers-text`}>
                                                                {usr.IDNumber ? usr.IDNumber : usr.Username}
                                                            </div>
                                                        </div>
                                                        <div className='admin-dash-users-table-headers-text right bold'>
                                                            {usr.Blocked === true &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'deactivated'}`}>
                                                                    {'Deactivated'}
                                                                </div>
                                                            }
                                                            {usr.Blocked === false && usr.ActivatedDate &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'active'}`}>
                                                                    {'Active'}
                                                                </div>
                                                            }
                                                            {usr.Blocked === false && !usr.ActivatedDate &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'pending'}`}>
                                                                    {'Pending'}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className='admin-dash-users-table-headers-text right'>
                                                            <div
                                                                className={`admin-dash-users-table-headers-text`}>
                                                                {usr.ActivatedDate ? new Date(usr.ActivatedDate).toLocaleDateString() : 'N/A'}
                                                            </div>
                                                        </div>
                                                        <div className='admin-dash-users-table-headers-text right'>
                                                            <div
                                                                className={`admin-dash-users-table-headers-text`}>
                                                                {usr.Client?.Name || "No client"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                }

                            </div>
                        </div>
                    </div>
                }
                {visibleChild === 'singular-user' &&
                    <div style={{ width: '100%' }}>

                        <div style={{ background: "#FFFFFF", width: '95%', borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', marginBottom: '20px', gap: '25px', alignItems: 'center' }}>
                                <button className='admin-dash-back-arrow-btn' onClick={() => setVisibleChild("Users")}>Back</button>
                                <header className='admin-dash-sub-header' style={{ fontSize: '13px' }}>
                                    <span className='admin-dash-blue-clients-text normal'>
                                        {'User > Active users '}</span>{` > ${currentUser.FirstName} ${currentUser.LastName}`}
                                </header>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>
                                <div>
                                    <p className="text-[15px] font-bold">Personal details</p>
                                    <br />
                                    <div class="flex gap-[17px] justify-start flex-row">
                                        <div className='flex flex-col justify-start'>
                                            <label className='admin-dash-client-details-text small'>First name</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {currentUser.FirstName}
                                            </div>
                                        </div>
                                        <div className='flex flex-col justify-start'>
                                            <label className='admin-dash-client-details-text small'>Last name</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {currentUser.LastName}
                                            </div>
                                        </div>
                                        <div className='flex flex-col justify-start'>
                                            <label className='admin-dash-client-details-text small'>ID number/passport</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {currentUser.Username}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ width: '100%' }} />
                            <br />
                            <p className="text-[15px] font-bold">Controls</p>
                            <br />
                            <div className='flex gap-6'>
                                {/* ACTIVE */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                                    <p>
                                        {currentUser.ActivatedDate ?
                                            `User activated ${new Date(currentUser.ActivatedDate).toLocaleString()}` :
                                            `User hasn't activated benefit`}
                                    </p>
                                    {currentUser.Blocked === true ?
                                        <p className='admin-dash-blue-clients-text' style={{ color: 'red' }}>User blocked</p> :
                                        <p className='admin-dash-blue-clients-text'>User active</p>
                                    }
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <IOSToggle checked={!userBlocked} onChange={handleCheckUserBlocked} sx={{ m: 1 }} />
                                            }
                                            label=""
                                        />
                                    </FormGroup>
                                </div>
                                <div>
                                    <p>Elected advance percentage</p>
                                    <InputSlider
                                        value={newPerc}
                                        min={0}
                                        max={25}
                                        onChange={handleSetPercValue}
                                    />

                                    {newPerc !== currentUser.ElectedAdvancePerc &&
                                        <p onClick={() => updateFpUser({ ElectedAdvancePerc: newPerc })}
                                            className='admin-dash-blue-clients-text'>
                                            Update percentage
                                        </p>
                                    }
                                </div>

                            </div>
                            <br />
                            <hr style={{ width: '100%' }} />
                            <br />
                            <div >
                                <p className="text-[15px] font-bold">Amount available</p>
                                <br />
                                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                    <p>{`R ${available.toLocaleString()}`}</p>
                                    <svg width="400" height="40" margin="0" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="10" y="10" width="380" height="20" fill="#e0e0e0" rx="10" ry="10" />

                                        <rect x="10" y="10" width={`${((available / max)) * 380}`} height="20" fill="#0156DE" rx="10" ry="10" />
                                    </svg>
                                    <p>{`out of R ${max.toLocaleString()}`}</p>
                                </div>

                            </div>
                            {!withdrawalVisible ?
                                <p onClick={() => setWithdrawalVisible(true)}
                                    className='admin-dash-blue-clients-text'>
                                    Process new advance
                                </p> :
                                <>
                                    <FormControl>
                                        <InputLabel style={{ fontFamily: "Inter" }} htmlFor="outlined-adornment-amount">
                                            Amount
                                        </InputLabel>
                                        <OutlinedInput
                                            style={{ fontFamily: "Inter" }}
                                            id="outlined-adornment-amount"
                                            startAdornment={<InputAdornment position="start">R</InputAdornment>}
                                            label="Amount"
                                            value={requestedAmount}
                                            onChange={(e) => handleAmountChange(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <IOSToggle checked={immediatePayment} onChange={handleSetImmediatePayment} sx={{ m: 1 }} />
                                            }
                                            label="Immediate payment"
                                        />
                                    </FormGroup>
                                    <br />
                                    <p onClick={handleNewAdvanceCancel}
                                        className='admin-dash-blue-clients-text'>
                                        Cancel
                                    </p>
                                    <br />
                                </>

                            }

                            {withdrawalVisible && enterAmountVisible && (
                                <div>
                                    <p>What method is this advance?</p>
                                    <GenericCustomSelect
                                        options={[{ key: "Manual", name: "Manual" }, { key: "Bank", name: "Bank" }]}
                                        value={advanceMethod ? advanceMethod : "Select option"}
                                        onChange={(e) => handleAdvanceMethodChange(e.key)}
                                    />
                                    <br />
                                </div>
                            )}

                            {withdrawalVisible && enterAmountVisible && advanceMethod === "Bank" && (
                                <div>
                                    <p>Select user's bank account</p>
                                    <GenericCustomSelect
                                        options={userBankAccounts}
                                        value={selectedBank.BankName ? `${selectedBank.BankName} ... ${selectedBank.AccountNumber.slice(-4)}` : "Select option"}
                                        onChange={(e) => handleBankChange(e.key)}
                                    />
                                    <br />
                                    {selectedBank.BankName && (
                                        <DialogConfirm
                                            content={dialogContent}
                                            openDialogText={"Proccess bank account advance"}
                                            headerText={"Confirm withdrawal details"}
                                            onSave={handleProcessAdvance}
                                            open={open}
                                            onClose={handleClose}
                                            onOpen={handleClickOpen}
                                            onSaveText={"Confirm advance"}
                                            handletextFieldOnchange={handleAdvanceNoteChange}
                                            textField={processAdvanceNote}
                                            textFieldVisible={true}
                                            textFieldLabel={"Add a note"}
                                        />
                                    )}
                                </div>
                            )}

                            {withdrawalVisible && enterAmountVisible && advanceMethod === "Manual" && (
                                <main style={{ border: '1px solid #DADADA', borderRadius: '12px' }}>
                                    <p>Date advance took place</p>
                                    <DateInput value={manualAdvanceDate} onChange={handleDateChange} />
                                    <BasicMuiTextField label='Reference' value={manualAdvanceReference} onChange={(e) => setManualAdvanceReference(e.target.value)} />
                                    {manualAdvanceDate && manualAdvanceReference &&
                                        <DialogConfirm
                                            content={dialogContent}
                                            openDialogText={"Process manual advance"}
                                            headerText={"Confirm withdrawal details"}
                                            onSave={handleCreateManualAdvance}
                                            open={open}
                                            onClose={handleClose}
                                            onOpen={handleClickOpen}
                                            onSaveText={"Confirm advance"}
                                            handletextFieldOnchange={handleAdvanceNoteChange}
                                            textField={processAdvanceNote}
                                            textFieldVisible={true}
                                            textFieldLabel={"Add a note"}
                                        />
                                    }
                                </main>
                            )}
                            <br />
                            <hr style={{ width: '100%' }} />
                            <br />
                            <div>
                                {/* HISTORY */}
                                <div>
                                    <p className="text-[15px] font-bold">Withdrawal history</p>
                                    <br />
                                    {history.length > 0 ?
                                        <div className='flex-column' style={{ gap: '6px' }}>
                                            {history.map((adv) => (
                                                <>
                                                    <div key={adv._id} className='flexible-pay-history-item-container'>
                                                        <div>
                                                            <p style={{ margin: 0 }}>{`Date: ${new Date(adv.Date).toLocaleDateString()} at ${new Date(adv.Date).toLocaleTimeString()}`}</p>
                                                            <p style={{ color: getStatusColor(adv.Status), margin: 0 }}>{`${adv.Status}`}</p>
                                                            <p>{`${adv.Amount?.toFixed(2)}`}</p>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                            {adv.Status === 'Pending' && <p onClick={() => { handleSetUpdateAdvance("Failed", adv) }} style={{ margin: 0, color: 'red', cursor: 'pointer' }}>Mark as failed</p>}
                                                            {adv.Status === 'Pending' && <p onClick={() => { handleSetUpdateAdvance("Paid", adv) }} style={{ margin: 0, color: 'green', cursor: 'pointer' }}>Mark as paid</p>}
                                                            {adv.TransactionID && <p className='text-[#00B8AD]' onClick={() => handleGetOzowPayoutStatus(adv)} style={{ margin: 0, cursor: 'pointer' }}>Get OZOW status<i class="fa-solid fa-spinner"></i></p>}
                                                            <p id={adv._id} onClick={() => handleAdvanceOpen(adv._id)} className='text-[15px] cursor-pointer'>{"..."}</p>

                                                        </div>
                                                    </div>
                                                    {openAdvanceActions === adv._id && (
                                                        <>
                                                            <div className='invoice-actions-section'>
                                                                {adv.Notes?.length > 0 &&
                                                                    <div className='flex gap-1'>
                                                                        <div className='flex gap-1 flex-column'>
                                                                            <p>Notes: </p>
                                                                            {adv.Notes.map((nt) => (
                                                                                <p className='text-[11px] font-light'>{`${new Date(nt.date).toLocaleDateString()} - ${nt.user} - ${nt.note}`}</p>
                                                                            ))}
                                                                        </div>
                                                                    </div>}
                                                                <p>{`Description: `}<span className='text-[11px] font-light'>{adv.Description || ""}</span></p>
                                                                <p>{`Transfer fee: `}<span className='text-[11px] font-light'>{adv.TransferFee || ""}</span></p>
                                                                <p>{`Bank account: `}<span className='text-[11px] font-light'>{adv.BankAccount ? `${adv.BankAccount.BankName} ...${adv.BankAccount.AccountNumber?.slice(-3)}`: ""}</span></p>
                                                                <p>{`Message: `}<span className='text-[11px] font-light'>{adv.Message || ""}</span></p>
                                                                <p>{`Transaction ID: `}<span className='text-[11px] font-light'>{adv.TransactionID || ""}</span></p>
                                                                <p>{`Reference: `}<span className='text-[11px] font-light'>{adv.Reference || ""}</span></p>
                                                            </div>
                                                            <br />
                                                        </>
                                                    )}
                                                    {updateAdvanceNoteVisible && updateAdvanceDetails.fpId === adv._id &&
                                                        <div>
                                                            <TextField label={"Reason"} value={updateAdvanceNote} onChange={handleUpdateAdvanceNoteChange} />
                                                            <div className='flex gap-3 items-center'>
                                                                <p onClick={() => updateAdvance()}
                                                                    className='admin-dash-blue-clients-text'>
                                                                    Update
                                                                </p>
                                                                <p onClick={handleCancelUpdateAdvance} className="text-11px cursor-pointer">
                                                                    Cancel
                                                                </p>
                                                            </div>
                                                        </div>

                                                    }
                                                </>


                                            ))}
                                        </div>

                                        :
                                        <div>No advances to date</div>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                }
            </>
        );
    }
})

export default AdminBenefitsFlexiblePayUsers
