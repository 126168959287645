const EmployeeBaslineResults = ({ OverallScore, SpendScore, SaveScore, BorrowScore, PlanScore }) => {

    const pointerColor = () => {
        if (OverallScore <= 40) return { bg: "#FFD1B6", bd: '#FB985E' };
        else if (OverallScore <= 70) return { bg: "#7E30FF", bd: '#A56EFF' };
        else if (OverallScore <= 100) return { bg: "#8dd0fd", bd: '#3291D0' };
        else return { bg: "#FFD1B6", bd: '#FB985E' };
    };
    const pointerBg = pointerColor()?.bg
    const pointerBd = pointerColor()?.bd

    return (
        <div>
            <br />
            <div className='w-full flex flex-row gap-1 relative'>
                <div className="rounded-md bg-[#FB985E] w-[40%] h-[10px]" />
                <div className="rounded-md bg-[#A56EFF] w-[30%] h-[10px]" />
                <div className="rounded-md bg-[#3291D0] w-[30%] h-[10px]" />

                <div className="absolute flex flex-column items-center gap-1"
                    style={{
                        bottom: '-6px',
                        left: `${OverallScore}%`, // Position based on the overall score
                        transform: 'translateX(-50%)' // Center the div based on its width
                    }}>
                    <div className={`p-1 rounded-md text-[${pointerBd}] text-[21px] font-bold`} style={{ background: `${pointerBg}` }}>{OverallScore.toFixed(0)}</div>
                    <div className="rounded-md w-[13px] h-[25px]" style={{ background: pointerBg, border: `3px solid ${pointerBd}` }} />
                </div>
            </div>

            <br />
            <div className='employee-dash-fin-health-info'>
                <div className='flex flex-row gap-1'>
                    <div className="rounded-md bg-[#FB985E] w-[40px] h-[15px]"></div>
                    <p className='font-bold w-[60px]'>0 - 40</p>
                    <p>Financially vulnerable</p>
                </div>
                <div className='flex flex-row gap-1'>
                    <div className="rounded-md bg-[#A56EFF] w-[40px] h-[15px]"></div>
                    <p className='font-bold w-[60px]'>41 - 70</p>
                    <p>Financially coping</p>
                </div>
                <div className='flex flex-row gap-1'>
                    <div className="rounded-md bg-[#3291D0] w-[40px] h-[15px]"></div>
                    <p className='font-bold w-[60px]'>71 - 100</p>
                    <p>Financially healthy</p>
                </div>
            </div>
            <br />
            <div className='flex flex-column gap-2 items-start'>
                <div>
                    <p className='font-bold'>Financial health scores between <span className='text-[#FB985E]'>0 - 39</span> are considered Financially Vulnerable.</p>
                    <p>Individuals with scores in this range report healthy outcomes across few, or none, of the eight financial health indicators.</p>
                </div>
                <div>
                    <p className='font-bold'>Financial health scores between <span className='text-[#A56EFF]'>40 - 69</span> are considered Financially Coping.</p>
                    <p>Individuals with scores in this range report healthy outcomes across few, or none, of the eight financial health indicators.</p>
                </div>
                <div>
                    <p className='font-bold'>Financial health scores between <span className='text-[#3291D0]'>70 - 100</span> are considered Financially Healthy.</p>
                    <p>Individuals with scores in this range report healthy outcomes across few, or none, of the eight financial health indicators.</p>
                </div>
            </div>
            <br />
            <div className='employee-dash-fin-health-quadrants-container'>
                <div className='employee-dash-fin-health-overall-circle'>
                    {OverallScore.toFixed(0)}
                </div>
                <div className='flex flex-row items-center justify-between'>
                    <div className='employee-dash-fin-health-quadrants-score-item top-left'>
                        <p>Spend</p>
                        <p class="font-bold text-[40px]">{SpendScore.toFixed(0)}</p>
                    </div>
                    <div className='employee-dash-fin-health-quadrants-score-item top-right'>
                        <p>Save</p>
                        <p className='font-bold text-[40px]'>{SaveScore.toFixed(0)}</p>
                    </div>
                </div>

                <div className='flex flex-row items-center justify-between'>
                    <div className='employee-dash-fin-health-quadrants-score-item bottom-left'>
                        <p>Borrow</p>
                        <p className='font-bold text-[40px]'>{BorrowScore.toFixed(0)}</p>
                    </div>
                    <div className='employee-dash-fin-health-quadrants-score-item bottom-right'>
                        <p>Plan</p>
                        <p className='font-bold text-[40px]'>{PlanScore.toFixed(0)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeBaslineResults