import React, { useState, useEffect, useLocation } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Link } from 'react-router-dom';
import { Button, Navbar, Input, ErrorCard } from '../../components/basic/index'

import './styles.scss';

const ErrorPage = observer(() => {

        return (
            <div>
                <Navbar />
                <main style={{ alignItems: 'center' }}>
                    <header className='employee-benefits-assessment-submit-header'>Oops!</header>
                    <div style={{ width: '50%' }} className='employee-benefits-assessment-submit-inputs-container'>
                        <h1>Page Error</h1>
                    </div>
                </main>
            </div>
        );
   
})

export default ErrorPage
