import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store'
import { isMobile } from 'is-mobile';
import allBenefits from '../../../components/benefits';
import AdminBenefitsFlexiblePay from './flexiblePay';
import './styles.scss';
import AdminBenefitsCreditBuilder from './creditBuilder';

const AdminBenefits = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [error, setError] = useState('');
    const [filter, setFilter] = useState('all')
    const [visible, setVisible] = useState("Home")
    const [searchField, setSearchField] = useState("")
    const [userPermissions, setUserPermissions] = useState(commonStore.user.Permissions || [])

    const resetVisible = () => {
        setVisible("Home")
    }

    const handleSelectFilter = (e) => {
        setFilter(e.currentTarget.id)
    }

    const handleBenefitClick = (name) => {
        if (!userPermissions.includes("edit-benefit-controls")) {
            commonStore.setSnackBar("warning", "You are not permitted to view benefits informations")
            return
        }
        switch (name) {
            case "Flexible pay":
                setVisible(name)
                break
            case "Credit builder":
                setVisible(name)
                break
            default:
                break
        }
    }

    const handleOnSearchFieldChange = (e) => {
        setSearchField(e.currentTarget.value)
    }

    useEffect(() => {
    }, [])

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <div>
                {visible === "Home" &&
                    <main style={{ background: "#F4F5FA", width: '100%' }}>
                        <header className='admin-dash-option-header'>{`Benefits`}</header>
                        <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                            <input className='admin-dash-search-input'
                                type='search' value={searchField}
                                onChange={handleOnSearchFieldChange}
                                placeholder='Search for benefits'
                            />
                            <br />
                            <div className='flex gap-1'>
                                <div id="all" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "all" ? 'selected' : null}`}>
                                    All
                                </div>
                                <div id="financial" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "financial" ? 'selected' : null}`}>
                                    Financial
                                </div>
                                <div id="physical" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "physical" ? 'selected' : null}`}>
                                    Physical
                                </div>
                                <div id="emotional" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "emotional" ? 'selected' : null}`}>
                                    Emotional
                                </div>
                                <div id="social" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "social" ? 'selected' : null}`}>
                                    Social
                                </div>
                            </div>

                            <br />

                            <div>
                                <div className='client-benefits-container-wrap' style={{ gap: '10px' }}>
                                    {allBenefits.filter((ben) => {
                                        if (filter === "all" && searchField === '') {
                                            return ben
                                        }
                                        else if (filter === ben.type && ben.tags.some(tag => tag.toLocaleLowerCase().includes(searchField.toLocaleLowerCase()))) {
                                            return ben
                                        }
                                    }).map((ben) => (
                                        <div onClick={() => handleBenefitClick(ben.Name)} className='employee-dash-explore-benefit-container' style={{ width: '250px', cursor: 'pointer', height: '140px' }}>
                                            <div className='flex flex-row justify-between w-full items-center'>
                                                <div style={{ color: '#010143', fontSize: '14px', fontWeight: 600 }}>{ben.Name}</div>
                                                <img src={ben.image} className='employee-dash-benefit-img' alt='benefit logo' />
                                            </div>
                                            <div className='confirm-employee-benefits-benefit-description-card-description-text-container'>
                                                <p style={{ margin: 0, fontSize: '11px' }} className='confirm-employee-benefits-benefit-description-card-type-text'>
                                                    {ben.description}
                                                </p>
                                            </div>
                                            {ben.active === true &&
                                                <div style={{ padding: '5px 8px', borderRadius: '10px', background: '#E3FFE2', marginLeft: 'auto' }}>
                                                    <div style={{ color: '#2CB573', fontSize: '10px', fontWeight: 500 }}>Active</div>
                                                </div>
                                            }
                                        </div>

                                    ))
                                    }
                                </div>
                            </div>
                        </main>

                    </main>
                }
                {visible === "Flexible pay" &&
                    <AdminBenefitsFlexiblePay backHome={resetVisible} />
                }
                {visible === "Credit builder" &&
                    <AdminBenefitsCreditBuilder />
                }
            </div>

        );
    }
})

export default AdminBenefits
