import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, ErrorCard, IOSToggle, InputSlider } from '../../../../components/basic/index'
import { GreenCheckCircle, FlexiblePayLogo } from '../../../../assets/icons/index'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import './styles.scss';

const ConfirmClientBenefits = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [userPermissions, setUserPermissions] = useState(commonStore.user.Permissions || [])

    const [error, setError] = useState('');
    const [errorType, setErrorType] = useState('');

    //benefit info
    const [flexiblePay, setFlexiblePay] = useState(false)
    const [globalEmployeeBenefit, setGlobalEmployeeBenefit] = useState(false);
    const [specialisedBenefit, setSpecialisedBenefit] = useState(false);
    const [diminishingLimitEnabled, setDiminishingLimitEnabled] = useState(false);
    const [diminishingLimitDecrementPerc, setDiminishingLimitDecrementPerc] = useState(5);
    const [diminishingLimitMonths, setDiminishingLimitMonths] = useState(6);
    const [cyclingEnabled, setCyclingEnabled] = useState(false);
    const [cyclingMonths, setCyclingMonths] = useState(0);
    const [percentage, setPercentage] = useState(25);

    console.log(commonStore.client)

    const handleConfirmBenefits = async () => {
        if (!userPermissions.includes("edit-benefit-controls")) {
            commonStore.setSnackBar("warning", "You are not permitted to perform this actions")
            return
        }
        let products = commonStore.client.AvailableProducts
        let activated = products.map((item) => {
            let temp = item
            if (temp.Name === "Flexible pay") {
                //set controls
                let controls = {}

                controls.ElectedAdvancePerc = percentage
                //diminishing limit
                controls.DiminishingLimit = {
                    enabled: diminishingLimitEnabled,
                    decrement: diminishingLimitDecrementPerc,
                    numberOfMonths: diminishingLimitMonths,
                }
                //cycling
                controls.Cycling = {
                    enabled: cyclingEnabled,
                    numberOfMonths: cyclingMonths
                }
                temp.Controls = controls

            }
            temp.ClientHasConfirmed = true
            temp.ConfirmedDate = new Date()
            temp.ConfirmedBy = commonStore.user.Username
            temp.Status = "Active"
            return temp
        })

        let args = {
            benefits: activated
        }
        console.log(args)
        commonStore.setLoading(true)
        try {
            let res = await commonStore.clientConfirmBenefits(commonStore.user.Client._id, args)
            console.log(res)
            commonStore.setLoading(false)
            if (res.success) {
                commonStore.setSnackBar("success", "Benefit activated")
                navigate('/client/home/Overview', { state: { refresh: true } })
                return
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error occured, please try again")
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }
    }

    useEffect(() => {
        let products = commonStore.client?.AvailableProducts
        console.log("Available products: ", products)
        let flexpay = false
        if (products.length > 0) {
            products.forEach((prod) => {
                if (prod.Name === "Flexible pay" && !prod.ClientHasConfirmed) {
                    flexpay = true
                }
            })
        }
        setFlexiblePay(flexpay)
        setGlobalEmployeeBenefit(false)
        setSpecialisedBenefit(false)
    }, [])

    const handleCheckDiminishingLimit = (e) => {
        setDiminishingLimitEnabled(e.currentTarget.checked)
    }

    const handleCheckCycle = (e) => {
        setCyclingEnabled(e.currentTarget.checked)
    }

    const handleSetPercValue = (val) => {
        setPercentage(val)
    }

    if (isMobile()) {
        return (
            <main>


            </main>
        );
    } else {
        return (
            <main>
                <header className='employee-benefits-assessment-submit-header'>Confirm employee benefits</header>
                <div>
                    <p className='employee-benefits-assessment-submit-subheader'>
                        Confirm the priority employee benefits for your company based on the pre-assessment and consultation with Level.
                    </p>
                    <br />
                    <br />
                </div>
                {flexiblePay &&
                    <div className='confirm-employee-benefits-benefit-description-card'>

                        <div className='confirm-employee-benefits-benefit-description-card-header-container'>
                            <p className='confirm-employee-benefits-benefit-description-card-header'>FlexiblePay</p>
                            <p className='confirm-employee-benefits-benefit-description-card-header'>{`${percentage}% of salary`}
                                <span style={{ fontWeight: '400' }}> / month</span>
                            </p>
                        </div>

                        <div className='confirm-employee-benefits-benefit-description-card-section2'>
                            <div className='confirm-employee-benefits-benefit-description-card-type-container'>
                                <p className='confirm-employee-benefits-benefit-description-card-type-text'>Type: <span style={{ fontWeight: '700' }}>Fixed marketplace spend</span></p>
                                <p className='confirm-employee-benefits-benefit-description-card-type-text'>Repayment: <span style={{ fontWeight: '700' }}>Deducted from employee payroll</span></p>
                            </div>
                            <br />
                            <p style={{ fontSize: '11px' }}>FlexiblePay is a financial benefit that allows employees to access a portion of their earned wages before their regular payday. Instead of waiting for the traditional monthly salary, employees can draw on the wages they've already accrued.</p>
                            <br />
                            <p style={{ fontWeight: 700, fontSize: '15px', color: 'black' }} className='confirm-employee-benefits-benefit-description-card-type-text'>Controls</p>
                            <br />
                            {/* PERCENTAGE */}
                            <div className='confirm-employee-benefits-benefit-description-card-description-container'>
                                <div className='confirm-employee-benefits-benefit-description-card-description-text-container'>
                                    <div className='confirm-employee-benefits-benefit-description-card-type-text'>
                                        <p className='confirm-employee-benefits-benefit-description-card-type-text' style={{ fontWeight: '700', color: 'black' }}>
                                            Elected advance percentage
                                        </p>
                                        <p className='confirm-employee-benefits-benefit-description-card-type-text'>
                                            {"Select a constant advance percentage to apply to all employees (Max 25%)."}
                                        </p>
                                    </div>
                                    <InputSlider
                                        value={percentage}
                                        min={0}
                                        max={25}
                                        onChange={handleSetPercValue}
                                    />
                                </div>

                            </div>
                            <br />
                            {/* DIMINISHING LIMIT */}
                            {/* <div className='confirm-employee-benefits-benefit-description-card-description-container'>
                                <div className='confirm-employee-benefits-benefit-description-card-description-text-container'>
                                    <div className='confirm-employee-benefits-benefit-description-card-type-text'>
                                        <p className='confirm-employee-benefits-benefit-description-card-type-text' style={{ fontWeight: '700', color: 'black' }}>
                                            Diminishing withdrawal limit
                                        </p>
                                        <p className='confirm-employee-benefits-benefit-description-card-type-text'>
                                            {`Reduce the withdrawal percentage limit of ${percentage} % by ${diminishingLimitDecrementPerc} % till it reaches zero. In month ${diminishingLimitMonths} the limit will go back to ${percentage} %.`}
                                        </p>
                                    </div>
                                    <div className='admin-dash-blue-clients-text'>
                                        Make changes
                                    </div>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <IOSToggle checked={diminishingLimitEnabled} onChange={handleCheckDiminishingLimit} sx={{ m: 1 }} />
                                            }
                                            label=""
                                        />
                                    </FormGroup>
                                </div>

                            </div>
                            <br /> */}
                            {/* CYCLES */}
                            {/* <div className='confirm-employee-benefits-benefit-description-card-description-container'>
                                <div className='confirm-employee-benefits-benefit-description-card-description-text-container'>
                                    <div className='confirm-employee-benefits-benefit-description-card-type-text'>
                                        <p className='confirm-employee-benefits-benefit-description-card-type-text' style={{ fontWeight: '700', color: 'black' }}>
                                            On and off access cycle
                                        </p>
                                        <p className='confirm-employee-benefits-benefit-description-card-type-text'>
                                            {"Limit the amount of times they can access the service in a 3 month cycle. Employees can access the service for 2 consecutive months, then will not be able to access it in month 3."}
                                        </p>
                                    </div>
                                    <div className='admin-dash-blue-clients-text'>
                                        Make changes
                                    </div>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <IOSToggle checked={cyclingEnabled} onChange={handleCheckCycle} sx={{ m: 1 }} />
                                            }
                                            label=""
                                        />
                                    </FormGroup>
                                </div>
                            </div> */}

                        </div>

                    </div>
                }

                <div style={{ width: '100%' }} className='two-buttons-container-pri-one-btn-right'>
                    <Button onClick={() => handleConfirmBenefits()}>Next</Button>
                </div>

            </main>
        );
    }
})

export default ConfirmClientBenefits
