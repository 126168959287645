import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function BasicMuiTextField({ value, onChange, label }) {
    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField style={{ fontSize: '13px', fontFamily: "Inter" }}
                value={value}
                onChange={onChange}
                id="outlined-basic"
                label={label}
                fontFamily="Inter"
                fontSize='13px'
                variant="outlined" />
        </Box>
    );
}
