import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Navbar, Input } from '../../../components/basic/index'
import { PinkCheckCircle, TCsPayroll2, TCsPayroll3, SageIcon, SimplePayIcon, LabourNetIcon, PaySpaceIcon } from '../../../assets/icons/index'

import './styles.scss';

const ConnectPayroll = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [clicked, setClicked] = useState('');
    //sage
    const [sageUsername, setSageUsername] = useState('');
    const [sagePassword, setSagePassword] = useState('');
    const [sageApiKey, setSageApiKey] = useState('');
    //simple pay
    const [simplePayApiKey, setSimplePayApiKey] = useState('');
    const [simplePayClientId, setSimplePayClientId] = useState('');
    //pay space
    const [paySpaceClientId, setPaySpaceClientId] = useState('');
    const [paySpaceApiKey, setPaySpaceApiKey] = useState('');
    const [paySpaceScope, setPaySpaceScope] = useState('');
    const [paySpaceCompanyId, setPaySpaceCompanyId] = useState('');
    //labournet
    const [labourNetUsername, setLabourNetUsername] = useState('');
    const [labourNetPassword, setLabourNetPassword] = useState('');
    const [labourNetInstance, setLabourNetInstance] = useState('');
    const [labourNetPayrollRegNo, setLabourNetPayrollRegNo] = useState('');
    const [labourNetInterfaceCode, setLabourNetInterfaceCode] = useState('');

    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

    const [displayImportResults, setDisplayImportResults] = useState(false)
    const [importResults, setImportResults] = useState({})

    const [payspaceClients, setPaySpaceClients] = useState([])
    const [displayPayspaceClients, setDisplayPayspaceClients] = useState(false)
    const [selectedPayspaceClient, setSelectedPayspaceClient] = useState({})
    const [confirmPayspaceClient, setConfirmPayspaceClient] = useState(false)

    const [simplePayClients, setSimplePayClients] = useState([])
    const [displaySimplePayClients, setDisplaySimplePayClients] = useState(false)
    const [selectedSimplePayClient, setSelectedSimplePayClient] = useState({})
    const [confirmSimplePayClient, setConfirmSimplePayClient] = useState(false)

    const [labournetClients, setLabournetClients] = useState([])
    const [displayLabournetClients, setDisplayLabournetClients] = useState(false)
    const [selectedLabournetClient, setSelectedLabournetClient] = useState({})
    const [confirmLabournetClient, setConfirmLabournetClient] = useState(false)

    const [error, setError] = useState('');
    const [errorType, setErrorType] = useState('');

    const [visible, setVisible] = useState("credentials")

    const reset = () => {
        setFile(null)
        // Reset clicked state
        setClicked('');

        // Reset Sage credentials
        setSageUsername('');
        setSagePassword('');
        setSageApiKey('');

        // Reset Simple Pay credentials
        setSimplePayApiKey('');
        setSimplePayClientId('');

        // Reset Pay Space credentials
        setPaySpaceClientId('');
        setPaySpaceApiKey('');
        setPaySpaceScope('');
        setPaySpaceCompanyId('');

        // Reset Labournet credentials
        setLabourNetUsername('');
        setLabourNetPassword('');
        setLabourNetInstance('');
        setLabourNetPayrollRegNo('');
        setLabourNetInterfaceCode('');

        // Reset button and import result states
        setNextButtonEnabled(false);
        setDisplayImportResults(false);
        setImportResults({});

        // Reset PaySpace client selection states
        setPaySpaceClients([]);
        setDisplayPayspaceClients(false);
        setSelectedPayspaceClient({});
        setConfirmPayspaceClient(false);

        // Reset Simple Pay client selection states
        setSimplePayClients([]);
        setDisplaySimplePayClients(false);
        setSelectedSimplePayClient({});
        setConfirmSimplePayClient(false);

        // Reset Labournet client selection states
        setLabournetClients([]);
        setDisplayLabournetClients(false);
        setSelectedLabournetClient({});
        setConfirmLabournetClient(false);

        // Reset error handling states
        setError('');
        setErrorType('');

        // Reset visibility and employee data
        setVisible('credentials');
    };

    const checkCompletedFields = () => {
        if (clicked === "") {
            return false
        }
        if (clicked === 'sage') {
            if (sageApiKey.length > 6 && sagePassword.length > 5 && sageUsername.length > 3) {
                return true
            }
            else {
                return false
            }
        }
        if (clicked === 'simple') {
            if (simplePayApiKey.length > 6) {
                return true
            }
            else {
                return false
            }
        }
        if (clicked === 'labour') {
            if (labourNetInstance.length > 6 && labourNetUsername.length > 6 && labourNetPassword.length > 6) {
                return true
            }
            else {
                return false
            }
        }
        if (clicked === 'payspace') {
            if (paySpaceApiKey.length > 6 && paySpaceClientId.length > 6 && paySpaceScope.length > 6) {
                return true
            }
            else {
                return false
            }
        }

    }

    const handleOnProviderClick = (value) => {
        setClicked(value === clicked ? "" : value)
    }
    // Sage
    const handleSageUsernameOnChange = (value) => {
        setSageUsername(value);
    };

    const handleSagepasswordOnChange = (value) => {
        setSagePassword(value);
    };

    const handleSageApiKeyOnChange = (value) => {
        setSageApiKey(value);
    };

    // Simple Pay
    const handleSimplePayKeyOnChange = (value) => {
        setSimplePayApiKey(value);
    };

    const handleSimplePayUsernameOnChange = (value) => {
        setSimplePayClientId(value);
    };

    // LabourNet
    const handleLabourNetUsernameOnChange = (value) => {
        setLabourNetUsername(value);
    };

    const handleLabourNetPasswordOnChange = (value) => {
        setLabourNetPassword(value);
    };

    const handleLabourNetInstanceOnChange = (value) => {
        setLabourNetInstance(value);
    };

    const handleLabourNetPayrollRegNoOnChange = (value) => {
        setLabourNetPayrollRegNo(value);
    };

    const handleLabourNetInterfaceCodeOnChange = (value) => {
        setLabourNetInterfaceCode(value);
    };

    // PaySpace
    const handlePaySpaceKeyOnChange = (value) => {
        setPaySpaceApiKey(value);
    };

    const handlePayClientIdOnChange = (value) => {
        setPaySpaceClientId(value);
    };

    const handlePayScopeOnChange = (value) => {
        setPaySpaceScope(value);
    };

    useEffect(() => {
        setClicked('')
        setNextButtonEnabled(false)
        if (commonStore.client) {
            let cli = commonStore.client
            if (cli.PayrollInformation) {
                const info = cli.PayrollInformation
                //payroll verified
                if (info.Provider) {
                    switch (info.Provider) {
                        case "Simple pay": {
                            setSimplePayApiKey(info.ApiKey || "")
                            setSimplePayClientId(info.ClientId || "")
                        } break
                        case "Sage 300": {
                            setSageApiKey(info.ApiKey || "")
                            setSageUsername(info.Username || "")
                            setSagePassword(info.Password || "")
                        } break
                        case "Labour net": {
                            setLabourNetUsername(info.Username || "")
                            setLabourNetPassword(info.Password || "")
                            setLabourNetPayrollRegNo(info.PayrollRegNo || "")
                            setLabourNetInterfaceCode(info.InterfaceCode || "")
                            setLabourNetInstance(info.Instance || "")
                        } break
                        case "Pay space": {
                            setPaySpaceApiKey(info.ApiKey || "")
                            setPaySpaceClientId(info.ClientId || "")
                            setPaySpaceScope(info.Scope || "")
                            setPaySpaceCompanyId(info.CompanyId || "")
                        } break
                        default:
                            break;
                    }
                }
                else {
                    if (info.Provider) {
                        switch (info.Provider) {
                            case "Simple pay": {
                                setSimplePayApiKey(info.ApiKey || "")
                                setSimplePayClientId(info.ClientId || "")
                            } break
                            case "Sage": {
                                setSageApiKey(info.ApiKey || "")
                                setSageUsername(info.Username || "")
                                setSagePassword(info.Password || "")
                            } break
                            case "Labour net": {
                                setLabourNetUsername(info.Username || "")
                                setLabourNetPassword(info.Password || "")
                                setLabourNetPayrollRegNo(info.PayrollRegNo || "")
                                setLabourNetInterfaceCode(info.InterfaceCode || "")
                                setLabourNetInstance(info.Instance || "")
                            } break
                            case "Pay space": {
                                setPaySpaceApiKey(info.ApiKey || "")
                                setPaySpaceClientId(info.ClientId || "")
                                setPaySpaceScope(info.Scope || "")
                                setPaySpaceCompanyId(info.CompanyId || "")
                            } break
                            default:
                                break;
                        }
                    }
                }
            }
        }
        else {
            commonStore.setLoading(true)
            commonStore.getClientByObjectId(commonStore.user.Client._id).then((res) => {
                commonStore.setClient(res)
                let info = {}
                if (res.PayrollInformation) {
                    info = res.PayrollInformation
                }
                if (info.Provider) {
                    switch (info.Provider) {
                        case "Simple pay": {
                            setSimplePayApiKey(info.ApiKey || "")
                            setSimplePayClientId(info.ClientId || "")
                        } break
                        case "Sage": {
                            setSageApiKey(info.ApiKey || "")
                            setSageUsername(info.Username || "")
                            setSagePassword(info.Password || "")
                        } break
                        case "Labour net": {
                            setLabourNetUsername(info.Username || "")
                            setLabourNetPassword(info.Password || "")
                            setLabourNetPayrollRegNo(info.PayrollRegNo || "")
                            setLabourNetInstance(info.Instance || "")
                        } break
                        case "Pay space": {
                            setPaySpaceApiKey(info.ApiKey || "")
                            setPaySpaceClientId(info.ClientId || "")
                            setPaySpaceScope(info.Scope || "")
                            setPaySpaceCompanyId(info.CompanyId || "")
                        } break
                        default:
                            break;
                    }
                }
                commonStore.setLoading(false)
            })
        }
        forceUpdate()
    }, [])

    const [file, setFile] = useState(null);

    const handleVerifyPayroll = async () => {
        try {
            let verify = checkCompletedFields()
            if (!verify) {
                alert("Complete all fields")
                return
            }
            else {
                commonStore.setLoading(true)
                let obj = {}
                let payObj = {}
                console.log(commonStore.client)
                if (commonStore.client) {
                    if (commonStore.client.PayrollInformation) {
                        payObj = commonStore.client.PayrollInformation
                    }
                }

                switch (clicked) {
                    case "simple": {
                        obj.Provider = "Simple pay"
                        obj.ApiKey = simplePayApiKey
                        obj.ClientId = simplePayClientId
                        obj.CompanyName = commonStore.client.Name
                        obj.PayrollInformation = payObj

                        let res = await commonStore.getSimplePayClients(obj)
                        console.log(res)
                        if (res !== 'error') {
                            setSimplePayClients(res.clients)
                            setDisplaySimplePayClients(true)
                            setVisible('displaySimplePayClients')
                            forceUpdate()
                        }
                        else {
                            commonStore.setSnackBar("error", "Error occured, please try again")
                        }
                        commonStore.setLoading(false)


                    } break
                    case "sage": {
                        obj.Provider = "Sage"
                        obj.ApiKey = sageApiKey
                        obj.Username = sageUsername
                        obj.Password = sagePassword

                        let res = await commonStore.linkSage(obj, commonStore.client._id)
                        console.log(res)
                        if (res.initialPayrollCount) {
                            commonStore.setClient(res.updatedClient)
                            let results = {
                                statusInfoAvailable: false,
                                initialPayrollCount: res.initialPayrollCount,
                                clientInitialEmployeeCount: res.clientInitialEmployeeCount,
                                foundAndTerminated: res.foundAndTerminated.length,
                                foundAndEmployed: res.foundAndEmployed.length,
                                notFoundAndEmployed: res.notFoundAndEmployed.length,
                                notfoundAndTerminated: res.notfoundAndTerminated.length,
                                failedImports: res.failedImports.length
                            }
                            setImportResults(results)
                            setDisplayImportResults(true)
                            setVisible('displayImportResults')
                            forceUpdate()
                            commonStore.setLoading(false)
                        }
                        else {
                            commonStore.setLoading(false)
                            commonStore.setSnackBar("error", "Error retrieving employees")
                        }
                        commonStore.setLoading(false)

                    } break
                    case "labour": {
                        obj.Provider = "Labour net"
                        obj.Username = labourNetUsername
                        obj.Password = labourNetPassword
                        obj.Instance = labourNetInstance
                        obj.InterfaceCode = labourNetInterfaceCode
                        obj.PayrollRegNo = labourNetPayrollRegNo

                        let res = await commonStore.getLabournetClients(obj)
                        console.log(res)
                        if (res !== 'error') {
                            setLabournetClients(res.clients)
                            setVisible('displayLabournetClients')
                            forceUpdate()
                        }
                        else {
                            commonStore.setSnackBar("error", "Error occured, please try again")
                        }
                        commonStore.setLoading(false)


                    } break
                    case "payspace": {
                        obj.Provider = "Pay space"
                        obj.ApiKey = paySpaceApiKey
                        obj.ClientId = paySpaceClientId
                        obj.Scope = paySpaceScope

                        let res = await commonStore.getPayspaceClients(obj)
                        console.log(res)
                        if (res !== 'error') {
                            setPaySpaceClients(res.clients)
                            setDisplayPayspaceClients(true)
                            setVisible('displayPayspaceClients')
                            forceUpdate()
                        }
                        else {
                            commonStore.setSnackBar("error", "Error occured, please try again")
                        }
                        commonStore.setLoading(false)

                    } break
                    default: {
                        break
                    }
                }

            }
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }


    }

    const handleOnPaySpaceClientClick = (comp) => {
        setSelectedPayspaceClient(comp)
        setConfirmPayspaceClient(true)
        forceUpdate()
    }

    const handleOnLabournetClientClick = (comp) => {
        setSelectedLabournetClient(comp)
        setConfirmLabournetClient(true)
        forceUpdate()
    }

    const handleOnSimplePayClientClick = (comp) => {
        setSelectedSimplePayClient(comp)
        setConfirmSimplePayClient(true)
        forceUpdate()
    }

    const handleLinkPayspace = async () => {
        try {
            commonStore.setLoading(true)

            let obj = {}
            let payObj = {}
            if (commonStore.client) {
                if (commonStore.client.PayrollInformation) {
                    payObj = commonStore.client.PayrollInformation
                }
            }

            obj.Provider = "Pay space"
            obj.ApiKey = paySpaceApiKey
            obj.ClientId = paySpaceClientId
            obj.Scope = paySpaceScope
            obj.CompanyId = selectedPayspaceClient.company_id
            obj.CompanyCode = selectedPayspaceClient.company_code
            obj.PayrollInformation = payObj

            let res = await commonStore.linkPayspace(obj, commonStore.client._id)
            console.log(res)
            if (res.initialPayrollCount) {
                commonStore.setClient(res.updatedClient)
                let results = {
                    statusInfoAvailable: true,
                    initialPayrollCount: res.initialPayrollCount,
                    clientInitialEmployeeCount: res.clientInitialEmployeeCount,
                    foundAndTerminated: res.foundAndTerminated.length,
                    foundAndEmployed: res.foundAndEmployed.length,
                    notFoundAndEmployed: res.notFoundAndEmployed.length,
                    notfoundAndTerminated: res.notfoundAndTerminated.length,
                    failedImports: res.failedImports.length
                }
                setImportResults(results)
                setDisplayImportResults(true)
                setVisible('displayImportResults')
                setDisplayPayspaceClients(false)
                forceUpdate()
            }
            commonStore.setLoading(false)
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }


    }

    const handleLinkSimplePay = async () => {
        try {
            commonStore.setLoading(true)

            let obj = {}
            let payObj = {}
            if (commonStore.client) {
                if (commonStore.client.PayrollInformation) {
                    payObj = commonStore.client.PayrollInformation
                }
            }

            obj.Provider = "Simple pay"
            obj.ApiKey = simplePayApiKey
            obj.ClientId = selectedSimplePayClient.id
            obj.PayrollInformation = payObj

            let res = await commonStore.linkSimplepay(obj, commonStore.client._id)
            console.log(res)
            if (res.initialPayrollCount) {
                commonStore.setClient(res.updatedClient)
                let results = {
                    statusInfoAvailable: true,
                    initialPayrollCount: res.initialPayrollCount,
                    clientInitialEmployeeCount: res.clientInitialEmployeeCount,
                    foundAndTerminated: res.foundAndTerminated.length,
                    foundAndEmployed: res.foundAndEmployed.length,
                    notFoundAndEmployed: res.notFoundAndEmployed.length,
                    notfoundAndTerminated: res.notfoundAndTerminated.length,
                    failedImports: res.failedImports.length
                }
                setImportResults(results)
                setDisplayImportResults(true)
                setVisible('displayImportResults')
                setDisplaySimplePayClients(false)
                forceUpdate()
                commonStore.setLoading(false)
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error retrieving employees")

            }
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }


    }

    const handleLinkLabournet = async () => {
        try {
            commonStore.setLoading(true)

            let obj = {}
            let payObj = {}
            if (commonStore.client) {
                if (commonStore.client.PayrollInformation) {
                    payObj = commonStore.client.PayrollInformation
                }
            }

            obj.Provider = "Labour net"
            obj.Username = labourNetUsername
            obj.Password = labourNetPassword
            obj.Instance = labourNetInstance
            obj.PayrollRegNo = selectedLabournetClient.payrollRegNo
            obj.InterfaceCode = "Level F"
            obj.PayrollInformation = payObj

            let res = await commonStore.linkLabournet(obj, commonStore.client._id)
            console.log(res)
            if (res.initialPayrollCount) {
                commonStore.setClient(res.updatedClient)
                let results = {
                    statusInfoAvailable: false,
                    initialPayrollCount: res.initialPayrollCount,
                    clientInitialEmployeeCount: res.clientInitialEmployeeCount,
                    foundAndTerminated: res.foundAndTerminated.length,
                    foundAndEmployed: res.foundAndEmployed.length,
                    notFoundAndEmployed: res.notFoundAndEmployed.length,
                    notfoundAndTerminated: res.notfoundAndTerminated.length,
                    failedImports: res.failedImports.length
                }
                setImportResults(results)
                setDisplayImportResults(true)
                setVisible('displayImportResults')
                setDisplayLabournetClients(false)
                forceUpdate()
                commonStore.setLoading(false)
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error retrieving employees")
            }
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }


    }

    if (isMobile()) {
        return (
            <div>
                <Navbar />
                <main style={{ alignItems: 'center' }}>
                    <div className='confirm-employee-benefits-progress-container'>
                        <img src={TCsPayroll2} />
                    </div>
                    <br />

                    <header className='employee-benefits-assessment-submit-header'>Connect your payroll</header>
                    <div style={{ width: '75%' }}>
                        <p className='employee-benefits-assessment-submit-subheader'>
                            Complete the details below to connect your payroll to Level. This step can also be completed later.
                        </p>
                        <br />

                        <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                        <br />
                    </div>
                    <div style={{ width: '75%' }}>
                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                            Select your payroll provider.
                        </p>
                        <br />
                        <div className='connect-payroll-providers-container'>
                            <div onClick={() => handleOnProviderClick('sage')}
                                className={`connect-payroll-providers-card${clicked === 'sage' ? ' active' : ''}`}>
                                {clicked === 'sage' &&
                                    <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                }
                                <div style={{ position: 'absolute' }}>
                                    <img className='connect-payroll-providers-card-logo' src={SageIcon} />
                                    <p style={{ fontWeight: '700', textAlign: 'center' }}>Sage 300</p>
                                </div>
                            </div>

                            <div onClick={() => handleOnProviderClick('simple')}
                                className={`connect-payroll-providers-card${clicked === 'simple' ? ' active' : ''}`}>
                                {clicked === 'simple' &&
                                    <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                }
                                <div style={{ position: 'absolute' }}>
                                    <img className='connect-payroll-providers-card-logo' src={SimplePayIcon} />
                                    <p style={{ fontWeight: '700', textAlign: 'center' }}>Simple pay</p>
                                </div>
                            </div>

                            <div onClick={() => handleOnProviderClick('labour')}
                                className={`connect-payroll-providers-card${clicked === 'labour' ? ' active' : ''}`}>
                                {clicked === 'labour' &&
                                    <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                }
                                <div style={{ position: 'absolute' }}>
                                    <img className='connect-payroll-providers-card-logo' src={LabourNetIcon} />
                                    <p style={{ fontWeight: '700', textAlign: 'center' }}>Labournet</p>
                                </div>
                            </div>

                            <div onClick={() => handleOnProviderClick('payspace')}
                                className={`connect-payroll-providers-card${clicked === 'payspace' ? ' active' : ''}`}>
                                {clicked === 'payspace' &&
                                    <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                }
                                <div style={{ position: 'absolute' }}>
                                    <img className='connect-payroll-providers-card-logo' src={PaySpaceIcon} />
                                    <p style={{ fontWeight: '700', textAlign: 'center' }}>Pay space</p>
                                </div>
                            </div>

                        </div>
                        <br />
                        {clicked === 'sage' &&
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                    Complete the fields below.
                                </p>
                                <Input value={sageApiKey}
                                    onChange={(e) => handleSageApiKeyOnChange(e.target.value)}
                                    placeholder={'API key'} />
                                <Input value={sageUsername}
                                    onChange={(e) => handleSageUsernameOnChange(e.target.value)}
                                    placeholder={'Username'} />
                                <Input value={sagePassword}
                                    onChange={(e) => handleSagepasswordOnChange(e.target.value)} inputType='password'
                                    placeholder={'Password'} />
                            </div>
                        }

                        {clicked === 'simple' &&
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                    Complete the fields below.
                                </p>
                                <Input value={simplePayApiKey}
                                    onChange={(e) => handleSimplePayKeyOnChange(e.target.value)}
                                    placeholder={'API key'} />
                                {/* <Input value={simplePayClientId}
                                    onChange={(e) => handleSimplePayUsernameOnChange(e.target.value)}
                                    placeholder={'Client ID'} /> */}
                            </div>
                        }

                        {clicked === 'labour' &&
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                    Complete the fields below.
                                </p>
                                <Input value={labourNetUsername}
                                    onChange={(e) => handleLabourNetUsernameOnChange(e.target.value)}
                                    placeholder={'Username'} />
                                <Input value={labourNetPassword}
                                    onChange={(e) => handleLabourNetPasswordOnChange(e.target.value)}
                                    placeholder={'Password'} />
                                <Input value={labourNetInstance}
                                    onChange={(e) => handleLabourNetInstanceOnChange(e.target.value)}
                                    placeholder={'Instance'} />
                            </div>
                        }

                        {clicked === 'payspace' &&
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                    Complete the fields below.
                                </p>
                                <Input value={paySpaceApiKey}
                                    onChange={(e) => handlePaySpaceKeyOnChange(e.target.value)}
                                    placeholder={'API key'} />
                                <Input value={paySpaceClientId}
                                    onChange={(e) => handlePayClientIdOnChange(e.target.value)}
                                    placeholder={'Client ID'} />
                                <Input value={paySpaceScope}
                                    onChange={(e) => handlePayScopeOnChange(e.target.value)}
                                    placeholder={'Scope'} />
                                {/* <Input value={paySpaceCompanyId}
                                    onChange={(e) => handlePayCompanyIdOnChange(e.target.value)}
                                    placeholder={'Company ID'} /> */}
                            </div>
                        }

                        <br />
                        <br />
                        <div className='two-buttons-container-pri-purinv' style={{ display: 'flex', alignItems: 'center' }}>
                            <Button type='purpleInvis'>Back</Button>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <div onClick={() => navigate('/client/home/Overview')} style={{ color: '#5D0070', fontWeight: '500', cursor: 'pointer' }}>Skip</div>
                                {nextButtonEnabled && <Button>Next</Button>}
                            </div>

                        </div>
                    </div>



                </main>
            </div>
        );
    } else {
        return (
            <div>
                <Navbar />
                <main style={{ alignItems: 'center' }}>
                    {!displayImportResults && <div className='confirm-employee-benefits-progress-container'>
                        <img src={TCsPayroll2} />
                    </div>}
                    {displayImportResults && <div className='confirm-employee-benefits-progress-container'>
                        <img src={TCsPayroll3} />
                    </div>}
                    {visible === "credentials" &&
                        <>
                            <header className='employee-benefits-assessment-submit-header'>Connect your payroll</header>
                            <div style={{ width: '75%' }}>
                                <p className='employee-benefits-assessment-submit-subheader'>
                                    Complete the details below to connect your payroll to Level. This step can also be completed later.
                                </p>
                                <br />

                                <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                <br />
                            </div>
                            <div style={{ width: '75%' }}>
                                <div className='flex flex-row justify-evenly items-center'>
                                    <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                        Select your payroll provider.
                                    </p>
                                    <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                        OR
                                    </p>
                                    <div>
                                        <Button onClick={() => navigate('/client/employee/upload')}>Manually upload</Button>
                                    </div>
                                </div>

                                <br />
                                <div className='connect-payroll-providers-container'>
                                    <div onClick={() => handleOnProviderClick('sage')}
                                        className={`connect-payroll-providers-card${clicked === 'sage' ? ' active' : ''}`}>
                                        {clicked === 'sage' &&
                                            <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                        }
                                        <div style={{ position: 'absolute' }}>
                                            <img className='connect-payroll-providers-card-logo' src={SageIcon} />
                                            <p style={{ fontWeight: '700', textAlign: 'center' }}>Sage 300</p>
                                        </div>
                                    </div>

                                    <div onClick={() => handleOnProviderClick('simple')}
                                        className={`connect-payroll-providers-card${clicked === 'simple' ? ' active' : ''}`}>
                                        {clicked === 'simple' &&
                                            <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                        }
                                        <div style={{ position: 'absolute' }}>
                                            <img className='connect-payroll-providers-card-logo' src={SimplePayIcon} />
                                            <p style={{ fontWeight: '700', textAlign: 'center' }}>Simple pay</p>
                                        </div>
                                    </div>

                                    <div onClick={() => handleOnProviderClick('labour')}
                                        className={`connect-payroll-providers-card${clicked === 'labour' ? ' active' : ''}`}>
                                        {clicked === 'labour' &&
                                            <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                        }
                                        <div style={{ position: 'absolute' }}>
                                            <img className='connect-payroll-providers-card-logo' src={LabourNetIcon} />
                                            <p style={{ fontWeight: '700', textAlign: 'center' }}>Labournet</p>
                                        </div>
                                    </div>

                                    <div onClick={() => handleOnProviderClick('payspace')}
                                        className={`connect-payroll-providers-card${clicked === 'payspace' ? ' active' : ''}`}>
                                        {clicked === 'payspace' &&
                                            <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                        }
                                        <div style={{ position: 'absolute' }}>
                                            <img className='connect-payroll-providers-card-logo' src={PaySpaceIcon} />
                                            <p style={{ fontWeight: '700', textAlign: 'center' }}>Pay space</p>
                                        </div>
                                    </div>

                                </div>
                                <br />
                                <br />
                                <br />

                                {clicked === 'sage' &&
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            Complete the fields below.
                                        </p>
                                        <Input value={sageApiKey}
                                            onChange={(e) => handleSageApiKeyOnChange(e.target.value)}
                                            placeholder={'API key'} />
                                        <Input value={sageUsername}
                                            onChange={(e) => handleSageUsernameOnChange(e.target.value)}
                                            placeholder={'Username'} />
                                        <Input value={sagePassword}
                                            onChange={(e) => handleSagepasswordOnChange(e.target.value)} inputType='password'
                                            placeholder={'Password'} />
                                    </div>
                                }

                                {clicked === 'simple' &&
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            Complete the fields below.
                                        </p>
                                        <Input value={simplePayApiKey}
                                            onChange={(e) => handleSimplePayKeyOnChange(e.target.value)}
                                            placeholder={'API key'} />
                                        {/* <Input value={simplePayClientId}
                                            onChange={(e) => handleSimplePayUsernameOnChange(e.target.value)}
                                            placeholder={'Client ID'} /> */}

                                    </div>
                                }

                                {clicked === 'labour' &&
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            Complete the fields below.
                                        </p>
                                        <Input value={labourNetUsername}
                                            onChange={(e) => handleLabourNetUsernameOnChange(e.target.value)}
                                            placeholder={'Username'} />
                                        <Input value={labourNetPassword}
                                            onChange={(e) => handleLabourNetPasswordOnChange(e.target.value)}
                                            placeholder={'Password'} />
                                        <Input value={labourNetInstance}
                                            onChange={(e) => handleLabourNetInstanceOnChange(e.target.value)}
                                            placeholder={'Instance'} />
                                    </div>
                                }

                                {clicked === 'payspace' &&
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            Complete the fields below.
                                        </p>
                                        <Input value={paySpaceApiKey}
                                            onChange={(e) => handlePaySpaceKeyOnChange(e.target.value)}
                                            placeholder={'API key'} />
                                        <Input value={paySpaceClientId}
                                            onChange={(e) => handlePayClientIdOnChange(e.target.value)}
                                            placeholder={'Client ID'} />
                                        <Input value={paySpaceScope}
                                            onChange={(e) => handlePayScopeOnChange(e.target.value)}
                                            placeholder={'Scope'} />
                                    </div>
                                }
                                <br />
                                <br />
                                <div className='two-buttons-container-pri-purinv' style={{ alignItems: 'center' }}>
                                    <div onClick={() => navigate('/client/home/Overview')} style={{ color: '#5D0070', fontWeight: '500', cursor: 'pointer' }}>
                                        Skip
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                        <Button onClick={handleVerifyPayroll}>Next</Button>
                                    </div>

                                </div>
                            </div>
                        </>
                    }
                    {visible === "displayPayspaceClients" &&
                        <main style={{ width: '75%' }}>
                            <header className='employee-benefits-assessment-submit-header'>Choose company</header>
                            <div>
                                <p className='employee-benefits-assessment-submit-subheader'>
                                    These are the companies linked to your payroll, please select the correct payroll representing your company
                                </p>
                                <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                {payspaceClients.map((group) => (
                                    <div key={group.group_description}>
                                        <p style={{ fontSize: '22px', textAlign: 'left' }}
                                            className='employee-benefits-assessment-submit-header'>
                                            {`Group name: ${group.group_description}`}
                                        </p>
                                        {group.companies.map((comp) => (
                                            <div style={{ display: 'flex', gap: '20px' }}>
                                                <div key={comp.company_name}
                                                    onClick={() => handleOnPaySpaceClientClick(comp)}
                                                    className={`payroll-client-card${selectedPayspaceClient.company_id === comp.company_id ? ' active' : ''}`}
                                                >
                                                    {`${comp.company_name}`}
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                ))}
                                <br />
                                {confirmPayspaceClient && <Button onClick={handleLinkPayspace}>Confirm</Button>}
                            </div>
                        </main>
                    }
                    {visible === "displayLabournetClients" &&
                        <div style={{ width: '75%' }}>
                            <header className='employee-benefits-assessment-submit-header'>Choose company</header>
                            <div>
                                <p className='employee-benefits-assessment-submit-subheader'>
                                    These are the companies linked to your payroll, please select the correct payroll representing your company
                                </p>
                                <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                <br />
                                <div style={{ display: 'flex', gap: '20px' }}>
                                    {labournetClients.map((comp) => (

                                        <div key={comp.payrollRegNo}
                                            onClick={() => handleOnLabournetClientClick(comp)}
                                            className={`payroll-client-card${selectedLabournetClient.payrollRegNo === comp.payrollRegNo ? ' active' : ''}`}
                                        >
                                            {`${comp.payrollName}`}
                                        </div>


                                    ))}
                                </div>
                                <br />
                                {confirmLabournetClient && <Button onClick={handleLinkLabournet}>Confirm</Button>}
                            </div>
                        </div>
                    }
                    {visible === "displaySimplePayClients" &&
                        <div style={{ width: '75%' }}>
                            <header className='employee-benefits-assessment-submit-header'>Choose company</header>
                            <div>
                                <p className='employee-benefits-assessment-submit-subheader'>
                                    These are the companies linked to your payroll, please select the correct payroll representing your company
                                </p>
                                <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                <br />
                                <div style={{ display: 'flex', gap: '20px' }}>
                                {simplePayClients.map((comp) => (
                                    
                                        <div key={comp.client.id}
                                            onClick={() => handleOnSimplePayClientClick(comp.client)}
                                            className={`payroll-client-card${selectedSimplePayClient.id === comp.client.id ? ' active' : ''}`}
                                        >
                                            {`${comp.client.name}`}
                                        </div>
                                    

                                ))}
                                </div>
                                <br />
                                {confirmSimplePayClient && <Button onClick={handleLinkSimplePay}>Confirm</Button>}
                            </div>
                        </div>
                    }

                    {visible === 'displayImportResults' &&
                        <div style={{ width: '75%' }}>
                            <header className='employee-benefits-assessment-submit-header'>Payroll connected!</header>
                            <div >
                                <p className='employee-benefits-assessment-submit-subheader'>
                                    See results
                                </p>
                                <br />

                                <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                <br />
                                {importResults.statusInfoAvailable ?
                                    <>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Initial employees: ${importResults.clientInitialEmployeeCount}`}
                                        </p>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Employees found in payroll: ${importResults.initialPayrollCount}`}
                                        </p>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`New employees added: ${importResults.notFoundAndEmployed}`}
                                        </p>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Updated employees: ${importResults.foundAndEmployed}`}
                                        </p>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Terminated employees (Found in Level): ${importResults.foundAndTerminated}`}
                                        </p>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Terminated employees (Not found in Level): ${importResults.notfoundAndTerminated}`}
                                        </p>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Failed imports: ${importResults.failedImports}`}
                                        </p>
                                    </> :
                                    <>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Initial employees: ${importResults.clientInitialEmployeeCount}`}
                                        </p>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Employees found in payroll: ${importResults.initialPayrollCount}`}
                                        </p>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`New employees added: ${importResults.notFoundAndEmployed}`}
                                        </p>
                                        <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Failed imports: ${importResults.failedImports}`}
                                        </p>
                                    </>
                                }
                                <br />
                                <Button onClick={() => navigate('/client/home/Overview', { state: { refresh: true } })}>Go to dashboard</Button>
                            </div>
                        </div>
                    }

                </main>
            </div >
        );
    }
})

export default ConnectPayroll
