import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CustomInput = ({
  type = "default",
  width = '100%',
  textColor = '#010143',
  borderRadius = '16px',
  fontSize = '17px',
  padding = '16px',
  fontFamily = 'Inter',
  fontWeight = '400',
  fontStyle = 'normal',
  lineHeight = 'normal',
  placeholder = null,
  height = null,
  onSubmit,
  onChange,
  onKeyDown,
  onEnter,
  value = '',
  inputType = '',
  autoComplete = 'off'
}) => {
  const [isActive, setIsActive] = useState(false);

  const inputStyle = {
    color: textColor,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    lineHeight: lineHeight,
    borderRadius: borderRadius,
    border: isActive ? '1px solid #ccc' : '1px solid #ccc',
    fontStyle: fontStyle,
    fontSize: fontSize,
    padding: padding,
    width: width,
    height: height,
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onEnter && onEnter(); // Invoke onEnter callback if provided
    }
    if (e.key === 'Backspace' && value === '') {
      e.preventDefault();
    }
    onKeyDown && onKeyDown(e);
  };

  return (
    type === 'default' ? (
      <input
        style={inputStyle}
        onMouseDown={() => setIsActive(true)}
        onMouseUp={() => setIsActive(false)}
        onClick={onSubmit}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
        type={inputType}
        autoComplete={autoComplete}
      />
    ) : (
      <textarea
        style={inputStyle}
        onMouseDown={() => setIsActive(true)}
        onMouseUp={() => setIsActive(false)}
        onClick={onSubmit}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
        rows="5" cols="5"
        autoComplete={autoComplete}
      />
    )
  );
};

CustomInput.propTypes = {
  textColor: PropTypes.string,
  borderRadius: PropTypes.string,
  fontSize: PropTypes.string,
  padding: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onEnter: PropTypes.func, // Prop type for onEnter callback
  children: PropTypes.node,
  placeholder: PropTypes.string
};

export default CustomInput;
