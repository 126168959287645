import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function DialogConfirm({ openDialogText, popUp = false,
    headerText, content = [], onSave, onClose, open, onOpen, onSaveText,
    textField, handletextFieldOnchange, textFieldVisible = false, textFieldLabel }) {

    return (
        <React.Fragment>
            {!popUp && <Button variant="outlined" onClick={onOpen}>
                {openDialogText}
            </Button>}
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {headerText}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content.map((info, index) => (
                        <Typography gutterBottom key={index}>
                            {`${info.key} : ${info.value}`}
                        </Typography>
                    ))}
                </DialogContent>
                {textFieldVisible &&
                    <>
                        <br />
                        <TextField
                            style={{ width: '90%', margin: 'auto' }}
                            id="outlined-multiline-flexible"
                            label={textFieldLabel}
                            multiline
                            maxRows={8}
                            value={textField}
                            onChange={(e) => handletextFieldOnchange(e)}
                        />
                    </>
                }
                <DialogActions>
                    <Button autoFocus onClick={onSave}>
                        {onSaveText}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
