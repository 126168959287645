import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store'
import { isMobile } from 'is-mobile';
import { SearchIcon } from '../../../assets/icons/index'
import AdminReportingHome from './home'
import AdminReportingTransactions from './transactions';
import AdminReportingInvoices from './invoices'
import AdminReportingLogs from './logs'

import './styles.scss';
import { common } from '@mui/material/colors';
import { Alert, TextField } from '@mui/material';

const AdminReporting = observer(() => {
    const navigate = useNavigate();
    const commonStore = useStore();
    const [filter, setFilter] = useState('home');
    const [reporting, setReporting] = useState(null); // Change to `null` initially to check loading state

    const loadAllData = async () => {
        commonStore.setLoading(true);
        try {
            const [clients, users] = await Promise.all([
                commonStore.getAllClients(),
                commonStore.getAllUsersNoPop()
            ]);

            setReporting({ clients, users });
        } catch (error) {
            commonStore.setSnackBar("error", "Error loading reporting data");
            console.error("Error loading data:", error);
            navigate(-1);
        } finally {
            commonStore.setLoading(false);
        }
    };

    useEffect(() => {
        loadAllData();
    }, []);

    const handleSelectFilter = (e) => setFilter(e.currentTarget.id);

    return (
        <main style={{ background: "#F4F5FA", width: '100%' }}>
            <header className='admin-dash-option-header'>Admin Reporting</header>
            <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>

                <div className='flex gap-1 margin-2 items-center'>
                    <div id="home" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "home" ? 'selected' : null}`}>
                        Home
                    </div>
                    <div id="transactions" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "transactions" ? 'selected' : null}`}>
                        Transactions
                    </div>
                    <div id="invoices" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "invoices" ? 'selected' : null}`}>
                        Invoices
                    </div>
                    <div id="logs" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "logs" ? 'selected' : null}`}>
                        Logs
                    </div>
                </div>

                {/* Only render components once `reporting` data is fully loaded */}
                {reporting ? (
                    <>
                        {filter === "home" && (
                            <AdminReportingHome data={reporting} setVisible={handleSelectFilter} refresh={loadAllData} />
                        )}
                        {filter === "transactions" && (
                            <AdminReportingTransactions data={reporting} setVisible={handleSelectFilter} refresh={loadAllData} />
                        )}
                        {filter === "invoices" && (
                            <AdminReportingInvoices data={reporting} setVisible={handleSelectFilter} refresh={loadAllData} />
                        )}
                        {filter === "logs" && (
                            <AdminReportingLogs data={reporting} setVisible={handleSelectFilter} refresh={loadAllData} />
                        )}
                    </>
                ) : (
                    <p>Loading data...</p> // Placeholder while data loads
                )}
            </main>
        </main>
    );
});

export default AdminReporting;


