import React, { useState } from 'react';
import { LevelBlueWhiteLogo, DropDownArrowDown, GreyProfileIcon, LogoutIconWhite, ProfileIconWhite } from '../../assets/icons';
import { useStore } from '../../stores/Store';
import './styles.scss'
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'is-mobile';

const Navbar = () => {

    const commonStore = useStore()
    const navigate = useNavigate()
    const [collapsed, setCollapsed] = useState(false)

    const handleDropDown = () => {
        setCollapsed(!collapsed)
    }
    const handleLogout = () => {
        commonStore.logout()
        navigate('/signin')
    }
    const handleToProfile = () => {
        navigate('/profile')
    }

    const goHome = () => {
        if (!commonStore.user?.Role) {
            return
        }
        if (commonStore.user.Role === "5d8e0b079f51d42bbece9201") {
            navigate('/admin/home/Overview')
        }
        else if (commonStore.user.Role === "5d8e0b079f51d42bbece9202") {
            navigate('/employee/home')
        }
        else if (commonStore.user.Role === "5d8e0b079f51d42bbece9002") {
            navigate('/employee/home')
        }
        else if (commonStore.user.Role === "62bd55b7c5793941427c702d") {
            navigate('/client/home/Overview')
        }
        else {
            navigate('/*')
        }
    }

    return (
        <div style={{ position: 'sticky', top: 0, background: '#ffffff', zIndex: 10 }} className='admin-dash-topbar'>
            <img src={LevelBlueWhiteLogo} className='cursor-pointer' onClick={goHome} />
            <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                <div className='admin-dash-sidebar-text'>
                    {`${commonStore.user.FirstName} ${commonStore.user.LastName}`}
                </div>
                <img src={DropDownArrowDown} style={{ cursor: 'pointer' }} onClick={handleDropDown} />
                <img src={GreyProfileIcon} />
                {collapsed &&
                    <div className='navbar-dropdown-section'>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '7px', alignItems: 'center' }}>
                            <div><img src={ProfileIconWhite} /></div>
                            <div className='admin-dash-sidebar-text' style={{ color: 'white', fontSize: '13px' }}
                                onClick={handleToProfile}>View profile</div>
                        </div>
                        <hr />
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '7px' }}>
                            <div><img src={LogoutIconWhite} /></div>
                            <div className='admin-dash-sidebar-text' style={{ color: 'white', fontSize: '13px' }}
                                onClick={handleLogout}>Logout</div>
                        </div>
                    </div>}
            </div>

        </div>
    );
};

export default Navbar;
