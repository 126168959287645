import React, { useState, useEffect, useRef } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { LevelBlueWhiteLogo, BenefitsReportHeaderImage } from '../../assets/icons';

import { toPng } from 'html-to-image';
import DoughnutChart from '../charts/DoughnutGraph';

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF'
    },
    logo: {
        width: 100
    },
    headerImages: {
        height: 120
    },
    topSection: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: '#010143',
        gap: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    main: {
        padding: 30,
        backgroundColor: '#FFFFFF',
        gap: 10
    },
    whiteHeaderTextLarge: {
        color: '#FFFFFF',
        fontWeight: "bold",
        fontSize: 26,
        //fontFamily: "Open Sans"
    },
    whiteHeaderTextMedium: {
        color: '#FFFFFF',
        fontWeight: "bold",
        fontSize: 20,
        fontFamily: "Oswald"
    },
    whiteHeaderTextSmall: {
        color: '#FFFFFF',
        fontWeight: "bold",
        fontSize: 13,
        //fontFamily: "Open Sans"
    },
    whiteHeaderTextSmaller: {
        color: '#FFFFFF',
        fontSize: 9,

    },
    whiteHeaderTextSmallerBottomBorder: {
        color: '#FFFFFF',
        fontSize: 9,
        borderBottom: '1px solid #ffffff',
        //fontFamily: "Open Sans"
    },
    blackHeaderTextLarge: {
        color: 'black',
        fontWeight: "bold",
        fontSize: 26,
        //fontFamily: "Open Sans"
    },
    blackHeaderTextMedium: {
        color: 'black',
        fontWeight: "bold",
        fontSize: 20,
        fontFamily: "Oswald",
        textAlign: 'center'
    },
    blackHeaderTextMediumUncentered: {
        color: 'black',
        fontWeight: "bold",
        fontSize: 20,
        fontFamily: "Oswald",
    },
    blackHeaderTextSmall: {
        width: '100%',
        color: 'black',
        fontWeight: "bold",
        fontSize: 13,
        //fontFamily: "Open Sans"
    },
    copyContainer: {
        borderRadius: 5,
        border: "0.2px solid rgba(0, 0, 0, 0.09)",
        padding: 15,
    },
    copyContainerFixed: {
        borderRadius: 5,
        border: "0.2px solid rgba(0, 0, 0, 0.09)",
        padding: 15,
        width: 300
    },
    copyContainerDemographics: {
        width: '50%',
        borderRadius: 20,
        border: "1px solid black",
        padding: 15,
    },
    copyContainerBlue: {
        width: '100%',
        borderRadius: 10,
        border: "1px solid black",
        padding: 15,
        backgroundColor: '#010143'
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        alignItems: 'center',
        gap: 10
    },
    doughnutGraphTotalPerc: {
        width: 150,
        height: 150
    },
    doughnutGraphDemographics: {
        width: 200,
        height: 200
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: 0
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    tableRowItem: {
        width: '50%',
        border: '0.5px solid #ffffff',
        padding: 5
    }

});

const BenefitsReportPdf = ({ client, report, graphs, wantDesired, findingsRecs, headerImage }) => {
    const [displayWantDesired, setDisplayWantDesired] = useState(false)
    const [displayFindingsRecs, setDisplayFindingsRecs] = useState(false)

    useEffect(() => {
        if (wantDesired) {
            if (wantDesired && Object.keys(wantDesired).length > 0) {
                setDisplayWantDesired(true)
            }
        }
        if (findingsRecs) {
            if (findingsRecs && Object.keys(wantDesired).length > 0) {
                setDisplayFindingsRecs(true)
            }
        }
    }, [wantDesired, findingsRecs])



    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.topSection}>
                        <View>
                            <Image src="https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png" style={styles.logo} />
                            <Text style={styles.whiteHeaderTextMedium}>
                                {`${client.Name} Benefits Report`}
                            </Text>
                            <Text style={styles.whiteHeaderTextSmall}>
                                Executive summary
                            </Text>
                        </View>
                        {/* <Image src={headerImage} style={styles.headerImages} /> */}
                    </View>
                    <View style={styles.main}>
                        <Text style={styles.blackHeaderTextMedium}>
                            Employer vs Employee score: A comparative view
                        </Text>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 25, width: '60%' }}>
                                <View style={styles.copyContainer}>
                                    <Text style={styles.blackHeaderTextSmall}>
                                        In evaluating the holistic well-being of an organisation, two pivotal metrics emerge: the Overall Employer Score and the Overall Employees Score. These scores serve as vital indicators, shedding light on the collective health landscape within a workplace. The Scores averages responses across four pillars: Physical, Emotional, Financial, and Social Health, reflecting both the Employer’s and Employee’s perceptions of organisational well-being. Comparing these scores reveals alignment or misalignment
                                        between organisational and individual well-being, guiding
                                        targeted interventions for a healthier workplace.
                                    </Text>
                                </View>
                                <View>
                                    <Text style={styles.blackHeaderTextMedium}>
                                        Pillar scores
                                    </Text>
                                    <View>
                                        {graphs.pillarScores && <Image src={graphs.pillarScores} />}
                                    </View>
                                </View>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 25, width: '30%' }}>
                                <View style={styles.columnContainer}>
                                    <Text style={styles.blackHeaderTextMedium}>
                                        Employer overall score
                                    </Text>
                                    <View>
                                        {graphs.employerTotalPerc && <Image style={styles.doughnutGraphTotalPerc} src={graphs.employerTotalPerc} />}
                                    </View>
                                    <Text style={styles.blackHeaderTextMedium}>
                                        Employee overall score
                                    </Text>
                                    <View>
                                        {graphs.employeeTotalPerc && <Image style={styles.doughnutGraphTotalPerc} src={graphs.employeeTotalPerc} />}
                                    </View>
                                </View>

                            </div>
                        </div>
                        <View style={styles.copyContainer}>
                            <Text style={styles.blackHeaderTextSmall}>
                                This graph provides a comparative analysis of the employee and organisational scores across the four pillars: Physical, Emotional, Financial, and Social Health. Each pillar's percentage score illustrates the perspective of both employees and the  organisation, offering insights into alignment or divergence in perception regarding well-being priorities within the workplace.
                            </Text>
                        </View>
                    </View>
                </Page>

                <Page size="A4" style={styles.page}>
                    <View style={styles.topSection}>
                        <View>
                            <Image src="https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png" style={styles.logo} />
                            <Text style={styles.whiteHeaderTextMedium}>
                                {`${client.Name} Benefits Report`}
                            </Text>
                            <Text style={styles.whiteHeaderTextSmall}>
                                Executive summary
                            </Text>
                        </View>
                        {/* <Image src={headerImage} style={styles.headerImages} /> */}
                    </View>
                    <View style={styles.main}>
                        <Text style={styles.blackHeaderTextMedium}>
                            Demographic data
                        </Text>
                        <View style={{ height: '65%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center' }}>
                            <View>
                                <View style={{ width: '100%' }}>
                                    <View>
                                        {graphs.avgGender && <Image style={styles.doughnutGraphDemographics} src={graphs.avgGender} />}
                                    </View>
                                </View>
                                <br />
                                <br />
                                <View style={{ width: '100%' }}>
                                    <View>
                                        {graphs.avgAge && <Image style={styles.doughnutGraphDemographics} src={graphs.avgAge} />}
                                    </View>
                                </View>
                            </View>
                            <View>
                                <View style={{ width: '100%' }}>
                                    <View>
                                        {graphs.avgEmploymentLength && <Image style={styles.doughnutGraphDemographics} src={graphs.avgEmploymentLength} />}
                                    </View>
                                </View>
                                <br />
                                <br />
                                <View style={{ width: '100%' }}>
                                    <View>
                                        {graphs.avgWorkArrangement && <Image style={styles.doughnutGraphDemographics} src={graphs.avgWorkArrangement} />}
                                    </View>
                                </View>
                            </View>
                        </View>

                        <Text style={styles.blackHeaderTextMedium}>
                            Conclusion
                        </Text>
                        <View style={styles.copyContainerBlue}>
                            <Text style={styles.whiteHeaderTextSmall}>
                                {displayFindingsRecs && findingsRecs.demographicsConclusion}
                            </Text>
                        </View>
                    </View>
                </Page>

                <Page size="A4" style={styles.page}>
                    <View style={styles.topSection}>
                        <View>
                            <Image src="https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png" style={styles.logo} />
                            <Text style={styles.whiteHeaderTextMedium}>
                                {`${client.Name} Benefits Report`}
                            </Text>
                            <Text style={styles.whiteHeaderTextSmall}>
                                Executive summary
                            </Text>
                        </View>
                        {/* <Image src={headerImage} style={styles.headerImages} /> */}
                    </View>
                    <View style={styles.main}>
                        <Text style={styles.blackHeaderTextMedium}>
                            Physical and Emotional health
                        </Text>
                        <View style={styles.copyContainerBlue}>
                            <View style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                                <View>
                                    <Text style={styles.whiteHeaderTextMedium}>
                                        Employer
                                    </Text>
                                    <br />
                                    <View style={styles.tableContainer}>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableRowItem}>
                                                <Text style={styles.whiteHeaderTextSmall}>Benefit</Text>
                                            </View>
                                            <View style={styles.tableRowItem}>
                                                <Text style={styles.whiteHeaderTextSmall}>Offered or Wanted</Text>
                                            </View>
                                        </View>
                                        {displayWantDesired &&
                                            wantDesired.physEmo.filter((item) => {
                                                return item.employerOffered > 0 || item.employerWanted > 0
                                            }).map((ben) => {
                                                return (
                                                    <View style={styles.tableRow}>
                                                        <View style={styles.tableRowItem}>
                                                            <Text style={styles.whiteHeaderTextSmaller}>
                                                                {ben.benefit}
                                                            </Text>
                                                        </View>
                                                        <View style={styles.tableRowItem}>
                                                            {
                                                                ben.employerOffered > 0 &&
                                                                <Text style={styles.whiteHeaderTextSmaller}>Offered</Text>
                                                            }
                                                            {
                                                                ben.employerWanted > 0 &&
                                                                <Text style={styles.whiteHeaderTextSmaller}>Wanted</Text>
                                                            }
                                                        </View>
                                                    </View>

                                                )
                                            })
                                        }

                                    </View>
                                </View>
                                <View style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 15 }}>
                                    <Text style={styles.whiteHeaderTextMedium}>
                                        Employees
                                    </Text>
                                    <View style={{ border: "none" }}>
                                        {graphs.physEmoWantDesiredChart ? <Image src={graphs.physEmoWantDesiredChart} /> : null}
                                    </View>
                                </View>
                            </View>
                        </View>

                    </View>
                </Page>


                <Page size="A4" style={styles.page}>
                    <View style={styles.topSection}>
                        <View>
                            <Image src="https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png" style={styles.logo} />
                            <Text style={styles.whiteHeaderTextMedium}>
                                {`${client.Name} Benefits Report`}
                            </Text>
                            <Text style={styles.whiteHeaderTextSmall}>
                                Executive summary
                            </Text>
                        </View>
                        {/* <Image src={headerImage} style={styles.headerImages} /> */}
                    </View>
                    <View style={styles.main}>
                        <Text style={styles.blackHeaderTextMedium}>
                            Physical Health
                        </Text>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'space-evenly' }}>
                            <View style={styles.copyContainerFixed}>
                                <Text style={styles.blackHeaderTextMedium}>
                                    Findings
                                </Text>
                                {displayFindingsRecs &&
                                    <Text style={styles.blackHeaderTextSmall}>{findingsRecs.physical.recommendations}</Text>
                                }
                            </View>
                            <View style={styles.copyContainerFixed}>
                                <Text style={styles.blackHeaderTextMedium}>
                                    Recommendations
                                </Text>
                                {displayFindingsRecs &&
                                    <Text style={styles.blackHeaderTextSmall}>{findingsRecs.physical.findings}</Text>
                                }
                            </View>
                        </View>
                    </View>
                </Page>

                <Page size="A4" style={styles.page}>
                    <View style={styles.topSection}>
                        <View>
                            <Image src="https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png" style={styles.logo} />
                            <Text style={styles.whiteHeaderTextMedium}>
                                {`${client.Name} Benefits Report`}
                            </Text>
                            <Text style={styles.whiteHeaderTextSmall}>
                                Executive summary
                            </Text>
                        </View>
                        {/* <Image src={headerImage} style={styles.headerImages} /> */}
                    </View>
                    <View style={styles.main}>
                        <Text style={styles.blackHeaderTextMedium}>
                            Emotional Health
                        </Text>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'space-evenly' }}>
                            <View style={styles.copyContainerFixed}>
                                <Text style={styles.blackHeaderTextMedium}>
                                    Findings
                                </Text>
                                {displayFindingsRecs &&
                                    <Text style={styles.blackHeaderTextSmall}>{findingsRecs.emotional.findings}</Text>
                                }
                            </View>
                            <View style={styles.copyContainerFixed}>
                                <Text style={styles.blackHeaderTextMedium}>
                                    Recommendations
                                </Text>
                                {displayFindingsRecs &&
                                    <Text style={styles.blackHeaderTextSmall}>{findingsRecs.emotional.recommendations}</Text>
                                }
                            </View>
                        </View>
                    </View>
                </Page>

                <Page size="A4" style={styles.page}>
                    <View style={styles.topSection}>
                        <View>
                            <Image src="https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png" style={styles.logo} />
                            <Text style={styles.whiteHeaderTextMedium}>
                                {`${client.Name} Benefits Report`}
                            </Text>
                            <Text style={styles.whiteHeaderTextSmall}>
                                Executive summary
                            </Text>
                        </View>
                        {/* <Image src={headerImage} style={styles.headerImages} /> */}
                    </View>
                    <View style={styles.main}>
                        <Text style={styles.blackHeaderTextMedium}>
                            Financial health
                        </Text>
                        <View style={styles.copyContainerBlue}>
                            <View style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                                <View>
                                    <Text style={styles.whiteHeaderTextMedium}>
                                        Employer
                                    </Text>
                                    <br />
                                    <View style={styles.tableContainer}>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableRowItem}>
                                                <Text style={styles.whiteHeaderTextSmall}>Benefit</Text>
                                            </View>
                                            <View style={styles.tableRowItem}>
                                                <Text style={styles.whiteHeaderTextSmall}>Offered or Wanted</Text>
                                            </View>
                                        </View>
                                        {displayWantDesired &&
                                            wantDesired.financial.filter((item) => {
                                                return item.employerOffered > 0 || item.employerWanted > 0
                                            }).map((ben) => {
                                                return (
                                                    <View style={styles.tableRow}>
                                                        <View style={styles.tableRowItem}>
                                                            <Text style={styles.whiteHeaderTextSmaller}>
                                                                {ben.benefit}
                                                            </Text>
                                                        </View>
                                                        <View style={styles.tableRowItem}>
                                                            {
                                                                ben.employerOffered > 0 &&
                                                                <Text style={styles.whiteHeaderTextSmaller}>Offered</Text>
                                                            }
                                                            {
                                                                ben.employerWanted > 0 &&
                                                                <Text style={styles.whiteHeaderTextSmaller}>Wanted</Text>
                                                            }
                                                        </View>
                                                    </View>

                                                )
                                            })
                                        }

                                    </View>
                                </View>
                                <View style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 15 }}>
                                    <Text style={styles.whiteHeaderTextMedium}>
                                        Employees
                                    </Text>
                                    <View>
                                        {graphs.financialWantDesiredChart ? <Image src={graphs.financialWantDesiredChart} /> : null}
                                    </View>
                                </View>
                            </View>
                        </View>

                    </View>
                </Page>

                <Page size="A4" style={styles.page}>
                    <View style={styles.topSection}>
                        <View>
                            <Image src="https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png" style={styles.logo} />
                            <Text style={styles.whiteHeaderTextMedium}>
                                {`${client.Name} Benefits Report`}
                            </Text>
                            <Text style={styles.whiteHeaderTextSmall}>
                                Executive summary
                            </Text>
                        </View>
                        {/* <Image src={headerImage} style={styles.headerImages} /> */}
                    </View>
                    <View style={styles.main}>
                        <Text style={styles.blackHeaderTextMedium}>
                            Financial Health
                        </Text>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'space-evenly' }}>
                            <View style={styles.copyContainerFixed}>
                                <Text style={styles.blackHeaderTextMedium}>
                                    Findings
                                </Text>
                                {displayFindingsRecs &&
                                    <Text style={styles.blackHeaderTextSmall}>{findingsRecs.financial.findings}</Text>}
                            </View>
                            <View style={styles.copyContainerFixed}>
                                <Text style={styles.blackHeaderTextMedium}>
                                    Recommendations
                                </Text>
                                {displayFindingsRecs &&
                                    <Text style={styles.blackHeaderTextSmall}>{findingsRecs.financial.recommendations}</Text>}
                            </View>
                        </View>
                    </View>
                </Page>

                <Page size="A4" style={styles.page}>
                    <View style={styles.topSection}>
                        <View>
                            <Image src="https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png" style={styles.logo} />
                            <Text style={styles.whiteHeaderTextMedium}>
                                {`${client.Name} Benefits Report`}
                            </Text>
                            <Text style={styles.whiteHeaderTextSmall}>
                                Executive summary
                            </Text>
                        </View>
                        {/* <Image src={headerImage} style={styles.headerImages} /> */}
                    </View>
                    <View style={styles.main}>
                        <Text style={styles.blackHeaderTextMedium}>
                            Social health
                        </Text>
                        <View style={styles.copyContainerBlue}>
                            <View style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                                <View>
                                    <Text style={styles.whiteHeaderTextMedium}>
                                        Employer
                                    </Text>
                                    <br />
                                    <View style={styles.tableContainer}>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableRowItem}>
                                                <Text style={styles.whiteHeaderTextSmall}>Benefit</Text>
                                            </View>
                                            <View style={styles.tableRowItem}>
                                                <Text style={styles.whiteHeaderTextSmall}>Offered or Wanted</Text>
                                            </View>
                                        </View>
                                        {displayWantDesired &&
                                            wantDesired.social.filter((item) => {
                                                return item.employerOffered > 0 || item.employerWanted > 0
                                            }).map((ben) => {
                                                return (
                                                    <View style={styles.tableRow}>
                                                        <View style={styles.tableRowItem}>
                                                            <Text style={styles.whiteHeaderTextSmaller}>
                                                                {ben.benefit}
                                                            </Text>
                                                        </View>
                                                        <View style={styles.tableRowItem}>
                                                            {
                                                                ben.employerOffered > 0 &&
                                                                <Text style={styles.whiteHeaderTextSmaller}>Offered</Text>
                                                            }
                                                            {
                                                                ben.employerWanted > 0 &&
                                                                <Text style={styles.whiteHeaderTextSmaller}>Wanted</Text>
                                                            }
                                                        </View>
                                                    </View>

                                                )
                                            })
                                        }

                                    </View>
                                </View>
                                <View style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 15 }}>
                                    <Text style={styles.whiteHeaderTextMedium}>
                                        Employees
                                    </Text>
                                    <View>
                                        {graphs.socialWantDesiredChart ? <Image src={graphs.socialWantDesiredChart} /> : null}
                                    </View>
                                </View>
                            </View>
                        </View>

                    </View>
                </Page>

                <Page size="A4" style={styles.page}>
                    <View style={styles.topSection}>
                        <View>
                            <Image src="https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png" style={styles.logo} />
                            <Text style={styles.whiteHeaderTextMedium}>
                                {`${client.Name} Benefits Report`}
                            </Text>
                            <Text style={styles.whiteHeaderTextSmall}>
                                Executive summary
                            </Text>
                        </View>
                        {/* <Image src={headerImage} style={styles.headerImages} /> */}
                    </View>
                    <View style={styles.main}>
                        <Text style={styles.blackHeaderTextMedium}>
                            Social Health
                        </Text>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'space-evenly' }}>
                            <View style={styles.copyContainerFixed}>
                                <Text style={styles.blackHeaderTextMedium}>
                                    Findings
                                </Text>
                                {displayFindingsRecs &&
                                    <Text style={styles.blackHeaderTextSmall}>{findingsRecs.social.findings}</Text>}
                            </View>
                            <View style={styles.copyContainerFixed}>
                                <Text style={styles.blackHeaderTextMedium}>
                                    Recommendations
                                </Text>
                                {displayFindingsRecs &&
                                    <Text style={styles.blackHeaderTextSmall}>{findingsRecs.social.recommendations}</Text>}
                            </View>
                        </View>
                    </View>
                </Page>

                <Page size="A4" style={styles.page}>
                    <View style={styles.topSection}>
                        <View>
                            <Image src="https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png" style={styles.logo} />
                            <Text style={styles.whiteHeaderTextMedium}>
                                {`${client.Name} Benefits Report`}
                            </Text>
                            <Text style={styles.whiteHeaderTextSmall}>
                                Executive summary
                            </Text>
                        </View>
                        {/* <Image src={headerImage} style={styles.headerImages} /> */}
                    </View>
                    <View style={styles.main}>
                        <Text style={styles.blackHeaderTextMediumUncentered}>
                            This report is intended for the exclusive use of the
                            individual or entity to whom it is addressed and may
                            contain confidential and/or privileged information. Any
                            unauthorized review, use, disclosure, or distribution is
                            prohibited. If you are not the intended recipient, please be
                            advised that any dissemination, distribution, or copying of
                            this report is strictly prohibited and may be illegal. If you
                            have received this report in error, please notify the sender
                            immediately and destroy the original document.
                        </Text>
                    </View>
                </Page>
            </Document>
        </>

    )
};

export default BenefitsReportPdf